import { MoreOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import styled from 'styled-components'

export const DropdownTrigger = styled(Button)`
  height: fit-content;
  padding: 0;

  font-size: 30px;
  color: ${({ theme }) => theme.colors.brand};

  &.ant-btn-text:disabled {
    color: rgba(65, 66, 67, 0.25);
    cursor: not-allowed;
  }
`

export const DropdownIcon = styled(MoreOutlined)``

export const ActionButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`

import { useEffect, useState } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { Popconfirm, Form, Switch } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { toast } from 'sonner'

import * as S from '../styles'
import { CreateProprertyButton, DescriptionContainer, CustomRoleTag } from './styles'

import { BaseTable } from '@/components'
import { DEFAULT_ROLE_PERMISSIONS, type RolePermissionTableValue } from '@/features/roles'

interface EditableCellProps {
  editing: boolean
  dataIndex: string
  children: React.ReactNode
}

interface RoleFormSecondStepProps {
  setIsAddingRole: React.Dispatch<React.SetStateAction<boolean>>
}

const EditableCell = ({ editing, dataIndex, children, ...restProps }: EditableCellProps) => {
  const defaultRules = [
    {
      required: true,
      message: `Campo obrigatório`,
    },
  ]

  const descriptionRule = [
    {
      max: 120,
      message: 'O campo deve ter no máximo 120 caracteres.',
    },
  ]

  const roleValueRule = [
    {
      pattern: /^\S*$/,
      message: 'O identificador deve ser uma única palavra, sem espaços.',
    },
  ]

  const rules = [
    ...defaultRules,
    ...(dataIndex === 'description' ? descriptionRule : dataIndex === 'value' ? roleValueRule : []),
  ]

  // const rules = [...defaultRules, ...(dataIndex === 'value' ? roleValueRule : [])]

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={rules}
        >
          <S.Input />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

export const RoleFormSecondStep = ({ setIsAddingRole }: RoleFormSecondStepProps) => {
  const parentForm = Form.useFormInstance()

  const [form] = Form.useForm()
  const [data, setData] = useState(() => {
    const permissions = parentForm.getFieldValue('role_permissions')

    if (permissions) {
      return {
        count: permissions.length,
        dataSource: permissions,
      }
    }

    return { count: 4, dataSource: DEFAULT_ROLE_PERMISSIONS }
  })

  const [editingKey, setEditingKey] = useState<number | undefined>()

  useEffect(() => {
    parentForm.setFieldValue('role_permissions', data.dataSource)
  }, [data.dataSource, parentForm])

  // disable buttons from parent component when editing
  useEffect(() => {
    setIsAddingRole(!!editingKey)
  }, [editingKey, setIsAddingRole])

  const isEditing = (record: RolePermissionTableValue) => record.key === editingKey

  const edit = (record: RolePermissionTableValue) => {
    form.setFieldsValue({
      // value: '',
      // title: '',
      // description: '',
      // custom: true,
      ...record,
    })
    setEditingKey(Number(record.key))
  }

  // const handleDelete = (key) => {
  //   const dataSource = [...data.dataSource]
  //   setData({
  //     ...data,
  //     dataSource: dataSource.filter((item) => item.key !== key),
  //   })
  // }

  const cancel = (key: number) => {
    const dataSource = [...data.dataSource]

    setData({
      ...data,
      dataSource: dataSource.filter((item) => item.key !== key),
    })
    setEditingKey(undefined)
  }

  const save = async (key: number) => {
    try {
      const row = await form.validateFields()
      const newData = [...data.dataSource]
      const index = newData.findIndex((item) => key === item.key)

      const invalidValues = new Set([
        'g',
        'p',
        'u',
        'd',
        'visualizar',
        'criar',
        'editar',
        'deletar',
      ])

      const isValueInvalid =
        invalidValues.has(row.value) ||
        data.dataSource.some((item: any) => item.value.toLowerCase() === row.value.toLowerCase())

      if (isValueInvalid) {
        toast.error('Valor inválido. Utilize um valor único.')
        return
      }

      // const matchItem = index > -1

      // if (!matchItem) {
      //   newData.push(row)

      //   setData((old) => ({ ...old, dataSource: newData }))

      //   setEditingKey(undefined)
      //   return
      // }

      const item = newData[index]

      newData.splice(index, 1, { ...item, ...row })

      setData((old) => ({ ...old, dataSource: newData }))
      setEditingKey(undefined)
      return
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo)
    }
  }

  const handleChangeSwitch = (checked: boolean, record: RolePermissionTableValue) => {
    const newData = [...data.dataSource]
    const item = newData.find((item) => item.key === record.key)

    if (item) {
      item.status = checked
      setData({ ...data, dataSource: newData })
    }
  }

  const handleAdd = () => {
    const { count, dataSource } = data

    const newData = {
      key: count,
      value: '',
      title: '',
      description: '',
      custom: true,
      status: true,
    }

    setData({
      dataSource: [...dataSource, newData],
      count: count + 1,
    })

    edit(newData)
  }

  // const handleDelete = (key) => {
  //   const dataSource = [...data.dataSource]
  //   setData({
  //     ...data,
  //     dataSource: dataSource.filter((item) => item.key !== key),
  //   })
  // }

  const columns = [
    {
      title: '',
      key: 'number',
      align: 'center',
      width: 50,
      render: (_: any, __: RolePermissionTableValue, index: number) => {
        const number = String(index + 1).padStart(2, '0')

        return <span>{number}</span>
      },
    },
    {
      title: 'Titulo',
      dataIndex: 'title',
      editable: true,
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      editable: true,
      render: (description: string, record: RolePermissionTableValue) => {
        if (!record.custom) return description

        return (
          <DescriptionContainer>
            <span>{record.description}</span>
            <CustomRoleTag>Customizada</CustomRoleTag>
          </DescriptionContainer>
        )
      },
    },
    {
      title: 'Valor',
      dataIndex: 'value',
      editable: true,
      align: 'center',
      render: (value: string, record: RolePermissionTableValue) => {
        if (!record.custom) return record.title.toLowerCase()

        return <span>{value}</span>
      },
    },
    {
      title: 'Ação',
      dataIndex: 'action',
      align: 'center',
      render: (_: any, record: RolePermissionTableValue) => {
        const editable = isEditing(record)

        if (editable) {
          return (
            <span>
              <a
                onClick={() => save(Number(record.key))}
                style={{
                  marginRight: 8,
                }}
              >
                Salvar
              </a>

              <Popconfirm title="Deseja cancelar?" onConfirm={() => cancel(Number(record.key))}>
                <a>Cancelar</a>
              </Popconfirm>
            </span>
          )
        }

        return (
          <Switch
            checkedChildren="Ativo"
            unCheckedChildren="Inativo"
            checked={record.status}
            onChange={(checked) => handleChangeSwitch(checked, record)}
          />
          // <span>
          //   <Typography.Link
          //     style={{ marginRight: 15 }}
          //     disabled={!!editingKey}
          //     onClick={() => edit(record)}
          //     >
          //     Edit
          //   </Typography.Link>
          //   <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
          //   <a>Delete</a>
          // </Popconfirm>
          // </span>
        )
      },
    },
  ]
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: (record: RolePermissionTableValue) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  }) as ColumnsType<RolePermissionTableValue>

  return (
    <S.Container>
      <Form form={form} component={false}>
        <BaseTable
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data.dataSource}
          columns={mergedColumns}
        />
      </Form>

      <CreateProprertyButton
        type="dashed"
        htmlType="button"
        icon={<PlusOutlined />}
        onClick={handleAdd}
        disabled={!!editingKey}
      >
        Criar Propriedade
      </CreateProprertyButton>
    </S.Container>
  )
}

import { Button } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  .ant-transfer-list-header-selected {
    /* order: 2; */
    display: none;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;

  h3 {
    flex: 1;
    padding-left: 4px;

    font-weight: 400;
    font-size: 20px;
    color: #666;
  }
`

export const TransferTitlesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  font-size: 14px;
  color: #5f5f5f;

  strong {
    font-weight: 600;
  }
`

export const TableButton = styled(Button)`
  font-size: 16px;

  .ant-btn {
    padding: 0 !important;
    font-size: 16px;
  }
`

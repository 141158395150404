import { Button, Input as AntdInput } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .ant-form-item {
    flex: 1;
    margin: 0;

    label {
      color: #6c6c6c;
    }
  }

  .ant-select,
  .ant-select-selector {
    height: 40px !important;
    display: flex;
    align-items: center;
  }

  .ant-select-arrow {
    color: ${({ theme }) => theme.colors.brand};
  }
`

export const Input = styled(AntdInput)`
  height: 40px;
  width: 100%;
`

export const AreaTitle = styled.h3`
  font-weight: 700;
  font-size: 16px;
  padding-top: 8px;
`

export const DynamicFieldContainer = styled.div`
  height: 100%;
  max-height: 300px;
  overflow-y: auto;

  margin: 0 -24px;
`

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 24px;
`

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`

export const AddFieldButton = styled(Button)`
  &.ant-btn.ant-btn-icon-only {
    height: 40px !important;
    width: 40px !important;

    border-radius: 9999999px;
    font-size: 14px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const RemoveFieldButton = styled(AddFieldButton)``

import { useState } from 'react'
import { BsQuestionLg } from 'react-icons/bs'

import { Button, Form, Steps, Tooltip } from 'antd'
import { toast } from 'sonner'

import {
  CreateRoleHelpDrawer,
  RoleHelpContentFirstStep,
  RoleHelpContentSecondStep,
  RoleHelpContentThirdStep,
} from '../../Drawer'
import { RoleFormFirstStep, RoleFormSecondStep, RoleFormThirdStep } from '../../Form'
import * as S from './styles'

import { useToggle } from '@/common'
import { BaseModal } from '@/components'
import type { SelectedRoleValue, RoleFormValues } from '@/features/roles'
import { formatRoleValue, formatRolePermissions, useCreateRoleMutation } from '@/features/roles'

interface ICreateRoleModalProps {
  isOpen: boolean
  onClose: () => void
}

export const CreateRoleModal = ({ isOpen, onClose }: ICreateRoleModalProps) => {
  const [form] = Form.useForm()
  const createRoleMutation = useCreateRoleMutation()

  const [currentStep, setCurrentStep] = useState(0)
  const [formValues, setFormValues] = useState<RoleFormValues>({} as RoleFormValues)
  const [isHelpDrawerOpen, toggleHelpDrawer] = useToggle(false)
  const [isAddingRole, setIsAddingRole] = useState(false)

  async function handleAdvanceStep() {
    try {
      await form.validateFields()

      const permissions: SelectedRoleValue[] = form.getFieldValue('role_permissions')

      if (currentStep === 1 && permissions) {
        const isValid: boolean = permissions.map((perm: any) => perm.status).some(Boolean)

        if (!isValid) {
          return toast.error('Ative pelo menos uma propriedade da permissão')
        }
      }

      const values = {
        ...form.getFieldsValue(),
        role_permissions: permissions,
      }

      setFormValues((old) => ({ ...old, ...values }))
      setCurrentStep((old) => old + 1)
    } catch (err) {
      console.error('Error advancing role step', err)
    }
  }

  function handleReturnStep() {
    if (currentStep === 2) {
      const systemModuleId = form.getFieldValue('system_module_id')

      setFormValues((old) => ({ ...old, system_module_id: systemModuleId }))
    }

    setCurrentStep((old) => old - 1)
  }

  async function handleSubmit({
    system_module_id,
  }: {
    system_module_id: { value: string; label: string }
  }) {
    try {
      await form.validateFields()

      const payload = {
        ...formValues,
        role_value: formatRoleValue(formValues.role_value),
        role_permissions: formatRolePermissions((formValues as any).role_permissions),
        system_module_id: system_module_id.value,
      }

      const promise = createRoleMutation.mutateAsync(payload)

      toast.promise(promise, {
        loading: 'Criando permissão...',
        success: 'Permissão criada com sucesso',
        error: 'Erro ao criar permissão',
      })

      await promise

      onClose()
    } catch (err) {
      console.error('Error creating role', err)
    }
  }

  const steps = [
    {
      title: 'Dados da permissão',
      info: {
        title: 'Dados da permissão',
        description: 'Defina nome e descrição da permisão',
      },
      content: <RoleFormFirstStep />,
      help: <RoleHelpContentFirstStep />,
    },
    {
      title: 'Propriedades da permissão',
      info: {
        title: 'Propriedades da permissão',
        description: 'Crie, ative ou inative as propriedades da permissão',
      },
      content: <RoleFormSecondStep setIsAddingRole={setIsAddingRole} />,
      help: <RoleHelpContentSecondStep />,
    },
    {
      title: 'Vincular sistema',
      info: {
        title: 'Vincular sistemas a permissão',
        description: 'Vincule a permissão ao sistema',
      },
      content: <RoleFormThirdStep formValues={formValues} />,
      help: <RoleHelpContentThirdStep />,
    },
  ]

  return (
    <BaseModal
      title="Criar permissão"
      open={isOpen}
      onCancel={onClose}
      width={currentStep > 0 ? 1000 : 740}
      footer={
        <>
          {currentStep === 0 ? (
            <Button onClick={onClose} type="link">
              Cancelar
            </Button>
          ) : (
            <Button
              type="link"
              onClick={handleReturnStep}
              disabled={createRoleMutation.isPending || isAddingRole}
            >
              Voltar
            </Button>
          )}

          {currentStep < 2 ? (
            <Button type="primary" onClick={handleAdvanceStep} disabled={isAddingRole}>
              Próximo
            </Button>
          ) : (
            <Button
              type="primary"
              onClick={() => form.submit()}
              loading={createRoleMutation.isPending}
            >
              Finalizar
            </Button>
          )}
        </>
      }
    >
      {isHelpDrawerOpen && (
        <CreateRoleHelpDrawer open={isHelpDrawerOpen} onClose={toggleHelpDrawer}>
          {steps[currentStep].help}
        </CreateRoleHelpDrawer>
      )}

      <S.Container>
        <Steps current={currentStep} items={steps} />

        <S.StepInfoContainer>
          <h3>{steps[currentStep].info.title}</h3>
          <span>{steps[currentStep].info?.description}</span>
        </S.StepInfoContainer>

        <Form
          form={form}
          requiredMark
          layout="vertical"
          name="create-role"
          onFinish={handleSubmit}
          disabled={createRoleMutation.isPending}
        >
          {steps[currentStep].content}
        </Form>

        <S.HelpContainer>
          <S.HelpButton type="link" onClick={toggleHelpDrawer}>
            Dúvidas com o preenchimento do Cadastro?
          </S.HelpButton>

          <Tooltip title="Clique no texto ao lado para instruções sobre como preencher o formulário.">
            <S.TooltipHelpIcon>
              <BsQuestionLg />
            </S.TooltipHelpIcon>
          </Tooltip>
        </S.HelpContainer>
      </S.Container>
    </BaseModal>
  )
}

import { Navigate, useSearchParams } from 'react-router-dom'

import { Space, Typography } from 'antd'
import { toast } from 'sonner'

import { useAuthStore } from '@/app'
import { PageTitle } from '@/components'
import {
  ResetFirstPasswordForm,
  ResetFirstPasswordFormData,
  useChangeFirstPasswordMutation,
} from '@/features/auth'
import { AccessLayout } from '@/layouts'

export const FirstLoginPage = () => {
  const changeTempPasswordMutation = useChangeFirstPasswordMutation()
  const userSession = useAuthStore((state) => state.tokens.session)
  const [params] = useSearchParams()

  const username = params.get('username')

  if (!userSession || !username) {
    toast.error('Sessão invalida')

    return <Navigate to="/auth/login" replace />
  }

  function handleChangePassword({ password }: ResetFirstPasswordFormData) {
    changeTempPasswordMutation.mutate({
      username: username as string,
      session: userSession as string,
      password,
    })
  }

  return (
    <AccessLayout>
      <PageTitle title={'Registrar senha'} />

      <Space size="middle" direction="vertical">
        <Typography.Text>
          Você está acessando o sistema pela primeira vez. Por favor, registre uma nova senha.
        </Typography.Text>

        <ResetFirstPasswordForm
          onSubmit={handleChangePassword}
          isLoading={changeTempPasswordMutation.isPending}
        />
      </Space>
    </AccessLayout>
  )
}

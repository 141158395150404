import { Navigate, Outlet } from 'react-router-dom'

import { useAuthStore } from '..'

import { useQueryClient } from '@tanstack/react-query'

import { clearAllCaches } from '@/common'

export const PrivateRoute = () => {
  const queryClient = useQueryClient()
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated)

  if (!isAuthenticated) {
    clearAllCaches(queryClient)
    return <Navigate to="/auth/login" replace />
  }

  return <Outlet />
}

import { FileDoneOutlined, WarningOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useTheme } from 'styled-components'

import { useSystemFilters } from '@/app'
import { type TableAuthenticatedFeatures, formatDate } from '@/common'
import { BaseTable, TableAction, TableActionButton, TableColumnButton } from '@/components'
import { System } from '@/features/systems'
import { SelectSystemAction } from '@/pages'

interface SystemsListTableProps extends TableAuthenticatedFeatures {
  data: System[]
  isLoading?: boolean
  handleSelectSystem: ({ type, systemPk }: SelectSystemAction) => void
}

export const SystemsListTable = ({
  data,
  isLoading,
  handleSelectSystem,
  canDelete,
}: SystemsListTableProps) => {
  const theme = useTheme()
  const setActiveSorting = useSystemFilters((state) => state.setActiveSorting)

  function confirmDisable(system: System) {
    Modal.confirm({
      title: `Tem certeza que deseja excluir este sistema?`,
      content: (
        <span>
          O sistema <strong>{system.system_name}</strong> será excluído permanentemente.
        </span>
      ),
      centered: true,
      icon: <WarningOutlined style={{ color: '#FF0000' }} />,
      okText: 'Excluir',
      cancelText: 'Cancelar',
      okButtonProps: {
        type: 'primary',
        style: {
          color: '#FFF',
          backgroundColor: theme.colors.brand,
        },
      },
      cancelButtonProps: {
        type: 'default',
      },
      onOk: () => handleSelectSystem({ systemPk: system.pk, type: 'DELETE_SYSTEM' }),
    })
  }

  const systemColumns: ColumnsType<System> = [
    {
      title: 'nº',
      key: 'number',
      align: 'center',
      width: 50,
      render: (_, __, index) => {
        const number = String(index + 1).padStart(2, '0')

        return <span>{number}</span>
      },
    },
    {
      title: 'Nome do sistema',
      dataIndex: 'system_name',
      key: 'system_name',
      width: 225,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (name, system) => (
        <TableColumnButton
          onClick={() => handleSelectSystem({ systemPk: system.pk, type: 'VIEW_SYSTEM' })}
        >
          {name}
        </TableColumnButton>
      ),
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Área',
      dataIndex: ['system_area', 'area_name'],
      key: 'area_name',
      width: 200,
    },
    {
      title: 'Criado em',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (date) => formatDate({ date }),
    },
    {
      title: 'Atualizado em',
      dataIndex: 'updated_at',
      key: 'updated_at',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (date) => formatDate({ date }),
    },
    {
      title: 'Ações',
      key: 'actions',
      width: 100,
      align: 'center',
      render: (_, system) => {
        const isActive = system.active

        const actions = [
          {
            key: '1',
            label: (
              <TableActionButton
                type="link"
                onClick={() => handleSelectSystem({ systemPk: system.pk, type: 'VIEW_SYSTEM' })}
                icon={<FileDoneOutlined />}
              >
                Dados cadastrais
              </TableActionButton>
            ),
          },
          canDelete
            ? {
                key: '2',
                label: (
                  <TableActionButton danger onClick={() => confirmDisable(system)}>
                    Excluir
                  </TableActionButton>
                ),
              }
            : null,
        ]

        return <TableAction actions={actions} disabled={!isActive} />
      },
    },
  ]

  return (
    <BaseTable
      dataSource={data}
      columns={systemColumns}
      loading={isLoading}
      rowKey={(system: System) => system.pk}
      setActiveSorting={setActiveSorting}
    />
  )
}

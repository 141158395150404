import { useState } from 'react'
import AuthCode from 'react-auth-code-input'

import { Button, Form } from 'antd'

import * as S from './styles'

export type MfaCodeFormData = {
  code: string
}

type MFACodeFormProps = {
  onSubmit: (data: MfaCodeFormData) => void
  isLoading: boolean
}

export const MFACodeForm = ({ onSubmit, isLoading }: MFACodeFormProps) => {
  const [form] = Form.useForm<MfaCodeFormData>()
  const [hasError, setHasError] = useState(false)

  return (
    <S.Container $hasError={hasError}>
      <Form
        layout="vertical"
        onFinish={onSubmit}
        form={form}
        onError={(e) => console.log(e)}
        disabled={isLoading}
      >
        <Form.Item
          name="code"
          validateTrigger="onSubmit"
          data-testid="mfa-form-code"
          rules={[
            () => ({
              validator(_, value) {
                if (value === undefined) {
                  setHasError(true)
                  return Promise.reject(new Error('Insira um codigo'))
                }

                if (value?.length < 6) {
                  setHasError(true)
                  return Promise.reject(new Error('Insira um codigo valido'))
                }

                setHasError(false)
                return Promise.resolve()
              },
            }),
          ]}
        >
          <AuthCode
            containerClassName="input-container"
            inputClassName="mfa-input"
            onChange={(code) => code}
          />
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          block
          loading={isLoading}
          data-testid="mfa-form-submit"
        >
          Enviar
        </Button>
      </Form>
    </S.Container>
  )
}

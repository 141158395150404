import { Button } from 'antd'

import type { RoleFilters } from '../../types'
import { RoleFilterForm } from '../Form'

import { useRoleFilterStore } from '@/app'
import { useFilterActions, useFormatFilters } from '@/common'
import type { BaseFilterSidebarProps } from '@/common'
import { FilterSidebar } from '@/components'

export const RoleFilterSidebar = ({ isOpen, toggleDrawer, form }: BaseFilterSidebarProps) => {
  const setRoleFilters = useRoleFilterStore((s) => s.setFilters)
  const resetFilters = useRoleFilterStore((s) => s.resetFilters)
  const resetRolePagination = useRoleFilterStore((s) => s.resetPagination)
  const { submitFilters, handleClearFilters } = useFilterActions(form)
  const { formatDateFieldFilter, formatNormalFields, formatRelationalFields } = useFormatFilters()

  function handleFilter() {
    const values = form.getFieldsValue()

    const filters: RoleFilters = {}

    const normalFields = ['role_value']

    const relationalFields = {
      groups: 'group_name',
      system: 'system_name',
    }

    formatDateFieldFilter(values, filters)
    formatNormalFields(values, filters, normalFields)
    formatRelationalFields(values, filters, relationalFields)

    setRoleFilters({ ...filters })
    resetRolePagination()
  }

  return (
    <FilterSidebar
      isDrawerOpen={isOpen}
      toggleDrawer={toggleDrawer}
      filterFooter={
        <>
          <Button onClick={() => handleClearFilters(resetFilters, toggleDrawer)}>Limpar</Button>
          <Button type="primary" htmlType="submit" onClick={() => submitFilters(toggleDrawer)}>
            Filtrar
          </Button>
        </>
      }
    >
      <RoleFilterForm form={form} onSubmit={handleFilter} />
    </FilterSidebar>
  )
}

import type { FormInstance } from 'antd'

import { FilterComparator } from '../types'

type ToggleFunction = () => void
type StoreCallbackFunction = () => void
type StoreSetterFunction<T> = (filters: T) => void

type HandleSearchFilter<T> = {
  searchValue: string
  storeCallback: StoreSetterFunction<T>
  filterKey: string
  comparator?: FilterComparator
  resetPagination: () => void
}

export const useFilterActions = (form: FormInstance<any>) => {
  function submitFilters(toggleDrawer?: ToggleFunction) {
    try {
      form.submit()
      toggleDrawer && toggleDrawer()
    } catch (error) {
      console.error('Error submitting filters:', error)
    }
  }

  function handleClearFilters(storeCallback: ToggleFunction, toggleDrawer?: StoreCallbackFunction) {
    try {
      form.resetFields()
      storeCallback()

      toggleDrawer && toggleDrawer()
    } catch (error) {
      console.error('Error in clearing filters', error)
    }
  }

  function handleSearchFilter<T>({
    searchValue,
    storeCallback,
    filterKey,
    comparator = 'like',
    resetPagination,
  }: HandleSearchFilter<T>) {
    try {
      storeCallback({
        [filterKey]: {
          value: searchValue.trim(),
          comparator,
        },
      } as T)

      resetPagination()
    } catch (error) {
      console.error('Error in with search filter', error)
    }
  }

  return { submitFilters, handleClearFilters, handleSearchFilter }
}

import type { RolePermissionTableValue } from '../types'

export const DEFAULT_ROLE_PERMISSIONS: RolePermissionTableValue[] = [
  {
    key: 0,
    value: 'g',
    title: 'Visualizar',
    description: 'Visualização de itens na interface, listagens, tabela de dados e etc.',
    status: true,
    custom: false,
  },
  {
    key: 1,
    value: 'p',
    title: 'Criar',
    description: 'Permite acessar todas as features de criação no sistema.',
    status: true,
    custom: false,
  },
  {
    key: 2,
    value: 'u',
    title: 'Editar',
    description: 'Permite acessar e editar features do sistema.',
    status: true,
    custom: false,
  },
  {
    key: 3,
    value: 'd',
    title: 'Deletar',
    description: 'Permite deletar itens e dados no sistema.',
    status: true,
    custom: false,
  },
]

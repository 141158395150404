import { Collapse as CollapseAntd, Skeleton } from 'antd'
import { Divider } from 'antd'
import styled from 'styled-components'

export const PageTitle = styled.div`
  padding-left: 8px;
`

export const CollapseDivider = styled(Divider)`
  .ant-divider-inner-text {
    font-size: 24px;
    font-weight: 700;
    padding-inline-start: 8px !important;
  }
`

export const ConfigCardsContainer = styled.div`
  /* display: flex;
  flex-wrap: wrap;
  padding-left: 40px;
  gap: 24px; */

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(144px, 1fr));
  gap: 24px;
  grid-template-rows: auto;
  padding-left: 40px;
`

export const ConfigCard = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 136px;
  max-height: 144px;
  padding: 20px 0 16px;

  border-radius: 6px;
  border: 2px solid #ededed;
  background-color: transparent;

  cursor: pointer;
  transition: all 0.2s ease-in-out;

  svg {
    font-size: 48px;
    color: #5f5f5f;
  }

  .ant-divider {
    margin: 20px 0 16px;
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.linkHover};
  }
`

export const CardTitle = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.brand};
  text-transform: capitalize;
`

export const LinkCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: 40px;
  gap: 24px;

  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(192px, 1fr));
  gap: 24px;
  grid-template-rows: auto;
  padding-left: 40px; */
`

export const LinkCard = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 192px;
  width: fit-content;
  height: auto;

  padding: 28px;

  border-radius: 6px;
  border: 2px solid #ededed;
  background-color: transparent;

  color: ${({ theme }) => theme.colors.brand};
  font-size: 20px;
  font-weight: 700;

  white-space: nowrap;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.brand};
    border-color: ${({ theme }) => theme.colors.linkHover};
  }
`

export const SystemLinkCard = styled(LinkCard)`
  flex-direction: column;
  gap: 4px;

  text-transform: capitalize;

  span {
    color: #434343;
    font-size: 14px;
    font-weight: 400;
  }
`

export const Collapse = styled(CollapseAntd)`
  width: 100%;

  .ant-collapse-header {
    display: flex;
    align-items: center !important;
    padding: 16px 0 !important;
  }

  .ant-collapse-expand-icon {
    color: ${({ theme }) => theme.colors.brand};
    width: 24px;
  }

  .ant-collapse-arrow {
    font-size: 24px !important;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }
`

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const SkeletonHeader = styled(Skeleton.Button)`
  margin-top: 16px;
`

import InputMask from 'react-input-mask'

import { Form, Input } from 'antd'

import * as S from './styles'

export const CompanyForm = () => {
  return (
    <S.FormContainer>
      <S.Title>Dados empresariais.</S.Title>

      <S.FormRow>
        <Form.Item
          name="company_name"
          label="Nome da empresa"
          rules={[
            {
              required: true,
              message: 'O nome da empresa é obrigatório',
            },
          ]}
        >
          <Input placeholder="Ex: ACER LTDA" />
        </Form.Item>

        <Form.Item
          name="company_fancy_name"
          label="Nome fantasia"
          rules={[
            {
              required: true,
              message: 'O nome fantasia é obrigatório',
            },
          ]}
        >
          <Input placeholder="Ex: ACER" />
        </Form.Item>
      </S.FormRow>

      <S.FormRow>
        <Form.Item
          name="company_cnpj"
          label="CNPJ da empresa"
          rules={[
            {
              required: true,
              message: 'O CNPJ é obrigatório',
            },
          ]}
        >
          <InputMask mask={'99.999.999/9999-99'} maskPlaceholder={''}>
            <Input placeholder="EX: 123456789/0001-23" />
          </InputMask>
        </Form.Item>

        <Form.Item
          name="manager_name"
          label="Nome do responsável"
          rules={[
            {
              required: true,
              message: 'O nome do responsável é obrigatório',
            },
          ]}
        >
          <Input placeholder="Ex: John Doe" />
        </Form.Item>
      </S.FormRow>

      <S.FormRow>
        <Form.Item
          name="manager_phone"
          label="Telefone do responsável"
          rules={[
            {
              required: true,
              message: 'O telefone do responsável é obrigatório',
            },
          ]}
        >
          <InputMask mask={'+55 (99) 99999-9999'} maskPlaceholder={''}>
            <Input placeholder="Ex: +55 (99) 99999-9999" />
          </InputMask>
        </Form.Item>

        <Form.Item
          name="manager_email"
          label="E-mail do responsável"
          rules={[
            {
              required: true,
              message: 'O e-mail do responsável é obrigatório',
            },
            {
              type: 'email',
              message: 'O e-mail do responsável é inválido',
            },
          ]}
        >
          <Input type="email" placeholder="Ex: john-doe@email.com" />
        </Form.Item>
      </S.FormRow>
    </S.FormContainer>
  )
}

import { UserOutlined } from '@ant-design/icons'
import { Button, Form } from 'antd'

import * as S from './styles'

export type ForgotPasswordFormData = {
  email: string
}

type ForgotPasswordFormProps = {
  onSubmit: (data: ForgotPasswordFormData) => void
  isLoading: boolean
}

export const ForgotPasswordForm = ({ onSubmit, isLoading }: ForgotPasswordFormProps) => {
  return (
    <S.Container>
      <Form layout="vertical" onFinish={onSubmit} disabled={isLoading} validateTrigger="onSubmit">
        <Form.Item
          name="email"
          data-testid="forgot-form-email"
          rules={[
            {
              required: true,
              message: 'E-mail é obrigatório',
            },
            {
              type: 'email',
              message: 'E-mail inválido',
            },
          ]}
        >
          <S.Input prefix={<UserOutlined />} placeholder="E-mail" />
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          block
          loading={isLoading}
          data-testid="forgot-form-submit"
        >
          Enviar código
        </Button>
      </Form>
    </S.Container>
  )
}

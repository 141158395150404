import { Layout } from 'antd'
import styled from 'styled-components'

type ContentContainerProps = {
  $isListPage: boolean
}

export const Container = styled(Layout)`
  min-height: 100vh;
  height: 100%;
`

export const SidebarContainer = styled(Layout)`
  height: 100%;
  flex-direction: row;

  .ant-layout-sider {
    background-color: #fff;
  }

  .ant-layout-content {
    width: 0;
  }
`

export const ContentContainer = styled(Layout)<ContentContainerProps>`
  padding: 24px;

  max-height: ${({ $isListPage }) =>
    $isListPage
      ? 'calc(100vh - 90px)'
      : '100%'}; // 50px is the height of the header, 40px is the heigh of the Acer Header, 50 + 40 = 90

  .ant-layout-content {
    width: 100%;
  }
`

export const Content = styled(Layout.Content)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

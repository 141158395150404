export const contractTypeOptions = [
  {
    value: 'CLT',
    label: 'CLT',
  },
  {
    value: 'PJ',
    label: 'PJ',
  },
  {
    value: 'Outros',
    label: 'Outros',
  },
  {
    value: 'Terceiros',
    label: 'Terceiros',
  },
]

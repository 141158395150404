import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Form } from 'antd'
import { shallow } from 'zustand/shallow'

import { useUserLogFilter } from '@/app'
import { getActiveFiltersList, removeActiveFilter, useFilterActions, useToggle } from '@/common'
import { ActiveFiltersList, CustomPagination, PageHeader } from '@/components'
import {
  UserLogsFilterSidebar,
  UserLogsListTable,
  ViewUserLogModal,
  userLogsFiltersRemap,
  userLogsQueries,
} from '@/features/users'
import type { LogUser, UserLogFilters } from '@/features/users'
import { PageLayout } from '@/layouts/PageLayout'

export type SelectUserLogAction = {
  log: LogUser
  type?: 'VIEW_LOG'
}

export const UserLogsPage = () => {
  const [form] = Form.useForm()
  const { handleClearFilters } = useFilterActions(form)

  const {
    userLogFilters,
    activeSorting,
    userLogPagination,
    setFilters,
    setUserLogPagination,
    resetFilters,
  } = useUserLogFilter(
    (s) => ({
      userLogFilters: s.filters,
      activeSorting: s.activeSorting,
      userLogPagination: s.pagination,
      setFilters: s.setFilters,
      setUserLogPagination: s.setPagination,
      resetFilters: s.resetFilters,
    }),
    shallow,
  )

  const {
    data: logs,
    isLoading,
    isFetching,
  } = useQuery(userLogsQueries.list(userLogFilters, activeSorting, userLogPagination))

  const [isViewModalOpen, toggleViewModal] = useToggle(false)
  const [isFilterDrawerOpen, toggleFilterDrawer] = useToggle(false)

  const [selectedUserLog, setSelectedLog] = useState('')

  function handleRemoveFilter(filterKey: keyof UserLogFilters, filterValue?: string) {
    const updatedFilters = removeActiveFilter({
      activeFilters: userLogFilters,
      filterKey,
      filterValue,
      form,
    })

    setFilters({ ...updatedFilters })
  }

  function handleSelectLog(logPk: string) {
    setSelectedLog(logPk)
    toggleViewModal()
  }

  return (
    <PageLayout
      isListPage
      title="Logs"
      sidebar={
        <UserLogsFilterSidebar
          form={form}
          isOpen={isFilterDrawerOpen}
          toggleDrawer={toggleFilterDrawer}
        />
      }
    >
      {isViewModalOpen && (
        <ViewUserLogModal
          logPk={selectedUserLog}
          isOpen={isViewModalOpen}
          onClose={toggleViewModal}
        />
      )}

      <PageHeader
        title="Logs de usuários"
        itemsFound={logs?.info.total || 0}
        isLoading={isLoading || isFetching}
      />

      {getActiveFiltersList(userLogFilters).length > 0 ? (
        <ActiveFiltersList
          filterList={getActiveFiltersList(userLogFilters)}
          removeFilter={handleRemoveFilter}
          valueRemap={userLogsFiltersRemap}
          clearFilters={() => handleClearFilters(resetFilters)}
        />
      ) : null}

      <UserLogsListTable
        data={logs?.results || []}
        isLoading={isLoading}
        handleSelectLog={handleSelectLog}
      />

      <CustomPagination
        scrollToTop
        isLoading={isLoading}
        page={logs?.info.current_page || userLogPagination.page}
        pageSize={userLogPagination.per_page}
        totalItems={logs?.info.total}
        totalPages={logs?.info.pages || 0}
        setPagination={setUserLogPagination}
      />
    </PageLayout>
  )
}

import { Button } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const StepInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  color: #434343;

  h3 {
    font-weight: 700;
    font-size: 16px;
  }

  span {
    font-size: 14px;
  }
`

export const HelpContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 24px;
`

export const HelpButton = styled(Button)`
  padding: 0;
  display: flex;
  align-items: center;
`

export const TooltipHelpIcon = styled.div`
  padding: 1px;
  font-size: 14px;
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 99999999px;

  background-color: ${({ theme }) => theme.colors.brand};
  color: ${({ theme }) => theme.colors.white};
`

import { useMutation } from '@tanstack/react-query'
import { jwtDecode } from 'jwt-decode'

import { useAuthStore } from '@/app'
import { api } from '@/services'

interface RegisterMfaMutation {
  code: string
}

export const registerMfaErrorMessages = new Map([['Code mismatch', 'Código inválido']])

export const useRegisterMfaMutation = () => {
  const accessToken = useAuthStore((state) => state.tokens.accessToken)

  let decodedUsername = ''

  if (accessToken) {
    decodedUsername = jwtDecode<{ username: string }>(accessToken).username
  }

  return useMutation({
    mutationFn: async ({ code }: RegisterMfaMutation) => {
      return api.post('/auth/verify-software-token-mfa', {
        username: decodedUsername,
        access_token: accessToken,
        mfa_code: code,
      })
    },
  })
}

import { Button } from 'antd'

import type { CompanyFilters } from '../../types'
import { ComapnyFilterForm } from '../Form'

import { useCompaniesFilter } from '@/app'
import { useFilterActions, useFormatFilters, type BaseFilterSidebarProps } from '@/common'
import { FilterSidebar } from '@/components'

export const CompanyFilterSidebar = ({ isOpen, toggleDrawer, form }: BaseFilterSidebarProps) => {
  const setCompanyFilters = useCompaniesFilter((s) => s.setFilters)
  const resetFilters = useCompaniesFilter((s) => s.resetFilters)
  const resetCompanyPagination = useCompaniesFilter((s) => s.resetPagination)
  const { submitFilters, handleClearFilters } = useFilterActions(form)
  const { formatDateFieldFilter, formatNormalFields, formatMaskedFields } = useFormatFilters()

  function handleFilter() {
    const values = form.getFieldsValue()

    const fields = ['company_name', 'manager_name', 'manager_email']
    const maskedFields = ['company_cnpj', 'manager_phone']

    const filters: CompanyFilters = {}

    formatDateFieldFilter(values, filters)
    formatNormalFields(values, filters, fields)
    formatMaskedFields(values, filters, maskedFields)

    setCompanyFilters({ ...filters })
    resetCompanyPagination()
  }

  return (
    <FilterSidebar
      isDrawerOpen={isOpen}
      toggleDrawer={toggleDrawer}
      filterFooter={
        <>
          <Button onClick={() => handleClearFilters(resetFilters, toggleDrawer)}>Limpar</Button>
          <Button type="primary" htmlType="submit" onClick={() => submitFilters(toggleDrawer)}>
            Filtrar
          </Button>
        </>
      }
    >
      <ComapnyFilterForm form={form} onSubmit={handleFilter} />
    </FilterSidebar>
  )
}

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { ICreateCompanyMutation } from '.'

import { companyQueries } from '..'

import { api } from '@/services'

interface EditCompanyMutation extends ICreateCompanyMutation {
  pk: string
}

export const useEditCompanyMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pk, ...rest }: EditCompanyMutation) => {
      return api.put(`/company/${pk}`, rest)
    },
    onMutate: async (editedCompany) => {
      await queryClient.cancelQueries({ queryKey: companyQueries.all() })

      const cachedCompany = queryClient.getQueryData(
        companyQueries.detail(editedCompany.pk).queryKey,
      )

      if (cachedCompany) {
        queryClient.setQueryData(companyQueries.detail(editedCompany.pk).queryKey, {
          ...cachedCompany,
          ...editedCompany,
        })
      }

      const cachedCompaniesList = queryClient.getQueryData(companyQueries.list().queryKey)

      if (cachedCompaniesList) {
        const updatedCompaniesList = cachedCompaniesList.results.map((company) => {
          if (company.pk === editedCompany.pk) {
            return {
              ...company,
              ...editedCompany,
            }
          }

          return company
        })

        queryClient.setQueryData(companyQueries.list().queryKey, {
          ...cachedCompaniesList,
          results: updatedCompaniesList,
        })
      }

      return { cachedCompaniesList, cachedCompany }
    },
    onError: (err, _, ctx) => {
      console.error(err)

      if (ctx?.cachedCompany) {
        queryClient.setQueryData(
          companyQueries.detail(ctx.cachedCompany.pk).queryKey,
          ctx.cachedCompany,
        )
      }

      if (ctx?.cachedCompaniesList) {
        queryClient.setQueryData(companyQueries.list().queryKey, ctx.cachedCompaniesList)
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: companyQueries.all() }),
  })
}

import { useEffect } from 'react'

import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Form, Input } from 'antd'

import * as S from './styles'

type SystemPage = {
  page: string
  link: string
}

interface CreateSystemFormSecondStepProps {
  systemPages: SystemPage[] | undefined
}

export const CreateSystemFormSecondStep = ({ systemPages }: CreateSystemFormSecondStepProps) => {
  const form = Form.useFormInstance()

  useEffect(() => {
    if (systemPages && systemPages.length > 0) {
      form.setFieldsValue({
        system_pages: systemPages,
      })
    } else {
      form.setFieldsValue({
        system_pages: [
          {
            page: '',
            link: '',
          },
        ],
      })
    }
  }, [form, systemPages])

  return (
    <S.Container>
      <S.AreaTitle>Cadastrar páginas</S.AreaTitle>

      <S.FormRow>
        <Form.Item
          name="default_system_page"
          label="Página padrão do sistema"
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Campo obrigatório',
            },
          ]}
        >
          <Input placeholder="Ex: Dashboard" />
        </Form.Item>

        <Form.Item
          name="default_system_link"
          label="Link da página padrão (URL)"
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Campo obrigatório',
            },
            {
              type: 'url',
              message: 'Insira uma URL válida',
            },
          ]}
        >
          <Input placeholder="Ex: https://acer-rental.com.br/dashboard" />
        </Form.Item>

        <S.PlaceholderButton />
      </S.FormRow>

      <S.DynamicFieldContainer>
        <S.FieldsContainer>
          <Form.List name="system_pages">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => {
                  const isFirstItem = fields.indexOf(field) === 0
                  const pageInputInitialValues =
                    systemPages && systemPages.length > 0 && systemPages?.[field.key]?.page

                  const linkInputInitialValues =
                    systemPages && systemPages.length > 0 && systemPages?.[field.key]?.link

                  return (
                    <S.FormRow key={field.key}>
                      <Form.Item
                        label="Página do sistema"
                        {...field}
                        name={[field.name, 'page']}
                        fieldId={`${field.key}-page`}
                        initialValue={pageInputInitialValues || ''}
                      >
                        <Input placeholder="Ex: Logs" defaultValue={pageInputInitialValues || ''} />
                      </Form.Item>

                      <S.Field>
                        <Form.Item>
                          <Form.Item
                            {...field}
                            label="Link da página"
                            name={[field.name, 'link']}
                            fieldId={`${field.key}-link`}
                            initialValue={linkInputInitialValues || ''}
                          >
                            <Input
                              placeholder="Ex: https://acer-rental.com.br/logs"
                              defaultValue={linkInputInitialValues || ''}
                            />
                          </Form.Item>
                        </Form.Item>

                        {isFirstItem && (
                          <S.AddFieldButton
                            type="primary"
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                          />
                        )}

                        {!isFirstItem && fields.length >= 1 && (
                          <S.RemoveFieldButton
                            onClick={() => remove(field.name)}
                            icon={<MinusOutlined />}
                          />
                        )}
                      </S.Field>
                    </S.FormRow>
                  )
                })}
              </>
            )}
          </Form.List>
        </S.FieldsContainer>
      </S.DynamicFieldContainer>
    </S.Container>
  )
}

import { Button } from 'antd'

import type { GroupFilters } from '../../types'
import { GroupsFilterForm } from '../Form'

import { useGroupFilters } from '@/app'
import { useFilterActions, useFormatFilters } from '@/common'
import type { BaseFilterSidebarProps } from '@/common'
import { FilterSidebar } from '@/components'

export const GroupsFilterSidebar = ({ isOpen, toggleDrawer, form }: BaseFilterSidebarProps) => {
  const setGroupFilters = useGroupFilters((s) => s.setFilters)
  const resetGroupFilters = useGroupFilters((s) => s.resetFilters)
  const resetGroupPagination = useGroupFilters((s) => s.resetPagination)
  const { submitFilters, handleClearFilters } = useFilterActions(form)
  const { formatDateFieldFilter, formatRelationalFields } = useFormatFilters()

  function handleFilter() {
    const values = form.getFieldsValue()

    const filters: GroupFilters = {}

    const relationalFields = {
      users: 'name',
      roles: 'title',
      systems: 'system_name',
    }

    formatDateFieldFilter(values, filters)
    formatRelationalFields(values, filters, relationalFields)

    setGroupFilters({ ...filters })
    resetGroupPagination()
  }

  return (
    <FilterSidebar
      isDrawerOpen={isOpen}
      toggleDrawer={toggleDrawer}
      filterFooter={
        <>
          <Button onClick={() => handleClearFilters(resetGroupFilters, toggleDrawer)}>
            Limpar
          </Button>
          <Button type="primary" htmlType="submit" onClick={() => submitFilters(toggleDrawer)}>
            Filtrar
          </Button>
        </>
      }
    >
      <GroupsFilterForm form={form} onSubmit={handleFilter} />
    </FilterSidebar>
  )
}

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { systemQueries } from '..'

import type { SystemLink, SystemModule, SystemArea } from '..'

import { api } from '@/services'

type CreateSystemLink = Omit<SystemLink, 'id' | 'system_pk'>
type CreateSystemModule = Pick<SystemModule, 'module_name'>

interface CreateSystemMutation {
  area_pk: string
  system_name: string
  description: string
  system_links: Array<CreateSystemLink>
  system_modules: Array<CreateSystemModule>
}

export const useCreateSystemMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: CreateSystemMutation) => {
      return api.post('/system', data)
    },
    onMutate: (data) => {
      queryClient.cancelQueries({ queryKey: systemQueries.all() })

      const cachedSystems = queryClient.getQueryData(systemQueries.list().queryKey)

      if (cachedSystems) {
        const updatedListResults = [
          ...cachedSystems.results,
          {
            pk: crypto.randomUUID(),
            system_name: data.system_name,
            description: data.description,
            system_links: data.system_links as unknown as SystemLink[],
            system_modules: data.system_modules as unknown as SystemModule[],
            system_area: {} as SystemArea,
            area_pk: '',
            active: true,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
          },
        ]

        queryClient.setQueryData(systemQueries.list().queryKey, {
          ...cachedSystems,
          results: updatedListResults,
        })
      }

      return cachedSystems
    },
    onError: (error, _, cachedSystems) => {
      console.error(error)

      if (cachedSystems) {
        queryClient.setQueryData(systemQueries.list().queryKey, cachedSystems)
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: systemQueries.lists() }),
  })
}

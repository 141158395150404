import { AxiosError } from 'axios'

export const errorMessages = [
  {
    pattern: 'An account with the given email already exists.',
    message: 'Uma conta com o email fornecido já existe.',
  },
  {
    pattern: /^User 'email' must be a valid email. \nError: The domain name (.*?) does not exist.$/,
    message: "E-mail '{email}' deve ser um e-mail válido. O domínio não existe.",
  },
]

export function getCreateUserErrorMessage(error: AxiosError<{ message: string }>) {
  const defaultMessage = 'Erro ao criar usuário'

  const foundMessage = errorMessages.find((msg) => {
    if (msg.pattern instanceof RegExp) {
      return msg.pattern.test(error.response?.data.message as string)
    }

    return msg.pattern === error.response?.data.message
  })

  const isEmailError = foundMessage && foundMessage.pattern instanceof RegExp

  // If the error is related to email, we can extract the email from the error message
  if (isEmailError) {
    const matches = error.response?.data.message.match(foundMessage.pattern)

    // matches[1] will contain the 'email' part in the error message due to the group (.*?) used in the pattern
    const emailUsed = matches && matches[1]
    if (emailUsed) {
      return foundMessage.message.replace('{email}', emailUsed)
    }
  }

  return foundMessage ? foundMessage.message : defaultMessage
}

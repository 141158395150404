import { Divider, Tooltip } from 'antd'

import * as S from './styles'

interface TableDataTooltopProps<T extends Item> {
  data: T[]
  firstItem: string
  listTitle: string
  nameKey: string
}

interface Item {
  [key: string]: any
  pk: string
}

export const TableDataTooltip = <T extends Item>({
  data,
  firstItem,
  listTitle,
  nameKey,
}: TableDataTooltopProps<T>) => {
  const hasMultiple = data.length > 1

  if (!data.length) return <S.EmptyValue>-</S.EmptyValue>

  return (
    <S.Container>
      <S.TitleButton type={hasMultiple ? 'link' : 'text'} htmlType="button">
        {firstItem}
      </S.TitleButton>

      {hasMultiple && (
        <Tooltip
          color="#FFF"
          arrow={{
            pointAtCenter: true,
          }}
          placement="left"
          overlayInnerStyle={{
            minWidth: 180,
            borderRadius: 4,
            padding: '0 0 8px',
            width: 'fit-content',
            maxHeight: '300px',
            overflowY: 'auto',
          }}
          overlayStyle={{
            boxShadow:
              'box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12)',
          }}
          title={
            <S.List>
              <h3>{listTitle}</h3>

              <Divider />

              {data.map((item, index) => (
                <S.Data key={item.pk}>
                  {index + 1}. {item[nameKey]}
                </S.Data>
              ))}
            </S.List>
          }
        >
          <S.Tooltip>+{data.length - 1}</S.Tooltip>
        </Tooltip>
      )}
    </S.Container>
  )
}

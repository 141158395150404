import { useNavigate } from 'react-router-dom'

import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from 'sonner'

import { api } from '@/services'

interface IUseChangeForgotPasswordMutation {
  username: string
  password: string
  confirmation_code: string
}

const errorMessages = new Map([
  [
    'Invalid verification code provided, please try again.',
    'Código de verificação inválido, por favor, tente novamente.',
  ],
])

export const useChangeForgotPasswordMutation = () => {
  const navigate = useNavigate()

  return useMutation({
    mutationFn: async (data: IUseChangeForgotPasswordMutation) => {
      return api.post('/no-auth/auth/change-forgot-password', data)
    },
    onSuccess: () => {
      toast.success('Senha alterada com sucesso!')

      return navigate('/auth')
    },
    onError: (error: AxiosError<{ message: string }>) => {
      console.error(error)

      const errorMessage =
        errorMessages.get(error.response?.data.message as string) ||
        'Erro ao alterar senha!, Por favor, tente novamente.'

      toast.error(errorMessage)
    },
  })
}

import { useEffect, useState } from 'react'

import { DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Button, Form, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'

import * as S from '../styles'

import { useToggle } from '@/common'
import { GroupSelectedSystems, GroupSystemModule } from '@/features/groups'
import { type System, systemQueries, type SystemModule } from '@/features/systems'

interface Props {
  initialValues?: GroupSelectedSystems[]
  initialAdmins?: any[]
}

interface GroupAdminTableData extends System {
  children: SystemModule[]
}

type DeselectSystem = {
  key: string
  label: string
  value: string
}

export const GroupFormSecondStep = ({ initialValues, initialAdmins }: Props) => {
  const { data: systems, isLoading } = useQuery(
    systemQueries.list(undefined, undefined, {
      page: 1,
      per_page: 0,
    }),
  )
  const form = Form.useFormInstance()

  const [isSelectDropdownOpen, toggleSelectDropdown] = useToggle(false)
  const [selectedSystems, setSelectedSystems] = useState<GroupSelectedSystems[]>(
    initialValues || [],
  )

  const [selectedAdmins, setSelectedAdmins] = useState<
    Array<GroupSelectedSystems | GroupSystemModule>
  >(() => {
    if (initialValues && !initialAdmins) {
      const systemAdmins = initialValues
        .map((system) => {
          if (system.is_admin) {
            return system
          }
          return undefined
        })
        .filter(Boolean) as GroupSelectedSystems[]

      const adminModules = initialValues.map((system) => system.admin_modules).flat()

      const admins = [...systemAdmins, ...adminModules]

      form.setFieldValue('group_admin', admins)
      return admins
    }

    // Used when creating a group (not used while editing)
    if (initialAdmins) {
      return initialAdmins
    }

    return []
  })

  // reset systems select after mutating
  useEffect(() => {
    return () => {
      form.resetFields(['systems'])
    }
  }, [form])

  const selectOptions = systems?.results.map((system) => ({
    value: system.pk,
    key: system.pk,
    label: system.system_name,
    title: undefined,
    disabled: undefined,
  })) as GroupSelectedSystems[]

  const handleSelectSystem = (selectedSystems: GroupSelectedSystems[]) => {
    setSelectedSystems(selectedSystems)

    form.setFieldValue('group_systems', selectedSystems)
    toggleSelectDropdown()
  }

  const deselectAdmins = (deselectedSystem: DeselectSystem) => {
    const isSelectedAsAdmin = selectedAdmins.some(
      (admin) =>
        (admin as GroupSelectedSystems).value === deselectedSystem.value ||
        (admin as GroupSystemModule).system_pk === deselectedSystem.value,
    )

    if (isSelectedAsAdmin) {
      const updatedAdmins = selectedAdmins.filter(
        (admin) =>
          (admin as GroupSelectedSystems).value !== deselectedSystem.value &&
          (admin as GroupSystemModule).system_pk !== deselectedSystem.value,
      )

      setSelectedAdmins(updatedAdmins)
      form.setFieldValue('group_admin', updatedAdmins)
    }
  }

  const handleDeselectOutside = (item: GroupSelectedSystems) => {
    const updatedSystems = selectedSystems.filter((system) => system.value !== item.value)

    const updatedSelectedAdmins =
      selectedAdmins.length > 0
        ? updatedSystems
            .map((system) => {
              if (system.is_admin) {
                return system
              } else {
                const adminModules = system?.admin_modules?.filter((module) => module?.is_admin)
                console.log({ adminModules })
                return adminModules
              }
            })
            .flat()
            .filter(Boolean)
        : []

    // console.log({ updatedSystems, updatedSelectedAdmins, selectedAdmins })

    form.setFieldValue('group_systems', updatedSystems)
    form.setFieldValue('systems', updatedSystems)
    form.setFieldValue('group_admin', updatedSelectedAdmins)
    setSelectedSystems(updatedSystems)
  }

  function getAdminTableData() {
    const tableData = systems?.results
      .map((system) => {
        // check if system is selected
        const isSelected = selectedSystems.some(
          (selectedSystem) => selectedSystem.value === system.pk,
        )

        if (isSelected) {
          return {
            ...system,
            children: system.system_modules,
          }
        }

        return undefined
      })
      .filter(Boolean)

    return tableData as GroupAdminTableData[]
  }

  const tableColumns: ColumnsType<GroupAdminTableData> = [
    {
      title: '',
      dataIndex: '',
      key: '',
      width: 10,
    },
    {
      title: 'Sistema',
      dataIndex: 'system_name',
      render: (_: any, record: GroupAdminTableData | SystemModule) => {
        if ((record as GroupAdminTableData).children) {
          return <span>{(record as GroupAdminTableData).system_name}</span>
        }

        if ((record as SystemModule).module_name) {
          return <span>{(record as SystemModule).module_name}</span>
        }
      },
    },
  ]

  return (
    <S.Container>
      <Form.Item
        initialValue={initialValues}
        name="systems"
        label="Sistemas cadastrados"
        rules={[
          {
            required: true,
            message: 'Selecione ao menos um sistema',
          },
        ]}
      >
        <S.SystemsSelect
          labelInValue
          value={selectedSystems}
          onChange={(selectedSystems) =>
            handleSelectSystem(selectedSystems as GroupSelectedSystems[])
          }
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          onDeselect={(deselectedSystem) => deselectAdmins(deselectedSystem as DeselectSystem)}
          loading={isLoading}
          mode="multiple"
          maxTagCount={0}
          options={selectOptions}
          suffixIcon={<SearchOutlined onClick={toggleSelectDropdown} />}
          open={isSelectDropdownOpen}
          onDropdownVisibleChange={toggleSelectDropdown}
        />
      </Form.Item>

      {selectedSystems && selectedSystems?.length > 0 && (
        <>
          <S.SelectedSystemsContainer>
            {selectedSystems && selectedSystems?.length > 0 && <span>Sistemas selecionados:</span>}

            <S.SelectedSystems>
              {selectedSystems?.map((system) => (
                <S.System key={system.value}>
                  {system.label}
                  <Button
                    type="link"
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeselectOutside(system)}
                  />
                </S.System>
              ))}
            </S.SelectedSystems>
          </S.SelectedSystemsContainer>

          <S.SelectAdminContainer>
            <S.ContainerTitle>
              <h3>
                Dados do administrador <span>(opcional)</span>
              </h3>

              <span>
                Selecione os módulos dentro do sistema para ser um administrador do módulo ou
                selecione o sistema para ser um administrador geral. Caso torne o grupo
                administrador, não será possível vincular as permissões.
              </span>
            </S.ContainerTitle>

            <Form.Item name="group_admin">
              <Table
                size="small"
                columns={tableColumns}
                dataSource={getAdminTableData()}
                pagination={false}
                rowKey={(record: any) => (record as System)?.pk || (record as SystemModule)?.id}
                loading={isLoading}
                rowSelection={{
                  hideSelectAll: true,
                  selectedRowKeys: selectedAdmins.map(
                    (admin) =>
                      (admin as unknown as System)?.pk ||
                      (admin as GroupSystemModule)?.id ||
                      (admin as GroupSelectedSystems)?.key,
                  ),
                  onChange: (_, selectedRows) => {
                    setSelectedAdmins(selectedRows)
                    form.setFieldValue('group_admin', selectedRows)
                  },
                }}
                expandable={{
                  defaultExpandAllRows: initialValues && initialValues.length > 0 ? true : false,
                }}
              />
            </Form.Item>
          </S.SelectAdminContainer>
        </>
      )}
    </S.Container>
  )
}

import { useEffect } from 'react'

import type { TitleTabItem } from '../types'

import { useRolesStore } from '@/app'

export const useAuth = () => {
  const decodedRoles = useRolesStore((state) => state.decodedRoles)
  const userAllowedModules = useRolesStore((state) => state.allowedModules)
  const isSystemAdmin = useRolesStore((state) => state.isSystemAdmin)
  const getDecodedRoles = useRolesStore((state) => state.getDecodedRoles)
  const getPagePermissions = useRolesStore((state) => state.getPagePermissions)

  useEffect(() => {
    if (!decodedRoles) {
      getDecodedRoles()
    }
  }, [decodedRoles, getDecodedRoles])

  function formatPagePermissions(permissions: string[] | undefined) {
    let isAdmin = false
    let canCreate = false
    let canEdit = false
    let canDelete = false

    if (permissions && permissions.length > 0) {
      isAdmin = isSystemAdmin || permissions.includes('*')

      canCreate = isAdmin || permissions.includes('p')
      canEdit = isAdmin || permissions.includes('u')
      canDelete = isAdmin || permissions.includes('d')
    }

    return { isAdmin, canCreate, canEdit, canDelete }
  }

  function formatTitleTabItems(tabItems: TitleTabItem[]) {
    const formattedTabItems = tabItems.filter((tab) => {
      if (isSystemAdmin) {
        return true
      }

      if (
        userAllowedModules?.includes('*') ||
        userAllowedModules?.some((role) => tab.allowedRoles.includes(role))
      ) {
        return true
      }

      return false
    })

    return formattedTabItems
  }

  return {
    decodedRoles,
    userAllowedModules,
    getPagePermissions,
    formatPagePermissions,
    formatTitleTabItems,
  }
}

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { parameterQueries } from '../queries'

import { api } from '@/services'

type DeleteParameterMutation = {
  parameterPk: string
}

export const useDeleteParameterMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ parameterPk }: DeleteParameterMutation) => {
      return api.delete(`/parameter/${parameterPk}`)
    },
    onMutate: async ({ parameterPk }) => {
      await queryClient.cancelQueries({ queryKey: parameterQueries.all() })

      const cachedParametersList = queryClient.getQueryData(parameterQueries.list().queryKey)

      if (cachedParametersList) {
        const updatedParameter = cachedParametersList.results.filter(
          (parameter) => parameter.pk !== parameterPk,
        )

        queryClient.setQueryData(parameterQueries.list().queryKey, {
          ...cachedParametersList,
          results: updatedParameter,
        })
      }
      return cachedParametersList
    },

    onError: (error, _, cachedParameter) => {
      console.error(error)

      if (cachedParameter) {
        queryClient.setQueryData(parameterQueries.list().queryKey, cachedParameter)
      }
    },

    onSettled: () => queryClient.invalidateQueries({ queryKey: parameterQueries.all() }),
  })
}

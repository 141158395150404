import { useMutation, useQueryClient } from '@tanstack/react-query'

import { UserFormData, User, userQueries, UserSituation } from '../..'

import { api } from '@/services'

interface EditUserMutation extends UserFormData {
  userId: string
}

export const useEditUserMutation = () => {
  const queryClient = useQueryClient()

  const mockValues = {
    nickname: '',
    status: '',
    last_sign_in: '',
    created_at: '',
    updated_at: '',
    user_groups: [],
    enabled: true,
    active: true,
    user_situation: 'Ativo' as UserSituation,
  }

  return useMutation({
    mutationFn: async (data: EditUserMutation) => {
      return api.put(`/user/${data.userId}`, data)
    },
    onMutate: async (editedUser) => {
      await queryClient.cancelQueries({ queryKey: userQueries.all() })

      const cachedUser = queryClient.getQueryData<User>(
        userQueries.detail(editedUser.userId).queryKey,
      )

      if (cachedUser) {
        queryClient.setQueryData(userQueries.detail(editedUser.userId).queryKey, {
          ...cachedUser,
          ...editedUser,
        })
      }

      const cachedUsersList = queryClient.getQueryData(userQueries.list().queryKey)

      if (cachedUsersList) {
        const updatedUsersList = cachedUsersList.results.map((user) => {
          if (user.id === editedUser.userId) {
            return {
              ...mockValues,
              ...editedUser,
              id: editedUser.userId,
            }
          }

          return user
        })

        queryClient.setQueryData(userQueries.list().queryKey, {
          ...cachedUsersList,
          results: updatedUsersList,
        })
      }

      return { cachedUsersList, cachedUser }
    },
    onError: (err, _, ctx) => {
      console.error(err)

      if (ctx?.cachedUser) {
        queryClient.setQueryData<User>(
          userQueries.detail(ctx.cachedUser.id).queryKey,
          ctx.cachedUser,
        )
      }

      if (ctx?.cachedUsersList) {
        queryClient.setQueryData(userQueries.list().queryKey, ctx.cachedUsersList)
      }
    },
    onSettled: (_, __, { userId }) => {
      queryClient.invalidateQueries(userQueries.list())
      queryClient.invalidateQueries(userQueries.detail(userId))
    },
  })
}

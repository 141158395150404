import { useNavigate } from 'react-router-dom'

import { useMutation } from '@tanstack/react-query'
import { toast } from 'sonner'

import { api } from '@/services'

interface IForgotPasswordMutation {
  email: string
}

export const useForgotPasswordCodeMutation = () => {
  const navigate = useNavigate()

  return useMutation({
    mutationFn: async ({ email }: IForgotPasswordMutation) => {
      return api.post(`/no-auth/auth/forgot-password/${email}`)
    },
    onSuccess: (_, { email }) => {
      toast.success('Código de recuperação enviado para o e-mail com sucesso!')

      const encodedEmail = encodeURIComponent(email)

      return navigate(`/auth/reset-password?email=${encodedEmail}`)
    },
    onError: (error) => {
      console.error(error)

      toast.error('Erro ao enviar código de recuperação!')
    },
  })
}

import { useReducer } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Button, Form } from 'antd'
import { toast } from 'sonner'
import { shallow } from 'zustand/shallow'

import * as S from './styles'

import { useParameterFilters } from '@/app'
import {
  getActiveFiltersList,
  pagePermissionsKeys,
  removeActiveFilter,
  useAuth,
  useFilterActions,
  useToggle,
} from '@/common'
import { ActiveFiltersList, CustomPagination, PageHeader, SearchInput } from '@/components'
import {
  Parameter,
  ParameterFilterSidebar,
  ParameterFilters,
  ParametersListTable,
  parameterQueries,
  useDeleteParameterMutation,
} from '@/features/parameters'
import { CreateParameterModal, ViewParameterModal } from '@/features/parameters/components/Modal'
import { PageLayout } from '@/layouts/PageLayout'

export type SelectParameterAction = {
  parameter: Parameter
  type: 'DELETE_PARAMETER' | 'VIEW_PARAMETER'
}

export const ParametersPage = () => {
  const [form] = Form.useForm()

  const { handleClearFilters, handleSearchFilter } = useFilterActions(form)

  const { getPagePermissions, formatPagePermissions } = useAuth()

  const pagePerms = getPagePermissions({
    moduleKey: pagePermissionsKeys.parameters.module,
    roleKey: pagePermissionsKeys.parameters.role,
  })

  const { isAdmin, canCreate, canDelete, canEdit } = formatPagePermissions(pagePerms)

  const {
    parameterFilters,
    activeSorting,
    parameterPagination,
    setFilters,
    setParameterPagination,
    resetFilters,
    resetParameterPagination,
  } = useParameterFilters(
    (s) => ({
      parameterFilters: s.filters,
      activeSorting: s.activeSorting,
      parameterPagination: s.pagination,
      setFilters: s.setFilters,
      setParameterPagination: s.setPagination,
      resetFilters: s.resetFilters,
      resetParameterPagination: s.resetPagination,
    }),
    shallow,
  )

  const {
    data: parameters,
    isLoading,
    isFetching,
  } = useQuery(parameterQueries.list(parameterFilters, activeSorting, parameterPagination))

  const deleteParameterMutation = useDeleteParameterMutation()

  const [isCreateParameterModalOpen, toggleCreateParameterModal] = useToggle(false)
  const [isViewModalOpen, toggleViewModal] = useToggle(false)
  const [isFilterDrawerOpen, toggleFilterDrawer] = useToggle(false)

  const [selectedParameter, selectedParameterDispatch] = useReducer(
    (_: Parameter, action: SelectParameterAction) => {
      switch (action.type) {
        case 'DELETE_PARAMETER':
          handleDeleteParameter(action.parameter)
          return action.parameter
        case 'VIEW_PARAMETER':
          toggleViewModal()
          return action.parameter
        default:
          return {} as Parameter
      }
    },
    {} as Parameter,
  )

  async function handleDeleteParameter(parameter: Parameter) {
    if (deleteParameterMutation.isPending) return

    const promise = deleteParameterMutation.mutateAsync({
      parameterPk: parameter.pk,
    })

    toast.promise(promise, {
      loading: 'Excluindo parâmetro...',
      success: 'Parâmetro excluído com sucesso!',
      error: 'Erro ao excluir parâmetro!',
    })
  }

  function handleRemoveFilter(filterKey: keyof ParameterFilters, filterValue?: string) {
    const updatedFilters = removeActiveFilter({
      activeFilters: parameterFilters,
      filterKey,
      filterValue,
      form,
    })

    setFilters({ ...updatedFilters })
  }

  return (
    <PageLayout
      isListPage
      title="Parâmetros"
      sidebar={
        <ParameterFilterSidebar
          isOpen={isFilterDrawerOpen}
          toggleDrawer={toggleFilterDrawer}
          form={form}
        />
      }
    >
      <PageHeader
        title="Lista de parâmetros"
        // actions={}
        itemsFound={parameters?.info.total || 0}
        isLoading={isLoading || isFetching}
      />

      {isCreateParameterModalOpen && (
        <CreateParameterModal
          isOpen={isCreateParameterModalOpen}
          onClose={toggleCreateParameterModal}
        />
      )}

      {isViewModalOpen && (
        <ViewParameterModal
          data={selectedParameter}
          isOpen={isViewModalOpen}
          onClose={toggleViewModal}
          canEdit={isAdmin || canEdit}
        />
      )}

      <S.FiltersAndActionsContainer>
        <ActiveFiltersList
          filterList={getActiveFiltersList(parameterFilters)}
          removeFilter={handleRemoveFilter}
          clearFilters={() => handleClearFilters(resetFilters)}
        />

        <S.HeaderActionsContainer>
          <SearchInput
            placeholder="Nome do parametro"
            initialValue={parameterFilters?.parameter_name?.value}
            onSearch={(searchValue) =>
              handleSearchFilter({
                searchValue,
                storeCallback: setFilters,
                filterKey: 'parameter_name',
                resetPagination: resetParameterPagination,
              })
            }
            onClose={() => setFilters({ parameter_name: undefined })}
          />

          {pagePerms && (isAdmin || canCreate) && (
            <Button type="primary" icon={<PlusOutlined />} onClick={toggleCreateParameterModal}>
              Novo Parâmetro
            </Button>
          )}
        </S.HeaderActionsContainer>
      </S.FiltersAndActionsContainer>

      <ParametersListTable
        data={parameters?.results || []}
        isLoading={isLoading}
        handleSelectParameter={selectedParameterDispatch}
        canDelete={isAdmin || canDelete}
      />

      <CustomPagination
        scrollToTop
        page={parameters?.info.current_page || parameterPagination.page}
        isLoading={isLoading}
        pageSize={parameters?.info.per_page || parameterPagination.per_page}
        totalItems={parameters?.info.total}
        totalPages={parameters?.info.pages || 0}
        setPagination={setParameterPagination}
      />
    </PageLayout>
  )
}

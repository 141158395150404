import { FormInstance } from 'antd'

interface RemoveActiveFilterProps<T> {
  activeFilters: T
  filterKey: keyof T
  filterValue?: string
  form: FormInstance
}

export function removeActiveFilter<T extends Record<string, any>>({
  activeFilters,
  filterKey,
  filterValue,
  form,
}: RemoveActiveFilterProps<T>) {
  const updatedFilters = { ...activeFilters }

  const isArray = Array.isArray(updatedFilters[filterKey])

  if (filterKey === 'user_situation') {
    ;(updatedFilters as Record<string, any>)['active'] = undefined as unknown as T[keyof T]
    ;(updatedFilters as Record<string, any>)['enabled'] = undefined as unknown as T[keyof T]
    updatedFilters[filterKey] = undefined as unknown as T[keyof T]
  } else if (isArray) {
    const currentFilterList = updatedFilters[filterKey] as string[]

    if (currentFilterList.length <= 0) {
      delete updatedFilters[filterKey]
      return updatedFilters
    }

    const updatedFilterList = currentFilterList.filter((filter) => filter !== filterValue)

    updatedFilters[filterKey] = updatedFilterList as unknown as T[keyof T]
  } else {
    updatedFilters[filterKey] = undefined as unknown as T[keyof T]
  }

  form?.setFieldsValue({ [filterKey]: updatedFilters[filterKey] }) // Directly update form fields
  return updatedFilters
}

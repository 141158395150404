import dayjs from 'dayjs'

import { FilterComparator } from '@/common/types'

type Dates = [dayjs.Dayjs | null, dayjs.Dayjs | null]

export function handleDateFilters(dates: Dates): {
  value: string | string[]
  comparator: FilterComparator
} {
  const filteredDates = dates.filter(Boolean) as dayjs.Dayjs[]

  const formattedDates = filteredDates.map((date, i, arr) => {
    const isMultipleDates = arr.length > 1

    if (isMultipleDates) {
      const isLastDate = i === arr.length - 1

      if (isLastDate) {
        return date.endOf('day').format('YYYY-MM-DDTHH:mm:ss')
      }
    }

    return date.startOf('day').format('YYYY-MM-DDTHH:mm:ss')
  })

  // use greater than or between based on the number of dates
  const comparator = filteredDates.length === 2 ? 'between' : 'ge'

  const [start, end] = formattedDates

  let value

  if (formattedDates.length == 1) {
    value = start || end
  } else {
    value = formattedDates
  }

  return {
    value,
    comparator,
  }
}

import { Button } from 'antd'

import { useUserFilters } from '@/app'
import { useFilterActions, useFormatFilters, type BaseFilterSidebarProps } from '@/common'
import { FilterSidebar } from '@/components'
import { UserFilterForm, type UserFilters } from '@/features/users'

export const UserFilterSidebar = ({ isOpen, toggleDrawer, form }: BaseFilterSidebarProps) => {
  const setUserFilters = useUserFilters((s) => s.setFilters)
  const resetUserFilters = useUserFilters((s) => s.resetFilters)
  const resetUserPagination = useUserFilters((s) => s.resetPagination)
  const { submitFilters, handleClearFilters } = useFilterActions(form)
  const { formatDateFieldFilter, formatRelationalFields, formatNormalFields, formatMaskedFields } =
    useFormatFilters()

  function handleFilter() {
    const values = form.getFieldsValue()

    const normalFields = ['name', 'email', 'empresa', 'tipo_contrato', 'groups', 'user_situation']

    const maskedFields = ['cpf', 'phone_number']

    const relationalFields = {
      groups: 'group_name',
    }

    const filters: UserFilters = {}

    formatDateFieldFilter(values, filters)
    formatNormalFields(values, filters, normalFields)
    formatMaskedFields(values, filters, maskedFields)
    formatRelationalFields(values, filters, relationalFields)

    setUserFilters({ ...filters })
    resetUserPagination()
  }

  return (
    <FilterSidebar
      isDrawerOpen={isOpen}
      toggleDrawer={toggleDrawer}
      filterFooter={
        <>
          <Button onClick={() => handleClearFilters(resetUserFilters, toggleDrawer)}>Limpar</Button>
          <Button type="primary" htmlType="submit" onClick={() => submitFilters(toggleDrawer)}>
            Filtrar
          </Button>
        </>
      }
    >
      <UserFilterForm form={form} onSubmit={handleFilter} />
    </FilterSidebar>
  )
}

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { userQueries } from '../../queries'

import { api } from '@/services'

type UpdateUserStatusMutation = {
  userId: string
  enabled: boolean
}

export const useUpdateUserStatusMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ userId, enabled }: UpdateUserStatusMutation) => {
      return api.put(`/user/set-access/${userId}`, {
        enabled,
      })
    },
    onMutate: async ({ userId, enabled }) => {
      await queryClient.cancelQueries({ queryKey: userQueries.all() })

      const cachedUsers = queryClient.getQueryData(userQueries.list().queryKey)

      if (cachedUsers) {
        const updatedUsers = cachedUsers.results.map((user) => {
          if (user.id === userId) {
            return { ...user, enabled }
          }

          return user
        })

        queryClient.setQueryData(userQueries.list().queryKey, {
          ...cachedUsers,
          results: updatedUsers,
        })
      }

      return cachedUsers
    },
    onError: (err, __, cachedUsers) => {
      console.error('Error updating user status', err)

      if (cachedUsers) {
        queryClient.setQueryData(userQueries.list().queryKey, cachedUsers)
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: userQueries.all() }),
  })
}

import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const StepDescription = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: #bfbfbf;
`

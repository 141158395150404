import { Tabs as AntdTabs } from 'antd'
import styled from 'styled-components'

export const Tabs = styled(AntdTabs)<{ $isCentered: boolean }>`
  width: 100%;

  .ant-tabs-nav {
    align-items: flex-end;
    justify-content: space-between;
    margin: 0;
  }

  .ant-tabs-nav .ant-tabs-nav-wrap {
    flex: ${({ $isCentered }) => !$isCentered && '0 0 auto'};
  }

  .ant-tabs-tab {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    font-family: ${({ theme }) => theme.base.fontFamily};
    color: ${({ theme }) => theme.base.colorPrimary};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.customTokens.tab.selectedColor};
  }

  .ant-tabs-ink-bar {
    height: 1px !important;
    background: ${({ theme }) => theme.customTokens.tab.selectedColor} !important;
  }

  .ant-tabs-nav::before {
    border-bottom: transparent !important;
  }

  .ant-tabs-extra-content {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    min-height: 40px;
  }
`

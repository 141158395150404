import { keepPreviousData, queryOptions } from '@tanstack/react-query'

import type { System, SystemFilters, SystemHomeInfo } from '..'

import type { ActiveSorting, PaginationExpression, ListResponse, FilterValue } from '@/common'
import {
  DEFAULT_ACTIVE_SORTING,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGINATION,
  getFilterExpression,
  handleActiveSorting,
} from '@/common'
import { api } from '@/services'

const fetchSystemsList = async (
  filters: SystemFilters,
  sorting: ActiveSorting,
  pagination: PaginationExpression,
) => {
  // TODO: Fix types to avoid type casting
  const filterExpression = getFilterExpression(filters as unknown as Record<string, FilterValue>)

  const activeSorting = handleActiveSorting(sorting)

  const { data } = await api.post<ListResponse<System[]>>('/system/list', {
    filter_expression: filterExpression,
    pagination_expression: {
      page: pagination.page || DEFAULT_PAGE,
      per_page: pagination.per_page === undefined ? DEFAULT_PAGE_SIZE : pagination.per_page,
    },
    order_by_expression: activeSorting,
  })

  return data
}

async function fetchSystem(systemPk: string) {
  const { data } = await api.get<System>(`/system/${systemPk}`)

  return data
}

async function fetchHomeInfo() {
  const { data } = await api.get<SystemHomeInfo>('/system/get/home_info')

  return data
}

export const systemQueries = {
  all: () => ['systems'],
  lists: () => [...systemQueries.all(), 'list'],
  list: (
    filters: SystemFilters = {},
    sorting: ActiveSorting = DEFAULT_ACTIVE_SORTING,
    pagination: PaginationExpression = DEFAULT_PAGINATION,
  ) =>
    queryOptions({
      queryKey: [...systemQueries.lists(), filters, sorting, pagination],
      queryFn: () => fetchSystemsList(filters, sorting, pagination),
      placeholderData: keepPreviousData,
    }),
  details: () => [...systemQueries.all(), 'details'],
  detail: (systemPk: string) =>
    queryOptions({
      queryKey: [...systemQueries.details(), systemPk],
      queryFn: () => fetchSystem(systemPk),
      placeholderData: keepPreviousData,
    }),
  homeInfo: () =>
    queryOptions({
      queryKey: [...systemQueries.all(), 'homeInfo'],
      queryFn: fetchHomeInfo,
    }),
}

import { useQuery } from '@tanstack/react-query'
import { jwtDecode } from 'jwt-decode'

import { useAuthStore } from '@/app'

import { mfaQueries } from '..'

export const useUserMfaStatus = () => {
  const idToken = useAuthStore((state) => state.tokens.idToken)

  const decodedToken = jwtDecode<{ email: string }>(idToken as string)

  const email = decodedToken.email

  return useQuery(mfaQueries.checkMfa(email))
}

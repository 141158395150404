import { useMutation, useQueryClient } from '@tanstack/react-query'

import { System, SystemLink, SystemModule, systemQueries } from '..'

import { api } from '@/services'

export interface EditSystemMutation {
  pk: string
  system_name: string
  description: string
  system_links: SystemLink[]
  system_modules: SystemModule[]
}

export const useEditSystemMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: EditSystemMutation) => {
      return api.put(`/system/${data.pk}`, data)
    },
    onMutate: async (editedSystem) => {
      await queryClient.cancelQueries({ queryKey: systemQueries.all() })

      const cachedSystem = queryClient.getQueryData(systemQueries.detail(editedSystem.pk).queryKey)

      if (cachedSystem) {
        queryClient.setQueryData<System>(systemQueries.detail(editedSystem.pk).queryKey, {
          ...cachedSystem,
          ...editedSystem,
        })
      }

      const cachedSystemsList = queryClient.getQueryData(systemQueries.list().queryKey)

      if (cachedSystemsList) {
        const updatedSystems = cachedSystemsList.results.map((system) => {
          if (system.pk === editedSystem.pk) {
            return {
              ...system,
              ...editedSystem,
            }
          }

          return system
        })

        queryClient.setQueryData(systemQueries.list().queryKey, {
          ...cachedSystemsList,
          results: updatedSystems,
        })
      }

      return { cachedSystemsList, cachedSystem }
    },
    onError: (err, _, ctx) => {
      console.error(err)

      if (ctx?.cachedSystem) {
        queryClient.setQueryData(
          systemQueries.detail(ctx.cachedSystem.pk).queryKey,
          ctx.cachedSystem,
        )
      }

      if (ctx?.cachedSystemsList) {
        queryClient.setQueryData(systemQueries.list().queryKey, ctx.cachedSystemsList)
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: systemQueries.all() }),
  })
}

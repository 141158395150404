import type { NotificationCategory } from '../types'

export function translateNotificationMessage(message: string, category: NotificationCategory) {
  if (category === 'INACTIVITY') {
    const hasBeenInactivatedRegex = /User '(.+)' has been inactivated\./
    const willBeInactivatedRegex = /User '(.+)' will be inactivated in (\d+) days\./

    const hasBeenInactivatedMatch = message.match(hasBeenInactivatedRegex)

    if (hasBeenInactivatedMatch) {
      const email = hasBeenInactivatedMatch[1]
      return `O usuário ${email} foi desativado.`
    }

    const willBeInactivatedMatch = message.match(willBeInactivatedRegex)

    if (willBeInactivatedMatch) {
      const email = willBeInactivatedMatch[1]
      const days = willBeInactivatedMatch[2]
      return `O usuário ${email} será desativado em ${days} dias.`
    }
  }

  if (category === 'CREATE_SYSTEM' || category === 'CREATE_USER') {
    const registerType = category === 'CREATE_SYSTEM' ? 'sistema' : 'usuário'

    return `Um novo ${registerType} foi cadastrado`
  }

  return ''
}

import { BrowserRouter, Route, Routes as ReactRoutes, Navigate } from 'react-router-dom'

import { AuthenticatedRoute } from './AuthenticatedRoute'
import { PrivateRoute } from './PrivateRoute'
import { PublicRoute } from './PublicRoute'

import {
  ForgotPasswordPage,
  LoginPage,
  ResetPasswordPage,
  FirstLoginPage,
  CodeAuthenticationPage,
  RegisterMFAPage,
  HomePage,
  AreasPage,
  SystemsPage,
  UsersPage,
  CompaniesPage,
  PermissionsPage,
  UserLogsPage,
  GroupsPage,
  ParametersPage,
} from '@/pages'

export const Routes = () => {
  return (
    <BrowserRouter>
      <ReactRoutes>
        {/* PUBLIC ROUTES */}
        <Route path="/auth" element={<PublicRoute />}>
          <Route index element={<Navigate to="login" replace={false} />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="reset-password" element={<ResetPasswordPage />} />
          <Route path="new-password" element={<FirstLoginPage />} />
          <Route path="code" element={<CodeAuthenticationPage />} />
        </Route>

        {/* PRIVATE ROUTES */}
        <Route path="/" element={<PrivateRoute />}>
          <Route index element={<Navigate to="/home" replace={false} />} />
          <Route path="/home" element={<HomePage />} />

          <Route path="/register-mfa" element={<RegisterMFAPage />} />

          {/* <Route path="/areas" element={<AreasPage />} /> */}
          <Route path="/areas" element={<AuthenticatedRoute allowedRoles={['areas']} />}>
            <Route index element={<AreasPage />} />
          </Route>

          {/* <Route path="/systems" element={<SystemsPage />} /> */}
          <Route path="/systems" element={<AuthenticatedRoute allowedRoles={['sistemas']} />}>
            <Route index element={<SystemsPage />} />
          </Route>

          {/* <Route path="/users" element={<UsersPage />} /> */}
          <Route path="/users" element={<AuthenticatedRoute allowedRoles={['usuarios']} />}>
            <Route index element={<UsersPage />} />
          </Route>

          {/* <Route path="/companies" element={<CompaniesPage />} /> */}
          <Route path="/companies" element={<AuthenticatedRoute allowedRoles={['empresas']} />}>
            <Route index element={<CompaniesPage />} />
          </Route>

          {/* <Route path="/permissions" element={<PermissionsPage />} /> */}
          <Route path="/permissions" element={<AuthenticatedRoute allowedRoles={['permissoes']} />}>
            <Route index element={<PermissionsPage />} />
          </Route>

          {/* <Route path="/logs/users" element={<UserLogsPage />} /> */}
          <Route path="/logs/users" element={<AuthenticatedRoute allowedRoles={['logs']} />}>
            <Route index element={<UserLogsPage />} />
          </Route>

          {/* <Route path="/groups" element={<GroupsPage />} /> */}
          <Route path="/groups" element={<AuthenticatedRoute allowedRoles={['grupos']} />}>
            <Route index element={<GroupsPage />} />
          </Route>

          {/* <Route path="/parameters" element={<ParametersPage />} /> */}
          <Route path="/parameters" element={<AuthenticatedRoute allowedRoles={['parametros']} />}>
            <Route index element={<ParametersPage />} />
          </Route>
        </Route>

        {/* NOT FOUND REDIRECT */}
        <Route path="*" element={<Navigate to="/auth" replace />} />
      </ReactRoutes>
    </BrowserRouter>
  )
}

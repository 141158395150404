import { Button } from 'antd'

import { UserLogsFilterForm } from '../../Form'

import { useUserLogFilter } from '@/app'
import { useFilterActions, useFormatFilters } from '@/common'
import type { BaseFilterSidebarProps } from '@/common'
import { FilterSidebar } from '@/components'
import type { UserLogFilters } from '@/features/users'

export const UserLogsFilterSidebar = ({ isOpen, toggleDrawer, form }: BaseFilterSidebarProps) => {
  const setUserLogsFilters = useUserLogFilter((s) => s.setFilters)
  const resetFilters = useUserLogFilter((s) => s.resetFilters)
  const resetUserLogsPagination = useUserLogFilter((s) => s.resetPagination)
  const { submitFilters, handleClearFilters } = useFilterActions(form)
  const { formatDateFieldFilter, formatNormalFields, formatRelationalFields } = useFormatFilters()

  function handleFilter() {
    const values = form.getFieldsValue()

    const normalFields = ['log_type', 'log_path', 'log_area']

    const relationalFields = {
      user: 'name',
    }

    const filters: UserLogFilters = {}

    formatDateFieldFilter(values, filters)
    formatNormalFields(values, filters, normalFields)
    formatRelationalFields(values, filters, relationalFields)

    setUserLogsFilters({ ...filters })
    resetUserLogsPagination()
  }

  return (
    <FilterSidebar
      isDrawerOpen={isOpen}
      toggleDrawer={toggleDrawer}
      filterFooter={
        <>
          <Button onClick={() => handleClearFilters(resetFilters, toggleDrawer)}>Limpar</Button>
          <Button type="primary" htmlType="submit" onClick={() => submitFilters(toggleDrawer)}>
            Filtrar
          </Button>
        </>
      }
    >
      <UserLogsFilterForm form={form} onSubmit={handleFilter} />
    </FilterSidebar>
  )
}

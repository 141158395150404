import { createJSONStorage, devtools, persist } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE, type BaseFilterStore } from '@/common'
import type { UserFilters } from '@/features/users'

interface UserFilterStore extends BaseFilterStore {
  filters: UserFilters
  setFilters: (filters: UserFilters) => void
}

const userFilterStore = createWithEqualityFn<UserFilterStore>()(
  devtools(
    persist(
      (set) => ({
        filters: {
          cpf: undefined,
          name: undefined,
          nickname: undefined,
          email: undefined,
          empresa: undefined,
          phone_number: undefined,
          tipo_contrato: undefined,
          active: undefined,
          created_at: undefined,
          updated_at: undefined,
          user_situation: undefined,
          groups: undefined,
        },

        activeSorting: {
          field: undefined,
          order: undefined,
        },

        pagination: {
          page: DEFAULT_PAGE,
          per_page: DEFAULT_PAGE_SIZE,
        },

        setFilters: (filters) => {
          set(
            (oldState) => ({
              filters: { ...oldState.filters, ...filters },
            }),
            false,
            'set-user-filters',
          )
        },

        setActiveSorting: (sorting) => {
          set({ activeSorting: sorting }, false, 'set-user-active-sorting')
        },

        setPagination: (page, pageSize) => {
          set(
            (oldState) => ({
              pagination: {
                page,
                per_page: pageSize || oldState.pagination.per_page,
              },
            }),
            false,
            'set-user-pagination',
          )
        },

        resetPagination: () => {
          set(
            {
              pagination: {
                page: DEFAULT_PAGE,
                per_page: DEFAULT_PAGE_SIZE,
              },
            },
            false,
            'reset-user-pagination',
          )
        },

        resetFilters: () => {
          set(
            {
              filters: {
                cpf: undefined,
                name: undefined,
                email: undefined,
                empresa: undefined,
                phone_number: undefined,
                tipo_contrato: undefined,
                active: undefined,
                created_at: undefined,
                updated_at: undefined,
                user_situation: undefined,
                groups: undefined,
              },
            },
            false,
            'reset-user-filters',
          )
        },
      }),
      {
        name: 'user-filter-store',
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
    { name: 'user-filter-store' },
  ),
  shallow,
)

export { userFilterStore, userFilterStore as useUserFilters }

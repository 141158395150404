import styled from 'styled-components'

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const FormArea = styled.div`
  display: flex;
  flex-direction: column;

  .ant-form-item,
  .ant-select-selector {
    flex: 1;
  }

  /* .ant-select-selection-item {
    display: none;
  } */
`

export const AreaTitle = styled.h3`
  font-size: 16px;
  font-weight: 700;
  color: #434343;
  padding-bottom: 24px;
`

export const FormRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  .ant-form-item {
    flex: 1;
    max-width: 334px;
  }

  .ant-input {
    height: 40px;
  }

  .ant-select,
  .ant-select-selector {
    height: 40px !important;
    display: flex;
    align-items: center;
  }
`

export const SelectedArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  > h3 {
    padding: 0;
  }
`

export const GroupsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const SystemTitle = styled.span`
  font-weight: 600;
  color: #8e8e8e;
  font-size: 14px;
  display: block;
`

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 20px;

  .ant-btn-icon > span {
    color: ${({ theme }) => theme.colors.brand} !important;
  }
`

export const GroupCard = styled.div`
  width: 100%;
  max-width: 332px;
  height: 40px;
  border-radius: 6px;
  padding: 12px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #fafafa;

  span {
    color: #434343;
    font-size: 14px;
    font-weight: 600;
  }
`

import { keepPreviousData, queryOptions } from '@tanstack/react-query'

import type { Parameter, ParameterFilters } from '../types'

import {
  DEFAULT_ACTIVE_SORTING,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGINATION,
  type FilterValue,
  getFilterExpression,
  handleActiveSorting,
  type ActiveSorting,
  type ListResponse,
  type PaginationExpression,
} from '@/common'
import { api } from '@/services'

const fetchParameterList = async (
  filters: ParameterFilters,
  sorting: ActiveSorting,
  pagination: PaginationExpression,
) => {
  const filterExpression = getFilterExpression(filters as unknown as Record<string, FilterValue>)

  const activeSorting = handleActiveSorting(sorting)

  const { data } = await api.post<ListResponse<Parameter[]>>('parameter/list', {
    filter_expression: filterExpression,
    pagination_expression: {
      page: pagination.page || DEFAULT_PAGE,
      per_page: pagination.per_page === undefined ? DEFAULT_PAGE_SIZE : pagination.per_page,
    },
    order_by_expression: activeSorting,
  })

  return data
}

async function fetchParameter(parameterPk: string) {
  const { data } = await api.get<Parameter>(`/parameter/${parameterPk}`)

  return data
}

export const parameterQueries = {
  all: () => ['parameters'],
  lists: () => [...parameterQueries.all(), 'list'],
  list: (
    filters: ParameterFilters = {},
    sorting: ActiveSorting = DEFAULT_ACTIVE_SORTING,
    pagination: PaginationExpression = DEFAULT_PAGINATION,
  ) =>
    queryOptions({
      queryKey: [...parameterQueries.lists(), filters, sorting, pagination],
      queryFn: () => fetchParameterList(filters, sorting, pagination),
      placeholderData: keepPreviousData,
    }),

  details: () => [...parameterQueries.all(), 'details'],
  detail: (parameterPk: string) =>
    queryOptions({
      queryKey: [...parameterQueries.details(), parameterPk],
      queryFn: () => fetchParameter(parameterPk),
      placeholderData: keepPreviousData,
    }),
}

import { FilterOutlined } from '@ant-design/icons'
import { Divider } from 'antd'

import * as S from './styles'

interface FilterSidebarProps {
  title?: string | React.ReactNode
  children: React.ReactNode
  filterFooter: React.ReactNode
  isLoading?: boolean
  isDrawerOpen: boolean
  toggleDrawer: () => void
}

export const FilterSidebar = ({
  title = 'Filtros',
  children,
  filterFooter,
  isLoading,
  isDrawerOpen,
  toggleDrawer,
}: FilterSidebarProps) => {
  return (
    <S.Container>
      <S.SidebarTrigger
        type="text"
        onClick={toggleDrawer}
        disabled={isLoading}
        data-testid="filter-trigger"
      >
        <FilterOutlined />
      </S.SidebarTrigger>

      <Divider style={{ marginTop: 8 }} />

      <S.FilterDrawer
        title={title}
        open={isDrawerOpen}
        placement="left"
        width={420}
        onClose={toggleDrawer}
        footer={<S.Footer>{filterFooter}</S.Footer>}
      >
        {children}
      </S.FilterDrawer>
    </S.Container>
  )
}

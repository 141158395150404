import { Button, Form } from 'antd'
import { toast } from 'sonner'

import { CompanyForm } from '../../Form'
import * as S from './styles'

import { unmaskValue } from '@/common'
import { BaseModal } from '@/components'
import { CompanyFormData, useCreateCompanyMutation } from '@/features/company'

interface ICreateComapanyModalProps {
  isOpen: boolean
  onClose: () => void
}

export const CreateCompanyModal = ({ isOpen, onClose }: ICreateComapanyModalProps) => {
  const [form] = Form.useForm()
  const createCompanyMutation = useCreateCompanyMutation()

  async function handleCreateUser(data: CompanyFormData) {
    const { company_cnpj, manager_phone, ...values } = data

    const payload = {
      ...values,
      company_cnpj: unmaskValue(company_cnpj, 'cnpj'),
      manager_phone: unmaskValue(manager_phone),
    }

    const promise = createCompanyMutation.mutateAsync(payload)

    toast.promise(promise, {
      loading: 'Cadastrando empresa...',
      success: 'Empresa cadastrada com sucesso!',
      error: 'Erro ao cadastrar empresa!',
    })

    await promise

    onClose()
  }

  return (
    <BaseModal
      title="Nova empresa"
      open={isOpen}
      onCancel={onClose}
      footer={
        <>
          <Button onClick={onClose}>Cancelar</Button>

          <Button type="primary" htmlType="submit" onClick={() => form.submit()}>
            Cadastrar
          </Button>
        </>
      }
    >
      <S.Container>
        <Form
          form={form}
          name="create-companie"
          layout="vertical"
          onFinish={handleCreateUser}
          requiredMark
        >
          <CompanyForm />
        </Form>
      </S.Container>
    </BaseModal>
  )
}

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { roleQueries } from '..'

import { api } from '@/services'

export const useDeleteRoleMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (rolePk: string) => {
      return api.delete(`/role/${rolePk}`)
    },
    onMutate: async (rolePk) => {
      await queryClient.cancelQueries({ queryKey: roleQueries.all() })

      const cachedRoles = queryClient.getQueryData(roleQueries.list().queryKey)

      if (cachedRoles) {
        const updatedRoles = cachedRoles.results.filter((role) => role.pk !== rolePk)

        queryClient.setQueryData(roleQueries.list().queryKey, {
          ...cachedRoles,
          results: updatedRoles,
        })
      }

      return cachedRoles
    },
    onError: (error, _, cachedRoles) => {
      console.error(error)

      if (cachedRoles) {
        queryClient.setQueryData(roleQueries.list().queryKey, cachedRoles)
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: roleQueries.all() }),
  })
}

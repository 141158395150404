import { useNavigate } from 'react-router-dom'

import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from 'sonner'

import { useAuthStore } from '@/app'
import { api } from '@/services'

interface IChangeFirstPasswordMutation {
  username: string
  password: string
  session: string
}

interface IChangeFirstPasswordResponse {
  ChallengeName: string
  ChallengeParameters: {
    message: string
    type: string
  }
  Session: string
}

const changeFirstPasswordErrorMessages = new Map([
  ['Invalid session for the user.', 'Sessão inválida. Por favor, faça um novo login.'],
])

export const useChangeFirstPasswordMutation = () => {
  const navigate = useNavigate()
  const setAuthTokens = useAuthStore((state) => state.setTokens)

  return useMutation({
    mutationFn: async ({ username, password, session }: IChangeFirstPasswordMutation) => {
      return api.post<IChangeFirstPasswordResponse>('/no-auth/auth/change-password-challenge', {
        session,
        username,
        password,
      })
    },
    onSuccess: ({ data }, { username }) => {
      const isEmailMfaRequired =
        data.ChallengeName === 'CUSTOM_CHALLENGE' &&
        data.ChallengeParameters.type === 'VALIDATE_EMAIL_MFA_CODE'

      if (isEmailMfaRequired) {
        setAuthTokens({ session: data.Session })

        const encodedEmail = encodeURIComponent(username)

        return navigate(`/auth/code?username=${encodedEmail}`) // Navigate to the email MFA code page (first login mfa is always email)
      }

      navigate('/auth')
    },
    onError: (error: AxiosError<{ message: string }>) => {
      const errorMessage =
        changeFirstPasswordErrorMessages.get(error.response?.data.message as string) ||
        'Erro ao alterar a senha. Por favor, tente novamente.'

      console.error(error), toast.error(errorMessage)

      navigate('/auth')
    },
  })
}

import * as S from './styles'

export const RoleHelpContentThirdStep = () => {
  return (
    <>
      <S.DescriptionContent>
        <S.DescriptionTitle>1. Campo Sistema:</S.DescriptionTitle>

        <S.DescriptionText>
          Campo sistema, onde o usuário poderá escolher um sistema para ser vinculado à permissão.
        </S.DescriptionText>

        <S.DescriptionText>
          Ao digitar um nome de sistema, o campo irá exibir uma lista de sistemas disponíveis para a
          escolha.
        </S.DescriptionText>
      </S.DescriptionContent>

      <S.DescriptionContent>
        <S.DescriptionTitle>2. Módulo Interno:</S.DescriptionTitle>

        <S.DescriptionText>
          Campo módulo interno, usado para vincular a permissão as funcionalidades internas do
          sistema selecionado.
        </S.DescriptionText>
      </S.DescriptionContent>
    </>
  )
}

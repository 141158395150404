import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Form, Input } from 'antd'

import * as S from './styles'

import { SystemLink } from '@/features/systems'

interface EditSystemPagesFormProps {
  initialValues: SystemLink[]
}

export const EditSystemPagesForm = ({ initialValues }: EditSystemPagesFormProps) => {
  return (
    <S.Container>
      <S.DynamicFieldContainer>
        <S.FieldsContainer>
          <Form.List name="system_pages" initialValue={initialValues}>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => {
                  const isFirstItem = fields.indexOf(field) === 0
                  const pageLabel = isFirstItem ? 'Página padrão do sistema' : 'Página do sistema'
                  const linkLabel = isFirstItem ? 'Link da página padrão (URL)' : 'Link da página'

                  console.log({ fields, initialValues })
                  return (
                    <S.FormRow key={field.key}>
                      <Form.Item
                        label={pageLabel}
                        {...field}
                        name={[field.name, 'page_name']}
                        fieldId={`${field.key}-page`}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Campo obrigatório',
                          },
                        ]}
                      >
                        <Input placeholder="Ex: Logs" />
                      </Form.Item>

                      <S.Field>
                        <Form.Item>
                          <Form.Item
                            {...field}
                            label={linkLabel}
                            name={[field.name, 'url']}
                            fieldId={`${field.key}-link`}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: 'Campo obrigatório',
                              },
                            ]}
                          >
                            <Input placeholder="Ex: https://acer-rental.com.br/logs" />
                          </Form.Item>
                        </Form.Item>

                        {isFirstItem && (
                          <S.AddFieldButton
                            type="primary"
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                          />
                        )}

                        {!isFirstItem && fields.length >= 1 && (
                          <S.RemoveFieldButton
                            onClick={() => remove(field.name)}
                            icon={<MinusOutlined />}
                          />
                        )}
                      </S.Field>
                    </S.FormRow>
                  )
                })}
              </>
            )}
          </Form.List>
        </S.FieldsContainer>
      </S.DynamicFieldContainer>
    </S.Container>
  )
}

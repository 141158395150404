import { useReducer } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Button, Form } from 'antd'
import { toast } from 'sonner'
import { shallow } from 'zustand/shallow'

import * as S from './styles'

import { useAreaFilters } from '@/app'
import {
  type TitleTabItem,
  getActiveFiltersList,
  pagePermissionsKeys,
  removeActiveFilter,
  useAuth,
  useFilterActions,
  useTabNavigation,
  useToggle,
} from '@/common'
import {
  ActiveFiltersList,
  CustomPagination,
  PageHeader,
  SearchInput,
  TitleTabs,
} from '@/components'
import {
  AreaListTable,
  CreateAreaModal,
  ViewAreaModal,
  useDeleteAreaMutation,
  areaQueries,
  AreaFilterSidebar,
} from '@/features/area'
import type { Area, AreaDrawerFilters, AreaFilters } from '@/features/area'
import { PageLayout } from '@/layouts/PageLayout'

export type SelectAreaAction = {
  area: Area
  type: 'DELETE_AREA' | 'VIEW_AREA'
}

export const AreasPage = () => {
  const navigateToTab = useTabNavigation()
  const [form] = Form.useForm<AreaDrawerFilters>()
  const { handleClearFilters, handleSearchFilter } = useFilterActions(form)
  const { getPagePermissions, formatPagePermissions, formatTitleTabItems } = useAuth()

  const pagePerms = getPagePermissions({
    moduleKey: pagePermissionsKeys.areas.module,
    roleKey: pagePermissionsKeys.areas.role,
  })

  const { isAdmin, canCreate, canDelete, canEdit } = formatPagePermissions(pagePerms)

  const {
    areaFilters,
    activeSorting,
    setFilters,
    resetFilters,
    currentPage,
    pageSize,
    setAreaPagination,
    resetPagination,
  } = useAreaFilters(
    (state) => ({
      areaFilters: state.filters,
      activeSorting: state.activeSorting,
      setFilters: state.setFilters,
      resetFilters: state.resetFilters,
      currentPage: state.pagination.page,
      pageSize: state.pagination.per_page,
      setAreaPagination: state.setPagination,
      resetPagination: state.resetPagination,
    }),
    shallow,
  )

  const {
    data: areas,
    isLoading,
    isFetching,
  } = useQuery(
    areaQueries.list(areaFilters, activeSorting, { page: currentPage, per_page: pageSize }),
  )
  const deleteAreaMutation = useDeleteAreaMutation()

  const [isCreateModalOpen, toggleCreateModal] = useToggle(false)
  const [isViewModalOpen, toggleViewModal] = useToggle(false)
  const [isFilterDrawerOpen, toggleFilterDrawer] = useToggle(false)

  const [selectedArea, selectAreaDispatch] = useReducer((_: Area, action: SelectAreaAction) => {
    switch (action.type) {
      case 'DELETE_AREA':
        handleDeleteArea(action.area)
        return action.area
      case 'VIEW_AREA':
        toggleViewModal()
        return action.area
      default:
        return {} as Area
    }
  }, {} as Area)

  const titleTabItems: TitleTabItem[] = [
    {
      key: 'areas',
      label: 'Áreas',
      disabled: true,
      allowedRoles: ['areas'],
    },
    {
      key: 'systems',
      label: 'Sistemas',
      disabled: false,
      allowedRoles: ['sistemas'],
    },
  ]

  async function handleDeleteArea(area: Area) {
    if (deleteAreaMutation.isPending) return

    const promise = deleteAreaMutation.mutateAsync({
      areaPk: area.pk,
    })

    toast.promise(promise, {
      loading: 'Excluindo área...',
      success: 'Área excluída com sucesso!',
      error: 'Erro ao excluir área!',
    })
  }

  function handleRemoveFilter(filterKey: keyof AreaFilters, filterValue?: string) {
    const updatedFilters = removeActiveFilter({
      activeFilters: areaFilters,
      filterKey,
      filterValue,
      form,
    })

    setFilters({ ...updatedFilters })
  }

  const ExtraContent = () => (
    <S.ExtraContent>
      <SearchInput
        placeholder="Buscar por nome..."
        onSearch={(searchValue) =>
          handleSearchFilter({
            searchValue,
            storeCallback: setFilters,
            filterKey: 'area_name',
            resetPagination,
          })
        }
        initialValue={areaFilters.area_name?.value}
        onClose={() => setFilters({ area_name: undefined })}
      />

      {pagePerms && (isAdmin || canCreate) && (
        <Button type="primary" icon={<PlusOutlined />} onClick={toggleCreateModal}>
          Nova área
        </Button>
      )}
    </S.ExtraContent>
  )

  return (
    <PageLayout
      title="Áreas"
      isListPage
      sidebar={
        <AreaFilterSidebar
          isOpen={isFilterDrawerOpen}
          toggleDrawer={toggleFilterDrawer}
          form={form}
        />
      }
    >
      {isCreateModalOpen && (
        <CreateAreaModal isOpen={isCreateModalOpen} onClose={toggleCreateModal} />
      )}

      {isViewModalOpen && (
        <ViewAreaModal
          data={selectedArea as Area}
          isOpen={isViewModalOpen}
          onClose={toggleViewModal}
          canEdit={isAdmin || canEdit}
        />
      )}

      <PageHeader
        title="Lista de áreas"
        itemsFound={areas?.info.total || 0}
        isLoading={isLoading || isFetching}
      />

      <TitleTabs
        items={isAdmin ? titleTabItems : formatTitleTabItems(titleTabItems)}
        defaultActiveKey="areas"
        onChange={navigateToTab}
        tabBarExtraContent={<ExtraContent />}
      />

      {getActiveFiltersList(areaFilters).length > 0 ? (
        <ActiveFiltersList
          filterList={getActiveFiltersList(areaFilters)}
          removeFilter={handleRemoveFilter}
          clearFilters={() => handleClearFilters(resetFilters)}
        />
      ) : null}

      <AreaListTable
        data={areas?.results || []}
        isLoading={isLoading}
        handleSelectArea={selectAreaDispatch}
        canDelete={isAdmin || canDelete}
      />

      <CustomPagination
        scrollToTop
        page={areas?.info.current_page || currentPage}
        isLoading={isLoading}
        pageSize={pageSize}
        totalItems={areas?.info.total}
        totalPages={areas?.info?.pages || 0}
        setPagination={setAreaPagination}
      />
    </PageLayout>
  )
}

import { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Checkbox, CollapseProps, DatePicker, Divider, Form, Input } from 'antd'

import * as S from './styles'

import { useUserLogFilter } from '@/app'
import { type FilterValue, useInitialFilterFormValues, type BaseFilterFormProps } from '@/common'
import { CheckboxFilter, FilterCollapse } from '@/components'
import { userQueries } from '@/features/users/queries'

export const UserLogsFilterForm = ({ form, onSubmit }: BaseFilterFormProps) => {
  const userLogsFilter = useUserLogFilter((s) => s.filters)
  const initialFilterValues = useInitialFilterFormValues(
    userLogsFilter as unknown as Record<string, FilterValue>,
  )

  // Users filter
  // Active users
  const [activeUsersSearch, setActiveUsersSearch] = useState('')
  const { data: activeUsers, isFetching: isActiveUsersFetching } = useQuery(
    userQueries.list({
      name: {
        value: activeUsersSearch,
        comparator: 'like',
      },
      active: {
        value: true,
        comparator: 'eq',
      },
      enabled: {
        value: true,
        comparator: 'eq',
      },
    }),
  )

  // Inactive users
  const [inactiveUsersSearch, setInactiveUsersSearch] = useState('')
  const { data: inactiveUsers, isFetching: isInactiveUsersFetching } = useQuery(
    userQueries.list({
      name: {
        value: inactiveUsersSearch,
        comparator: 'like',
      },
      active: {
        value: true,
        comparator: 'eq',
      },
      enabled: {
        value: false,
        comparator: 'eq',
      },
    }),
  )

  // Deleted users
  const [deletedUsersSearch, setDeletedUsersSearch] = useState('')
  const { data: deletedUsers, isFetching: isDeletedUsersLoading } = useQuery(
    userQueries.list({
      name: {
        value: deletedUsersSearch,
        comparator: 'like',
      },
      active: {
        value: false,
        comparator: 'eq',
      },
      enabled: {
        value: false,
        comparator: 'eq',
      },
    }),
  )

  useEffect(() => {
    form.setFieldsValue(initialFilterValues)
  }, [form, initialFilterValues])

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Área',
      children: (
        <Form.Item name="log_area">
          <Checkbox.Group
            options={[
              {
                label: 'Logs',
                value: 'LOGS',
              },
              {
                label: 'Autenticação',
                value: 'AUTENTICAÇÃO',
              },
              {
                label: 'Permissões',
                value: 'PERMISSÕES',
              },
              {
                label: 'Empresas',
                value: 'EMPRESAS',
              },
              {
                label: 'Áreas',
                value: 'ÁREAS',
              },
              {
                label: 'Sistemas',
                value: 'SISTEMAS',
              },
              {
                label: 'Grupos',
                value: 'GRUPOS',
              },
              {
                label: 'Usuários',
                value: 'USUÁRIOS',
              },
            ]}
          />
        </Form.Item>
      ),
    },
    {
      key: '2',
      label: 'Local',
      children: (
        <Form.Item name="log_path">
          <Input.Search placeholder="Ex: /log" />
        </Form.Item>
      ),
    },
    {
      key: '3',
      label: 'Usuários',
      children: (
        <>
          <S.CollapseSubtitle>Ativos</S.CollapseSubtitle>
          <Form.Item name="user">
            <CheckboxFilter
              filterNameKey="name"
              formFieldName="user"
              searchValue={activeUsersSearch}
              filterSearch={setActiveUsersSearch}
              optionsData={activeUsers?.results}
              isSearching={isActiveUsersFetching}
              initialValues={initialFilterValues?.user?.name}
            />
          </Form.Item>

          <Divider />

          <S.CollapseSubtitle>Inativos</S.CollapseSubtitle>
          <Form.Item name="user">
            <CheckboxFilter
              filterNameKey="name"
              formFieldName="user"
              searchValue={inactiveUsersSearch}
              filterSearch={setInactiveUsersSearch}
              optionsData={inactiveUsers?.results}
              isSearching={isInactiveUsersFetching}
              initialValues={initialFilterValues?.user?.name}
            />
          </Form.Item>

          <Divider />

          <S.CollapseSubtitle>Excluídos</S.CollapseSubtitle>
          <Form.Item name="user">
            <CheckboxFilter
              filterNameKey="name"
              formFieldName="user"
              searchValue={deletedUsersSearch}
              filterSearch={setDeletedUsersSearch}
              optionsData={deletedUsers?.results}
              isSearching={isDeletedUsersLoading}
              initialValues={initialFilterValues?.user?.name}
            />
          </Form.Item>
        </>
      ),
    },
    {
      key: '4',
      label: 'Tipo',
      children: (
        <Form.Item name="log_type">
          <Checkbox.Group
            options={[
              {
                label: 'Consulta',
                value: 'GET',
              },
              {
                label: 'Criação',
                value: 'CREATE',
              },
              {
                label: 'Atualização',
                value: 'UPDATE',
              },
              {
                label: 'Exclusão',
                value: 'DELETE',
              },
            ]}
          />
        </Form.Item>
      ),
    },
    {
      key: '5',
      label: 'Data de criação',
      children: (
        <Form.Item name="created_at">
          <DatePicker.RangePicker allowEmpty format={'DD/MM/YYYY'} />
        </Form.Item>
      ),
    },
    {
      key: '6',
      label: 'Data de atualizacão',
      children: (
        <Form.Item name="updated_at">
          <DatePicker.RangePicker allowEmpty format={'DD/MM/YYYY'} />
        </Form.Item>
      ),
    },
  ]

  return <FilterCollapse form={form} onSubmit={onSubmit} items={items} />
}

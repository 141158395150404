import { createGlobalStyle } from 'styled-components'

import AcerFocoBlack from '../../assets/fonts/AcerFoco-Black.ttf'
import AcerFocoBold from '../../assets/fonts/AcerFoco-Bold.ttf'
import AcerFocoLigth from '../../assets/fonts/AcerFoco-Light.ttf'
import AcerFocoRegular from '../../assets/fonts/AcerFoco-Regular.ttf'
import AcerFocoSemiBold from '../../assets/fonts/AcerFoco-Semibold.ttf'

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'AcerFoco';
    src: url(${AcerFocoLigth}) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: auto;
  }

  @font-face {
    font-family: 'AcerFoco';
    src: url(${AcerFocoRegular}) format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
  }

  @font-face {
    font-family: 'AcerFoco';
    src: url(${AcerFocoSemiBold}) format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: auto;
  }

  @font-face {
    font-family: 'AcerFoco';
    src: url(${AcerFocoBold}) format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: auto;
  }

  @font-face {
    font-family: 'AcerFoco';
    src: url(${AcerFocoBlack}) format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: auto;
  }


  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: 'AcerFoco', sans-serif;
    -webkit-font-smoothing: antialiased;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 400;
  }

  p {
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  ul, li {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  &.ant-dropdown-menu .ant-dropdown-menu-submenu-title{
    display: flex;
    align-items: center;
  }
`

import { Input as AntdInput, Select as AntdSelect } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .ant-table {
    overflow-y: auto;
    max-height: 500px;
  }
`

export const Input = styled(AntdInput)`
  height: 40px;
`

export const TextArea = styled(AntdInput.TextArea)``

export const Select = styled(AntdSelect)`
  height: 40px;
`

import { Checkbox as AntdCheckbox } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const CheckboxGroup = styled(AntdCheckbox.Group)`
  margin-top: 4px;
  max-height: 175px;
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  gap: 4px;

  span {
    text-transform: capitalize;
    text-align: left;
    max-width: 278px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: fit-content;
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin: 0;
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-button:start:decrement,
  ::-webkit-scrollbar-button:end:increment {
    display: none;
  }

  ::-webkit-scrollbar-track-piece {
    background-color: rgba(130, 153, 194, 0.2);
    -webkit-border-radius: 9999px;
    border-radius: 9999px;
  }

  ::-webkit-scrollbar-thumb:vertical {
    background-color: ${({ theme }) => theme.colors.brand};
    -webkit-border-radius: 9999px;
    border-radius: 9999px;
  }
`
export const Checkbox = styled(AntdCheckbox)`
  text-transform: capitalize;
`

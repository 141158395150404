import { handleDateFilters, unmaskValue } from '../utils'

export const useFormatFilters = () => {
  function formatNormalFields(formValues: any, filters: Record<string, any>, fields: string[]) {
    const userSituations = {
      active: { active: true, enabled: true },
      inactive: { active: true, enabled: false },
      excluded: { active: false, enabled: false },
    }

    fields.forEach((field) => {
      const fieldValue = formValues[field]

      if (field === 'user_situation') {
        const active = userSituations?.[fieldValue as keyof typeof userSituations]?.active
        const enabled = userSituations?.[fieldValue as keyof typeof userSituations]?.enabled

        filters.active = { value: active, comparator: 'eq' }
        filters.enabled = { value: enabled, comparator: 'eq' }
        filters.user_situation = { value: fieldValue, comparator: 'eq' }
      } else if (typeof fieldValue === 'boolean') {
        filters[field] = {
          value: fieldValue,
          comparator: 'eq',
        }
      } else if (fieldValue) {
        if (Array.isArray(fieldValue)) {
          filters[field] = {
            value: fieldValue,
            comparator: 'in',
          }
        } else {
          filters[field] = {
            value: fieldValue.trim(),
            comparator: 'like', //TODO: Change comparator ?
          }
        }
      }
    })

    return filters
  }

  function formatMaskedFields(formValues: any, filters: Record<string, any>, fields: string[]) {
    fields.forEach((field) => {
      const fieldValue = formValues[field]

      if (fieldValue) {
        let unmaskedValue = unmaskValue(fieldValue)

        if (field.includes('cnpj')) {
          unmaskedValue = unmaskValue(fieldValue, 'cnpj')
        }

        filters[field] = {
          value: unmaskedValue.trim(),
          comparator: 'eq',
        }
      }
    })

    return filters
  }

  function formatRelationalFields(
    values: any,
    filters: Record<string, any>,
    relationalFields: Record<string, string>,
  ) {
    Object.entries(relationalFields).forEach(([field, filterName]) => {
      const value = values[field]

      if (Array.isArray(value) && value.length === 0) {
        filters[field] = undefined
      } else if (value) {
        filters[field] = {
          [filterName]: {
            value,
            comparator: 'in',
          },
        }
      } else {
        filters[field] = undefined
      }
    })
  }

  function formatDateFieldFilter(formValues: any, filters: Record<string, any>) {
    const dateFields = ['created_at', 'updated_at']

    dateFields.forEach((field) => {
      if (formValues[field]) {
        filters[field] = handleDateFilters(formValues[field])
      }
    })
  }

  return { formatNormalFields, formatRelationalFields, formatDateFieldFilter, formatMaskedFields }
}

import { useNavigate, useSearchParams, Navigate } from 'react-router-dom'

import { Button } from 'antd'

import { PageTitle } from '@/components'
import {
  ResetPasswordForm,
  ResetPasswordFormData,
  useChangeForgotPasswordMutation,
} from '@/features/auth'
import { AccessLayout } from '@/layouts'

export const ResetPasswordPage = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const resetPasswordMutation = useChangeForgotPasswordMutation()

  const email = searchParams.get('email')

  if (!email) {
    return <Navigate to="/auth/forgot-password" replace />
  }

  function handleChangePassword(data: ResetPasswordFormData) {
    resetPasswordMutation.mutate({
      username: email as string,
      password: data.password,
      confirmation_code: data.code,
    })
  }

  return (
    <AccessLayout>
      <PageTitle title="Recuperar senha" />

      <ResetPasswordForm
        userEmail={email}
        onSubmit={handleChangePassword}
        isLoading={resetPasswordMutation.isPending}
      />

      <Button
        type="link"
        block
        onClick={() => navigate(-1)}
        disabled={resetPasswordMutation.isPending}
      >
        Voltar
      </Button>
    </AccessLayout>
  )
}

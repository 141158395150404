import { useQuery } from '@tanstack/react-query'
import { Skeleton } from 'antd'

import * as S from './styles'

import { formatDate } from '@/common'
import { BaseModal } from '@/components'
import { logTypes } from '@/features/users'
import { userLogsQueries } from '@/features/users/queries'

interface UserLogModalProps {
  logPk: string
  isOpen: boolean
  onClose: () => void
}

export const ViewUserLogModal = ({ logPk, isOpen, onClose }: UserLogModalProps) => {
  const { data: userLog, isLoading } = useQuery(userLogsQueries.detail(logPk))

  const LogView = () => (
    <S.Container>
      <S.CardContainer>
        <S.CardTitle>Dados Gerais</S.CardTitle>

        <S.Card>
          <S.CardColumn>
            <S.CardContent>
              <strong>{formatDate({ date: userLog?.created_at || '' })}</strong>
              <span>Data de criação</span>
            </S.CardContent>

            <S.CardContent>
              <strong>{formatDate({ date: userLog?.log_user.last_sign_in || '' })}</strong>
              <span>Último acesso (usuário)</span>
            </S.CardContent>
          </S.CardColumn>

          <S.CardColumn>
            <S.CardContent>
              <div>
                <S.Divider type="vertical" />
                <strong>{userLog?.log_user.name}</strong>
              </div>

              <div>
                <S.Divider type="vertical" />
                <span>Usuário</span>
              </div>
            </S.CardContent>

            <S.CardContent>
              <div>
                <S.Divider type="vertical" />
                <strong>{userLog?.log_user.email}</strong>
              </div>

              <div>
                <S.Divider type="vertical" />
                <span>username</span>
              </div>
            </S.CardContent>
          </S.CardColumn>

          <S.CardColumn>
            <S.CardContent>
              <div>
                <S.Divider type="vertical" />
                <strong>{logTypes.get(userLog?.log_type as string) || userLog?.log_type}</strong>
              </div>

              <div>
                <S.Divider type="vertical" />
                <span>Tipo</span>
              </div>
            </S.CardContent>

            <S.CardContent>
              <div>
                <S.Divider type="vertical" />
                <S.LogArea>{userLog?.log_area.toLowerCase()}</S.LogArea>
              </div>

              <div>
                <S.Divider type="vertical" />
                <span>Área</span>
              </div>
            </S.CardContent>
          </S.CardColumn>
        </S.Card>
      </S.CardContainer>

      <S.CardContainer>
        <S.CardTitle>IP</S.CardTitle>

        <S.Card>
          <S.CardContent>{userLog?.host_ip}</S.CardContent>
        </S.Card>
      </S.CardContainer>

      <S.CardContainer>
        <S.CardTitle>Local</S.CardTitle>

        <S.Card>
          <S.CardContent>{userLog?.log_path}</S.CardContent>
        </S.Card>
      </S.CardContainer>
    </S.Container>
  )

  return (
    <BaseModal
      open={isOpen}
      onCancel={onClose}
      title="Log de Usuário"
      footer={
        <S.CloseButton onClick={onClose} htmlType="button" type="primary">
          Fechar
        </S.CloseButton>
      }
    >
      {isLoading ? <Skeleton active /> : <LogView />}
    </BaseModal>
  )
}

import { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { DatePicker, Form, Input, Radio, Select, type CollapseProps } from 'antd'

import { useUserFilters } from '@/app'
import { type BaseFilterFormProps, type FilterValue, useInitialFilterFormValues } from '@/common'
import { CheckboxFilter, FilterCollapse } from '@/components'
import { companyQueries } from '@/features/company'
import { groupQueries } from '@/features/groups'
import { contractTypeOptions } from '@/features/parameters'

export const UserFilterForm = ({ form, onSubmit }: BaseFilterFormProps) => {
  const userFilters = useUserFilters((s) => s.filters)

  const initialFilterValues = useInitialFilterFormValues(
    userFilters as unknown as Record<string, FilterValue>,
  )

  // Company filter
  const { data: companies, isPending } = useQuery(
    companyQueries.list({}, { field: undefined, order: undefined }, { per_page: 0, page: 1 }),
  )
  const companySelectOptions = companies?.results.map((company) => ({
    label: company.company_fancy_name,
    value: company.company_fancy_name,
  }))

  // Group filter
  const [groupsSearch, setGroupsSearch] = useState('')
  const { data: groups, isFetching } = useQuery(
    groupQueries.list({
      group_name: {
        value: groupsSearch,
        comparator: 'like',
      },
    }),
  )

  useEffect(() => {
    form.setFieldsValue(initialFilterValues)
  }, [form, initialFilterValues, userFilters.active, userFilters.enabled])

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Data de criação',
      children: (
        <Form.Item name="created_at">
          <DatePicker.RangePicker allowEmpty format={'DD/MM/YYYY'} />
        </Form.Item>
      ),
    },
    {
      key: '2',
      label: 'Data de atualizacão',
      children: (
        <Form.Item name="updated_at">
          <DatePicker.RangePicker allowEmpty format={'DD/MM/YYYY'} />
        </Form.Item>
      ),
    },
    {
      key: '3',
      label: 'E-mail',
      children: (
        <Form.Item name="email">
          <Input.Search placeholder="Ex: john-doe@email.com.br" />
        </Form.Item>
      ),
    },
    // {
    //   key: '4',
    //   label: 'CPF',
    //   children: (
    //     <Form.Item name="cpf">
    //       <Input.Search placeholder="Ex: 123.456.789-00" />
    //     </Form.Item>
    //   ),
    // },
    {
      key: '5',
      label: 'Telefone',
      children: (
        <Form.Item name="phone_number">
          <Input.Search placeholder="Ex: (11) 99999-9999" />
        </Form.Item>
      ),
    },
    {
      key: '6',
      label: 'Empresa (nome fantasia)',
      children: (
        <Form.Item name="empresa">
          <Select loading={isPending} options={companySelectOptions} />
        </Form.Item>
      ),
    },
    // {
    //   key: '7',
    //   label: 'Status',
    //   children: (
    //     <Form.Item name="active">
    //       <Radio.Group>
    //         <Radio value={true}>Ativo</Radio>
    //         <Radio value={false}>Inativo</Radio>
    //       </Radio.Group>
    //     </Form.Item>
    //   ),
    // },
    {
      key: '7',
      label: 'Status',
      children: (
        <Form.Item name="user_situation">
          <Radio.Group>
            <Radio value={'active'}>Ativo</Radio>
            <Radio value={'inactive'}>Inativo</Radio>
            <Radio value={'excluded'}>Excluído</Radio>
          </Radio.Group>
        </Form.Item>
      ),
    },
    {
      key: '8',
      label: 'Tipo de contrato',
      children: (
        <Form.Item name="tipo_contrato">
          <Select options={contractTypeOptions} />
        </Form.Item>
      ),
    },
    {
      key: '9',
      label: 'Grupos',
      children: (
        <Form.Item name="groups">
          <CheckboxFilter
            filterNameKey="group_name"
            formFieldName="groups"
            searchValue={groupsSearch}
            filterSearch={setGroupsSearch}
            optionsData={groups?.results}
            isSearching={isFetching}
            initialValues={initialFilterValues?.groups?.group_name}
          />
        </Form.Item>
      ),
    },
  ]

  return <FilterCollapse form={form} onSubmit={onSubmit} items={items} />
}

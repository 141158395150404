import { Table } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .ant-table-container,
  .ant-table-title {
    border: 1px solid #e2e4e7;
    border-radius: 4px;
  }

  .ant-table-title {
    border-bottom: 0;
  }
`

export const GroupData = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;

  strong {
    color: #434343;
    text-transform: capitalize;
  }
`

export const GroupSystems = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`

export const System = styled.div`
  min-width: 300px;
  max-width: 340px;
  width: 100%;
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 12px 8px;
  color: #6c6c6c;
  font-weight: 600;

  background-color: #fafafa;
  border-radius: 4px;

  > div {
    display: flex;
    align-items: center;
    gap: 8px;

    span {
      color: #b5b5b5;
      font-size: 12px;
      font-style: italic;
    }
  }
`

export const TableHeader = styled.div`
  display: flex;
  align-items: center;

  font-size: 14px;
  color: #434343;

  strong {
    font-weight: 600;
  }
`

export const ViewTable = styled(Table)`
  max-height: 500px;
  overflow-y: auto;
`

import { devtools, persist } from 'zustand/middleware'
import { createWithEqualityFn } from 'zustand/traditional'

import rolesStore from './roles'

interface IAuthorization {
  accessToken?: string
  refreshToken?: string
  idToken?: string
  session?: string
}

interface IAuthStoreState extends IAuthorization {
  tokens: IAuthorization
  isAuthenticated: boolean
  setAuthenticated: (isAuthenticated: boolean) => void
  setTokens: (authTokens: IAuthorization) => void
  clearStore: () => void
}

const authStore = createWithEqualityFn<IAuthStoreState>()(
  devtools(
    persist(
      (set) => ({
        tokens: {
          accessToken: undefined,
          refreshToken: undefined,
          idToken: undefined,
          session: undefined,
        },

        isAuthenticated: false,

        setAuthenticated: (isAuthenticated) => {
          set({ isAuthenticated }, false, 'set-auth-status')
        },

        setTokens: (authTokens) => {
          set(
            (oldState) => ({
              tokens: { ...oldState.tokens, ...authTokens },
            }),
            false,
            'setTokens',
          )

          rolesStore.getState().getDecodedRoles(authTokens.idToken)
        },

        clearStore: () =>
          set(
            {
              isAuthenticated: false,
              tokens: {
                accessToken: undefined,
                refreshToken: undefined,
                idToken: undefined,
                session: undefined,
              },
            },
            false,
            'clearStore',
          ),
      }),
      { name: 'datalake-auth' },
    ),
    { name: 'datalake-auth' },
  ),
)

export const clearAuthStore = authStore.getState().clearStore

export { authStore as useAuthStore }

export default authStore

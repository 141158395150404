import { Form as AntdForm } from 'antd'
import styled from 'styled-components'

export const Form = styled(AntdForm)`
  gap: 0px;

  .ant-form-item {
    margin: 0px;
    padding: 0px;
  }

  .ant-collapse-header {
    color: #434343;
    font-size: 16px;
    font-weight: 600;
    padding: 12px 0 6px !important;
  }

  .ant-collapse-content-box {
    padding: 12px 0 !important;
  }

  .ant-collapse-expand-icon {
    color: ${(props) => props.theme.colors.brand};
    font-weight: 600 !important;
  }

  .ant-picker {
    height: 40px;
    width: 100%;
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }
`

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

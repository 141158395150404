import { Button, Tag } from 'antd'
import styled from 'styled-components'

export const CreateProprertyButton = styled(Button)`
  color: ${({ theme }) => theme.colors.brand};
  border-color: ${({ theme }) => theme.colors.brand};
`

export const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  span {
    max-width: 450px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`

export const CustomRoleTag = styled(Tag)`
  font-weight: 600;
`

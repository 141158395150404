import { Button, Form } from 'antd'
import { toast } from 'sonner'

import { ParameterForm } from '../../Form'

import { BaseModal } from '@/components'
import { useCreateParameterMutation, type CreateParameterFormValues } from '@/features/parameters'

interface CreateParameterModalProps {
  isOpen: boolean
  onClose: () => void
}

export const CreateParameterModal = ({ isOpen, onClose }: CreateParameterModalProps) => {
  const [form] = Form.useForm<CreateParameterFormValues>()
  const createParameterMutation = useCreateParameterMutation()

  async function handleSubmit(values: CreateParameterFormValues) {
    const promise = createParameterMutation.mutateAsync(values)

    toast.promise(promise, {
      loading: 'Criando parâmetro...',
      success: 'Parâmetro cadastrado com sucesso',
      error: 'Erro ao cadastrar parâmetro',
    })

    await promise

    onClose()
  }

  return (
    <BaseModal
      title="Novo Parâmetro"
      open={isOpen}
      onCancel={onClose}
      footer={
        <>
          <Button onClick={onClose} disabled={createParameterMutation.isPending}>
            Cancelar
          </Button>

          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={createParameterMutation.isPending}
          >
            Cadastrar
          </Button>
        </>
      }
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        disabled={createParameterMutation.isPending}
      >
        <ParameterForm />
      </Form>
    </BaseModal>
  )
}

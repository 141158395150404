import { queryOptions, keepPreviousData } from '@tanstack/react-query'

import type { Area, AreaFilters } from '..'

import type { ActiveSorting, PaginationExpression, ListResponse, FilterValue } from '@/common'
import {
  DEFAULT_ACTIVE_SORTING,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGINATION,
  getFilterExpression,
  handleActiveSorting,
} from '@/common'
import { api } from '@/services'

const fetchAreasList = async (
  filters: AreaFilters,
  sorting: ActiveSorting,
  pagination: PaginationExpression,
) => {
  const filterExpression = getFilterExpression(filters as unknown as Record<string, FilterValue>)

  const activeSorting = handleActiveSorting(sorting)

  const { data } = await api.post<ListResponse<Area[]>>('/area/list', {
    filter_expression: filterExpression,
    pagination_expression: {
      page: pagination.page || DEFAULT_PAGE,
      per_page: pagination.per_page === undefined ? DEFAULT_PAGE_SIZE : pagination.per_page,
    },
    order_by_expression: activeSorting,
  })

  return data
}

async function fetchArea(areaPk: string) {
  const { data } = await api.get<Area>(`/area/${areaPk}`)

  return data
}

export const areaQueries = {
  all: () => ['areas'],
  lists: () => [...areaQueries.all(), 'list'],
  list: (
    filters: AreaFilters = {},
    sorting: ActiveSorting = DEFAULT_ACTIVE_SORTING,
    pagination: PaginationExpression = DEFAULT_PAGINATION,
  ) =>
    queryOptions({
      queryKey: [...areaQueries.lists(), filters, sorting, pagination],
      queryFn: () => fetchAreasList(filters, sorting, pagination),
      placeholderData: keepPreviousData,
    }),

  details: () => [...areaQueries.all(), 'details'],
  detail: (areaPk: string) =>
    queryOptions({
      queryKey: [...areaQueries.details(), areaPk],
      queryFn: () => fetchArea(areaPk),
      placeholderData: keepPreviousData,
    }),
}

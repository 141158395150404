import { QueryClient } from '@tanstack/react-query'

import { clearAuthStore, clearRolesStore } from '@/app'

export async function clearAllCaches(queryClient?: QueryClient): Promise<void> {
  clearAuthStore()
  clearRolesStore()
  localStorage.clear()
  sessionStorage.clear()

  // Optional as the user might not send a queryClient
  queryClient?.clear()

  try {
    const cacheNames = await caches.keys()
    await Promise.all(cacheNames.map((name) => caches.delete(name)))
  } catch (error) {
    console.error('Failed to clear caches:', error)
  }
}

import { useLayoutEffect } from 'react'

export const useDocumentTitle = (pageTitle: string | undefined) => {
  const defaultTitle = 'Login Único'

  const title = pageTitle ? `Login Único | ${pageTitle}` : defaultTitle

  useLayoutEffect(() => {
    document.title = `${title}`
  }, [title])
}

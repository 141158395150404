import { CloseOutlined } from '@ant-design/icons'
import { DrawerProps } from 'antd'

import * as S from './styles'

interface Props extends DrawerProps {}

export const CreateRoleHelpDrawer = ({ children, onClose, ...rest }: Props) => {
  return (
    <S.Drawer
      title="Como preencher os campos de cadastro?"
      placement="right"
      onClose={onClose}
      closable={false}
      extra={<CloseOutlined onClick={onClose} />}
      footer={<S.CloseButton onClick={onClose}>Fechar</S.CloseButton>}
      {...rest}
    >
      <S.Container>{children}</S.Container>
    </S.Drawer>
  )
}

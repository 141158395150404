import { AcerLinksHeader } from '@fitec/acer-header'

import * as S from './styles'

import { useAuthStore } from '@/app'
import { useDocumentTitle, useUserId } from '@/common'
import { Header } from '@/components/Layout/PageLayout'

type PageLayoutProps = {
  title?: string
  children: React.ReactNode
  sidebar?: React.ReactNode
  isListPage: boolean
}

export const PageLayout = ({ title, children, sidebar, isListPage }: PageLayoutProps) => {
  useDocumentTitle(title)
  const userId = useUserId()
  const idToken = useAuthStore((state) => state.tokens.idToken)
  const refreshToken = useAuthStore((state) => state.tokens.refreshToken)

  return (
    <S.Container>
      <AcerLinksHeader
        idToken={idToken as string}
        redirectInfo={{
          userId,
          refreshToken: refreshToken as string,
        }}
      />
      <Header />

      <S.SidebarContainer>
        {sidebar}

        <S.ContentContainer $isListPage={isListPage}>
          <S.Content>{children}</S.Content>
        </S.ContentContainer>
      </S.SidebarContainer>
    </S.Container>
  )
}

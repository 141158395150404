import { keepPreviousData, queryOptions } from '@tanstack/react-query'

import type { Group, GroupFilters } from '..'

import type { ActiveSorting, PaginationExpression, ListResponse, FilterValue } from '@/common'
import {
  DEFAULT_ACTIVE_SORTING,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGINATION,
  getFilterExpression,
  handleActiveSorting,
} from '@/common'
import { api } from '@/services'

async function fetchGroupsList(
  filters: GroupFilters,
  sorting: ActiveSorting,
  pagination: PaginationExpression,
) {
  const filterExpression = getFilterExpression(filters as unknown as Record<string, FilterValue>)

  const activeSorting = handleActiveSorting(sorting)

  const { data } = await api.post<ListResponse<Group[]>>('/group/list', {
    filter_expression: filterExpression,
    pagination_expression: {
      page: pagination.page || DEFAULT_PAGE,
      per_page: pagination.per_page === undefined ? DEFAULT_PAGE_SIZE : pagination.per_page,
    },
    order_by_expression: activeSorting,
  })

  return data
}

async function fetchGroup(groupPk: string) {
  const { data } = await api.get<Group>(`/group/${groupPk}`)

  return data
}

export const groupQueries = {
  all: () => ['groups'],
  lists: () => [...groupQueries.all(), 'list'],
  list: (
    filters: GroupFilters = {},
    sorting: ActiveSorting = DEFAULT_ACTIVE_SORTING,
    pagination: PaginationExpression = DEFAULT_PAGINATION,
  ) =>
    queryOptions({
      queryKey: [...groupQueries.lists(), filters, sorting, pagination],
      queryFn: () => fetchGroupsList(filters, sorting, pagination),
      placeholderData: keepPreviousData,
    }),
  details: () => [...groupQueries.all(), 'details'],
  detail: (groupPk: string) =>
    queryOptions({
      queryKey: [...groupQueries.details(), groupPk],
      queryFn: () => fetchGroup(groupPk),
      placeholderData: keepPreviousData,
    }),
}

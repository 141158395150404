import { QuestionOutlined } from '@ant-design/icons'
import { QRCode } from 'antd'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const PageTitle = styled.h1`
  font-size: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.textPrimary};
`

export const NewMfaSection = styled.div`
  height: 100%;
  max-width: 442px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`

export const NewMfaText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  text-align: center;

  span {
    font-size: 20px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.textPrimary};
  }
`

export const MfaSection = styled.div<{ $hasError?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  max-width: 360px;
  margin: 132px auto;

  form {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-form-item {
      margin: 0;
    }

    .input-container {
      margin: 0 auto;
      display: flex;
      align-items: center;
      gap: 24px;

      ::placeholder {
        color: #00000040;
      }
    }

    .mfa-input {
      width: 40px;
      height: 40px;

      padding: 0;
      font-size: 16px;
      text-align: center;
      text-transform: uppercase;

      color: #494949;
      border: 1px solid #d9d9d9;
      border-radius: 2px;

      background: #fff;
      background-clip: padding-box;
      outline-color: ${({ theme }) => theme.colors.brand};

      :focus {
        border-color: red;
        outline-color: red;
      }
    }

    ${({ $hasError }) =>
      $hasError &&
      css`
        .mfa-input {
          border-color: red;
          outline-color: red;
        }
      `}
  }
`

export const TooltipContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  position: relative;

  .anticon {
    position: absolute;
    right: -18px;
    top: 0;
  }
`

export const TooltipIcon = styled(QuestionOutlined)`
  padding: 2px;
  margin-top: 4px;

  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.brand};

  border: 1px solid ${({ theme }) => theme.colors.brand};
  border-radius: 9999999px;
`

export const QrCode = styled(QRCode)`
  border: 1px solid #000;
  border-radius: 4px;
`

export const Disclaimer = styled.h6<{ $size: number }>`
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.textPrimary};
  max-width: ${({ $size }) => $size}px;
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

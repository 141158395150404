import type { FormInstance } from 'antd'

export type NavRoute = {
  path: string
  label: string
  key: string
  icon: JSX.Element
  allowedRoles?: string[]
}

export type ListResponseInfo = {
  total: number
  pages: number
  per_page: number
  current_page: number
}

export type ListResponse<T> = {
  info: ListResponseInfo
  results: T
}

export type FilterComparator =
  | 'eq'
  | 'ne'
  | 'like'
  | 'gt'
  | 'ge'
  | 'lt'
  | 'le'
  | 'startswith'
  | 'endswith'
  | 'in'
  | 'between'

export interface FilterExpression {
  value: any
  comparator: FilterComparator
}

export interface OrderExpression {
  field: string
  order: 'ASC' | 'DESC'
  then_by?: OrderExpression
}

export type ActiveSorting = {
  field: string | undefined
  order: 'ASC' | 'DESC' | undefined
}

export type PaginationExpression = {
  page: number
  per_page: number
}
export interface Expression {
  filter_expression?: FilterExpression
  pagination_expression?: PaginationExpression
  order_by_expression?: OrderExpression
}

export type BaseFilterStore = {
  activeSorting: ActiveSorting
  pagination: PaginationExpression
  resetFilters: () => void
  setActiveSorting: (sorting: ActiveSorting) => void
  setPagination: (page: number, pageSize?: number) => void
  resetPagination: () => void
}

export type BaseFilterSidebarProps = {
  isOpen: boolean
  toggleDrawer: () => void
  form: FormInstance
}

export type BaseFilterFormProps = {
  form: FormInstance
  onSubmit: () => void
}

export type TitleTabItem = {
  key: string
  label: string
  disabled: boolean
  allowedRoles: string[]
}

export type ModalAuthenticatedFeatures = {
  canEdit: boolean
}

export type TableAuthenticatedFeatures = {
  canDelete: boolean
  canEdit?: boolean
}

export enum PageModule {
  Areas = 'areas',
  Systems = 'sistemas',
  Companies = 'empresas',
  Users = 'usuarios',
  Groups = 'grupos',
  Permissions = 'permissoes',
  Parameters = 'parametros',
  Logs = 'logs',
}

export enum PageRole {
  Areas = 'area',
  Systems = 'system',
  Companies = 'company',
  Users = 'user',
  Groups = 'group',
  Permissions = 'role',
  Parameters = 'parameter',
  Logs = 'log',
}

export type PagePermissionKey = {
  module: PageModule
  role: PageRole
}

export type AuthenticatedPages = {
  areas: PagePermissionKey
  systems: PagePermissionKey
  companies: PagePermissionKey
  users: PagePermissionKey
  groups: PagePermissionKey
  permissions: PagePermissionKey
  parameters: PagePermissionKey
  logs: PagePermissionKey
}

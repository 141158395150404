import { Button } from 'antd'

import type { ParameterFilters } from '../../types'
import { ParameterFilterForm } from '../Form'

import { useParameterFilters } from '@/app'
import { useFilterActions, useFormatFilters, type BaseFilterSidebarProps } from '@/common'
import { FilterSidebar } from '@/components'

export const ParameterFilterSidebar = ({ isOpen, toggleDrawer, form }: BaseFilterSidebarProps) => {
  const setParameterFilters = useParameterFilters((s) => s.setFilters)
  const resetFilters = useParameterFilters((s) => s.resetFilters)
  const resetParameterPagination = useParameterFilters((s) => s.resetPagination)
  const { submitFilters, handleClearFilters } = useFilterActions(form)
  const { formatDateFieldFilter, formatNormalFields } = useFormatFilters()

  function handleFilter() {
    const values = form.getFieldsValue()

    const fields = ['tipo_contrato', 'inactivity_days']

    const filters: ParameterFilters = {}

    formatDateFieldFilter(values, filters)
    formatNormalFields(values, filters, fields)

    setParameterFilters({ ...filters })
    resetParameterPagination()
  }

  return (
    <FilterSidebar
      isDrawerOpen={isOpen}
      toggleDrawer={toggleDrawer}
      filterFooter={
        <>
          <Button onClick={() => handleClearFilters(resetFilters, toggleDrawer)}>Limpar</Button>
          <Button type="primary" htmlType="submit" onClick={() => submitFilters(toggleDrawer)}>
            Filtrar
          </Button>
        </>
      }
    >
      <ParameterFilterForm form={form} onSubmit={handleFilter} />
    </FilterSidebar>
  )
}

import { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { CollapseProps, DatePicker, Divider, Form } from 'antd'

import * as S from './styles'

import { useGroupFilters } from '@/app'
import { type FilterValue, useInitialFilterFormValues, type BaseFilterFormProps } from '@/common'
import { CheckboxFilter, FilterCollapse } from '@/components'
import { roleQueries } from '@/features/roles'
import { systemQueries } from '@/features/systems'
import { userQueries } from '@/features/users'

export const GroupsFilterForm = ({ form, onSubmit }: BaseFilterFormProps) => {
  const groupFilters = useGroupFilters((s) => s.filters)
  const initialFilterValues = useInitialFilterFormValues(
    groupFilters as unknown as Record<string, FilterValue>,
  )

  // Users filter
  // Active users
  const [activeUsersSearch, setActiveUsersSearch] = useState('')
  const { data: activeUsers, isFetching: isActiveUsersFetching } = useQuery(
    userQueries.list({
      name: {
        value: activeUsersSearch,
        comparator: 'like',
      },
      active: {
        value: true,
        comparator: 'eq',
      },
      enabled: {
        value: true,
        comparator: 'eq',
      },
    }),
  )

  // Inactive users
  const [inactiveUsersSearch, setInactiveUsersSearch] = useState('')
  const { data: inactiveUsers, isFetching: isInactiveUsersFetching } = useQuery(
    userQueries.list({
      name: {
        value: inactiveUsersSearch,
        comparator: 'like',
      },
      active: {
        value: true,
        comparator: 'eq',
      },
      enabled: {
        value: false,
        comparator: 'eq',
      },
    }),
  )

  // Deleted users
  const [deletedUsersSearch, setDeletedUsersSearch] = useState('')
  const { data: deletedUsers, isFetching: isDeletedUsersLoading } = useQuery(
    userQueries.list({
      name: {
        value: deletedUsersSearch,
        comparator: 'like',
      },
      active: {
        value: false,
        comparator: 'eq',
      },
      enabled: {
        value: false,
        comparator: 'eq',
      },
    }),
  )

  // Roles filter
  const [rolesSearch, setRolesSearch] = useState('')
  const { data: roles, isFetching: isRolesFetching } = useQuery(
    roleQueries.list({
      title: {
        value: activeUsersSearch,
        comparator: 'like',
      },
    }),
  )

  // Systems filter
  const [systemsSearch, setSystemsSearch] = useState('')
  const { data: systems, isFetching: isSystemsFetching } = useQuery(
    systemQueries.list({
      system_name: {
        value: systemsSearch,
        comparator: 'like',
      },
    }),
  )

  useEffect(() => {
    form.setFieldsValue(initialFilterValues)
  }, [form, initialFilterValues])

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Data de criação',
      children: (
        <Form.Item name="created_at">
          <DatePicker.RangePicker allowEmpty format={'DD/MM/YYYY'} />
        </Form.Item>
      ),
    },
    {
      key: '2',
      label: 'Data de atualizacão',
      children: (
        <Form.Item name="updated_at">
          <DatePicker.RangePicker allowEmpty format={'DD/MM/YYYY'} />
        </Form.Item>
      ),
    },
    {
      key: '3',
      label: 'Usuários',
      children: (
        <>
          <S.CollapseSubtitle>Ativos</S.CollapseSubtitle>

          <Form.Item name="users">
            <CheckboxFilter
              filterNameKey="name"
              formFieldName="users"
              searchValue={activeUsersSearch}
              filterSearch={setActiveUsersSearch}
              optionsData={activeUsers?.results}
              isSearching={isActiveUsersFetching}
              initialValues={initialFilterValues?.users?.name}
            />
          </Form.Item>

          <Divider />

          <S.CollapseSubtitle>Inativos</S.CollapseSubtitle>
          <Form.Item name="users">
            <CheckboxFilter
              filterNameKey="name"
              formFieldName="users"
              searchValue={inactiveUsersSearch}
              filterSearch={setInactiveUsersSearch}
              optionsData={inactiveUsers?.results}
              isSearching={isInactiveUsersFetching}
              initialValues={initialFilterValues?.users?.name}
            />
          </Form.Item>

          <Divider />

          <S.CollapseSubtitle>Excluídos</S.CollapseSubtitle>
          <Form.Item name="users">
            <CheckboxFilter
              filterNameKey="name"
              formFieldName="users"
              searchValue={deletedUsersSearch}
              filterSearch={setDeletedUsersSearch}
              optionsData={deletedUsers?.results}
              isSearching={isDeletedUsersLoading}
              initialValues={initialFilterValues?.users?.name}
            />
          </Form.Item>
        </>
      ),
    },
    {
      key: '4',
      label: 'Permissões',
      children: (
        <Form.Item name="roles">
          <CheckboxFilter
            filterNameKey="title"
            formFieldName="roles"
            searchValue={rolesSearch}
            filterSearch={setRolesSearch}
            optionsData={roles?.results}
            isSearching={isRolesFetching}
            initialValues={initialFilterValues?.roles?.title}
          />
        </Form.Item>
      ),
    },
    {
      key: '5',
      label: 'Sistemas',
      children: (
        <Form.Item name="systems">
          <CheckboxFilter
            filterNameKey="system_name"
            formFieldName="systems"
            searchValue={systemsSearch}
            filterSearch={setSystemsSearch}
            optionsData={systems?.results}
            isSearching={isSystemsFetching}
            initialValues={initialFilterValues?.systems?.system_name}
          />
        </Form.Item>
      ),
    },
  ]

  return <FilterCollapse form={form} onSubmit={onSubmit} items={items} />
}

import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  form {
    .ant-form-item {
      flex: 1;
      margin: 0;

      label {
        color: #6c6c6c;
      }
    }
  }
`

export const SystemData = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;

  strong {
    color: #434343;
  }
`

export const ModulesContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  row-gap: 8px;
`

export const Module = styled.div`
  display: flex;
  align-items: center;
`

export const PagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const PageSubtitle = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #666;
`

export const FieldRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  strong {
    color: #434343;
  }
`

import { useEffect, useMemo, useState } from 'react'

import { DownOutlined, RightOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Button, Form, Skeleton, TabsProps, Tag } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { toast } from 'sonner'

import * as S from './styles'

import { type ModalAuthenticatedFeatures, useToggle } from '@/common'
import { BaseModal, TitleTabs } from '@/components'
import {
  GroupFormFirstStep,
  GroupFormFourthStep,
  GroupFormSecondStep,
  GroupFormThirdStep,
  getSystemsPk,
  formatGroupRoles,
  useEditGroupMutation,
  formatGroupSelectedPermissions,
  formatGroupSystems,
  groupQueries,
  formatAdminSelection,
  filterOutExcludedUserIds,
} from '@/features/groups'
import type {
  EditGroupMutation,
  Group,
  GroupRoles,
  GroupSelectedPermissions,
  GroupSelectedSystems,
  GroupSystem,
} from '@/features/groups'
import { User, UserSituation } from '@/features/users'

interface ViewGroupModalProps extends ModalAuthenticatedFeatures {
  data: Group
  isOpen: boolean
  onClose: () => void
}

interface GroupData {
  group_name: string | undefined
  description: string | undefined
  systems: GroupSelectedSystems[]
  selectedPermissions: GroupSelectedPermissions[]
  group_users_id: string[] | undefined
  isAdmin: boolean
}

export const ViewGroupModal = ({ data, isOpen, onClose, canEdit }: ViewGroupModalProps) => {
  const [form] = Form.useForm()
  const [isEditing, toggleEdit] = useToggle(false)
  const [currentStep, setCurrentStep] = useState(0)

  const { data: group, isFetched, isLoading } = useQuery(groupQueries.detail(data.pk))
  const editGroupMutation = useEditGroupMutation()

  const groupData: GroupData = useMemo(() => {
    const isAdminGroup =
      !!group?.group_systems.find((system) => system.is_admin) ||
      !!group?.group_systems.find((system) => system.admin_modules.length > 0)

    return {
      group_name: group?.group_name,
      description: group?.description,
      systems: formatGroupSystems(group?.group_systems as GroupSystem[]),
      selectedPermissions: formatGroupSelectedPermissions(group?.group_roles),
      group_users_id: filterOutExcludedUserIds(group?.group_users), // remove excluded users
      isAdmin: isAdminGroup,
    }
  }, [group])

  useEffect(() => {
    if (isFetched) {
      form.setFieldsValue(groupData)
    }
  }, [form, groupData, isFetched])

  const groupTabs: TabsProps['items'] = useMemo(() => {
    const tabs = [
      {
        key: '0',
        label: 'Dados do grupo',
      },
      {
        key: '1',
        label: 'Sistemas vinculados',
      },
      {
        key: '2',
        label: 'Permissões vinculadas',
      },
      {
        key: '3',
        label: 'Usuários vinculados',
      },
    ]

    if (groupData.isAdmin) {
      tabs.splice(2, 1)
    }

    return tabs
  }, [groupData])

  const editSteps = [
    {
      title: 'Dados do grupo',
      info: {
        title: 'Dados do grupo',
        description: 'Defina nome e descrição do grupo',
      },
      content: <GroupFormFirstStep />,
    },
    {
      title: 'Vincular sistemas',
      info: {
        title: 'Vincular sistemas ao grupo',
        description: 'Vincule a permissão ao sistema',
      },
      content: <GroupFormSecondStep initialValues={groupData?.systems} />,
    },
    {
      title: 'Vincular permissões',
      info: {
        title: 'Vincular permissões ao grupo',
        description: 'Transfira a permissão para o grupo criado',
      },
      content: (
        <GroupFormThirdStep
          groupName={groupData?.group_name as string}
          groupSystems={groupData?.systems}
          initialData={{
            leftTable: form.getFieldValue('rolesLeftTable') || [],
            rightTable: groupData?.selectedPermissions || [],
          }}
        />
      ),
    },
    {
      title: 'Vincular usuários',
      info: {
        title: 'Vincular usuários ao grupo',
      },
      content: (
        <GroupFormFourthStep
          groupName={groupData?.group_name as string}
          initialData={groupData?.group_users_id}
        />
      ),
    },
  ]

  function handleCancelEdit() {
    toggleEdit()
    form.resetFields()
    form.setFieldsValue(groupData)
  }

  async function handleEditGroup(data: Record<string, unknown>) {
    try {
      await form.validateFields()

      const values = {
        ...form.getFieldsValue(),
        ...data,
      }

      const isGroupStep = String(currentStep) === '0'
      const isSystemsStep = String(currentStep) === '1'
      const isRolesStep = String(currentStep) === '2'
      const isUsersStep = String(currentStep) === '3'

      let payload = {
        pk: group?.pk as string,
      } as EditGroupMutation

      if (isGroupStep) {
        payload = {
          ...payload,
          group_name: values.group_name || (group?.group_name as string),
          description: values.description || (group?.description as string),
        } as EditGroupMutation
      }

      if (isSystemsStep) {
        const wasGroupAdmin = groupData.isAdmin
        const hasSelectedAdmins = values?.group_admin?.length > 0

        if (wasGroupAdmin && !hasSelectedAdmins) {
          toast.error('Selecione pelo menos um sistema ou módulo como administrador para o grupo')
          return
        }

        payload = {
          ...payload,
          group_systems_pk: values.systems
            ? getSystemsPk(values.systems)
            : getSystemsPk(group?.group_systems as GroupSystem[]),
        } as EditGroupMutation

        if (hasSelectedAdmins && values.group_admin) {
          const { group_module_admin, group_system_admin } = formatAdminSelection(
            values.group_admin,
          )

          payload = {
            ...payload,
            group_systems_pk: values.systems
              ? getSystemsPk(values.systems)
              : getSystemsPk(group?.group_systems as GroupSystem[]),
            group_system_admin,
            group_module_admin,
          } as EditGroupMutation
        }
      }

      if (isRolesStep) {
        const isRolesValid =
          values.selectedPermissions &&
          values.selectedPermissions.length > 0 &&
          values.selectedPermissions.every((role: any) => role.active)

        if (!isRolesValid) {
          console.error('Editing with inactive roles')
          toast.error('Remova as permissões inativas antes de continuar')
          return
        }

        payload = {
          ...payload,
          group_systems_pk: values.systems
            ? getSystemsPk(values.systems)
            : getSystemsPk(group?.group_systems as GroupSystem[]),
          group_roles: values.selectedPermissions
            ? formatGroupRoles(values.selectedPermissions)
            : formatGroupRoles(group?.group_roles as GroupRoles[]),
        } as EditGroupMutation
      }

      if (isUsersStep) {
        payload = {
          ...payload,
          group_users_id: values.group_users_id || (groupData.group_users_id as string[]),
        } as EditGroupMutation
      }

      const promise = editGroupMutation.mutateAsync(payload)

      toast.promise(promise, {
        loading: 'Salvando alterações...',
        success: 'Grupo atualizado com sucesso!',
        error: 'Erro ao atualizar grupo',
      })

      await promise

      handleCancelEdit()
    } catch (err) {
      console.error('Error editing group', err)
    }
  }

  const GroupDataView = () => {
    const activeTab = String(currentStep)

    const permissionColumns = [
      {
        title: 'Permissões',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'Descrição',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Status',
        dataIndex: 'active',
        key: 'active',
        render: (active: boolean, record: any) => {
          if (!record.children) return null

          return active ? <Tag color="green">Ativa</Tag> : <Tag color="red">Inativa</Tag>
        },
      },
    ]

    const userColumns = [
      {
        title: 'Nome',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Telefone',
        dataIndex: 'phone_number',
        key: 'phone_number',
      },
      {
        title: 'Status',
        dataIndex: 'user_situation',
        key: 'user_situation',
        render: (status: UserSituation, user: User) => {
          const userSituation =
            user.active && user.enabled
              ? 'Ativo'
              : user.active && !user.enabled
              ? 'Inativo'
              : !user.active && !user.enabled
              ? 'Excluído'
              : 'Indefinido'

          if (status === 'Ativo' || userSituation === 'Ativo') {
            return <Tag color="green">Ativo</Tag>
          }
          if (status === 'Inativo' || userSituation === 'Inativo') {
            return <Tag>Inativo</Tag>
          }
          if (status === 'Excluído' || userSituation === 'Excluído') {
            return <Tag color="red">Excluído</Tag>
          }

          return <Tag>Indefinido</Tag>
        },
      },
    ]

    const tableColumns: ColumnsType<any> = activeTab === '2' ? permissionColumns : userColumns

    const tableData = activeTab === '2' ? groupData?.selectedPermissions : group?.group_users

    if (activeTab === '0') {
      return (
        <S.Container>
          <S.GroupData>
            <span>Nome do grupo</span>
            <strong>{group?.group_name}</strong>
          </S.GroupData>

          <S.GroupData>
            <span>Descrição</span>
            <strong>{group?.description}</strong>
          </S.GroupData>
        </S.Container>
      )
    }

    if (activeTab === '1') {
      return (
        <S.Container>
          <S.GroupSystems>
            {groupData?.systems?.map((system) => {
              const hasAdminModules = system.admin_modules.length > 0

              return (
                <S.System key={system.value}>
                  <div>
                    {system.label}
                    <span>
                      {system.is_admin ? 'Admin sistema' : hasAdminModules ? 'Admin módulo' : ''}
                    </span>
                  </div>
                </S.System>
              )
            })}
          </S.GroupSystems>
        </S.Container>
      )
    }

    return (
      <S.Container>
        <S.ViewTable
          size="small"
          columns={tableColumns}
          pagination={false}
          dataSource={tableData as any}
          rowKey={(record) => record.id || record.key || record.pk}
          title={() => (
            <S.TableHeader>
              {activeTab === '2' ? (
                <span>
                  Sistemas:{' '}
                  <strong>{groupData.systems?.map((system) => system.label)?.join(', ')}</strong>
                </span>
              ) : (
                <span>
                  Grupo: <strong>{groupData.group_name}</strong>
                </span>
              )}
            </S.TableHeader>
          )}
          expandable={{
            expandIcon: ({ expanded, onExpand, record }) => {
              if (!record.children) return null

              if (expanded) {
                return (
                  <Button
                    onClick={(e) => onExpand(record, e)}
                    type="link"
                    icon={<DownOutlined />}
                  />
                )
              }

              return (
                <Button onClick={(e) => onExpand(record, e)} type="link" icon={<RightOutlined />} />
              )
            },
          }}
        />
      </S.Container>
    )
  }

  const ModalFooter = () => {
    if (!isEditing) {
      return (
        <>
          <Button
            onClick={onClose}
            type="link"
            htmlType="button"
            disabled={editGroupMutation.isPending}
          >
            Cancelar
          </Button>

          {canEdit && (
            <Button type="primary" htmlType="button" onClick={toggleEdit}>
              Editar
            </Button>
          )}
        </>
      )
    }

    return (
      <>
        <Button onClick={handleCancelEdit} type="link" disabled={editGroupMutation.isPending}>
          Cancelar
        </Button>

        <Button type="primary" onClick={() => form.submit()} loading={editGroupMutation.isPending}>
          Finalizar
        </Button>
      </>
    )
  }

  return (
    <BaseModal
      open={isOpen}
      onCancel={onClose}
      title="Dados cadastrais do grupo"
      width={!isEditing || currentStep < 2 ? 840 : 1360}
      footer={<ModalFooter />}
    >
      <S.Container>
        {isLoading ? (
          <Skeleton active />
        ) : (
          <>
            {!isEditing ? (
              <>
                <TitleTabs
                  centered
                  defaultActiveKey=""
                  activeKey={String(currentStep)}
                  onChange={(key) => setCurrentStep(String(key) as any)}
                  items={groupTabs}
                />

                <GroupDataView />
              </>
            ) : (
              <Form
                form={form}
                onFinish={handleEditGroup}
                layout="vertical"
                requiredMark
                disabled={editGroupMutation.isPending}
              >
                {editSteps[currentStep].content || editSteps[currentStep].title}
              </Form>
            )}
          </>
        )}
      </S.Container>
    </BaseModal>
  )
}

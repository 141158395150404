import { Button } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const DynamicFieldContainer = styled.div`
  height: 100%;
  max-height: 300px;
  overflow-y: auto;

  margin: 0 -24px;
`

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 24px;
`

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`

export const AddFieldButton = styled(Button)`
  &.ant-btn.ant-btn-icon-only {
    height: 40px !important;
    width: 40px !important;

    border-radius: 9999999px;
    font-size: 14px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const RemoveFieldButton = styled(AddFieldButton)``

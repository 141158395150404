import { useMutation, useQueryClient } from '@tanstack/react-query'

import { parameterQueries } from '../queries'

import { api } from '@/services'

interface EditParameterMutation {
  pk: string
  parameter_name: string
  parameter_type: string
  tipo_contrato: string
  inactivity_days: number
}

export const useEditParameterMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: EditParameterMutation) => {
      return api.put(`parameter/${data.pk}`, data)
    },
    onMutate: async (editedParameter) => {
      await queryClient.cancelQueries({ queryKey: parameterQueries.all() })

      const cachedParameter = queryClient.getQueryData(
        parameterQueries.detail(editedParameter.pk).queryKey,
      )

      if (cachedParameter) {
        queryClient.setQueryData(parameterQueries.detail(editedParameter.pk).queryKey, {
          ...cachedParameter,
          ...editedParameter,
        })
      }

      const cachedParameterList = queryClient.getQueryData(parameterQueries.list().queryKey)

      if (cachedParameterList) {
        const updatedParameterList = cachedParameterList.results.map((parameter) => {
          if (parameter.pk === editedParameter.pk) {
            return {
              ...parameter,
              ...editedParameter,
            }
          }

          return parameter
        })

        queryClient.setQueryData(parameterQueries.list().queryKey, {
          ...cachedParameterList,
          results: updatedParameterList,
        })
      }

      return { cachedParameter, cachedParameterList }
    },
    onError: (err, _, ctx) => {
      console.error('Error editing parameter', err)

      if (ctx?.cachedParameter) {
        queryClient.setQueryData(
          parameterQueries.detail(ctx.cachedParameter.pk).queryKey,
          ctx.cachedParameter,
        )
      }

      if (ctx?.cachedParameterList) {
        queryClient.setQueryData(parameterQueries.list().queryKey, ctx.cachedParameterList)
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: parameterQueries.all() }),
  })
}

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { parameterQueries } from '../queries'
import type { ContractType, ParameterType } from '../types'

import { api } from '@/services'

interface ICreateParameterMutation {
  parameter_name: string
  parameter_type: ParameterType
  tipo_contrato: ContractType
  inactivity_days: number
}

export const useCreateParameterMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: ICreateParameterMutation) => {
      return api.post('/parameter', data)
    },
    onMutate: async (newParameter) => {
      await queryClient.cancelQueries({ queryKey: parameterQueries.all() })

      const cachedParamsList = queryClient.getQueryData(parameterQueries.list().queryKey)

      if (cachedParamsList) {
        const updatedParamsList = [
          ...cachedParamsList.results,
          {
            ...newParameter,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            active: true,
            pk: crypto.randomUUID(),
          },
        ]

        queryClient.setQueryData(parameterQueries.list().queryKey, {
          ...cachedParamsList,
          results: updatedParamsList,
        })
      }

      return cachedParamsList
    },
    onError: (error, _, cachedParamsList) => {
      console.error('Error creating parameter', error)

      if (cachedParamsList) {
        queryClient.setQueryData(parameterQueries.list().queryKey, cachedParamsList)
      }
    },
    onSettled: async () =>
      await queryClient.invalidateQueries({ queryKey: parameterQueries.all() }),
  })
}

import { useEffect } from 'react'

import { CollapseProps, DatePicker, Form, Input } from 'antd'

import { useCompaniesFilter } from '@/app'
import { type FilterValue, useInitialFilterFormValues, type BaseFilterFormProps } from '@/common'
import { FilterCollapse } from '@/components'

export const ComapnyFilterForm = ({ form, onSubmit }: BaseFilterFormProps) => {
  const companyFilters = useCompaniesFilter((s) => s.filters)

  const initialFilterValues = useInitialFilterFormValues(
    companyFilters as unknown as Record<string, FilterValue>,
  )

  useEffect(() => {
    form.setFieldsValue(initialFilterValues)
  }, [form, initialFilterValues])

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Data de criação',
      children: (
        <Form.Item name="created_at">
          <DatePicker.RangePicker allowEmpty format={'DD/MM/YYYY'} />
        </Form.Item>
      ),
    },
    {
      key: '2',
      label: 'Data de atualizacão',
      children: (
        <Form.Item name="updated_at">
          <DatePicker.RangePicker allowEmpty format={'DD/MM/YYYY'} />
        </Form.Item>
      ),
    },
    {
      key: '3',
      label: 'Razão social',
      children: (
        <Form.Item name="company_name">
          <Input.Search placeholder="Ex: ACER" />
        </Form.Item>
      ),
    },
    {
      key: '4',
      label: 'CNPJ',
      children: (
        <Form.Item name="company_cnpj">
          <Input.Search placeholder="Ex: 123456789/0001-23" />
        </Form.Item>
      ),
    },
    {
      key: '5',
      label: 'Responsável',
      children: (
        <Form.Item name="manager_name">
          <Input.Search placeholder="Ex: John Doe" />
        </Form.Item>
      ),
    },
    {
      key: '6',
      label: 'Responsável (e-mail)',
      children: (
        <Form.Item name="manager_email">
          <Input.Search placeholder="Ex: john-doe@email.com" />
        </Form.Item>
      ),
    },
    {
      key: '7',
      label: 'Responsável (telefone)',
      children: (
        <Form.Item name="manager_phone">
          <Input.Search placeholder="Ex: +55 (99) 99999-9999" />
        </Form.Item>
      ),
    },
  ]

  return <FilterCollapse form={form} onSubmit={onSubmit} items={items} />
}

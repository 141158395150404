import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const StepInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  color: #434343;

  h3 {
    font-weight: 700;
    font-size: 16px;
  }

  span {
    font-size: 14px;
  }
`

import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  background-color: #efefef;
  border: solid 1px #d9d9d9;
  border-radius: 6px;
  /* overflow-y: hidden; */
  overflow-y: auto !important;

  th {
    background-color: #ededed !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
    color: ${(props) => props.theme.customTokens.text.tableHeaderText} !important;
  }

  .ant-table-cell-scrollbar {
    background-color: #efefef !important;
    box-shadow: none !important;
  }

  .ant-table-header table {
    table-layout: fixed !important;
  }
  .ant-table-body table {
    table-layout: auto !important;
  }

  .ant-table table {
    font-size: 14px;
  }

  .ant-table th {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
  }

  .ant-table td {
    color: rgba(0, 0, 0, 0.85);
  }

  tr:nth-child(odd) {
    background-color: #efefef;
  }

  .ant-table .ant-table-container .ant-table-content table thead.ant-table-thead .ant-table-cell {
    border-top: 1px solid rgba(0, 0, 0, 0.42);
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-radius: 0px;
    background-color: #ededed;
    color: ${(props) => props.theme.customTokens.text.tableHeaderText};
    font-weight: 400;
    font-size: 14px;
  }

  .ant-table-column-title {
    white-space: nowrap;
  }

  .ant-table .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #eaf0f9;
  }

  .ant-table-wrapper .ant-table-column-sort {
    background: none;
  }

  .ant-table-tbody > tr > td {
    max-height: 56px !important;
    padding: 3px;
  }

  .ant-table-thead > tr > th {
    position: sticky;
    top: 0;
    background-color: #ededed !important;
    z-index: 20;
  }
`

import { DeleteOutlined, FileDoneOutlined, WarningOutlined } from '@ant-design/icons'
import { Button, Modal, Tag } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { useTheme } from 'styled-components'

import { useGroupFilters } from '@/app'
import { type TableAuthenticatedFeatures, formatDate } from '@/common'
import {
  BaseTable,
  TableAction,
  TableDataTooltip,
  TableColumnButton,
  TooltipTable,
} from '@/components'
import type { Group } from '@/features/groups'
import { Role } from '@/features/roles'
import type { System } from '@/features/systems'
import { User } from '@/features/users'
import { SelectGroupAction } from '@/pages'

interface GroupListTableProps extends TableAuthenticatedFeatures {
  data: Group[]
  isLoading: boolean
  handleSelectGroup: ({ group, type }: SelectGroupAction) => void
}

export const GroupListTable = ({
  data,
  isLoading,
  handleSelectGroup,
  canDelete,
}: GroupListTableProps) => {
  const theme = useTheme()
  const setActiveSorting = useGroupFilters((state) => state.setActiveSorting)

  function confirmDeletion(group: Group) {
    Modal.confirm({
      title: `Tem certeza que deseja excluir este grupo?`,
      content: (
        <span>
          O grupo <strong>{group.group_name}</strong> será excluído permanentemente.
        </span>
      ),
      centered: true,
      icon: <WarningOutlined style={{ color: '#FF0000' }} />,
      okText: 'Excluir',
      cancelText: 'Cancelar',
      okButtonProps: {
        type: 'primary',
        style: {
          color: '#FFF',
          backgroundColor: theme.colors.brand,
        },
      },
      cancelButtonProps: {
        type: 'default',
      },
      onOk: () => handleSelectGroup({ group, type: 'DELETE_GROUP' }),
    })
  }

  const columns: ColumnsType<Group> = [
    {
      title: 'nº',
      key: 'number',
      align: 'center',
      width: 50,
      render: (_, __, index) => {
        const number = String(index + 1).padStart(2, '0')

        return <span>{number}</span>
      },
    },
    {
      title: 'Nome do grupo',
      dataIndex: 'group_name',
      key: 'group_name',
      width: 224,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (groupName, group) => (
        <TableColumnButton onClick={() => handleSelectGroup({ group, type: 'VIEW_GROUP' })}>
          {groupName}
        </TableColumnButton>
      ),
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Sistema',
      dataIndex: 'group_systems',
      key: 'system',
      width: 180,
      render: (systems: System[]) => {
        return (
          <TableDataTooltip<System>
            data={systems}
            firstItem={systems?.[0]?.system_name}
            listTitle="Sistemas vinculados"
            nameKey="system_name"
          />
        )
      },
    },
    {
      title: 'Usuários',
      dataIndex: 'group_users',
      key: 'group_users',
      align: 'center',
      width: 220,
      render: (users: User[], record) => {
        return (
          <TooltipTable
            data={users}
            record={record}
            fieldKey="name"
            columns={[
              {
                title: 'Usuário',
                key: 'name',
                dataIndex: 'name',
                align: 'center',
              },
              {
                title: 'Email',
                key: 'email',
                dataIndex: 'email',
                align: 'center',
              },
            ]}
          />
        )
      },
    },
    {
      title: 'Permissões',
      dataIndex: 'group_roles',
      key: 'group_roles',
      align: 'center',
      width: 150,
      render: (roles: Role[], group) => {
        const isAdminGroup =
          !!group?.group_systems.find((system) => system.is_admin) ||
          !!group?.group_systems.find((system) => system.admin_modules.length > 0)

        if (isAdminGroup) return <i style={{ fontWeight: 600 }}>admin</i>

        return (
          <TooltipTable
            tooltipOnly
            data={roles}
            record={group}
            columns={[
              {
                title: 'Permissão',
                key: 'title',
                dataIndex: 'title',
                width: 150,
              },
              {
                title: 'Descrição',
                key: 'description',
                dataIndex: 'description',
                width: 150,
              },
              {
                title: 'Status',
                key: 'active',
                dataIndex: 'active',
                width: 50,
                render: (active: boolean) =>
                  active ? <Tag color="green">Ativa</Tag> : <Tag color="red">Inativa</Tag>,
              },
            ]}
          />
        )
      },
    },
    {
      title: 'Criado em',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 150,
      align: 'center',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (date: string) => formatDate({ date }),
    },
    {
      title: 'Atualizado em',
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: 150,
      align: 'center',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (date: string) => formatDate({ date }),
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      align: 'center',
      render: (_, group) => {
        const actions = [
          {
            key: '1',
            label: (
              <Button
                type="link"
                onClick={() => handleSelectGroup({ group, type: 'VIEW_GROUP' })}
                icon={<FileDoneOutlined />}
              >
                Dados cadastrais
              </Button>
            ),
          },
          canDelete
            ? {
                key: '2',
                label: (
                  <Button
                    danger
                    type="link"
                    icon={<DeleteOutlined />}
                    onClick={() => confirmDeletion(group)}
                  >
                    Excluir
                  </Button>
                ),
              }
            : null,
        ]

        return <TableAction actions={actions} />
      },
    },
  ]

  return (
    <BaseTable
      dataSource={data}
      columns={columns}
      loading={isLoading}
      rowKey={(record) => record.pk}
      setActiveSorting={setActiveSorting}
    />
  )
}

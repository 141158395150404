import { Button } from 'antd'

import type { AreaFilters } from '../../types'
import { AreaFilterForm } from '../Form'

import { useAreaFilters } from '@/app'
import { useFilterActions, useFormatFilters, type BaseFilterSidebarProps } from '@/common'
import { FilterSidebar } from '@/components'

export const AreaFilterSidebar = ({ isOpen, toggleDrawer, form }: BaseFilterSidebarProps) => {
  const setAreaFilters = useAreaFilters((s) => s.setFilters)
  const resetFilters = useAreaFilters((s) => s.resetFilters)
  const resetAreaPagination = useAreaFilters((s) => s.resetPagination)
  const { submitFilters, handleClearFilters } = useFilterActions(form)
  const { formatDateFieldFilter, formatRelationalFields } = useFormatFilters()

  function handleFilter() {
    const values = form.getFieldsValue()

    const filters: AreaFilters = {}

    const relationalFields = {
      systems: 'system_name',
    }

    formatDateFieldFilter(values, filters)
    formatRelationalFields(values, filters, relationalFields)

    setAreaFilters({ ...filters })
    resetAreaPagination()
  }

  return (
    <FilterSidebar
      isDrawerOpen={isOpen}
      toggleDrawer={toggleDrawer}
      filterFooter={
        <>
          <Button onClick={() => handleClearFilters(resetFilters, toggleDrawer)}>Limpar</Button>
          <Button type="primary" htmlType="submit" onClick={() => submitFilters(toggleDrawer)}>
            Filtrar
          </Button>
        </>
      }
    >
      <AreaFilterForm form={form} onSubmit={handleFilter} />
    </FilterSidebar>
  )
}

import { useQuery } from '@tanstack/react-query'

import { mfaQueries } from '..'

import { jwtDecode } from 'jwt-decode'

import { useAuthStore } from '@/app'

export const useRegisterMfaCode = (enabled = true) => {
  const accessToken = useAuthStore((state) => state.tokens.accessToken)

  let decodedToken

  if (accessToken) {
    decodedToken = jwtDecode<{ username: string }>(accessToken)
  }

  return useQuery({
    ...mfaQueries.code(decodedToken?.username as string, accessToken as string),
    enabled: enabled,
  })
}

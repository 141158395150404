import { api } from '@/services'

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { groupQueries } from '..'

export const useDeleteGroupMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (groupPk: string) => {
      return api.delete(`/group/${groupPk}`)
    },
    onMutate: async (groupPk: string) => {
      await queryClient.cancelQueries({ queryKey: groupQueries.all() })

      const cachedGroups = queryClient.getQueryData(groupQueries.list().queryKey)

      if (cachedGroups) {
        const updatedGroups = cachedGroups.results.filter((group) => group.pk !== groupPk)

        queryClient.setQueryData(groupQueries.list().queryKey, {
          ...cachedGroups,
          results: updatedGroups,
        })
      }

      return cachedGroups
    },
    onError: (error, _, cachedGroups) => {
      console.error(error)

      if (cachedGroups) {
        queryClient.setQueryData(groupQueries.list().queryKey, cachedGroups)
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: groupQueries.all() }),
  })
}

import { DeleteOutlined, FileDoneOutlined, WarningOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useTheme } from 'styled-components'

import { useParameterFilters } from '@/app'
import { type TableAuthenticatedFeatures, formatDate } from '@/common'
import { BaseTable, TableAction, TableColumnButton } from '@/components'
import { Parameter } from '@/features/parameters'
import { SelectParameterAction } from '@/pages'

interface ParameterListTableProps extends TableAuthenticatedFeatures {
  data: Parameter[]
  isLoading?: boolean
  handleSelectParameter: ({ parameter, type }: SelectParameterAction) => void
}

export const ParametersListTable = ({
  data,
  isLoading,
  handleSelectParameter,
  canDelete,
}: ParameterListTableProps) => {
  const theme = useTheme()
  const setActiveSorting = useParameterFilters((state) => state.setActiveSorting)

  function confirmDisable(parameter: Parameter) {
    Modal.confirm({
      title: `Tem certeza que deseja excluir este parâmetro?`,
      content: (
        <span>
          O parâmetro <strong>{parameter.parameter_name}</strong> será excluído permanentemente.
        </span>
      ),
      centered: true,
      icon: <WarningOutlined style={{ color: '#FF0000' }} />,
      okText: 'Excluir',
      cancelText: 'Cancelar',
      okButtonProps: {
        type: 'primary',
        style: {
          color: '#FFF',
          backgroundColor: theme.colors.brand,
        },
      },
      cancelButtonProps: {
        type: 'default',
      },
      onOk: () => handleSelectParameter({ parameter, type: 'DELETE_PARAMETER' }),
    })
  }

  const parametersColumns: ColumnsType<Parameter> = [
    {
      title: 'ID',
      key: 'id',
      align: 'center',
      width: 50,
      render: (_, __, index) => {
        const numberIndex = String(index + 1).padStart(2, '0')

        return <span>{numberIndex}</span>
      },
    },
    {
      title: 'Nome',
      dataIndex: 'parameter_name',
      key: 'parameter_name',
      width: 400,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (name: string, parameter) => (
        <TableColumnButton
          onClick={() => handleSelectParameter({ parameter, type: 'VIEW_PARAMETER' })}
        >
          {name}
        </TableColumnButton>
      ),
    },
    {
      title: 'Tipo',
      dataIndex: 'parameter_type',
      key: 'parameter_type',
      width: 400,
    },
    {
      title: 'Contrato',
      dataIndex: 'tipo_contrato',
      key: 'tipo_contrato',
      width: 400,
    },
    {
      title: 'Tempo de inatividade',
      dataIndex: 'inactivity_days',
      key: 'inactivity_days',
      width: 400,
      align: 'center',
      render: (days) => {
        const daysString = String(days).padStart(2, '0')
        const daysText = days === 1 ? 'dia' : 'dias'

        return <span>{`${daysString} ${daysText}`}</span>
      },
    },
    {
      title: 'Criado em',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 400,
      align: 'center',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (date) => formatDate({ date }),
    },
    {
      title: 'Atualizado em',
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: 400,
      align: 'center',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (date) => formatDate({ date }),
    },

    {
      title: 'Ações',
      key: 'actions',
      width: 100,
      align: 'center',
      render: (_, parameter) => {
        const actions = [
          {
            key: '1',
            label: (
              <Button
                type="link"
                onClick={() => handleSelectParameter({ parameter, type: 'VIEW_PARAMETER' })}
                icon={<FileDoneOutlined />}
              >
                Dados Cadastrais
              </Button>
            ),
          },
          canDelete
            ? {
                key: '2',
                label: (
                  <Button
                    danger
                    type="link"
                    onClick={() => confirmDisable(parameter)}
                    icon={<DeleteOutlined />}
                  >
                    Excluir
                  </Button>
                ),
              }
            : null,
        ]
        return <TableAction actions={actions} />
      },
    },
  ]

  return (
    <BaseTable
      dataSource={data}
      columns={parametersColumns}
      loading={isLoading}
      rowKey={(param: Parameter) => param.pk}
      setActiveSorting={setActiveSorting}
    />
  )
}

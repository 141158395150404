import { keepPreviousData, queryOptions } from '@tanstack/react-query'

import type { Role, RoleFilters } from '..'

import type { ActiveSorting, PaginationExpression, ListResponse, FilterValue } from '@/common'
import {
  DEFAULT_ACTIVE_SORTING,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGINATION,
  getFilterExpression,
  handleActiveSorting,
} from '@/common'
import { api } from '@/services'

const fetchRolesList = async (
  filters: RoleFilters,
  sorting: ActiveSorting,
  pagination: PaginationExpression,
) => {
  const filterExpression = getFilterExpression(filters as unknown as Record<string, FilterValue>)

  const activeSorting = handleActiveSorting(sorting)

  const { data } = await api.post<ListResponse<Role[]>>('/role/list', {
    filter_expression: filterExpression,
    pagination_expression: {
      page: pagination.page || DEFAULT_PAGE,
      per_page: pagination.per_page === undefined ? DEFAULT_PAGE_SIZE : pagination.per_page,
    },
    order_by_expression: activeSorting,
  })

  return data
}

const fetchRole = async (rolePk: string) => {
  const { data } = await api.get<Role>(`/role/${rolePk}`)

  return data
}

export const roleQueries = {
  all: () => ['roles'],
  lists: () => [...roleQueries.all(), 'list'],
  list: (
    filters: RoleFilters = {},
    sorting: ActiveSorting = DEFAULT_ACTIVE_SORTING,
    pagination: PaginationExpression = DEFAULT_PAGINATION,
  ) =>
    queryOptions({
      queryKey: [...roleQueries.lists(), filters, sorting, pagination],
      queryFn: () => fetchRolesList(filters, sorting, pagination),
      placeholderData: keepPreviousData,
    }),
  details: () => [...roleQueries.all(), 'details'],
  detail: (rolePk: string) =>
    queryOptions({
      queryKey: [...roleQueries.details(), rolePk],
      queryFn: () => fetchRole(rolePk),
      placeholderData: keepPreviousData,
    }),
}

import { useState } from 'react'
import AuthCode from 'react-auth-code-input'

import { WarningOutlined } from '@ant-design/icons'
import { Button, Divider, Form, Modal, Skeleton, Tooltip } from 'antd'
import { AxiosError } from 'axios'
import { toast } from 'sonner'

import * as S from './styles'

import {
  registerMfaErrorMessages,
  useRegisterMfaCode,
  useRegisterMfaMutation,
  useUserMfaStatus,
} from '@/features/auth'
import { PageLayout } from '@/layouts/PageLayout'

type RegisterMfaFormData = {
  code: string
}

export const RegisterMFAPage = () => {
  const [form] = Form.useForm<RegisterMfaFormData>()
  const [hasError, setHasError] = useState(false)

  const {
    data: userMfaStatus,
    isLoading: userMfaStatusInitialLoading,
    isPending: userMfaStatusLoading,
    isError: userMfaStatusError,
    refetch: refetchUserMfaStatus,
  } = useUserMfaStatus()

  const shouldFetchMfaCode =
    !userMfaStatusError &&
    !userMfaStatusInitialLoading &&
    (userMfaStatus?.software_token_mfa_status === 'VERIFY_SOFTWARE_TOKEN' ||
      userMfaStatus?.software_token_mfa_status === 'SOFTWARE_TOKEN_CONFIGURATION')

  const {
    data: mfaQrCode,
    isLoading: mfaQrCodeInitialLoading,
    isFetching: mfaQrCodeFetching,
    isError: mfaQrCodeError,
    isRefetching: mfaQrCodeRefetching,
    refetch: refetchMfaQrCode,
  } = useRegisterMfaCode(shouldFetchMfaCode)

  const registerMfaMutation = useRegisterMfaMutation()

  const userHasMfaEnabled = userMfaStatus?.software_token_mfa_status === 'SUCCESS'

  async function handleSubmit({ code }: RegisterMfaFormData) {
    const promise = registerMfaMutation.mutateAsync({ code })

    toast.promise(promise, {
      loading: 'Cadastrando MFA...',
      success: () => {
        refetchUserMfaStatus()

        return 'MFA cadastrado com sucesso!'
      },
      error: (error: AxiosError<{ message?: string }>) => {
        console.error(error)

        const errorMessage =
          registerMfaErrorMessages.get(error.response?.data?.message as string) ||
          'Error ao cadastrar MFA'

        return errorMessage
      },
    })
  }

  function confirmChangeMFA() {
    Modal.confirm({
      title: `Tem certeza que deseja alterar seu código MFA cadastrado?`,
      content: (
        <span>
          Ao confirmar, seu antigo código MFA será desativado e você precisará cadastrar um novo
        </span>
      ),
      centered: true,
      icon: <WarningOutlined style={{ color: '#ff8b27' }} />,
      okText: 'Alterar',
      cancelText: 'Cancelar',
      okButtonProps: {
        type: 'primary',
        style: {
          color: '#FFF',
          backgroundColor: '#80c343',
        },
      },
      cancelButtonProps: {
        type: 'default',
      },
      onOk: () => handleRegisterNewMFA(),
    })
  }

  async function handleRegisterNewMFA() {
    await refetchMfaQrCode()

    refetchUserMfaStatus()
  }

  const SkeletonPage = () => (
    <S.Container>
      <S.PageTitle>
        <Skeleton.Input active block style={{ width: 128 }} />
      </S.PageTitle>

      <S.MfaSection $hasError={hasError} style={{ width: 360 }}>
        <Skeleton.Input active block />

        <Skeleton.Image active style={{ width: 256, height: 256 }} />

        <Divider type="horizontal" style={{ margin: '8px 0px' }} />

        <Skeleton.Input active block />

        <Skeleton.Input active block />

        <Skeleton.Button active block />
      </S.MfaSection>
    </S.Container>
  )

  return (
    <PageLayout title="Cadastrar MFA" isListPage={false}>
      {mfaQrCodeInitialLoading || userMfaStatusInitialLoading ? (
        <SkeletonPage />
      ) : (
        <S.Container>
          <S.PageTitle>Cadastrar MFA</S.PageTitle>

          {userHasMfaEnabled ? (
            <S.NewMfaSection>
              <S.NewMfaText>
                <S.Disclaimer $size={442}>
                  Você já possui um app de autenticação cadastrado
                </S.Disclaimer>

                <span>Deseja cadastrar um app diferente?</span>
              </S.NewMfaText>

              <Button
                type="primary"
                htmlType="button"
                block
                onClick={confirmChangeMFA}
                loading={userMfaStatusLoading || mfaQrCodeRefetching}
              >
                Alterar MFA
              </Button>
            </S.NewMfaSection>
          ) : (
            <>
              <S.MfaSection $hasError={hasError}>
                <S.TooltipContainer>
                  <S.Disclaimer $size={292}>
                    Faça a leitura do QR Code com seu app de autenticação{' '}
                  </S.Disclaimer>

                  <Tooltip title="Posicione a câmera do seu aplicativo de autenticação sobre o QR Code para escanear. Isso permitirá que o aplicativo acesse as informações necessárias para a autenticação segura da sua conta.">
                    <S.TooltipIcon />
                  </Tooltip>
                </S.TooltipContainer>

                <S.QrCode
                  size={256}
                  value={`${mfaQrCode?.data.mfa_qrcode_value}`}
                  status={
                    mfaQrCodeInitialLoading || mfaQrCodeFetching
                      ? 'loading'
                      : mfaQrCodeError
                      ? 'expired'
                      : 'active'
                  }
                  onRefresh={() => refetchMfaQrCode()}
                />

                <Divider type="horizontal" style={{ margin: '8px 0px' }} />

                <S.Disclaimer $size={392}>
                  Digite o código fornecido pelo app para concluir o seu cadastro
                </S.Disclaimer>

                <Form
                  layout="vertical"
                  onFinish={handleSubmit}
                  form={form}
                  onError={(e) => console.log(e)}
                  disabled={registerMfaMutation.isPending}
                >
                  <S.FormContainer>
                    <Form.Item
                      name="code"
                      validateTrigger="onSubmit"
                      data-testid="mfa-form-code"
                      rules={[
                        () => ({
                          validator(_, value) {
                            if (value === undefined) {
                              setHasError(true)
                              return Promise.reject(new Error('Insira um codigo'))
                            }

                            if (value?.length < 6) {
                              setHasError(true)
                              return Promise.reject(new Error('Insira um codigo valido'))
                            }

                            setHasError(false)
                            return Promise.resolve()
                          },
                        }),
                      ]}
                    >
                      <AuthCode
                        containerClassName="input-container"
                        inputClassName="mfa-input"
                        onChange={(code) => code}
                        placeholder="3"
                      />
                    </Form.Item>

                    <Button
                      block
                      type="primary"
                      htmlType="submit"
                      loading={registerMfaMutation.isPending}
                    >
                      Concluir cadastro
                    </Button>
                  </S.FormContainer>
                </Form>
              </S.MfaSection>
            </>
          )}
        </S.Container>
      )}
    </PageLayout>
  )
}

import { Select } from 'antd'

import * as S from './styles'

import { DEFAULT_PAGE } from '@/common'

interface CustomPaginationProps {
  page: number | undefined
  pageSize: number | undefined
  totalItems: number | undefined
  totalPages: number
  isLoading: boolean
  setPagination: (page: number, pageSize: number) => void
  scrollToTop?: boolean
}

export const CustomPagination = ({
  page = 0,
  pageSize,
  totalItems,
  totalPages,
  isLoading,
  setPagination,
  scrollToTop,
}: CustomPaginationProps) => {
  function handleChangePage(page: number, perPage: number) {
    if (page > 0) {
      const pageNumber = page > totalPages ? totalPages : page
      const isNewPageSize = pageSize !== perPage

      if (isNewPageSize) {
        return setPagination(DEFAULT_PAGE, perPage)
      }

      setPagination(pageNumber, perPage)

      if (scrollToTop) {
        window.scrollTo(0, 0)
      }
    }
  }

  return (
    <S.Pagination
      size="small"
      current={page}
      defaultCurrent={page}
      total={totalItems}
      pageSize={pageSize}
      defaultPageSize={pageSize}
      disabled={isLoading}
      showQuickJumper
      showSizeChanger={false}
      onChange={(page, pageSize) => handleChangePage(page, pageSize)}
      showTotal={(total, range) => {
        if (isLoading) return <></>

        return (
          <S.TotalContainer>
            <Select
              size="small"
              value={pageSize}
              options={[
                { label: '10 / página', value: 10 },
                { label: '20 / página', value: 20 },
                { label: '50 / página', value: 50 },
                { label: '100 / página', value: 100 },
              ]}
              onChange={(newPageSize) => handleChangePage(page, newPageSize)}
            />
            <span>
              Exibindo {range[0]} - {range[1]} do total de <S.TotalPages>{total}</S.TotalPages>{' '}
              registros
            </span>
          </S.TotalContainer>
        )
      }}
      locale={{
        items_per_page: '/ página',
        jump_to: 'Ir para página',
        jump_to_confirm: 'Confirma ir para',
        page: '',
        prev_page: 'Página anterior',
        next_page: 'Próxima página',
      }}
    />
  )
}

import { CloseOutlined } from '@ant-design/icons'
import { Button, Input } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  .ant-input-suffix {
    transition: all 0.2s ease;

    :hover {
      filter: brightness(1.6);
    }
  }
`

export const InputTrigger = styled(Button)`
  border-color: ${({ theme }) => theme.colors.brand};
  color: ${({ theme }) => theme.colors.brand};
  background-color: #fff;
`

export const CloseIcon = styled(CloseOutlined)`
  transition: all 0.2s ease;
  cursor: pointer;
`

export const SearchInput = styled(Input.Search)`
  .ant-input-search-button {
    color: ${({ theme }) => theme.colors.brand} !important;
  }
`

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { roleQueries } from '../queries'
import type { Role, RegisterRolePermission } from '../types'

import type { Group } from '@/features/groups'
import { api } from '@/services'

interface ICreateRoleMutation {
  title: string
  description: string
  role_value: string
  role_permissions: RegisterRolePermission[]
  system_module_id: string
}

export const useCreateRoleMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: ICreateRoleMutation) => {
      return api.post('/role', data)
    },
    onMutate: async (data) => {
      await queryClient.cancelQueries({ queryKey: roleQueries.all() })

      const cachedRoles = queryClient.getQueryData(roleQueries.list().queryKey)

      if (cachedRoles) {
        const newRole = {
          ...data,
          role_permissions: data.role_permissions,
          pk: crypto.randomUUID(),
          active: true,
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          system_module: {},
          system: {},
          groups: [] as Group[],
        } as Role

        queryClient.setQueryData(roleQueries.list().queryKey, {
          ...cachedRoles,
          results: [...cachedRoles.results, newRole],
        })
      }

      return cachedRoles
    },
    onError: (error, _, cachedRoles) => {
      console.error(error)

      if (cachedRoles) {
        queryClient.setQueryData(roleQueries.list().queryKey, cachedRoles)
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: roleQueries.all() }),
  })
}

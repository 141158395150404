import { Button } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const TitleButton = styled(Button)`
  font-size: 14px;
  /* font-weight: 600; */
  padding: 0;

  cursor: default;
  pointer-events: none;
`

export const List = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    color: #525252;
    font-weight: 700;
    padding: 8px 8px 0;
  }

  .ant-divider {
    margin: 8px 0;
  }
`

export const Data = styled.span`
  color: #525252;
  padding: 0 16px;
`

export const Tooltip = styled.div`
  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 2px;

  font-size: 14px;
  color: #fff;
  background-color: ${({ theme }) => theme.colors.brand};
  border-radius: 9999999px;

  cursor: default;
`

export const EmptyValue = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`

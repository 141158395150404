import { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { CollapseProps, DatePicker, Form } from 'antd'

import { useAreaFilters } from '@/app'
import { type FilterValue, useInitialFilterFormValues, type BaseFilterFormProps } from '@/common'
import { CheckboxFilter, FilterCollapse } from '@/components'
import { systemQueries } from '@/features/systems'

export const AreaFilterForm = ({ form, onSubmit }: BaseFilterFormProps) => {
  const areaFilters = useAreaFilters((state) => state.filters)

  const initialFilterValues = useInitialFilterFormValues(
    areaFilters as unknown as Record<string, FilterValue>,
  )

  const [systemsSearch, setSystemsSearch] = useState('')

  const { data: systems, isFetching } = useQuery(
    systemQueries.list({
      system_name: {
        value: systemsSearch,
        comparator: 'like',
      },
    }),
  )

  useEffect(() => {
    form.setFieldsValue(initialFilterValues)
  }, [form, initialFilterValues])

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Data de criação',
      children: (
        <Form.Item name="created_at">
          <DatePicker.RangePicker allowEmpty format={'DD/MM/YYYY'} />
        </Form.Item>
      ),
    },
    {
      key: '2',
      label: 'Data de atualizacão',
      children: (
        <Form.Item name="updated_at">
          <DatePicker.RangePicker allowEmpty format={'DD/MM/YYYY'} />
        </Form.Item>
      ),
    },
    {
      key: '3',
      label: 'Sistema',
      children: (
        <Form.Item name="systems">
          <CheckboxFilter
            filterNameKey="system_name"
            formFieldName="systems"
            searchValue={systemsSearch}
            filterSearch={setSystemsSearch}
            optionsData={systems?.results}
            isSearching={isFetching}
            initialValues={initialFilterValues?.systems?.system_name}
          />
        </Form.Item>
      ),
    },
  ]

  return <FilterCollapse form={form} onSubmit={onSubmit} items={items} />
}

import { useMemo } from 'react'

import { TabsProps } from 'antd'

import * as S from './styles'

interface TitleTabsProps extends TabsProps {
  title?: string
}

export const TitleTabs = ({ title, ...rest }: TitleTabsProps) => {
  const isCentered = rest.centered ?? true

  const items: TabsProps['items'] = useMemo(() => {
    if (title) {
      return [
        {
          key: title,
          label: title,
        },
      ]
    }
  }, [title])

  return <S.Tabs items={items} moreIcon={false} $isCentered={isCentered} {...rest} />
}

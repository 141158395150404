import * as S from './styles'

type PageTitleProps = {
  title: string
}

export const PageTitle = ({ title }: PageTitleProps) => {
  const items = [
    {
      label: title,
      key: title,
    },
  ]

  return <S.Title items={items} />
}

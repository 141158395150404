import { FilterTag } from '../FilterTag'
import * as S from './styles'

import { filtersRemap, formatDate, isPlainObject } from '@/common'

interface ActiveFiltersListProps<T> {
  filterList: [string, any][]

  removeFilter: (filterKey: keyof T, filterValue?: string) => void
  clearFilters: () => void
  valueRemap?: Map<string, string>
}

export const ActiveFiltersList = <T extends Record<string, any>>({
  filterList,
  removeFilter,
  clearFilters,
  valueRemap,
}: ActiveFiltersListProps<T>) => {
  function filterNullValues(arr: [string, any][]) {
    return arr.filter(([_, value]) => value)
  }

  function handleDates(dates: string | string[]): string | string[] {
    const isArray = Array.isArray(dates)

    let formattedDates: string | string[] = ''

    if (isArray) {
      const filteredDates = dates?.filter(Boolean)

      formattedDates = filteredDates.map((date) => {
        return formatDate({ date })
      })
    }

    if (!isArray) {
      formattedDates = formatDate({ date: dates as string })
    }

    return formattedDates
  }

  function getDateFilterName(filterKey: string) {
    const filterName = filterKey === 'created_at' ? 'Data de criação' : 'Data de atualização'

    return filterName
  }

  // remove active & enabled filters for user_situation filter
  const formattedFilterList = filterList
    ?.filter(([key]) => key !== 'active' && key !== 'enabled')
    .filter(([key, value]) => {
      // remove user_situation filter if value is empty
      if (key === 'user_situation' && !value?.value) {
        return false
      }

      return true
    })

  return (
    <S.Container>
      {formattedFilterList.map(([filterKey, filterValue]) => {
        // if (filterKey === 'active' && filterValue !== undefined) {
        //   return (
        //     <FilterTag
        //       closable
        //       handleRemoveTag={() => removeFilter(filterKey)}
        //       key={`${filterKey}-${filterValue.value}`}
        //     >
        //       {`${filtersRemap.get(filterKey) || filterKey} : ${
        //         filterValue.value ? 'Ativo' : 'Inativo'
        //       }`}
        //     </FilterTag>
        //   )
        // }

        if (filterKey === 'active' || filterKey === 'enabled') return null

        if (!filterValue) return null

        const { value } = filterValue

        const isRelationalFilterOrUserSituation = !value && isPlainObject(filterValue)

        const isDate = filterKey === 'created_at' || filterKey === 'updated_at'

        if (isDate) {
          const dates = handleDates(value)

          const dateFilterName = getDateFilterName(filterKey)

          return (
            <FilterTag
              closable
              handleRemoveTag={() => removeFilter(filterKey)}
              key={`${filterKey}-${value}`}
            >
              <S.FilterContainer>
                <S.FilterKey>{dateFilterName}</S.FilterKey>

                <S.Divider type="vertical" />

                <S.FilterValue>
                  {typeof dates === 'string' ? dates : dates.join(' - ')}
                </S.FilterValue>
              </S.FilterContainer>
            </FilterTag>
          )
        }

        if (Array.isArray(value)) {
          const formattedValues = value.map((val) => valueRemap?.get(val) || val)

          return (
            <FilterTag closable handleRemoveTag={() => removeFilter(filterKey)} key={filterKey}>
              <S.FilterContainer>
                <S.FilterKey>{filtersRemap.get(filterKey) || filterKey}</S.FilterKey>

                <S.Divider type="vertical" />

                <S.FilterValue>{formattedValues.join(', ')}</S.FilterValue>
              </S.FilterContainer>
            </FilterTag>
          )
        }

        if (isRelationalFilterOrUserSituation) {
          console.log({ filterKey, value })

          if (filterKey === 'user_situation' && !value) {
            return null
          }

          const formattedValues = Object.values(filterValue).map((val) => {
            const value = (val as Record<string, string[]>)?.value

            if (!value || value.length <= 0) return null

            const formattedValues = value.map(
              (checkedFilter) => valueRemap?.get(checkedFilter) || checkedFilter,
            )

            if (formattedValues.length > 4) {
              formattedValues.splice(4, formattedValues.length - 4, '...')
            }

            return formattedValues.join(', ')
          })

          return (
            <FilterTag closable handleRemoveTag={() => removeFilter(filterKey)} key={filterKey}>
              <S.FilterContainer>
                <S.FilterKey>{filtersRemap.get(filterKey) || filterKey}</S.FilterKey>

                <S.Divider type="vertical" />

                <S.FilterValue>{formattedValues}</S.FilterValue>
              </S.FilterContainer>
            </FilterTag>
          )
        }

        return (
          <FilterTag
            closable
            handleRemoveTag={() => removeFilter(filterKey)}
            key={`${filterKey}-${value}`}
          >
            <S.FilterContainer>
              <S.FilterKey>{filtersRemap.get(filterKey) || filterKey}</S.FilterKey>

              <S.Divider type="vertical" />

              <S.FilterValue>{valueRemap?.get(value) || value}</S.FilterValue>
            </S.FilterContainer>
          </FilterTag>
        )
      })}

      {formattedFilterList && filterNullValues(formattedFilterList).length > 1 && (
        <S.ClearFilters type="default" htmlType="button" onClick={clearFilters}>
          <span>Limpar Filtros</span>
        </S.ClearFilters>
      )}
    </S.Container>
  )
}

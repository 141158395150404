import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Collapse, CollapseProps } from 'antd'

import * as S from './styles'

import type { BaseFilterFormProps } from '@/common'

interface FilterCollapseProps extends BaseFilterFormProps, CollapseProps {}

export const FilterCollapse = ({ form, onSubmit, ...rest }: FilterCollapseProps) => {
  return (
    <S.Form onFinish={onSubmit} layout="vertical" form={form}>
      <Collapse
        accordion
        defaultActiveKey={'1'}
        expandIconPosition="end"
        bordered={false}
        style={{ background: 'transparent' }}
        expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}
        {...rest}
      />
    </S.Form>
  )
}

import { Pagination as AntdPagination } from 'antd'
import styled from 'styled-components'

export const Pagination = styled(AntdPagination)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 0;

  .ant-pagination-total-text {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .ant-pagination-item-link:not([disabled]) {
    color: ${({ theme }) => theme.colors.brand};
  }

  .ant-pagination-options {
    margin: 0;
  }

  .ant-pagination-item {
    a {
      color: ${({ theme }) => theme.colors.brand} !important;
      font-weight: 500;
      border-radius: 4px !important;
    }
  }

  .ant-pagination-item-active {
    background-color: transparent !important;

    a {
      color: #434343 !important;
      font-weight: 900;
    }
  }
`

export const TotalContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const TotalPages = styled.strong`
  color: #0c6dff;
`

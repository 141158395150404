import { Button, Drawer, Layout } from 'antd'
import { rgba } from 'polished'
import styled from 'styled-components'

export const Container = styled(Layout.Header)`
  height: 50px;
  padding: 0 16px;
  background-color: ${({ theme }) => theme.customTokens.header.bgColor};
  border-bottom: 3px solid ${({ theme }) => theme.colors.brand};

  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    cursor: pointer;
  }
`

export const LogoContainer = styled.div`
  padding: 0 8px;
`

export const SystemName = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #94d956;
`

export const HeaderActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const HeaderButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;

  padding: 4px 8px;
  font-size: 14px;

  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;

  transition: all 0.2s ease;

  &:hover,
  .ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
    color: ${({ theme }) => theme.colors.brand};
  }

  .ant-badge {
    transition: all 0.2s ease;
    color: ${({ theme }) => theme.colors.white};

    &:hover,
    .ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
      color: ${({ theme }) => theme.colors.brand};
    }
  }
`

export const MenuDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 8px;
  }

  .ant-drawer-title {
    color: ${({ theme }) => theme.colors.text};

    :not(:disabled):hover {
      color: ${({ theme }) => theme.colors.text};
    }
  }

  .ant-drawer-extra {
    color: ${({ theme }) => theme.colors.brand};

    :not(:disabled):hover {
      color: ${({ theme }) => rgba(theme.colors.brand, 0.8)};
    }
  }

  .ant-drawer-footer {
    display: flex;
    justify-content: end;

    .ant-btn-default {
      :not(:disabled):hover {
        color: ${({ theme }) => theme.colors.brand};
      }
    }
  }
`

export const MenuContent = styled.div`
  .ant-menu-light.ant-menu-root.ant-menu-inline {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 0;
  }

  .ant-menu-item {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.brand};

    &:hover {
      color: ${({ theme }) => theme.colors.white} !important;
      background: ${({ theme }) => rgba(theme.colors.brand, 1)} !important;
    }

    &.ant-menu-item.ant-menu-item-selected {
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => rgba(theme.colors.brand, 1)};

      font-weight: 700;
    }

    span.ant-menu-title-content {
      transition: 0s;
    }
  }
`

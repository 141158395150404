import { useReducer } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Button, Form } from 'antd'
import { toast } from 'sonner'
import { shallow } from 'zustand/shallow'

import * as S from './styles'

import { useCompaniesFilter } from '@/app'
import {
  type TitleTabItem,
  getActiveFiltersList,
  pagePermissionsKeys,
  removeActiveFilter,
  useAuth,
  useFilterActions,
  useTabNavigation,
  useToggle,
} from '@/common'
import { ActiveFiltersList, CustomPagination, PageHeader, SearchInput } from '@/components'
import { TitleTabs } from '@/components/Layout'
import {
  CompaniesListTable,
  CompanyFilterSidebar,
  CreateCompanyModal,
  ViewCompanyModal,
  companyQueries,
  useDeleteCompanyMutation,
} from '@/features/company'
import type { Company, CompanyFilters } from '@/features/company'
import { PageLayout } from '@/layouts/PageLayout'

export type SelectCompanyAction = {
  company: Company
  type: 'DELETE_COMPANY' | 'VIEW_COMPANY'
}

export const CompaniesPage = () => {
  const navigateToTab = useTabNavigation()
  const [form] = Form.useForm()

  const { handleClearFilters, handleSearchFilter } = useFilterActions(form)

  const { getPagePermissions, formatPagePermissions, formatTitleTabItems } = useAuth()

  const pagePerms = getPagePermissions({
    moduleKey: pagePermissionsKeys.companies.module,
    roleKey: pagePermissionsKeys.companies.role,
  })

  const { isAdmin, canCreate, canDelete, canEdit } = formatPagePermissions(pagePerms)

  const {
    companyFilters,
    activeSorting,
    companyPagination,
    setFilters,
    setCompanyPagination,
    resetFilters,
    resetCompanyPagination,
  } = useCompaniesFilter(
    (s) => ({
      companyFilters: s.filters,
      activeSorting: s.activeSorting,
      companyPagination: s.pagination,
      setFilters: s.setFilters,
      setCompanyPagination: s.setPagination,
      resetFilters: s.resetFilters,
      resetCompanyPagination: s.resetPagination,
    }),
    shallow,
  )

  const {
    data: companies,
    isLoading,
    isFetching,
  } = useQuery(companyQueries.list(companyFilters, activeSorting, companyPagination))

  const deleteCompanyMutation = useDeleteCompanyMutation()

  const [isFilterDrawerOpen, toggleFilterDrawer] = useToggle(false)
  const [isCreateCompanyModalOpen, toggleCreateCompanyModal] = useToggle(false)
  const [isViewCompanyModalOpen, toggleViewCompanyModal] = useToggle(false)

  const [selectedCompany, selectCompanyDispatch] = useReducer(
    (_: Company | null, action: SelectCompanyAction) => {
      switch (action.type) {
        case 'DELETE_COMPANY':
          handleDeleteCompany(action.company)
          return null
        case 'VIEW_COMPANY':
          toggleViewCompanyModal()
          return action.company
        default:
          return null
      }
    },
    null,
  )

  const titleTabItems: TitleTabItem[] = [
    {
      key: 'users',
      label: 'Usuários',
      disabled: false,
      allowedRoles: ['usuarios'],
    },
    {
      key: 'companies',
      label: 'Empresas',
      disabled: true,
      allowedRoles: ['empresas'],
    },
  ]

  async function handleDeleteCompany(company: Company) {
    if (deleteCompanyMutation.isPending) return

    const promise = deleteCompanyMutation.mutateAsync(company.pk)

    toast.promise(promise, {
      loading: 'Excluindo empresa...',
      success: 'Empresa excluída com sucesso!',
      error: 'Erro ao excluir a empresa!',
    })
  }

  function handleRemoveFilter(filterKey: keyof CompanyFilters, filterValue?: string) {
    const updatedFilters = removeActiveFilter({
      activeFilters: companyFilters,
      filterKey,
      filterValue,
      form,
    })

    setFilters({ ...updatedFilters })
  }

  const TabExtraContent = () => (
    <S.ExtraContent>
      <SearchInput
        placeholder="Nome fantasia"
        initialValue={companyFilters.company_fancy_name?.value}
        onSearch={(searchValue) =>
          handleSearchFilter({
            searchValue,
            storeCallback: setFilters,
            filterKey: 'company_fancy_name',
            resetPagination: resetCompanyPagination,
          })
        }
        onClose={() => setFilters({ company_fancy_name: undefined })}
      />

      {pagePerms && (isAdmin || canCreate) && (
        <Button type="primary" icon={<PlusOutlined />} onClick={toggleCreateCompanyModal}>
          Nova empresa
        </Button>
      )}
    </S.ExtraContent>
  )

  return (
    <PageLayout
      title="Empresas"
      isListPage
      sidebar={
        <CompanyFilterSidebar
          form={form}
          isOpen={isFilterDrawerOpen}
          toggleDrawer={toggleFilterDrawer}
        />
      }
    >
      {isCreateCompanyModalOpen && (
        <CreateCompanyModal isOpen={isCreateCompanyModalOpen} onClose={toggleCreateCompanyModal} />
      )}

      {isViewCompanyModalOpen && (
        <ViewCompanyModal
          isOpen={isViewCompanyModalOpen}
          onClose={toggleViewCompanyModal}
          data={selectedCompany as Company}
          canEdit={isAdmin || canEdit}
        />
      )}

      <PageHeader
        title="Lista de empresas"
        isLoading={isLoading || isFetching}
        itemsFound={companies?.info.total || 0}
      />

      <TitleTabs
        defaultActiveKey="companies"
        items={isAdmin ? titleTabItems : formatTitleTabItems(titleTabItems)}
        onChange={navigateToTab}
        tabBarExtraContent={<TabExtraContent />}
      />

      {getActiveFiltersList(companyFilters).length > 0 ? (
        <ActiveFiltersList
          filterList={getActiveFiltersList(companyFilters)}
          removeFilter={handleRemoveFilter}
          clearFilters={() => handleClearFilters(resetFilters)}
        />
      ) : null}

      <CompaniesListTable
        data={companies?.results || []}
        isLoading={isLoading}
        handleSelectCompany={selectCompanyDispatch}
        canDelete={isAdmin || canDelete}
      />

      <CustomPagination
        scrollToTop
        isLoading={isLoading}
        page={companies?.info.current_page || companyPagination.page}
        pageSize={companyPagination.per_page}
        totalItems={companies?.info.total}
        totalPages={companies?.info.pages || 0}
        setPagination={setCompanyPagination}
      />
    </PageLayout>
  )
}

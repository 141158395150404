import { createJSONStorage, devtools, persist } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE, type BaseFilterStore } from '@/common'
import type { ParameterFilters } from '@/features/parameters'

interface ParameterFilterStore extends BaseFilterStore {
  filters: ParameterFilters
  setFilters: (filters: ParameterFilters) => void
}

const parameterFilterStore = createWithEqualityFn<ParameterFilterStore>()(
  devtools(
    persist(
      (set) => ({
        filters: {
          parameter_name: undefined,
          tipo_contrato: undefined,
          inactivity_days: undefined,
          created_at: undefined,
          updated_at: undefined,
        },

        activeSorting: {
          field: undefined,
          order: undefined,
        },

        pagination: {
          page: DEFAULT_PAGE,
          per_page: DEFAULT_PAGE_SIZE,
        },

        setFilters: (filters) => {
          set(
            (oldState) => ({
              filters: { ...oldState.filters, ...filters },
            }),
            false,
            'set-parameter-filters',
          )
        },

        setActiveSorting: (sorting) => {
          set({ activeSorting: sorting }, false, 'set-parameter-sorting')
        },

        setPagination: (page, pageSize) => {
          set(
            (oldState) => ({
              pagination: {
                page,
                per_page: pageSize || oldState.pagination.per_page,
              },
            }),
            false,
            'set-parameter-pagination',
          )
        },

        resetPagination: () => {
          set(
            {
              pagination: {
                page: DEFAULT_PAGE,
                per_page: DEFAULT_PAGE_SIZE,
              },
            },
            false,
            'reset-parameter-pagination',
          )
        },

        resetFilters: () => {
          set(
            {
              filters: {
                parameter_name: undefined,
                tipo_contrato: undefined,
                inactivity_days: undefined,
                created_at: undefined,
                updated_at: undefined,
              },
            },
            false,
            'reset-parameter-filters',
          )
        },
      }),
      {
        name: 'parameters-filter-store',
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
    { name: 'parameters-filter-store' },
  ),
  shallow,
)

export { parameterFilterStore, parameterFilterStore as useParameterFilters }

import { Button, Divider as AntdDivider } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  /* max-width: 90%; */
  /* overflow: hidden; */
`

export const ClearFilters = styled(Button)`
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 8px;
  height: 22px;
  border-radius: 4px;

  color: ${({ theme }) => theme.colors.brand};
  border: 1px solid ${({ theme }) => theme.colors.brand};
`

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  max-width: 100%;
`

export const FilterKey = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: #8c8c8c;
`

export const FilterValue = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: #0c6dff;
  /* max-width: 40%; */
  /* overflow: hidden; */
  white-space: break-spaces;
`

export const Divider = styled(AntdDivider)`
  margin: 0;
`

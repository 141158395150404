import { createJSONStorage, devtools, persist } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@/common'
import type { BaseFilterStore } from '@/common'
import type { UserLogFilters } from '@/features/users'

interface UserLogFilterStore extends BaseFilterStore {
  filters: UserLogFilters
  setFilters: (filters: UserLogFilters) => void
}

const userLogFilterStore = createWithEqualityFn<UserLogFilterStore>()(
  devtools(
    persist(
      (set) => ({
        filters: {
          // host_ip: undefined,
          log_type: undefined,
          log_path: undefined,
          log_area: undefined,
          // user_id: undefined,
          created_at: undefined,
          updated_at: undefined,
          user: undefined,
        },

        activeSorting: {
          field: undefined,
          order: undefined,
        },

        pagination: {
          page: DEFAULT_PAGE,
          per_page: DEFAULT_PAGE_SIZE,
        },

        setFilters: (filters) => {
          set(
            (oldState) => ({
              filters: { ...oldState.filters, ...filters },
            }),
            false,
            'set-user-logs-filters',
          )
        },

        setActiveSorting: (sorting) => {
          set({ activeSorting: sorting }, false, 'set-user-logs-sorting')
        },

        setPagination: (page, pageSize) => {
          set(
            (oldState) => ({
              pagination: {
                page,
                per_page: pageSize ?? oldState.pagination.per_page,
              },
            }),
            false,
            'set-user-logs-pagination',
          )
        },

        resetPagination: () => {
          set(
            {
              pagination: {
                page: DEFAULT_PAGE,
                per_page: DEFAULT_PAGE_SIZE,
              },
            },
            false,
            'reset-user-logs-pagination',
          )
        },

        resetFilters: () => {
          set(
            {
              filters: {
                // host_ip: undefined,
                log_type: undefined,
                log_path: undefined,
                log_area: undefined,
                // user_id: undefined,
                created_at: undefined,
                updated_at: undefined,
                user: undefined,
              },
            },
            false,
            'reset-user-logs-filters',
          )
        },
      }),
      {
        name: 'user-logs-filter-store',
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
    {
      name: 'user-logs-filter-store',
    },
  ),
  shallow,
)

export { userLogFilterStore, userLogFilterStore as useUserLogFilter }

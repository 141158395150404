import { api } from '@/services'

import { keepPreviousData, queryOptions } from '@tanstack/react-query'

import { RegisterMFACodeResponse, UserMfaStatus } from '..'

async function fetchUserMfaStatus(email: string) {
  const { data } = await api.get<UserMfaStatus>(`/auth/get-software-token_mfa-status/${email}`)

  return data
}

async function fetchRegisterMfaCode(username: string, accessToken: string) {
  return api.post<RegisterMFACodeResponse>('/auth/configure-software-token-mfa', {
    username,
    access_token: accessToken,
  })
}

export const mfaQueries = {
  all: () => ['mfa'],
  codes: () => [...mfaQueries.all(), 'codes'],
  code: (username: string, accessToken: string) =>
    queryOptions({
      queryKey: [...mfaQueries.codes(), username, accessToken],
      queryFn: () => fetchRegisterMfaCode(username, accessToken),
      placeholderData: keepPreviousData,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      staleTime: Infinity,
    }),
  checkMfa: (email: string) =>
    queryOptions({
      queryKey: [...mfaQueries.all(), 'check-mfa', email],
      queryFn: () => fetchUserMfaStatus(email),
    }),
}

import React from 'react'

import { Tooltip } from 'antd'
import { ColumnProps } from 'antd/es/table'

import * as S from './styles'

type GenericObject = Record<string, any>

/**
 * TooltipComponentProps type definition.
 *
 * Use `tooltipOnly` flag for displaying only the tooltip with the count of data.
 * If `tooltipOnly` is truthy, the `fieldKey` prop is not required.
 *
 * Otherwise, if `tooltipOnly` is falsy or missing, the `fieldKey` prop becomes required.
 * The `fieldKey` prop determines the key from the data object to be displayed directly.
 */

type TooltipComponentProps<TTooltipOnly extends boolean = boolean> = TTooltipOnly extends true
  ? {
      data: GenericObject[]
      record: GenericObject
      columns: ColumnProps<GenericObject>[]
      tooltipOnly: true
    }
  : {
      data: GenericObject[]
      record: GenericObject
      columns: ColumnProps<GenericObject>[]
      fieldKey: string
      tooltipOnly?: false
    }

interface TooltipComponent extends Omit<TooltipComponentProps, 'tooltipOnly' | 'fieldKey'> {
  tooltipLabel: React.ReactNode
}

const TooltipComponent: React.FC<TooltipComponent> = ({ data, record, columns, tooltipLabel }) => (
  <Tooltip
    trigger={['hover', 'click']}
    color={'#fbfbfb'}
    overlayInnerStyle={{
      minWidth: 500,
      width: 'fit-content',
      borderRadius: '5px',
      // maxHeight: '300px',
      // overflowY: 'auto',
    }}
    title={
      <S.Table
        // virtual
        // scroll={{ x: 525, y: 400 }}
        bordered
        rowKey={() => `${crypto.randomUUID()}-${record.pk}`}
        dataSource={data}
        columns={columns}
        pagination={false}
        size="small"
      />
    }
  >
    <S.TooltipData>{tooltipLabel}</S.TooltipData>
  </Tooltip>
)

export const TooltipTable: React.FC<TooltipComponentProps> = (props) => {
  const hasData = props.data.length > 0
  const hasMultiple = props.data.length > 1

  if (!hasData) return <span>-</span>

  if (props.tooltipOnly) {
    return (
      <S.Container>
        <TooltipComponent
          data={props.data}
          record={props.record}
          columns={props.columns}
          tooltipLabel={props.data.length}
        />
      </S.Container>
    )
  }

  return (
    <S.Container>
      <S.TitleSpan $hasMultiple={hasMultiple}>{props.data[0][props.fieldKey]}</S.TitleSpan>

      {hasMultiple && (
        <TooltipComponent
          data={props.data}
          record={props.record}
          columns={props.columns}
          tooltipLabel={`+${props.data.length - 1}`}
        />
      )}
    </S.Container>
  )
}

import styled from 'styled-components'

export const FiltersAndActionsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
`

export const HeaderActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

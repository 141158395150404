import { Button, Card as CardAntd } from 'antd'
import { Divider as DividerAntd } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const CardTitle = styled.h6`
  font-size: 14px;
  font-weight: 600;
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Card = styled.div`
  display: flex;
  justify-content: flex-start;

  background-color: #f5f5f5;
  border: 2px solid #d9d9d9;
  border-radius: 5px;
  padding: 12px 8px 12px 8px;
  gap: 40px;
`

export const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const CardContent = styled(CardAntd.Grid)`
  display: flex;
  flex-direction: column;
`

export const Divider = styled(DividerAntd)`
  height: 100%;
  border: 1px solid #d9d9d9;
`

export const LogArea = styled.strong`
  text-transform: capitalize;
`

export const CloseButton = styled(Button)`
  margin-left: auto;
`

import { InputProps } from 'antd'
import { SearchProps } from 'antd/es/input'

import * as S from './styles'

interface SearchInputProps extends InputProps, SearchProps {
  initialValue?: string
  onClose: () => void
}

export const SearchInput = ({ initialValue, onClose, ...rest }: SearchInputProps) => {
  return (
    <S.Container>
      <S.SearchInput
        defaultValue={initialValue}
        placeholder="Buscar..."
        // suffix={!defaultOpen && <S.CloseIcon onClick={handleCloseInput} />}
        {...rest}
        onSearch={(value) => {
          // close input if submit empty value
          if (!value) {
            return onClose()
          }

          rest.onSearch && rest.onSearch(value)
        }}
      />
    </S.Container>
  )
}

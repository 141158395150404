import { Button, Drawer, Layout } from 'antd'
import styled from 'styled-components'

export const Container = styled(Layout.Sider)`
  max-width: 42px !important;
  width: 100% !important;
  min-width: fit-content !important;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #fff;
  padding: 16px 0;

  .ant-layout-sider-children {
    width: 100%;
  }

  button.ant-btn {
    width: 100%;
  }
`

export const SidebarTrigger = styled(Button)`
  .anticon {
    color: ${({ theme }) => theme.base.colorPrimary};
    font-size: 18px;

    :hover {
      color: ${({ theme }) => theme.base.colorPrimary};
    }
  }
`

export const FilterDrawer = styled(Drawer)`
  .ant-drawer-close {
    position: absolute;
    top: 24px;
    right: 10px;
    color: ${({ theme }) => theme.colors.brand};
  }

  .ant-drawer-title {
    font-size: 20px;
    font-weight: 700;
  }
`

export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

import { useCallback, useEffect, useMemo, useState } from 'react'
import { FixedSizeList } from 'react-window'

import { Form, Input } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'

import * as S from './styles'

import { useDebounce } from '@/common'

interface CheckboxFilterProps<T> {
  optionsData: T[] | undefined
  filterNameKey: keyof T
  searchValue: string
  filterSearch: React.Dispatch<React.SetStateAction<string>>
  formFieldName: string
  isSearching?: boolean
  initialValues?: string[]
}

export const CheckboxFilter = <T,>({
  searchValue,
  filterSearch,
  optionsData,
  filterNameKey,
  formFieldName,
  isSearching,
  initialValues,
}: CheckboxFilterProps<T>) => {
  const form = Form.useFormInstance()

  const originalOptions = useMemo(() => {
    return optionsData?.map((option) => ({
      label: option[filterNameKey] as string,
      pk: (option as any).pk as string,
    }))
  }, [filterNameKey, optionsData])

  const [options, setOptions] = useState(originalOptions)
  const [checkedOptions, setCheckedOptions] = useState<string[]>(initialValues || [])
  const [searchValueLocal, setSearchValueLocal] = useState(searchValue)

  const debouncedSearch = useDebounce(searchValueLocal, 500).trim()

  useEffect(() => {
    setOptions(originalOptions)
  }, [originalOptions])

  useEffect(() => {
    filterSearch(debouncedSearch) // send debounced value to parent component
  }, [debouncedSearch, filterSearch])

  // Handle initial values
  useEffect(() => {
    if (initialValues) {
      setCheckedOptions(initialValues)
      form.setFieldValue(formFieldName, initialValues)
    } else {
      setCheckedOptions([])
    }
  }, [form, formFieldName, initialValues])

  const handleCheckboxChange = useCallback(
    (e: CheckboxChangeEvent) => {
      const currentIndex = checkedOptions.indexOf(e.target.value)
      const newCheckedOptions = [...checkedOptions]

      if (currentIndex === -1) {
        newCheckedOptions.push(e.target.value)
      } else {
        newCheckedOptions.splice(currentIndex, 1)
      }

      setCheckedOptions(newCheckedOptions)
      form.setFieldValue(formFieldName, newCheckedOptions)
    },
    [checkedOptions, form, formFieldName],
  )

  const CheckboxRow = ({ index, style }: any) => {
    const option = options?.[index]

    if (!option) return null

    return (
      <div style={style}>
        <S.Checkbox
          value={option?.label}
          key={option?.pk}
          checked={checkedOptions.includes(option?.pk) || checkedOptions.includes(option?.label)}
          onChange={(e) => handleCheckboxChange(e)}
        >
          {option?.label}
        </S.Checkbox>
      </div>
    )
  }

  return (
    <S.Container>
      <Input.Search
        enterButton
        loading={isSearching}
        value={searchValueLocal}
        onChange={(e) => setSearchValueLocal(e.target.value)}
      />

      <S.CheckboxGroup value={checkedOptions}>
        <FixedSizeList
          height={300}
          width={'100%'}
          itemData={options}
          itemCount={options?.length || 0}
          itemSize={26}
          style={{ height: 'fit-content' }}
        >
          {CheckboxRow}
        </FixedSizeList>
      </S.CheckboxGroup>
    </S.Container>
  )
}

import { useEffect, useReducer, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { PlusOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Button, Form } from 'antd'
import { toast } from 'sonner'
import { shallow } from 'zustand/shallow'

import * as S from './styles'

import { useSystemFilters } from '@/app'
import {
  type TitleTabItem,
  getActiveFiltersList,
  pagePermissionsKeys,
  removeActiveFilter,
  useAuth,
  useFilterActions,
  useTabNavigation,
  useToggle,
} from '@/common'
import {
  ActiveFiltersList,
  CustomPagination,
  PageHeader,
  SearchInput,
  TitleTabs,
} from '@/components'
import {
  CreateSystemModal,
  SystemModal,
  SystemsListTable,
  useDeleteSystemMutation,
  systemQueries,
  SystemFilterSidebar,
} from '@/features/systems'
import type { SystemDrawerFilters, SystemFilters } from '@/features/systems'
import { PageLayout } from '@/layouts/PageLayout'

export type SelectSystemAction = {
  systemPk: string
  type: 'DELETE_SYSTEM' | 'VIEW_SYSTEM'
}

export const SystemsPage = () => {
  const { search } = useLocation()
  const navigateToTab = useTabNavigation()
  const [form] = Form.useForm<SystemDrawerFilters>()
  const { handleClearFilters, handleSearchFilter } = useFilterActions(form)
  const hasRanEffect = useRef(false)
  const { getPagePermissions, formatPagePermissions, formatTitleTabItems } = useAuth()

  const pagePerms = getPagePermissions({
    moduleKey: pagePermissionsKeys.systems.module,
    roleKey: pagePermissionsKeys.systems.role,
  })

  const { isAdmin, canCreate, canDelete, canEdit } = formatPagePermissions(pagePerms)

  const {
    systemFilters,
    activeSorting,
    systemPagination,
    setFilters,
    setSystemPagination,
    resetFilters,
    resetSystemPagination,
  } = useSystemFilters(
    (s) => ({
      systemFilters: s.filters,
      activeSorting: s.activeSorting,
      systemPagination: s.pagination,
      setFilters: s.setFilters,
      setSystemPagination: s.setPagination,
      resetFilters: s.resetFilters,
      resetSystemPagination: s.resetPagination,
    }),
    shallow,
  )

  const {
    data: systems,
    isLoading,
    isFetching,
  } = useQuery(systemQueries.list(systemFilters, activeSorting, systemPagination))

  const deleteSystemMutation = useDeleteSystemMutation()

  const [isCreateSystemModalOpen, toggleCreateSystemModal] = useToggle(false)
  const [isViewSystemModalOpen, toggleViewSystemModal] = useToggle(false)
  const [isFilterDrawerOpen, toggleFilterDrawer] = useToggle(false)

  const [selectedSystemPk, selectSystemDispatch] = useReducer(
    (_: string | null, action: SelectSystemAction) => {
      switch (action.type) {
        case 'DELETE_SYSTEM':
          handleDeleteSystem(action.systemPk)
          return null
        case 'VIEW_SYSTEM':
          toggleViewSystemModal()
          return action.systemPk
        default:
          return null
      }
    },
    null,
  )

  const titleTabItems: TitleTabItem[] = [
    {
      key: 'areas',
      label: 'Áreas',
      disabled: false,
      allowedRoles: ['areas'],
    },
    {
      key: 'systems',
      label: 'Sistemas',
      disabled: true,
      allowedRoles: ['sistemas'],
    },
  ]

  useEffect(() => {
    if (!!search && !hasRanEffect.current && !isViewSystemModalOpen) {
      hasRanEffect.current = true

      const params = new URLSearchParams(search)
      const systemPk = params.get('system')

      if (systemPk) {
        selectSystemDispatch({ systemPk, type: 'VIEW_SYSTEM' })
      }
    }

    return () => {
      hasRanEffect.current = false
    }
  }, [search, selectedSystemPk])

  async function handleDeleteSystem(systemPk: string) {
    if (deleteSystemMutation.isPending) return

    const promise = deleteSystemMutation.mutateAsync(systemPk)

    toast.promise(promise, {
      loading: 'Excluindo sistema...',
      success: 'Sistema excluído com sucesso!',
      error: 'Erro ao excluir sistema!',
    })
  }

  function handleRemoveFilter(filterKey: keyof SystemFilters, filterValue?: string) {
    const updatedFilters = removeActiveFilter({
      activeFilters: systemFilters,
      filterKey,
      filterValue,
      form,
    })

    setFilters({ ...updatedFilters })
  }

  const ExtraContent = () => (
    <S.ExtraContent>
      <SearchInput
        placeholder="Buscar por nome..."
        initialValue={systemFilters.system_name?.value}
        onSearch={(searchValue) =>
          handleSearchFilter({
            searchValue,
            storeCallback: setFilters,
            filterKey: 'system_name',
            resetPagination: resetSystemPagination,
          })
        }
        onClose={() => setFilters({ system_name: undefined })}
      />

      {pagePerms && (isAdmin || canCreate) && (
        <Button type="primary" icon={<PlusOutlined />} onClick={toggleCreateSystemModal}>
          Novo sistema
        </Button>
      )}
    </S.ExtraContent>
  )

  return (
    <PageLayout
      isListPage
      title="Sistemas"
      sidebar={
        <SystemFilterSidebar
          form={form}
          isOpen={isFilterDrawerOpen}
          toggleDrawer={toggleFilterDrawer}
        />
      }
    >
      {isCreateSystemModalOpen && (
        <CreateSystemModal isOpen={isCreateSystemModalOpen} onClose={toggleCreateSystemModal} />
      )}

      {isViewSystemModalOpen && (
        <SystemModal
          isOpen={isViewSystemModalOpen}
          onClose={toggleViewSystemModal}
          systemPk={selectedSystemPk as string}
          canEdit={isAdmin || canEdit}
        />
      )}

      <PageHeader
        title="Lista de sistemas"
        isLoading={isLoading || isFetching}
        itemsFound={systems?.info.total || 0}
      />

      <TitleTabs
        items={isAdmin ? titleTabItems : formatTitleTabItems(titleTabItems)}
        defaultActiveKey="systems"
        onChange={navigateToTab}
        tabBarExtraContent={<ExtraContent />}
      />

      {getActiveFiltersList(systemFilters).length > 0 ? (
        <ActiveFiltersList
          filterList={getActiveFiltersList(systemFilters)}
          removeFilter={handleRemoveFilter}
          clearFilters={() => handleClearFilters(resetFilters)}
        />
      ) : null}

      <SystemsListTable
        data={systems?.results || []}
        isLoading={isLoading}
        handleSelectSystem={selectSystemDispatch}
        canDelete={isAdmin || canDelete}
      />

      <CustomPagination
        scrollToTop
        isLoading={isLoading}
        page={systems?.info.current_page || systemPagination.page}
        pageSize={systemPagination.per_page}
        totalItems={systems?.info.total}
        totalPages={systems?.info.pages || 0}
        setPagination={setSystemPagination}
      />
    </PageLayout>
  )
}

import { keepPreviousData, queryOptions } from '@tanstack/react-query'

import type { Log, UserLogFilters } from '..'

import type { ActiveSorting, PaginationExpression, ListResponse, FilterValue } from '@/common'
import {
  DEFAULT_ACTIVE_SORTING,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGINATION,
  getFilterExpression,
  handleActiveSorting,
} from '@/common'
import { api } from '@/services'

const fetchUserLogsList = async (
  filters: UserLogFilters,
  sorting: ActiveSorting,
  pagination: PaginationExpression,
) => {
  const filterExpression = getFilterExpression(filters as unknown as Record<string, FilterValue>)

  const activeSorting = handleActiveSorting(sorting)

  const { data } = await api.post<ListResponse<Log[]>>('/log/list', {
    filter_expression: filterExpression,
    pagination_expression: {
      page: pagination.page || DEFAULT_PAGE,
      per_page: pagination.per_page === undefined ? DEFAULT_PAGE_SIZE : pagination.per_page,
    },
    order_by_expression: activeSorting,
  })

  return data
}

async function fetchUserLog(logPk: string) {
  const { data } = await api.get<Log>(`/log/${logPk}`)

  return data
}

export const userLogsQueries = {
  all: () => ['user-logs'] as const,
  lists: () => [...userLogsQueries.all(), 'list'] as const,
  list: (
    filters: UserLogFilters = {},
    sorting: ActiveSorting = DEFAULT_ACTIVE_SORTING,
    pagination: PaginationExpression = DEFAULT_PAGINATION,
  ) =>
    queryOptions({
      queryKey: [...userLogsQueries.lists(), filters, sorting, pagination],
      queryFn: () => fetchUserLogsList(filters, sorting, pagination),
      placeholderData: keepPreviousData,
    }),

  details: () => [...userLogsQueries.all(), 'details'],
  detail: (logPk: string) =>
    queryOptions({
      queryKey: [...userLogsQueries.details(), logPk],
      queryFn: () => fetchUserLog(logPk),
      placeholderData: keepPreviousData,
    }),
}

import { useEffect } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Button, Form } from 'antd'
import { toast } from 'sonner'

import * as S from './styles'

import { type ModalAuthenticatedFeatures, useToggle } from '@/common'
import { BaseModal } from '@/components'
import {
  Company,
  CompanyForm,
  CompanyFormData,
  companyQueries,
  useEditCompanyMutation,
} from '@/features/company'

interface CompanyModalProps extends ModalAuthenticatedFeatures {
  data: Company
  isOpen: boolean
  onClose: () => void
}

export const ViewCompanyModal = ({ data, isOpen, onClose, canEdit }: CompanyModalProps) => {
  const [form] = Form.useForm<CompanyFormData>()
  const [isEditing, toggleEdit] = useToggle(false)

  const editCompanyMutation = useEditCompanyMutation()

  const { data: company } = useQuery(companyQueries.detail(data.pk))

  useEffect(() => {
    if (data || company) {
      form.setFieldsValue(company || data)
    }
  }, [data, form, company])

  async function handleEditCompany(values: CompanyFormData) {
    const promise = editCompanyMutation.mutateAsync({
      pk: data.pk,
      ...values,
    })

    toast.promise(promise, {
      loading: 'Editando empresa...',
      success: 'Empresa editada com sucesso!',
      error: 'Erro ao editar empresa!',
    })

    await promise

    handleCancelEdit()
  }

  function handleCancelEdit() {
    form.resetFields()
    form.setFieldsValue(company || data)
    toggleEdit()
  }

  const CompanyView = () => (
    <>
      <S.FieldRow>
        <S.SystemData>
          <span>Nome da empresa</span>
          <strong>{company?.company_name || data.company_name}</strong>
        </S.SystemData>

        <S.SystemData>
          <span>Nome fantasia</span>
          <strong>{company?.company_fancy_name || data.company_fancy_name}</strong>
        </S.SystemData>
      </S.FieldRow>

      <S.FieldRow>
        <S.SystemData>
          <span>CNPJ da empresa</span>
          <strong>{company?.company_cnpj || data.company_cnpj}</strong>
        </S.SystemData>

        <S.SystemData>
          <span>Nome do responsável</span>
          <strong>{company?.manager_name || data.manager_name}</strong>
        </S.SystemData>
      </S.FieldRow>

      <S.FieldRow>
        <S.SystemData>
          <span>Telefone do responsável</span>
          <strong>{company?.manager_phone || data.manager_phone}</strong>
        </S.SystemData>

        <S.SystemData>
          <span>E-mail do responsável</span>
          <strong>{company?.manager_email || data.manager_email}</strong>
        </S.SystemData>
      </S.FieldRow>
    </>
  )

  return (
    <BaseModal
      open={isOpen}
      onCancel={onClose}
      title="Dados cadastrais da empresa"
      width={740}
      footer={
        <>
          <Button
            onClick={isEditing ? handleCancelEdit : onClose}
            type="link"
            htmlType="button"
            disabled={editCompanyMutation.isPending}
          >
            Cancelar
          </Button>

          {!isEditing ? (
            <>
              {canEdit && (
                <Button type="primary" htmlType="button" onClick={toggleEdit}>
                  Editar
                </Button>
              )}
            </>
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => form.submit()}
              loading={editCompanyMutation.isPending}
            >
              Salvar
            </Button>
          )}
        </>
      }
    >
      <S.Container>
        {!isEditing ? (
          <CompanyView />
        ) : (
          <Form
            form={form}
            onFinish={handleEditCompany}
            layout="vertical"
            requiredMark
            disabled={editCompanyMutation.isPending}
          >
            <CompanyForm />
          </Form>
        )}
      </S.Container>
    </BaseModal>
  )
}

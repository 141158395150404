import { UnorderedListOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { ColumnsType } from 'antd/es/table'

import * as S from './styles'

import { formatDate } from '@/common'
import { BaseTable, TableAction } from '@/components'
import { Log, logTypes } from '@/features/users'

interface UserLogsListTableProps {
  data: Log[]
  isLoading: boolean
  handleSelectLog: (logPk: string) => void
}

export const UserLogsListTable = ({ data, isLoading, handleSelectLog }: UserLogsListTableProps) => {
  const columns: ColumnsType<Log> = [
    {
      title: 'Data',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 150,
      align: 'center',
      render: (date) => formatDate({ date, dateFormat: 'DD/MM/YYYY - HH:mm' }),
    },
    {
      title: 'Usuário',
      dataIndex: ['log_user', 'name'],
      key: 'user_name',
    },
    {
      title: 'Email',
      dataIndex: ['log_user', 'email'],
      key: 'user_email',
    },
    {
      title: 'Área',
      dataIndex: 'log_area',
      key: 'log_area',
      render: (area: string) => <S.TableText>{area.toLowerCase()}</S.TableText>,
    },
    {
      title: 'Local',
      dataIndex: 'log_path',
      key: 'log_path',
    },
    {
      title: 'Tipo',
      dataIndex: 'log_type',
      key: 'type',
      align: 'center',
      render: (type: string) => logTypes.get(type) || type,
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      align: 'center',
      render: (_, log) => {
        const actions = [
          {
            key: '1',
            label: (
              <Button
                type="link"
                onClick={() => handleSelectLog(log.pk)}
                icon={<UnorderedListOutlined />}
              >
                Visualizar log
              </Button>
            ),
          },
        ]
        return <TableAction actions={actions} />
      },
    },
  ]

  return (
    <BaseTable
      dataSource={data}
      columns={columns}
      rowKey={(log: Log) => `${log.pk}`}
      loading={isLoading}
      pagination={false}
    />
  )
}

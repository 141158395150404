import { Navigate, Outlet } from 'react-router-dom'

import { useQueryClient } from '@tanstack/react-query'

import { clearAllCaches, useAuth } from '@/common'

interface PrivateRouteProps {
  allowedRoles?: string[]
}

export const AuthenticatedRoute = ({ allowedRoles }: PrivateRouteProps) => {
  const queryClient = useQueryClient()
  const { userAllowedModules } = useAuth()

  if (allowedRoles && userAllowedModules) {
    if (!userAllowedModules || userAllowedModules.length === 0) {
      console.error('No roles')

      clearAllCaches(queryClient)
      return <Navigate to="/auth/login" replace />
    }

    const isAllowed =
      userAllowedModules.includes('*') ||
      userAllowedModules.some((role) => allowedRoles.includes(role))

    if (!isAllowed) {
      console.error('Not allowed')

      return <Navigate to="/" replace />
    }

    return <Outlet />
  }

  return <Outlet />
}

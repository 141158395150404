import * as S from './styles'

export const RoleHelpContentFirstStep = () => {
  return (
    <>
      <S.DescriptionContent>
        <S.DescriptionTitle>1. Campo Nome:</S.DescriptionTitle>

        <S.DescriptionText>
          Campo nome, onde o usuário poderá preencher manualmente.
        </S.DescriptionText>
      </S.DescriptionContent>

      <S.DescriptionContent>
        <S.DescriptionTitle>2. Campo Valor:</S.DescriptionTitle>

        <S.DescriptionText>
          Campo valor, usado para definir o identificador da permissão. Deve ser uma única palavra.
        </S.DescriptionText>
      </S.DescriptionContent>

      <S.DescriptionContent>
        <S.DescriptionTitle>3. Campo Descrição:</S.DescriptionTitle>

        <S.DescriptionText>
          Campo descritivo, onde o usuário poderá preencher manualmente, com informações adicionais
          sobre o papel desta permissão.
        </S.DescriptionText>
      </S.DescriptionContent>
    </>
  )
}

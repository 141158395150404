import { FileDoneOutlined, WarningOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useTheme } from 'styled-components'

import { useAreaFilters } from '@/app'
import { type TableAuthenticatedFeatures, formatDate } from '@/common'
import {
  BaseTable,
  TableAction,
  TableActionButton,
  TableColumnButton,
  TableDataTooltip,
} from '@/components'
import { Area } from '@/features/area'
import type { System } from '@/features/systems'
import { SelectAreaAction } from '@/pages'

interface AreaListTableProps extends TableAuthenticatedFeatures {
  data: Area[]
  isLoading?: boolean
  handleSelectArea: ({ area, type }: SelectAreaAction) => void
}

export const AreaListTable = ({
  data,
  isLoading,
  handleSelectArea,
  canDelete,
}: AreaListTableProps) => {
  const theme = useTheme()
  const setActiveSorting = useAreaFilters((state) => state.setActiveSorting)

  function confirmDisable(area: Area) {
    Modal.confirm({
      title: `Tem certeza que deseja excluir esta área?`,
      content: (
        <span>
          A área <strong>{area.area_name}</strong> será excluída permanentemente.
        </span>
      ),
      centered: true,
      icon: <WarningOutlined style={{ color: '#FF0000' }} />,
      okText: 'Excluir',
      cancelText: 'Cancelar',
      okButtonProps: {
        type: 'primary',
        style: {
          color: '#FFF',
          backgroundColor: theme.colors.brand,
        },
      },
      cancelButtonProps: {
        type: 'default',
      },
      onOk: () => handleSelectArea({ area, type: 'DELETE_AREA' }),
    })
  }

  const areaColumns: ColumnsType<Area> = [
    {
      title: 'nº',
      key: 'number',
      align: 'center',
      width: 50,
      render: (_, __, index) => {
        const number = String(index + 1).padStart(2, '0')

        return <span>{number}</span>
      },
    },
    {
      title: 'Nome da área',
      dataIndex: 'area_name',
      key: 'area_name',
      width: 225,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (name: string, area) => (
        <TableColumnButton onClick={() => handleSelectArea({ area, type: 'VIEW_AREA' })}>
          {name}
        </TableColumnButton>
      ),
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Criado em',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 275,
      align: 'center',
      render: (date) => formatDate({ date }),
    },
    {
      title: 'Atualizado em',
      dataIndex: 'updated_at',
      key: 'updated_at',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 275,
      align: 'center',
      render: (date) => formatDate({ date }),
    },
    {
      title: 'Sistemas vinculados',
      dataIndex: 'systems',
      key: 'systems',
      width: 180,
      render: (systems: System[]) => {
        return (
          <TableDataTooltip<System>
            data={systems}
            firstItem={systems?.[0]?.system_name}
            listTitle="Sistemas vinculados"
            nameKey="system_name"
          />
        )
      },
    },
    {
      title: 'Ações',
      key: 'actions',
      width: 100,
      align: 'center',
      render: (_, area) => {
        const isActive = area.active

        const actions = [
          {
            key: '1',
            label: (
              <Button
                type="link"
                onClick={() => handleSelectArea({ area, type: 'VIEW_AREA' })}
                icon={<FileDoneOutlined />}
              >
                Dados cadastrais
              </Button>
            ),
          },
          canDelete
            ? {
                key: '2',
                label: (
                  <TableActionButton danger onClick={() => confirmDisable(area)}>
                    Excluir
                  </TableActionButton>
                ),
              }
            : null,
        ]

        return <TableAction actions={actions} disabled={!isActive} />
      },
    },
  ]

  return (
    <BaseTable
      dataSource={data}
      columns={areaColumns}
      loading={isLoading}
      rowKey={(area: Area) => area.pk}
      setActiveSorting={setActiveSorting}
    />
  )
}

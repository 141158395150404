import { Link, useLocation } from 'react-router-dom'

import { BellOutlined, CloseOutlined, MenuOutlined } from '@ant-design/icons'
import { useInfiniteQuery } from '@tanstack/react-query'
import { Menu, MenuProps, Button, Badge } from 'antd'

import * as S from './styles'

// import acerLogo from '@/assets/acer-login-unico-white.svg'
import { NAV_ROUTES, useToggle, useAuth } from '@/common'
import { useSignOutMutation } from '@/features/auth'
import { NotificationsDrawer, notificationQueries } from '@/features/notifications'

type MenuItem = Required<MenuProps>['items'][number]

export const Header = () => {
  const { pathname } = useLocation()
  const signOutMutation = useSignOutMutation()
  const { userAllowedModules } = useAuth()

  const [isMenuOpen, toggleMenu] = useToggle(false)
  const [isNotificationsOpen, toggleNotifications] = useToggle(false)

  const {
    data: notifications,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isPending,
  } = useInfiniteQuery({
    ...notificationQueries.list(),
    refetchInterval: 60000 * 5, // 5 minutes,
    refetchIntervalInBackground: true,
  })

  const navItems: MenuItem[] = NAV_ROUTES.map(({ path, label, allowedRoles }) => {
    // Remove link if user is not allowed to see it
    if (
      allowedRoles &&
      !userAllowedModules?.includes('*') &&
      !userAllowedModules?.some((role) => allowedRoles.includes(role))
    ) {
      return null
    }

    return {
      key: path,
      path,
      label: <Link to={path}>{label}</Link>,
    }
  })

  return (
    <S.Container>
      <S.LogoContainer>
        <Link to="/">
          {/* <Image src={acerLogo} alt="Acer logo" width={214} preview={false} /> */}
          <S.SystemName>Login Único</S.SystemName>
        </Link>
      </S.LogoContainer>

      <S.HeaderActionsContainer>
        <S.HeaderButton type="text" onClick={toggleNotifications}>
          <Badge
            size="small"
            count={notifications?.pages?.[0]?.info?.total}
            overflowCount={20}
            style={{ fontFamily: 'AcerFoco' }}
          >
            <BellOutlined style={{ fontSize: 20 }} />
          </Badge>
        </S.HeaderButton>

        <S.HeaderButton type="text" onClick={toggleMenu}>
          Menu
          <MenuOutlined style={{ fontSize: 24 }} />
        </S.HeaderButton>
      </S.HeaderActionsContainer>

      <NotificationsDrawer
        isOpen={isNotificationsOpen}
        onClose={toggleNotifications}
        data={notifications?.pages || []}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isInitialLoading={isPending}
        isLoadingMoreNotifications={isFetchingNextPage}
      />

      <S.MenuDrawer
        title="Menu"
        placement="right"
        open={isMenuOpen}
        onClose={toggleMenu}
        width={350}
        closable={false}
        extra={<CloseOutlined onClick={toggleMenu} />}
        footer={
          <Button onClick={() => signOutMutation.mutate()} loading={signOutMutation.isPending}>
            Logout
          </Button>
        }
      >
        <S.MenuContent>
          <Menu
            items={navItems}
            mode="inline"
            selectedKeys={[pathname]}
            disabled={signOutMutation.isPending}
          />
        </S.MenuContent>
      </S.MenuDrawer>
    </S.Container>
  )
}

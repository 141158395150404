import { DeleteOutlined, FileDoneOutlined, WarningOutlined } from '@ant-design/icons'
import { Button, Modal, Switch, Tag } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useTheme } from 'styled-components'

import { useUserFilters } from '@/app'
import { TableAuthenticatedFeatures, formatDate, formatPhone } from '@/common'
import { BaseTable, TableAction, TableColumnButton, TableDataTooltip } from '@/components'
import { Group } from '@/features/groups'
import { User, UserSituation } from '@/features/users'
import { SelectUserAction } from '@/pages'

interface UsersListTableProps extends TableAuthenticatedFeatures {
  data: User[]
  isLoading: boolean
  handleSelectUser: ({ userId, type }: SelectUserAction) => void
  handleChangeUserStatus: (userId: string, newStatus: boolean) => void
}

export const UsersListTable = ({
  data,
  isLoading,
  handleSelectUser,
  handleChangeUserStatus,
  canDelete,
  canEdit,
}: UsersListTableProps) => {
  const theme = useTheme()
  const setActiveSorting = useUserFilters((state) => state.setActiveSorting)

  function confirmStatusChange(user: User, newStatus: boolean) {
    Modal.confirm({
      title: `Tem certeza que deseja alterar o status deste usuário?`,
      content: (
        <span>
          O novo status do usuário <strong>{user.name}</strong> será:{' '}
          <strong>{newStatus ? 'Ativo' : 'Inativo'}</strong>.
        </span>
      ),
      centered: true,
      icon: <WarningOutlined style={{ color: '#FF0000' }} />,
      okText: 'Alterar status',
      cancelText: 'Cancelar',
      okButtonProps: {
        type: 'primary',
        style: {
          color: '#FFF',
          backgroundColor: theme.colors.brand,
        },
      },
      cancelButtonProps: {
        type: 'default',
      },
      onOk: () => handleChangeUserStatus(user.id, newStatus),
    })
  }

  function confirmDeletion(user: User) {
    Modal.confirm({
      title: `Tem certeza que deseja excluir este usuário?`,
      content: (
        <span>
          O usuário <strong>{user.name}</strong> será excluído permanentemente.
        </span>
      ),
      centered: true,
      icon: <WarningOutlined style={{ color: '#FF0000' }} />,
      okText: 'Excluir',
      cancelText: 'Cancelar',
      okButtonProps: {
        type: 'primary',
        style: {
          color: '#FFF',
          backgroundColor: theme.colors.brand,
        },
      },
      cancelButtonProps: {
        type: 'default',
      },
      onOk: () => handleSelectUser({ userId: user.id, type: 'DELETE_USER' }),
    })
  }

  const columns: ColumnsType<User> = [
    {
      title: 'nº',
      key: 'number',
      align: 'center',
      width: 50,
      render: (_, __, index) => {
        const number = String(index + 1).padStart(2, '0')

        return <span>{number}</span>
      },
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (name: string, user) => (
        <TableColumnButton onClick={() => handleSelectUser({ userId: user.id, type: 'VIEW_USER' })}>
          {name}
        </TableColumnButton>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Telefone',
      dataIndex: 'phone_number',
      key: 'phone',
      render: (phone: string) => formatPhone(phone),
    },
    {
      title: 'Empresa',
      dataIndex: 'empresa',
      key: 'company',
      align: 'center',
    },
    {
      title: 'Tipo de contrato',
      dataIndex: 'tipo_contrato',
      key: 'tipo_contrato',
      align: 'center',
    },
    {
      title: 'Ultimo acesso',
      dataIndex: 'last_sign_in',
      key: 'last_sign_in',
      align: 'center',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (date: string | null) => {
        if (!date) {
          return `-`
        }

        return formatDate({ date })
      },
    },
    {
      title: 'Grupos',
      dataIndex: 'user_groups',
      key: 'user_groups',
      width: 180,
      render: (groups: Group[]) => {
        return (
          <TableDataTooltip<Group>
            data={groups}
            firstItem={groups?.[0]?.group_name}
            listTitle="Grupos vinculados"
            nameKey="group_name"
          />
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'user_situation',
      key: 'user_situation',
      width: 50,
      align: 'center',
      render: (status: UserSituation, user) => {
        if (status === 'Excluído') {
          return <Tag color="red">Excluído</Tag>
        }

        return (
          <Switch
            disabled={!canEdit}
            checked={status === 'Ativo'}
            checkedChildren="Ativo"
            unCheckedChildren="Inativo"
            onChange={(newStatus) => confirmStatusChange(user, newStatus)}
          />
        )
      },
    },
    {
      title: 'Ações',
      key: 'actions',
      width: 100,
      align: 'center',
      render: (_, user) => {
        const actions = [
          {
            key: '1',
            label: (
              <Button
                type="link"
                onClick={() => handleSelectUser({ userId: user.id, type: 'VIEW_USER' })}
                icon={<FileDoneOutlined />}
              >
                Dados cadastrais
              </Button>
            ),
          },
          canDelete
            ? {
                key: '2',
                label: (
                  <Button
                    danger
                    type="link"
                    icon={<DeleteOutlined />}
                    onClick={() => confirmDeletion(user)}
                  >
                    Excluir
                  </Button>
                ),
              }
            : null,
        ]

        return <TableAction actions={actions} />
      },
    },
  ]

  return (
    <BaseTable
      loading={isLoading}
      dataSource={data}
      columns={columns}
      rowKey={(user: User) => user.id}
      setActiveSorting={setActiveSorting}
    />
  )
}

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { CloseOutlined } from '@ant-design/icons'
import { Button, DrawerProps, Empty, Skeleton } from 'antd'

import * as S from './styles'

import { ListResponse, formatDate } from '@/common'
import { LoadingSpinner } from '@/components'
import {
  type Notification,
  notificationCategoryRemap,
  translateNotificationMessage,
  useViewNotificationMutation,
  useClearAllNotificationsMutation,
  CurrentNotification,
} from '@/features/notifications'

interface NotificationsDrawer extends DrawerProps {
  isOpen: boolean
  onClose: () => void
  data: Array<ListResponse<Notification[]>>
  hasNextPage: boolean
  isInitialLoading: boolean
  isLoadingMoreNotifications: boolean
  fetchNextPage: () => void
}

const DrawerTitle = () => (
  <S.DrawerTitle>
    <h3>Notificações</h3>
    <span>-</span>
    <span>Login Único</span>
  </S.DrawerTitle>
)

export const NotificationsDrawer = ({
  isOpen,
  onClose,
  data,
  hasNextPage,
  isInitialLoading,
  isLoadingMoreNotifications,
  fetchNextPage,
  ...rest
}: NotificationsDrawer) => {
  const navigate = useNavigate()
  const hasData = data.some((page) => page.info.total > 0)

  const [viewingNotificationPk, setViewingNotificationPk] = useState<string | null>(null)

  const viewNotificationMutation = useViewNotificationMutation()
  const clearAllNotificationsMutation = useClearAllNotificationsMutation()

  async function handleViewNotification(notificationPk: string) {
    try {
      setViewingNotificationPk(notificationPk)

      await viewNotificationMutation.mutateAsync(notificationPk)

      setViewingNotificationPk(null)
    } catch (err) {
      console.error('Error viewing notification', err)
      setViewingNotificationPk(null)
    }
  }

  async function handleClearNotifications() {
    const notificationPks = data.flatMap((page) =>
      page.results.map((notification) => notification.pk),
    )

    await clearAllNotificationsMutation.mutateAsync(notificationPks)

    onClose()
  }

  async function handleOpenNotification(notification: Notification) {
    const isInactivityNotification = notification.category === 'INACTIVITY'
    const isCreateUserNotification = notification.category === 'CREATE_USER'
    const isCreateSystemNotification = notification.category === 'CREATE_SYSTEM'

    let navigateUrl = ''

    if (isInactivityNotification) {
      const currentNotification = notification as CurrentNotification<'INACTIVITY'>

      navigateUrl = `/users?user=${currentNotification.params.object.user_id}`
    }

    if (isCreateUserNotification) {
      const currentNotification = notification as CurrentNotification<'CREATE_USER'>

      navigateUrl = `/users?user=${currentNotification.params.object.user_id}`
    }

    if (isCreateSystemNotification) {
      const currentNotification = notification as CurrentNotification<'CREATE_SYSTEM'>

      navigateUrl = `/systems?system=${currentNotification.params.object.pk}`
    }

    handleViewNotification(notification.pk)
    onClose()
    return navigate(navigateUrl)
  }

  return (
    <S.Drawer
      open={isOpen}
      title={<DrawerTitle />}
      placement="right"
      width={350}
      closable={false}
      extra={<CloseOutlined onClick={onClose} />}
      {...rest}
    >
      <S.Container>
        {isInitialLoading ? (
          <Skeleton active />
        ) : (
          <>
            {!hasData ? (
              <Empty description="Nenhuma notificação" />
            ) : (
              <>
                <S.ClearAllButton
                  type="link"
                  onClick={handleClearNotifications}
                  loading={clearAllNotificationsMutation.isPending}
                >
                  Limpar todas notificações
                </S.ClearAllButton>

                {data.map((page) =>
                  page.results.map((notification) => {
                    const buttonText =
                      notification.category === 'CREATE_SYSTEM' ? 'sistema' : 'usuário'

                    return (
                      <S.NotificationCard key={notification.pk}>
                        <S.CardRow>
                          <span>
                            {notificationCategoryRemap.get(notification.category) ||
                              notification.category}
                          </span>

                          <S.CloseButton
                            type="link"
                            onClick={() => handleViewNotification(notification.pk)}
                            disabled={clearAllNotificationsMutation.isPending}
                          >
                            {viewNotificationMutation.isPending &&
                            viewingNotificationPk === notification.pk ? (
                              <LoadingSpinner />
                            ) : (
                              <CloseOutlined />
                            )}
                          </S.CloseButton>
                        </S.CardRow>

                        <S.CardRow>
                          <S.NotificationMessage>
                            -{' '}
                            {translateNotificationMessage(
                              notification.message,
                              notification.category,
                            ) || notification.message}
                          </S.NotificationMessage>
                        </S.CardRow>

                        <S.CardRow>
                          <span>{formatDate({ date: notification.updated_at })}</span>

                          <Button
                            type="primary"
                            onClick={() => handleOpenNotification(notification)}
                            disabled={clearAllNotificationsMutation.isPending}
                          >
                            Abrir {buttonText}
                          </Button>
                        </S.CardRow>
                      </S.NotificationCard>
                    )
                  }),
                )}

                {isLoadingMoreNotifications ? (
                  <LoadingSpinner />
                ) : (
                  hasNextPage && (
                    <Button onClick={fetchNextPage} type="link" block>
                      Carregar mais
                    </Button>
                  )
                )}
              </>
            )}
          </>
        )}
      </S.Container>
    </S.Drawer>
  )
}

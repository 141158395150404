import {
  AiOutlineFileSearch,
  AiOutlineHome,
  AiOutlineLaptop,
  AiOutlinePartition,
  AiOutlineSafety,
  AiOutlineSetting,
  AiOutlineTeam,
  AiOutlineUnlock,
  AiOutlineUser,
} from 'react-icons/ai'
import { MdOutlineHomeWork } from 'react-icons/md'

import {
  PageModule,
  PageRole,
  type ActiveSorting,
  type NavRoute,
  type PaginationExpression,
} from '../types'

export const NAV_ROUTES: NavRoute[] = [
  { path: '/home', label: 'Home', key: 'home', icon: <AiOutlineHome /> },
  {
    path: '/areas',
    label: 'Áreas',
    key: 'areas',
    icon: <AiOutlinePartition />,
    allowedRoles: ['areas'],
  },
  {
    path: '/systems',
    label: 'Sistemas',
    key: 'systems',
    icon: <AiOutlineLaptop />,
    allowedRoles: ['sistemas'],
  },
  {
    path: '/companies',
    label: 'Empresas',
    key: 'companies',
    icon: <MdOutlineHomeWork />,
    allowedRoles: ['empresas'],
  },
  {
    path: '/users',
    label: 'Usuários',
    key: 'users',
    icon: <AiOutlineUser />,
    allowedRoles: ['usuarios'],
  },
  {
    path: '/groups',
    label: 'Grupos',
    key: 'groups',
    icon: <AiOutlineTeam />,
    allowedRoles: ['grupos'],
  },
  {
    path: '/permissions',
    label: 'Permissões',
    key: 'permissions',
    icon: <AiOutlineUnlock />,
    allowedRoles: ['permissoes'],
  },
  {
    path: '/parameters',
    label: 'Parâmetros',
    key: 'parameters',
    icon: <AiOutlineSetting />,
    allowedRoles: ['parametros'],
  },
  {
    path: '/logs/users',
    label: 'Logs de Usuários',
    key: 'logs',
    icon: <AiOutlineFileSearch />,
    allowedRoles: ['logs'],
  },
  { path: '/register-mfa', label: 'Cadastrar MFA', key: 'mfa', icon: <AiOutlineSafety /> },
]

export const DEFAULT_PAGE = 1
export const DEFAULT_PAGE_SIZE = 20

export const DEFAULT_ACTIVE_SORTING: ActiveSorting = { field: undefined, order: undefined }
export const DEFAULT_PAGINATION: PaginationExpression = {
  page: DEFAULT_PAGE,
  per_page: DEFAULT_PAGE_SIZE,
}

export const filtersRemap = new Map([
  // Area
  ['area_name', 'Nome da área'],
  ['systems', 'Sistema'], // relational

  // System
  ['system_name', 'Nome do sistema'],
  ['system_area', 'Área'], // relational

  // Groups
  ['group_name', 'Nome do grupo'],
  ['roles', 'Permissões'],
  ['users', 'Usuários'], // relational

  // Companies
  ['company_name', 'Razão social'],
  ['company_fancy_name', 'Nome fantasia'],
  ['company_cnpj', 'CNPJ'],
  ['manager_name', 'Responsável'],
  ['manager_phone', 'Telefone'],
  ['manager_email', 'E-mail'],

  // User Logs
  ['log_type', 'Tipo'],
  ['log_path', 'Local'],
  ['log_area', 'Área'],
  ['user', 'Usuários'],
  ['user_situation', 'Status'],

  // Roles
  ['title', 'Permissão'],
  ['role_value', 'Acesso'],
  ['system', 'Sistema'], // relational

  // Parametros
  ['parameter_name', 'Nome'],
  ['inactivity_days', 'Dias de inatividade'],
  ['tipo_contrato', 'Tipo de contrato'],

  // Users
  ['name', 'Nome'],
  ['cpf', 'CPF'],
  ['email', 'E-mail'],
  ['empresa', 'Empresa'],
  ['phone_number', 'Telefone'],
  ['groups', 'Grupo'], // relational
  ['active', 'Status'],
])

export const pagePermissionsKeys = {
  areas: {
    module: PageModule.Areas,
    role: PageRole.Areas,
  },
  systems: {
    module: PageModule.Systems,
    role: PageRole.Systems,
  },
  companies: {
    module: PageModule.Companies,
    role: PageRole.Companies,
  },
  users: {
    module: PageModule.Users,
    role: PageRole.Users,
  },
  groups: {
    module: PageModule.Groups,
    role: PageRole.Groups,
  },
  permissions: {
    module: PageModule.Permissions,
    role: PageRole.Permissions,
  },
  parameters: {
    module: PageModule.Parameters,
    role: PageRole.Parameters,
  },
  logs: {
    module: PageModule.Logs,
    role: PageRole.Logs,
  },
}

import { useReducer } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Button, Form } from 'antd'
import { toast } from 'sonner'

import * as S from './styles'

import { useGroupFilters } from '@/app'
import {
  getActiveFiltersList,
  pagePermissionsKeys,
  removeActiveFilter,
  useAuth,
  useFilterActions,
  useToggle,
} from '@/common'
import { ActiveFiltersList, CustomPagination, PageHeader, SearchInput } from '@/components'
import {
  CreateGroupModal,
  GroupListTable,
  ViewGroupModal,
  useDeleteGroupMutation,
  groupQueries,
  GroupsFilterSidebar,
} from '@/features/groups'
import type { Group, GroupDrawerFilters, GroupFilters } from '@/features/groups'
import { PageLayout } from '@/layouts/PageLayout'

export type SelectGroupAction = {
  group: Group
  type: 'DELETE_GROUP' | 'VIEW_GROUP'
}

export const GroupsPage = () => {
  const [form] = Form.useForm<GroupDrawerFilters>()
  const { handleClearFilters, handleSearchFilter } = useFilterActions(form)

  const { getPagePermissions, formatPagePermissions } = useAuth()

  const pagePerms = getPagePermissions({
    moduleKey: pagePermissionsKeys.groups.module,
    roleKey: pagePermissionsKeys.groups.role,
  })

  const { isAdmin, canCreate, canDelete, canEdit } = formatPagePermissions(pagePerms)

  const {
    groupFilters,
    activeSorting,
    groupsPagination,
    setGroupFilters,
    setGroupPagination,
    resetFilters,
    resetGroupPagination,
  } = useGroupFilters((state) => ({
    groupFilters: state.filters,
    activeSorting: state.activeSorting,
    groupsPagination: state.pagination,
    setGroupFilters: state.setFilters,
    setGroupPagination: state.setPagination,
    resetFilters: state.resetFilters,
    resetGroupPagination: state.resetPagination,
  }))

  const {
    data: groups,
    isLoading,
    isFetching,
  } = useQuery(groupQueries.list(groupFilters, activeSorting, groupsPagination))

  const deleteGroupMutation = useDeleteGroupMutation()

  const [isFilterDrawerOpen, toggleFilterDrawer] = useToggle(false)
  const [isCreateGroupModalOpen, toggleCreateGroupModal] = useToggle(false)
  const [isViewGroupModalOpen, toggleViewGroupModal] = useToggle(false)

  const [selectedGroup, selectGroupDispatch] = useReducer(
    (_: Group | null, action: SelectGroupAction) => {
      switch (action.type) {
        case 'DELETE_GROUP':
          handleDeleteGroup(action.group.pk)
          return null
        case 'VIEW_GROUP':
          toggleViewGroupModal()
          return action.group
        default:
          return null
      }
    },
    null,
  )

  async function handleDeleteGroup(groupPk: string) {
    if (deleteGroupMutation.isPending) return

    const promise = deleteGroupMutation.mutateAsync(groupPk)

    toast.promise(promise, {
      loading: 'Excluindo grupo...',
      success: 'Grupo excluído com sucesso!',
      error: 'Erro ao excluir grupo!',
    })
  }

  function handleRemoveFilter(filterKey: keyof GroupFilters, filterValue?: string) {
    const updatedFilters = removeActiveFilter({
      activeFilters: groupFilters,
      filterKey,
      filterValue,
      form,
    })

    setGroupFilters({ ...updatedFilters })
  }

  const PageActions = () => (
    <S.ExtraContent>
      <SearchInput
        placeholder="Buscar por nome..."
        initialValue={groupFilters.group_name?.value}
        onSearch={(searchValue) =>
          handleSearchFilter({
            searchValue,
            storeCallback: setGroupFilters,
            filterKey: 'group_name',
            resetPagination: resetGroupPagination,
          })
        }
        onClose={() => setGroupFilters({ group_name: undefined })}
      />

      {pagePerms && (isAdmin || canCreate) && (
        <Button type="primary" icon={<PlusOutlined />} onClick={toggleCreateGroupModal}>
          Novo grupo
        </Button>
      )}
    </S.ExtraContent>
  )

  return (
    <PageLayout
      isListPage
      title="Grupos"
      sidebar={
        <GroupsFilterSidebar
          form={form}
          isOpen={isFilterDrawerOpen}
          toggleDrawer={toggleFilterDrawer}
        />
      }
    >
      {isViewGroupModalOpen && (
        <ViewGroupModal
          isOpen={isViewGroupModalOpen}
          onClose={toggleViewGroupModal}
          data={selectedGroup as Group}
          canEdit={isAdmin || canEdit}
        />
      )}

      {isCreateGroupModalOpen && (
        <CreateGroupModal isOpen={isCreateGroupModalOpen} onClose={toggleCreateGroupModal} />
      )}

      <PageHeader
        title="Lista de grupos"
        // actions={<PageActions />}
        itemsFound={groups?.info.total || 0}
        isLoading={isLoading || isFetching}
      />

      <S.FiltersAndActionsContainer>
        <ActiveFiltersList
          filterList={getActiveFiltersList(groupFilters)}
          removeFilter={handleRemoveFilter}
          clearFilters={() => handleClearFilters(resetFilters)}
        />

        <PageActions />
      </S.FiltersAndActionsContainer>

      <GroupListTable
        data={groups?.results || []}
        isLoading={isLoading}
        handleSelectGroup={selectGroupDispatch}
        canDelete={isAdmin || canDelete}
      />

      <CustomPagination
        scrollToTop
        isLoading={isLoading}
        page={groups?.info.current_page || groupsPagination.page}
        pageSize={groupsPagination.per_page}
        totalItems={groups?.info.total}
        totalPages={groups?.info.pages || 0}
        setPagination={setGroupPagination}
      />
    </PageLayout>
  )
}

import { Form } from 'antd'

import * as S from '../styles'

export const RoleFormFirstStep = () => {
  return (
    <S.Container>
      <Form.Item
        name="title"
        label="Nome da permissão"
        rules={[
          {
            required: true,
            message: 'Campo obrigatório',
          },
        ]}
      >
        <S.Input placeholder="Ex: Permissão xpto" />
      </Form.Item>

      <Form.Item
        name="role_value"
        label="Valor da permissão"
        rules={[
          {
            required: true,
            message: 'Campo obrigatório',
          },
        ]}
      >
        <S.Input placeholder="Ex: dashboard" />
      </Form.Item>

      <Form.Item
        name="description"
        label="Descrição"
        rules={[
          {
            required: true,
            message: 'Campo obrigatório',
          },
          {
            max: 300,
            message: 'Máximo de 300 caracteres',
          },
        ]}
      >
        <S.TextArea rows={4} placeholder="Descrição da permissão" />
      </Form.Item>
    </S.Container>
  )
}

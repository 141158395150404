import { BiHash } from 'react-icons/bi'

import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Form } from 'antd'

import * as S from './styles'

import { passwordValidator } from '@/features/auth'

export type ResetPasswordFormData = {
  email: string
  code: string
  password: string
  confirmPassword: string
}

type ResetPasswordFormProps = {
  userEmail: string
  onSubmit: (data: ResetPasswordFormData) => void
  isLoading: boolean
}

export const ResetPasswordForm = ({ userEmail, onSubmit, isLoading }: ResetPasswordFormProps) => {
  return (
    <S.Container>
      <Form
        layout="vertical"
        onFinish={onSubmit}
        disabled={isLoading}
        initialValues={{
          email: userEmail,
        }}
      >
        <Form.Item
          name="email"
          data-testid="change-password-form-email"
          rules={[
            {
              required: true,
              message: 'E-mail é obrigatório',
            },
            {
              type: 'email',
              message: 'E-mail inválido',
            },
          ]}
        >
          <S.Input prefix={<UserOutlined />} placeholder="E-mail" disabled />
        </Form.Item>

        <Form.Item
          name="code"
          data-testid="change-password-form-code"
          rules={[
            {
              required: true,
              message: 'O código é obrigatório',
            },
          ]}
        >
          <S.Input prefix={<BiHash />} placeholder="Código recebido" />
        </Form.Item>

        <Form.Item
          name="password"
          data-testid="change-password-form-password"
          rules={[
            () => ({
              validator(_, value) {
                return passwordValidator(value)
              },
            }),
          ]}
        >
          <S.InputPassword prefix={<LockOutlined />} placeholder="Senha" />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          data-testid="change-password-form-confirm-password"
          rules={[
            { required: true, message: 'A confirmação da senha é obrigatória' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('As senhas não coincidem'))
              },
            }),
          ]}
        >
          <S.InputPassword
            type="password"
            prefix={<LockOutlined />}
            placeholder="Confirme a senha"
          />
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          block
          loading={isLoading}
          data-testid="change-password-form-submit"
        >
          Trocar senha
        </Button>
      </Form>
    </S.Container>
  )
}

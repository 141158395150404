import { DeleteOutlined, FileDoneOutlined, WarningOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useTheme } from 'styled-components'

import { useCompaniesFilter } from '@/app'
import { type TableAuthenticatedFeatures, formatDate, formatPhone } from '@/common'
import { BaseTable, TableAction, TableColumnButton } from '@/components'
import { Company } from '@/features/company'
import { SelectCompanyAction } from '@/pages'

interface CompaniesListTableProps extends TableAuthenticatedFeatures {
  data: Company[]
  isLoading: boolean
  handleSelectCompany: ({ type, company }: SelectCompanyAction) => void
}

export const CompaniesListTable = ({
  data,
  isLoading,
  handleSelectCompany,
  canDelete,
}: CompaniesListTableProps) => {
  const theme = useTheme()
  const setActiveSorting = useCompaniesFilter((state) => state.setActiveSorting)

  function confirmDeletion(company: Company) {
    Modal.confirm({
      title: `Tem certeza que deseja excluir esta empresa?`,
      content: (
        <span>
          A empresa <strong>{company.company_fancy_name}</strong> será excluída permanentemente.
        </span>
      ),
      centered: true,
      icon: <WarningOutlined style={{ color: '#FF0000' }} />,
      okText: 'Excluir',
      cancelText: 'Cancelar',
      okButtonProps: {
        type: 'primary',
        style: {
          color: '#FFF',
          backgroundColor: theme.colors.brand,
        },
      },
      cancelButtonProps: {
        type: 'default',
      },
      onOk: () => handleSelectCompany({ company, type: 'DELETE_COMPANY' }),
    })
  }

  const columns: ColumnsType<Company> = [
    {
      title: 'nº',
      key: 'number',
      align: 'center',
      width: 50,
      render: (_, __, index) => {
        const number = String(index + 1).padStart(2, '0')

        return <span>{number}</span>
      },
    },
    {
      title: 'Razão social',
      key: 'company_name',
      dataIndex: 'company_name',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (name: string, company) => (
        <TableColumnButton onClick={() => handleSelectCompany({ company, type: 'VIEW_COMPANY' })}>
          {name}
        </TableColumnButton>
      ),
    },
    {
      title: 'Nome fantasia',
      key: 'company_fancy_name',
      dataIndex: 'company_fancy_name',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'CNPJ',
      key: 'company_cnpj',
      dataIndex: 'company_cnpj',
    },
    {
      title: 'Responsável',
      key: 'manager',
      dataIndex: 'manager_name',
    },
    {
      title: 'Email',
      key: 'manager_email',
      dataIndex: 'manager_email',
    },
    {
      title: 'Telefone',
      key: 'manager_phone',
      dataIndex: 'manager_phone',
      render: (phone: string) => formatPhone(phone),
    },

    {
      title: 'Criado em',
      key: 'created_at',
      dataIndex: 'created_at',
      width: 125,
      align: 'center',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (date: string) => formatDate({ date }),
    },
    {
      title: 'Atualizado em',
      key: 'updated_at',
      dataIndex: 'updated_at',
      width: 125,
      align: 'center',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (date: string) => formatDate({ date }),
    },
    {
      title: 'Ações',
      key: 'actions',
      width: 100,
      align: 'center',
      render: (_, company) => {
        const isActive = company.active

        const actions = [
          {
            key: '1',
            label: (
              <Button
                type="link"
                onClick={() => handleSelectCompany({ company, type: 'VIEW_COMPANY' })}
                icon={<FileDoneOutlined />}
              >
                Dados cadastrais
              </Button>
            ),
          },
          canDelete
            ? {
                key: '2',
                label: (
                  <Button
                    danger
                    type="link"
                    onClick={() => confirmDeletion(company)}
                    icon={<DeleteOutlined />}
                  >
                    Excluir
                  </Button>
                ),
              }
            : null,
        ]

        return <TableAction actions={actions} disabled={!isActive} />
      },
    },
  ]

  return (
    <BaseTable
      loading={isLoading}
      dataSource={data}
      columns={columns}
      rowKey={(company: Company) => company.pk}
      setActiveSorting={setActiveSorting}
    />
  )
}

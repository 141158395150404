export function passwordValidator(password: string) {
  if (!password) {
    return Promise.reject(new Error('A senha é obrigatória'))
  }

  if (!/[a-z]/.test(password)) {
    return Promise.reject(new Error('A senha deve ter pelo menos uma letra minúscula'))
  }

  if (!/[A-Z]/.test(password)) {
    return Promise.reject(new Error('A senha deve ter pelo menos uma letra maiúscula'))
  }

  if (!/[0-9]/.test(password)) {
    return Promise.reject(new Error('A senha deve conter pelo menos um número'))
  }

  if (!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?+]/.test(password)) {
    return Promise.reject(new Error('A senha deve ter pelo menos um caractere especial'))
  }

  if (password.length < 14) {
    return Promise.reject(new Error('A senha deve ter no mínimo 14 caracteres'))
  }

  return Promise.resolve()
}

import { Button, Form } from 'antd'
import { toast } from 'sonner'

import { CreateAreaForm } from '../..'
import * as S from './styles'

import { BaseModal } from '@/components'
import { useCreateAreaMutation } from '@/features/area'

interface CreateAreaModalProps {
  isOpen: boolean
  onClose: () => void
}

interface CreateAreaFormData {
  area_name: string
  description: string
}

export const CreateAreaModal = ({ isOpen, onClose }: CreateAreaModalProps) => {
  const [form] = Form.useForm<CreateAreaFormData>()
  const createAreaMutation = useCreateAreaMutation()

  async function handleCreateArea({ area_name, description }: CreateAreaFormData) {
    const promise = createAreaMutation.mutateAsync({
      area_name,
      description,
    })

    toast.promise(promise, {
      success: 'Área criada com sucesso',
      error: 'Erro ao criar área',
      loading: 'Criando área...',
    })

    await promise

    onClose()
  }

  return (
    <BaseModal
      title="Nova área"
      open={isOpen}
      onCancel={onClose}
      closable={!createAreaMutation.isPending}
      maskClosable={!createAreaMutation.isPending}
      footer={
        <>
          <Button onClick={onClose} disabled={createAreaMutation.isPending}>
            Cancelar
          </Button>

          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={createAreaMutation.isPending}
          >
            Cadastrar
          </Button>
        </>
      }
    >
      <S.Container>
        <S.Subtitle>Criar nova área</S.Subtitle>

        <Form
          form={form}
          requiredMark
          layout="vertical"
          onFinish={handleCreateArea}
          disabled={createAreaMutation.isPending}
        >
          <CreateAreaForm />
        </Form>
      </S.Container>
    </BaseModal>
  )
}

import * as S from './styles'

export const RoleHelpContentSecondStep = () => {
  return (
    <>
      <S.DescriptionContent>
        <S.DescriptionTitle>Propriedades:</S.DescriptionTitle>

        <S.DescriptionText>
          Nesta tabela você poderá visualizar as propriedades da permissão. Contendo:{' '}
          <strong>Titulo</strong>, <strong>Descrição</strong>, <strong>Valor</strong> e{' '}
          <strong>Status</strong> (Ativo/Inativo).
        </S.DescriptionText>

        <S.DescriptionText>
          As propriedades <strong>ativas</strong> serão salvas dentro desta permissão e irão
          determinar quais ações o usuário poderá realizar.
        </S.DescriptionText>

        <S.DescriptionText>
          O campo <strong>Valor</strong> é um idenficador único que será utilizado para identificar
          a permissão internamente. Deve ser preenchido com um valor único e sem espaços.
        </S.DescriptionText>

        <S.DescriptionText>
          Os campos <strong>Titulo</strong> e <strong>Descrição</strong> são campos visuais que irão
          representar a permissão no sistema.
        </S.DescriptionText>
      </S.DescriptionContent>

      <S.DescriptionContent>
        <S.DescriptionTitle>Botão &quot;Criar Propriedade&quot;:</S.DescriptionTitle>

        <S.DescriptionText>
          Para adicionar uma nova propriedade customizada, clique no botão &quot;Criar
          Propriedade&quot;.
        </S.DescriptionText>

        <S.DescriptionText>
          Após clicar no botão, uma nova linha será inserida na tabela onde você poderá preencher os
          campos obrigatórios para criar uma nova propriedade.
        </S.DescriptionText>
      </S.DescriptionContent>
    </>
  )
}

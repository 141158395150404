import { useMutation, useQueryClient } from '@tanstack/react-query'

import { areaQueries } from '..'

import { api } from '@/services'

interface ICreateAreaMutation {
  area_name: string
  description: string
}

export const useCreateAreaMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (area: ICreateAreaMutation) => {
      return api.post('/area', area)
    },
    onMutate: ({ area_name, description }) => {
      queryClient.cancelQueries({ queryKey: areaQueries.all() })

      const cachedAreas = queryClient.getQueryData(areaQueries.list().queryKey)

      if (cachedAreas) {
        const updatedListResults = [
          ...cachedAreas.results,
          {
            pk: crypto.randomUUID(),
            area_name,
            description,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            active: true,
            systems: [],
          },
        ]

        queryClient.setQueryData(areaQueries.list().queryKey, {
          ...cachedAreas,
          results: updatedListResults,
        })
      }

      return cachedAreas
    },
    onError: (error, _, cachedAreas) => {
      console.error(error)

      if (cachedAreas) {
        queryClient.setQueryData(areaQueries.list().queryKey, cachedAreas)
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: areaQueries.lists() }),
  })
}

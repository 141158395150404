import { useState } from 'react'

import { DeleteOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Button, Form, Select } from 'antd'

import * as S from './styles'

import { groupQueries } from '@/features/groups'
import { systemQueries } from '@/features/systems'

interface GroupWithLabel {
  label: string
  value: string
  key: string
}

interface SelectedGroup {
  system: string
  groups: GroupWithLabel[]
}

export const UserFormSecondStep = () => {
  const form = Form.useFormInstance()
  const [selectedGroups, setSelectedGroups] = useState<SelectedGroup[]>([])

  const { data: systems, isLoading: isSystemsLoading } = useQuery(
    systemQueries.list(undefined, undefined, {
      page: 1,
      per_page: 0,
    }),
  )

  const systemSelectOptions = systems?.results.map((system) => ({
    value: system.system_name,
    label: system.system_name,
    key: system.pk,
  }))

  const selectedSystem = Form.useWatch('system')

  const { data: groups, isLoading: isGroupsLoading } = useQuery({
    ...groupQueries.list({
      systems: {
        system_name: {
          value: [selectedSystem],
          comparator: 'in',
        },
      },
    }),
    enabled: !!selectedSystem,
  })

  const groupSelectOptions = groups?.results.map((group) => ({
    value: group.pk,
    label: group.group_name,
  }))

  // function handleSystemChange() {
  //   // form.setFieldValue('groups', undefined)
  //   const currentGroupPks: string[] = form.getFieldValue('user-groups') || []
  //   // console.log({ currentGroupPks })
  // }

  function handleSelectGroup(selected: GroupWithLabel) {
    const currentSystem = form.getFieldValue('system')

    setSelectedGroups((prevGroups) => {
      const existingSystemIndex = prevGroups.findIndex((item) => item.system === currentSystem)

      if (existingSystemIndex !== -1) {
        // Update existing system's groups
        const updatedGroups = prevGroups.map((item, index) =>
          index === existingSystemIndex ? { ...item, groups: [...item.groups, selected] } : item,
        )

        // Remove the system if there are no groups left
        return updatedGroups.filter((item) => item.groups.length > 0)
      } else {
        // Add new system with selected groups only if there are groups
        return [...prevGroups, { system: currentSystem, groups: [selected] }]
      }
    })

    const currentGroupPks: string[] = form.getFieldValue('user-groups') || []

    form.setFieldValue('user-groups', [...new Set([...currentGroupPks, selected.value])])
  }

  function handleDeselect(deselected: GroupWithLabel, system?: string) {
    const currentSystem = system || form.getFieldValue('system')

    const groupSystem = selectedGroups.find((group) => group.system === currentSystem)

    const updatedGroups = groupSystem?.groups.filter((group) => group.value !== deselected.value)

    setSelectedGroups((prevGroups) => {
      const existingSystemIndex = prevGroups.findIndex((item) => item.system === currentSystem)

      if (existingSystemIndex !== -1) {
        // Update existing system's groups or remove if no groups left
        const updatedGroupsList = prevGroups
          .map((item, index) =>
            index === existingSystemIndex ? { ...item, groups: updatedGroups || [] } : item,
          )
          // Remove item if there's no groups left
          .filter((item) => item.groups.length > 0)

        return updatedGroupsList
      }

      return prevGroups
    })

    const updatedGroupsFormValue = form
      .getFieldValue('groups')
      ?.filter((item: GroupWithLabel) => item.value !== deselected.value)

    form.setFieldValue('groups', updatedGroupsFormValue || [])

    const currentGroupPks: string[] = form.getFieldValue('user-groups') || []

    const updatedGroupPks = currentGroupPks.filter((item) => item !== deselected.value)

    form.setFieldValue('user-groups', updatedGroupPks)
  }

  return (
    <S.FormContainer>
      <S.FormArea>
        <S.AreaTitle>Vincular grupo ao usuário</S.AreaTitle>

        <S.FormRow>
          <Form.Item name={'system'} label="Sistema">
            <Select
              showSearch
              allowClear
              // onChange={handleSystemChange}
              options={systemSelectOptions}
              loading={isSystemsLoading}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>

          <Form.Item
            name={'groups'}
            label="Grupos cadastrados*"
            rules={[
              () => ({
                validator(_, value) {
                  if (!value || selectedGroups.length <= 0 || !selectedGroups) {
                    return Promise.reject(new Error('Selecione ao menos um grupo'))
                  }
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <Select
              labelInValue
              value={selectedGroups}
              showSearch
              mode="multiple"
              maxTagCount={0}
              options={groupSelectOptions}
              loading={isGroupsLoading}
              disabled={!selectedSystem}
              onDeselect={(deselected) => handleDeselect(deselected as unknown as GroupWithLabel)}
              onSelect={(selected) => handleSelectGroup(selected as unknown as GroupWithLabel)}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </S.FormRow>
      </S.FormArea>

      {selectedGroups.length > 0 ? (
        <S.SelectedArea>
          <S.AreaTitle>Grupos Vinculados</S.AreaTitle>

          {selectedGroups.map((group) => {
            return (
              <S.GroupsContainer key={group.system}>
                <S.SystemTitle>{group.system}</S.SystemTitle>

                <S.CardsContainer>
                  {group.groups.map((g) => (
                    <S.GroupCard key={g.value}>
                      <span>{g.label}</span>

                      <Button
                        type="text"
                        icon={<DeleteOutlined />}
                        onClick={() => handleDeselect(g, group.system)}
                      />
                    </S.GroupCard>
                  ))}
                </S.CardsContainer>
              </S.GroupsContainer>
            )
          })}
        </S.SelectedArea>
      ) : null}
    </S.FormContainer>
  )
}

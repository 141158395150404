import { useMutation, useQueryClient } from '@tanstack/react-query'

import { ICreateGroupMutation } from '.'

import { groupQueries } from '..'

import { api } from '@/services'

export type EditGroupMutation = ICreateGroupMutation & {
  pk: string
}

export const useEditGroupMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ pk, ...rest }: EditGroupMutation) => {
      return api.put(`/group/${pk}`, rest)
    },
    onMutate: async (editedGroup) => {
      await queryClient.cancelQueries({ queryKey: groupQueries.all() })

      const cachedGroup = queryClient.getQueryData(groupQueries.detail(editedGroup.pk).queryKey)

      if (cachedGroup) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { group_systems_pk, group_users_id, ...rest } = editedGroup

        queryClient.setQueryData(groupQueries.detail(editedGroup.pk).queryKey, {
          ...cachedGroup,
          ...rest,
          group_roles: [],
        })
      }

      const cachedGroupsList = queryClient.getQueryData(groupQueries.list().queryKey)

      if (cachedGroupsList) {
        const updatedGroupsList = cachedGroupsList.results.map((group) => {
          if (group.pk === editedGroup.pk) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { group_systems_pk, group_users_id, ...rest } = editedGroup

            return {
              ...group,
              ...rest,
              group_roles: [],
            }
          }

          return group
        })

        queryClient.setQueryData(groupQueries.list().queryKey, {
          ...cachedGroupsList,
          results: updatedGroupsList,
        })
      }

      return { cachedGroupsList, cachedGroup }
    },
    onError: (err, _, ctx) => {
      console.error(err)

      if (ctx?.cachedGroup) {
        queryClient.setQueryData(groupQueries.detail(ctx.cachedGroup.pk).queryKey, ctx.cachedGroup)
      }

      if (ctx?.cachedGroupsList) {
        queryClient.setQueryData(groupQueries.list().queryKey, ctx.cachedGroupsList)
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: groupQueries.all() }),
  })
}

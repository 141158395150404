import { Divider, Space } from 'antd'

import * as S from './styles'

import { LoadingSpinner } from '..'

interface PageHeaderProps {
  title: string
  actions?: React.ReactNode
  itemsFound?: number
  isLoading?: boolean
}

export const PageHeader = ({ title, actions, itemsFound, isLoading }: PageHeaderProps) => {
  return (
    <S.Container>
      <S.TitleContainer>
        <Space style={{ minHeight: '25px' }}>
          <S.Title>{title}</S.Title>

          {itemsFound && (
            <>
              <Divider type="vertical" style={{ margin: 0 }} />
              <S.TotalItems>{itemsFound}</S.TotalItems>
              <S.ItemsText>{itemsFound > 1 ? 'encontrados' : 'encontrado'}</S.ItemsText>
            </>
          )}

          {isLoading && <LoadingSpinner />}
        </Space>
      </S.TitleContainer>

      <S.ActionsContainer>{actions}</S.ActionsContainer>
    </S.Container>
  )
}

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { areaQueries } from '..'

import { api } from '@/services'

interface EditAreaMutation {
  pk: string
  area_name: string
  description: string
  active: boolean
}

export const useEditAreaMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ area_name, description, pk }: EditAreaMutation) => {
      return api.put(`area/${pk}`, {
        area_name,
        description,
      })
    },
    onMutate: async (editedArea) => {
      await queryClient.cancelQueries({ queryKey: areaQueries.all() })

      const cachedArea = queryClient.getQueryData(areaQueries.detail(editedArea.pk).queryKey)

      if (cachedArea) {
        queryClient.setQueryData(areaQueries.detail(editedArea.pk).queryKey, {
          ...cachedArea,
          area_name: editedArea.area_name,
          description: editedArea.description,
          updated_at: Date.now().toString(),
        })
      }

      const cachedAreasList = queryClient.getQueryData(areaQueries.list().queryKey)

      if (cachedAreasList) {
        const updatedAreasList = cachedAreasList.results.map((area) => {
          if (area.pk === editedArea.pk) {
            return {
              ...area,
              ...editedArea,
            }
          }

          return area
        })

        queryClient.setQueryData(areaQueries.list().queryKey, {
          ...cachedAreasList,
          results: updatedAreasList,
        })
      }

      return { cachedArea, cachedAreasList }
    },
    onError: (err, _, ctx) => {
      console.error(err)

      if (ctx?.cachedArea) {
        queryClient.setQueryData(areaQueries.detail(ctx.cachedArea.pk).queryKey, ctx.cachedArea)
      }

      if (ctx?.cachedAreasList) {
        queryClient.setQueryData(areaQueries.list().queryKey, ctx.cachedAreasList)
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: areaQueries.all() }),
  })
}

import { Form, FormInstance, Input } from 'antd'

import { AreaFormData } from '../..'

interface EditAreaFormProps {
  form: FormInstance<AreaFormData>
  handleSubmit: (values: AreaFormData) => void
  isLoading?: boolean
}

export const EditAreaForm = ({ form, handleSubmit, isLoading }: EditAreaFormProps) => {
  return (
    <Form layout="vertical" form={form} onFinish={handleSubmit} disabled={isLoading} requiredMark>
      <Form.Item
        name="area_name"
        label="Nome"
        rules={[
          {
            required: true,
            message: 'Campo obrigatório',
            whitespace: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="description"
        label="Descrição"
        rules={[
          {
            required: true,
            message: 'Campo obrigatório',
            whitespace: true,
          },
          {
            max: 300,
            message: 'Máximo de 300 caracteres',
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Form>
  )
}

import { Input as AntdInput } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Input = styled(AntdInput)`
  height: 40px;
`

export const TextArea = styled(AntdInput.TextArea)``

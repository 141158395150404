import styled from 'styled-components'

export const HeaderActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .ant-select {
    width: 200px;
  }

  .ant-select-arrow {
    color: ${({ theme }) => theme.colors.brand};
  }
`
export const FiltersAndActionsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
`

import type { ModalProps as AntdModalProps } from 'antd'

import * as S from './styles'

interface ModalProps extends AntdModalProps {
  title?: string | React.ReactNode
}

export const BaseModal = ({ title, ...rest }: ModalProps) => {
  return <S.Modal width={740} centered title={<S.Header>{title}</S.Header>} {...rest} />
}

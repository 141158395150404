import type { SystemModule } from '@/features/systems'

import type {
  GroupRoles,
  GroupSelectedPermissions,
  GroupSelectedSystems,
  GroupSystem,
  GroupSystemModule,
} from '..'

import { User } from '@/features/users'

// return only system pk
export function getSystemsPk(groupSystems: GroupSelectedSystems[] | GroupSystem[]) {
  if ('pk' in groupSystems[0]) {
    return (groupSystems as GroupSystem[]).map((system) => system.pk)
  }

  return (groupSystems as GroupSelectedSystems[]).map((system) => system.value)
}

export function formatGroupRoles(selectedPermissions: GroupSelectedPermissions[] | GroupRoles[]) {
  if ('children' in selectedPermissions[0]) {
    return (selectedPermissions as GroupSelectedPermissions[]).map((perm) => {
      const permissions = perm.children.map((child) => {
        return {
          custom: child.custom,
          description: child.description,
          title: child.title,
          value: child.value,
        }
      })

      return {
        role_pk: perm.pk,
        role_permissions: permissions,
      }
    })
  }

  return (selectedPermissions as GroupRoles[]).map((role) => {
    return {
      role_pk: role.pk,
      role_permissions: role.role_permissions,
    }
  })
}

export function formatGroupSelectedPermissions(
  groupRoles: GroupRoles[] | undefined,
): GroupSelectedPermissions[] {
  if (!groupRoles) return [] as GroupSelectedPermissions[]

  return groupRoles?.map((role) => ({
    ...role,
    key: role.pk,
    children: role.role_permissions.map((perm) => ({
      key: `${role.pk}.${perm.id}`,
      title: perm.title,
      description: perm.description,
      value: perm.value,
      custom: perm.custom,
    })),
  }))
}

export function formatGroupSystems(groupSystems: Array<GroupSystem>): GroupSelectedSystems[] {
  return groupSystems?.map((system) => ({
    label: system.system_name,
    value: system.pk,
    key: system.pk,
    disabled: undefined,
    title: undefined,
    is_admin: system.is_admin,
    admin_modules: system.admin_modules,
  }))
}

export function formatAdminSelection(selectedAdmins: (GroupSystem | GroupSystemModule)[]) {
  const systemAdmins: string[] = []
  const moduleAdmins: string[] = []

  // Type guard functions
  function isSystem(admin: GroupSystem | GroupSystemModule): admin is GroupSystem {
    return (
      (admin as GroupSystem).system_name !== undefined &&
      (admin as SystemModule).module_name === undefined
    )
  }

  // This one is used for initial values unformatted by the table
  function isGroupSelectedSystems(
    admin: GroupSystemModule | GroupSelectedSystems,
  ): admin is GroupSelectedSystems {
    return (admin as GroupSelectedSystems).is_admin && !!(admin as GroupSelectedSystems).value
  }

  function isSystemModule(admin: GroupSystem | GroupSystemModule): admin is GroupSystemModule {
    admin as GroupSystem

    return (
      ((admin as GroupSystemModule).module_name !== undefined &&
        (admin as GroupSystem).system_name === undefined) ||
      ((admin as GroupSystemModule).is_admin && !!(admin as GroupSystemModule).id)
    )
  }

  selectedAdmins.forEach((admin) => {
    if (isSystem(admin) || isGroupSelectedSystems(admin)) {
      systemAdmins.push((admin as GroupSystem)?.pk || (admin as GroupSelectedSystems)?.value) // system_pk
    }

    if (isSystemModule(admin)) {
      moduleAdmins.push(admin.id) // module_pk
    }
  })

  return {
    group_system_admin: systemAdmins,
    group_module_admin: moduleAdmins,
  }
}

export function filterOutExcludedUserIds(groupUsers: User[] | undefined) {
  const filteredUsers = groupUsers?.filter((user) => user.active)

  const userIds = filteredUsers?.map((user) => user.id)

  return userIds
}

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { type CreateGroupWithAdmins, type CreateGroupWithRoles, groupQueries } from '..'

import { api } from '@/services'

export type ICreateGroupMutation = {
  group_name: string
  description: string
  group_systems_pk: string[]
  group_users_id: string[]
} & (CreateGroupWithRoles | CreateGroupWithAdmins)

export const useCreateGroupMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: ICreateGroupMutation) => {
      return api.post('/group', data)
    },
    onMutate: async (data) => {
      await queryClient.cancelQueries({ queryKey: groupQueries.all() })

      const cachedGroups = queryClient.getQueryData(groupQueries.list().queryKey)

      if (cachedGroups) {
        const updatedGroupsList = [
          ...cachedGroups.results,
          {
            ...data,
            pk: crypto.randomUUID(),
            active: true,
            group_systems: [],
            group_users: [],
            group_roles: [],
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
          },
        ]

        queryClient.setQueryData(groupQueries.list().queryKey, {
          ...cachedGroups,
          results: updatedGroupsList,
        })
      }

      return cachedGroups
    },
    onError: (err, _, cachedGroups) => {
      console.error(err)

      if (cachedGroups) {
        queryClient.setQueryData(groupQueries.list().queryKey, cachedGroups)
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: groupQueries.all() }),
  })
}

import { useState } from 'react'

import { Button, Form, Steps } from 'antd'
import { toast } from 'sonner'

import { CreateSystemFormFirstStep, CreateSystemFormSecondStep } from '../..'
import * as S from './styles'

import { BaseModal } from '@/components'
import { formatSystemLinks, formatSystemModules, useCreateSystemMutation } from '@/features/systems'

interface CreateSystemModalProps {
  isOpen: boolean
  onClose: () => void
}

export interface FirstStepData {
  system_name: string
  description: string
  system_area: string
  system_modules: string[]
}

interface SecondStepData {
  default_system_page: string
  default_system_link: string
  system_pages?: [
    {
      page: string
      link: string
    },
    {
      page: string
      link: string
    },
  ]
}

interface FormData extends FirstStepData, SecondStepData {}

export const CreateSystemModal = ({ isOpen, onClose }: CreateSystemModalProps) => {
  const [form] = Form.useForm()
  const createSystemMutation = useCreateSystemMutation()

  const [currentStep, setCurrentStep] = useState(0)
  const [formValues, setFormValues] = useState({} as FormData)

  const steps = [
    {
      title: 'Dados do sistema',
      content: <CreateSystemFormFirstStep systemModules={formValues.system_modules} />,
    },
    {
      title: 'Páginas',
      content: <CreateSystemFormSecondStep systemPages={formValues.system_pages} />,
    },
  ]

  async function handleAdvanceStep() {
    try {
      const step1Values = await form.validateFields()

      setFormValues((old) => ({ ...old, ...step1Values }))

      setCurrentStep(1)
    } catch (error) {
      console.error(error)
    }
  }

  function handleReturnStep() {
    const step2Values = form.getFieldsValue()

    setFormValues((old) => ({ ...old, ...step2Values }))

    setCurrentStep(0)
  }

  async function handleCreateSystem(data: SecondStepData) {
    const formData: FormData = {
      ...formValues,
      ...data,
    }

    const payload = {
      area_pk: formData.system_area,
      system_name: formData.system_name,
      description: formData.description,
      system_links: formatSystemLinks({
        defaultLink: {
          page_name: formData.default_system_page,
          url: formData.default_system_link,
        },
        links: formData.system_pages,
      }),
      system_modules: formatSystemModules(formData.system_modules),
    }

    const promise = createSystemMutation.mutateAsync(payload)

    toast.promise(promise, {
      loading: 'Cadastrando sistema...',
      success: 'Sistema cadastrado com sucesso!',
      error: 'Erro ao cadastrar sistema',
    })

    await promise

    onClose()
  }

  return (
    <BaseModal
      title="Novo sistema"
      open={isOpen}
      onCancel={onClose}
      footer={
        <>
          {currentStep === 0 ? (
            <Button onClick={onClose}>Cancelar</Button>
          ) : (
            <Button onClick={handleReturnStep} disabled={createSystemMutation.isPending}>
              Voltar
            </Button>
          )}

          {currentStep === 0 ? (
            <Button type="primary" onClick={handleAdvanceStep}>
              Próximo
            </Button>
          ) : (
            <Button
              type="primary"
              onClick={() => form.submit()}
              disabled={createSystemMutation.isPending}
            >
              Cadastrar
            </Button>
          )}
        </>
      }
    >
      <S.Container>
        <Steps current={currentStep} items={steps} />

        <Form
          form={form}
          requiredMark
          layout="vertical"
          onFinish={handleCreateSystem}
          disabled={createSystemMutation.isPending}
        >
          {steps[currentStep].content}
        </Form>
      </S.Container>
    </BaseModal>
  )
}

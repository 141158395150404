import { useMutation, useQueryClient } from '@tanstack/react-query'

import { userQueries } from '../..'

import { api } from '@/services'

export const useDeleteUserMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (userId: string) => api.delete(`/user/${userId}`),
    onMutate: async (userId: string) => {
      await queryClient.cancelQueries({ queryKey: userQueries.all() })

      const cachedUsersList = queryClient.getQueryData(userQueries.list().queryKey)

      if (cachedUsersList) {
        const updatedUsersList = cachedUsersList.results.filter((user) => user.id !== userId)

        queryClient.setQueryData(userQueries.list().queryKey, {
          ...cachedUsersList,
          results: updatedUsersList,
        })
      }

      return cachedUsersList
    },
    onError: (err, _, cachedUsersList) => {
      console.error(err)

      if (cachedUsersList) {
        queryClient.setQueryData(userQueries.list().queryKey, cachedUsersList)
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: userQueries.all() }),
  })
}

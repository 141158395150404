import { Form } from 'antd'

import * as S from './styles'

export const CreateAreaForm = () => {
  return (
    <S.Container>
      <Form.Item
        name="area_name"
        label="Nome da área"
        rules={[
          {
            required: true,
            message: 'Campo obrigatório',
            whitespace: true,
          },
        ]}
      >
        <S.Input placeholder="Ex: Comercial" />
      </Form.Item>

      <Form.Item
        name="description"
        label="Descrição"
        rules={[
          {
            required: true,
            message: 'Campo obrigatório',
            whitespace: true,
          },
          {
            max: 300,
            message: 'Máximo de 300 caracteres',
          },
        ]}
      >
        <S.TextArea rows={4} placeholder="Descrição da area" />
      </Form.Item>
    </S.Container>
  )
}

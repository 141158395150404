import { Link } from 'react-router-dom'

import { Image } from 'antd'

import * as S from './styles'

type AccessLayoutProps = {
  title?: string
  children: React.ReactNode
}

import acerLogo from '@/assets/acer-login-unico-gray.svg'
import { useDocumentTitle } from '@/common'

export const AccessLayout = ({ title, children }: AccessLayoutProps) => {
  useDocumentTitle(title)

  return (
    <S.Container>
      <S.Layout>
        <S.Content>
          <header>
            <Link to="/auth">
              <Image src={acerLogo} alt="Logo da Acer" width={320} preview={false} />
            </Link>
          </header>

          <main>{children}</main>
        </S.Content>
      </S.Layout>

      <S.ImageContainer>
        <S.IllustrationImage />
      </S.ImageContainer>
    </S.Container>
  )
}

import { useState } from 'react'

import { Button, Form, Steps } from 'antd'
import { toast } from 'sonner'

import {
  GroupFormFirstStep,
  GroupFormSecondStep,
  GroupFormThirdStep,
  GroupFormFourthStep,
} from '../../Form'
import * as S from './styles'

import { BaseModal } from '@/components'
import {
  useCreateGroupMutation,
  GroupFormValues,
  getSystemsPk,
  formatGroupRoles,
  formatAdminSelection,
  ICreateGroupMutation,
} from '@/features/groups'

interface ICreateGroupModalProps {
  isOpen: boolean
  onClose: () => void
}

export const CreateGroupModal = ({ isOpen, onClose }: ICreateGroupModalProps) => {
  const [form] = Form.useForm()
  const createGroupMutation = useCreateGroupMutation()

  const [currentStep, setCurrentStep] = useState(0)

  const [formValues, setFormValues] = useState<GroupFormValues>({} as GroupFormValues)

  async function handleAdvanceStep() {
    const isSystemStep = currentStep === 1

    try {
      await form.validateFields()

      if (isSystemStep) {
        const selectedAdmins = form.getFieldValue('group_admin')

        const hasSelectedAdmins = selectedAdmins?.length > 0

        if (hasSelectedAdmins) {
          setFormValues((old) => ({ ...old, ...form.getFieldsValue(), selectedAdmins }))
          setCurrentStep((old) => old + 2) // skip permissions step
          return
        }
      }

      // save form values into state
      setFormValues((old) => ({ ...old, ...form.getFieldsValue() }))
      setCurrentStep((old) => old + 1)
    } catch (e) {
      console.error(e)
    }
  }

  function handleReturnStep() {
    const isSystemStep = currentStep === 1
    const isUsersStep = currentStep === 3

    // reset systems field value if user goes back to the first step without confirming the selected systems
    if (isSystemStep) {
      form.setFieldsValue({ systems: formValues.systems })
    }

    if (isUsersStep) {
      const hasSelectedAdmins = formValues?.selectedAdmins && formValues.selectedAdmins.length > 0

      if (hasSelectedAdmins) {
        setCurrentStep((old) => old - 2) // skip permissions step
        return
      }
    }

    setCurrentStep((old) => old - 1)
  }

  async function handleCreateGroup(data: { group_users_id: string[] }) {
    try {
      await form.validateFields()

      const hasSelectedAdmins = formValues?.selectedAdmins && formValues.selectedAdmins.length > 0

      const values = { ...formValues, ...data }

      let payload = {
        group_name: values.group_name,
        description: values.description,
        group_systems_pk: getSystemsPk(values.systems),
        group_users_id: values.group_users_id,
      } as ICreateGroupMutation

      if (!hasSelectedAdmins) {
        // Add group_roles
        payload = {
          ...payload,
          group_roles: formatGroupRoles(values.selectedPermissions),
        } as ICreateGroupMutation
      }

      if (hasSelectedAdmins && formValues.selectedAdmins) {
        // add selected admins (system & module)
        const { group_module_admin, group_system_admin } = formatAdminSelection(
          formValues?.selectedAdmins,
        )

        payload = {
          ...payload,
          group_system_admin,
          group_module_admin,
        } as ICreateGroupMutation
      }

      const promise = createGroupMutation.mutateAsync(payload)

      toast.promise(promise, {
        loading: 'Criando grupo...',
        success: 'Grupo criado com sucesso!',
        error: 'Erro ao criar grupo.',
      })

      await promise

      onClose()
    } catch (err) {
      console.error(err)
    }
  }

  const steps = [
    {
      title: 'Dados do grupo',
      info: {
        title: 'Dados do grupo',
        description: 'Defina nome e descrição do grupo',
      },
      content: <GroupFormFirstStep />,
    },
    {
      title: 'Vincular sistemas',
      info: {
        title: `Vincular sistemas ao ${formValues?.group_name || 'grupo'}`,
        description: 'Defina os sistemas que serão vinculados ao grupo',
      },
      content: (
        <GroupFormSecondStep
          initialValues={formValues.systems}
          initialAdmins={form.getFieldValue('group_admin')}
        />
      ),
    },
    {
      title: 'Vincular permissões',
      info: {
        title: `Vincular permissões ao ${formValues?.group_name || 'grupo'}`,
        description: 'Transfira a permissão para o grupo criado',
      },
      content: (
        <GroupFormThirdStep
          groupName={formValues.group_name}
          groupSystems={formValues.systems}
          initialData={{
            leftTable: form.getFieldValue('rolesLeftTable') || [],
            rightTable: formValues.selectedPermissions || [],
          }}
        />
      ),
    },
    {
      title: 'Vincular usuários',
      info: {
        title: `Vincular usuários ao ${formValues?.group_name || 'grupo'}`,
      },
      content: <GroupFormFourthStep groupName={formValues.group_name} />,
    },
  ]

  return (
    <BaseModal
      title="Criar grupo"
      open={isOpen}
      onCancel={onClose}
      width={currentStep > 1 ? 1360 : 840}
      footer={
        <>
          {currentStep === 0 ? (
            <Button onClick={onClose} type="link" disabled={createGroupMutation.isPending}>
              Cancelar
            </Button>
          ) : (
            <Button type="link" onClick={handleReturnStep}>
              Voltar
            </Button>
          )}

          {currentStep < 3 ? (
            <Button type="primary" onClick={handleAdvanceStep}>
              Próximo
            </Button>
          ) : (
            <Button
              type="primary"
              onClick={() => form.submit()}
              loading={createGroupMutation.isPending}
            >
              Finalizar
            </Button>
          )}
        </>
      }
    >
      <S.Container>
        <Steps current={currentStep} items={steps} />

        <S.StepInfoContainer>
          <h3>{steps[currentStep].info.title}</h3>
          <span>{steps[currentStep].info?.description}</span>
        </S.StepInfoContainer>

        <Form
          form={form}
          requiredMark
          layout="vertical"
          name="create-group"
          onFinish={handleCreateGroup}
          disabled={createGroupMutation.isPending}
        >
          {steps[currentStep].content}
        </Form>
      </S.Container>
    </BaseModal>
  )
}

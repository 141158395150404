import { useMemo } from 'react'

import dayjs from 'dayjs'

import { FilterExpression } from '../types'

type NestedFilterValue = FilterExpression | { [key: string]: FilterExpression | undefined }

const useInitialFilterFormValues = <T extends Record<string, NestedFilterValue | undefined>>(
  filtersInput: T,
) => {
  return useMemo<Record<string, any>>(() => {
    const dateFields = ['created_at', 'updated_at']

    function processValue(field: string, input: NestedFilterValue | undefined): any {
      if (!input) return undefined

      if (input?.value !== undefined) {
        // It's a leaf node
        const value = input.value

        if (!value) return

        if (dateFields.includes(field)) {
          // Handle date formatting
          if (typeof value === 'string') {
            return dayjs(value)
          } else if (Array.isArray(value)) {
            return value.map((date) => dayjs(date))
          }
        }
        return value
      } else if (typeof input === 'object' && input !== null) {
        // It's a nested object; recurse into it
        return Object.entries(input).reduce(
          (acc, [key, nestedValue]) => {
            const result = processValue(key, nestedValue as NestedFilterValue | undefined)
            if (result !== undefined) {
              // Only add if result is not undefined
              acc[key] = result
            }
            return acc
          },
          {} as Record<string, any>,
        )
      }
    }

    return Object.entries(filtersInput).reduce(
      (filters, [field, objValue]) => {
        if (objValue === undefined) return filters // skip if undefined

        const result = processValue(field, objValue)
        if (result !== undefined) {
          filters[field] = result
        }
        return filters
      },
      {} as Record<string, any>,
    )
  }, [filtersInput])
}

export { useInitialFilterFormValues }

import { useReducer } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Button, Form } from 'antd'
import { toast } from 'sonner'
import { shallow } from 'zustand/shallow'

import * as S from './styles'

import { useRoleFilterStore } from '@/app'
import {
  getActiveFiltersList,
  pagePermissionsKeys,
  removeActiveFilter,
  useAuth,
  useFilterActions,
  useToggle,
} from '@/common'
import { ActiveFiltersList, CustomPagination, PageHeader, SearchInput } from '@/components'
import {
  RolesListTable,
  useDeleteRoleMutation,
  roleQueries,
  RoleFilterSidebar,
  CreateRoleModal,
  ViewRoleModal,
} from '@/features/roles'
import type { Role, RoleFilters } from '@/features/roles'
import { PageLayout } from '@/layouts/PageLayout'

export type SelectRoleAction = {
  role: Role
  type: 'DELETE_ROLE' | 'VIEW_ROLE'
}

export const PermissionsPage = () => {
  const [form] = Form.useForm()
  const { handleClearFilters, handleSearchFilter } = useFilterActions(form)
  const { getPagePermissions, formatPagePermissions } = useAuth()

  const pagePerms = getPagePermissions({
    moduleKey: pagePermissionsKeys.permissions.module,
    roleKey: pagePermissionsKeys.permissions.role,
  })

  const { isAdmin, canCreate, canDelete, canEdit } = formatPagePermissions(pagePerms)

  const {
    roleFilters,
    activeSorting,
    rolePagination,
    setFilters,
    setRolePagination,
    resetFilters,
    resetRolePagination,
  } = useRoleFilterStore(
    (s) => ({
      roleFilters: s.filters,
      activeSorting: s.activeSorting,
      rolePagination: s.pagination,
      setFilters: s.setFilters,
      setRolePagination: s.setPagination,
      resetFilters: s.resetFilters,
      resetRolePagination: s.resetPagination,
    }),
    shallow,
  )

  const {
    data: roles,
    isLoading,
    isFetching,
  } = useQuery(roleQueries.list(roleFilters, activeSorting, rolePagination))

  const deleteRoleMutation = useDeleteRoleMutation()

  const [isFilterDrawerOpen, toggleFilterDrawer] = useToggle(false)
  const [isCreateRoleModalOpen, toggleCreateRoleModal] = useToggle(false)
  const [isViewRoleModalOpen, toggleViewRoleModal] = useToggle(false)

  const [selectedRole, selectRoleDispatch] = useReducer(
    (_: Role | null, action: SelectRoleAction) => {
      switch (action.type) {
        case 'DELETE_ROLE':
          handleDeleteRole(action.role)
          return null
        case 'VIEW_ROLE':
          toggleViewRoleModal()
          return action.role
        default:
          return null
      }
    },
    null,
  )

  async function handleDeleteRole(role: Role) {
    if (deleteRoleMutation.isPending) return

    const promise = deleteRoleMutation.mutateAsync(role.pk)

    toast.promise(promise, {
      loading: 'Excluindo permissão...',
      success: 'Permissão excluída com sucesso!',
      error: 'Erro ao excluir a permissão!',
    })
  }

  function handleRemoveFilter(filterKey: keyof RoleFilters, filterValue?: string) {
    const updatedFilters = removeActiveFilter({
      activeFilters: roleFilters,
      filterKey,
      filterValue,
      form,
    })

    setFilters({ ...updatedFilters })
  }

  const PageActions = () => {
    return (
      <S.HeaderActionsContainer>
        <SearchInput
          placeholder="Buscar por permissão"
          initialValue={roleFilters.title?.value}
          onSearch={(searchValue) =>
            handleSearchFilter({
              searchValue,
              storeCallback: setFilters,
              filterKey: 'title',
              resetPagination: resetRolePagination,
            })
          }
          onClose={() => setFilters({ title: undefined })}
        />

        {pagePerms && (isAdmin || canCreate) && (
          <Button type="primary" icon={<PlusOutlined />} onClick={toggleCreateRoleModal}>
            Nova permissão
          </Button>
        )}
      </S.HeaderActionsContainer>
    )
  }

  return (
    <PageLayout
      isListPage
      title="Permissões"
      sidebar={
        <RoleFilterSidebar
          isOpen={isFilterDrawerOpen}
          toggleDrawer={toggleFilterDrawer}
          form={form}
        />
      }
    >
      {isCreateRoleModalOpen && (
        <CreateRoleModal isOpen={isCreateRoleModalOpen} onClose={toggleCreateRoleModal} />
      )}

      {isViewRoleModalOpen && (
        <ViewRoleModal
          isOpen={isViewRoleModalOpen}
          onClose={toggleViewRoleModal}
          data={selectedRole as Role}
          canEdit={isAdmin || canEdit}
        />
      )}

      <PageHeader
        title="Lista de permissões"
        itemsFound={roles?.info.total || 0}
        isLoading={isLoading || isFetching}
        // actions={<PageActions />}
      />

      <S.FiltersAndActionsContainer>
        <ActiveFiltersList
          filterList={getActiveFiltersList(roleFilters)}
          removeFilter={handleRemoveFilter}
          clearFilters={() => handleClearFilters(resetFilters)}
        />

        <PageActions />
      </S.FiltersAndActionsContainer>

      <RolesListTable
        data={roles?.results || []}
        isLoading={isLoading}
        handleSelectRole={selectRoleDispatch}
        canDelete={isAdmin || canDelete}
      />

      <CustomPagination
        scrollToTop
        isLoading={isLoading}
        page={roles?.info.current_page || rolePagination.page}
        pageSize={rolePagination.per_page}
        totalItems={roles?.info.total}
        totalPages={roles?.info.pages || 0}
        setPagination={setRolePagination}
      />
    </PageLayout>
  )
}

import { Table, TableProps } from 'antd'
import { SorterResult } from 'antd/es/table/interface'

import * as S from './styles'

import type { ActiveSorting } from '@/common'

interface BaseTableProps<T> extends TableProps<T> {
  setActiveSorting?: (sorting: ActiveSorting) => void
}

export const BaseTable = <T extends object>({ setActiveSorting, ...rest }: BaseTableProps<T>) => {
  function handleSort(sorter: SorterResult<T>) {
    if (!setActiveSorting) return

    const { order, columnKey } = sorter

    if (order) {
      const formattedOrder = order === 'ascend' ? 'ASC' : 'DESC'

      return setActiveSorting({
        field: columnKey as string,
        order: formattedOrder,
      })
    }

    setActiveSorting({
      field: undefined,
      order: undefined,
    })
  }

  const tableProps: TableProps<T> = {
    pagination: false,
    ...(setActiveSorting && { onChange: (_, __, sorter) => handleSort(sorter as SorterResult<T>) }),
    ...rest,
  }

  return (
    <S.Container>
      <Table {...tableProps} />
    </S.Container>
  )
}

import { DEFAULT_ROLE_PERMISSIONS } from '../constants'
import type { RolePermissionTableValue } from '../types'

export function mergeRolePermissions(
  serverPermissions?: RolePermissionTableValue[],
): RolePermissionTableValue[] {
  const mergedPermissions: RolePermissionTableValue[] = []

  DEFAULT_ROLE_PERMISSIONS.forEach((defaultPermission, index) => {
    const serverPermission = serverPermissions?.find(
      (permission) => permission.value === defaultPermission.value,
    )

    if (serverPermission) {
      // Add server permission if found
      mergedPermissions.push({ ...serverPermission, key: index })
    } else {
      // Add default permission with status: false if not found in server permissions
      mergedPermissions.push({ ...defaultPermission, status: false, key: index })
    }
  })

  // Add remaining custom server permissions
  serverPermissions?.forEach((serverPermission) => {
    if (!mergedPermissions.find((permission) => permission.value === serverPermission.value)) {
      mergedPermissions.push(serverPermission)
    }
  })

  return mergedPermissions
}

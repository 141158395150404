import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
export const FieldRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
`

export const SystemData = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

import { useEffect } from 'react'

import { Form, FormInstance } from 'antd'

import { SystemTabs } from '../..'
import { EditSystemPagesForm } from './PagesForm'
import { EditSystemDataForm } from './SystemForm'

import { System } from '@/features/systems'

interface EditSystemFormProps {
  form: FormInstance<any>
  onSubmit: (values: any) => void
  activeTab: SystemTabs
  data: System
}

export const EditSystemForm = ({ form, onSubmit, activeTab, data }: EditSystemFormProps) => {
  useEffect(() => {
    form.setFieldsValue({
      ...data,
      system_area: data.system_area.area_name,
    })
  }, [form, data])

  return (
    <Form form={form} layout="vertical" requiredMark onFinish={onSubmit}>
      {activeTab === 'system' ? (
        <EditSystemDataForm initialValues={data.system_modules} />
      ) : (
        <EditSystemPagesForm initialValues={data.system_links} />
      )}
    </Form>
  )
}

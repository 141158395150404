interface DefaultLink {
  page_name: string
  url: string
}

interface Link {
  page: string
  link: string
}

interface FormatSystemLinksProps {
  defaultLink: DefaultLink
  links?: Link[]
}

export function formatSystemLinks({ defaultLink, links }: FormatSystemLinksProps) {
  const defaultSystemPage = {
    ...defaultLink,
    default: true,
  }

  const systemPages = (links || [])
    ?.map((page) => {
      return {
        page_name: page.page,
        url: page.link,
        default: false,
      }
    })
    .filter((page) => page.page_name && page.url)

  return [defaultSystemPage, ...systemPages]
}

import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`

export const Title = styled.h2`
  color: #434343;
  font-weight: 700;
  font-size: 20px;
`

export const TotalItems = styled.span`
  color: #0c6dff;
  font-weight: 600;
  font-size: 20px;
`

export const ItemsText = styled.span`
  color: #434343;
  font-weight: 500;
  align-self: flex-end;
  justify-self: flex-end;
  font-size: 18px;
`

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

import { useEffect, useState } from 'react'

import { SearchOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { AutoComplete, Form } from 'antd'

import * as S from '../styles'

import { useDebounce } from '@/common'
import { LoadingSpinner } from '@/components'
import type { RoleFormValues } from '@/features/roles'
import { systemQueries, type SystemModule } from '@/features/systems'

type SelectedSystem = {
  label: string
  value: string
  system_modules: SystemModule[]
}

interface RoleFormThirdStepProps {
  formValues: RoleFormValues
  isEditing?: boolean
}

export const RoleFormThirdStep = ({ formValues, isEditing }: RoleFormThirdStepProps) => {
  const form = Form.useFormInstance()
  const [autocompleteSearch, setAutocompleteSearch] = useState('')
  const [selectedSystem, setSelectedSystem] = useState<SelectedSystem | undefined>(undefined)

  const debouncedSearch = useDebounce(autocompleteSearch, 500)

  const {
    data: systems,
    isFetching,
    isLoading,
  } = useQuery(
    systemQueries.list({
      system_name: {
        value: debouncedSearch.trim(),
        comparator: 'like',
      },
    }),
  )

  const autocompleteOptions = systems?.results.map((system) => ({
    value: system.pk,
    label: system.system_name,
    system_modules: system.system_modules,
  }))

  const systemModuleOptions = selectedSystem?.system_modules.map(({ id, module_name }) => ({
    value: id,
    label: module_name,
  }))

  useEffect(() => {
    if (selectedSystem) {
      form.setFieldValue('system_module_id', undefined)
    }
  }, [form, selectedSystem])

  return (
    <S.Container>
      <Form.Item name="system" label="Vincular sistema">
        <AutoComplete
          value={selectedSystem?.label}
          disabled={isLoading}
          options={autocompleteOptions}
          onSearch={(search) => setAutocompleteSearch(search)}
          onSelect={(_: string, selected: SelectedSystem) => {
            form.setFieldValue('system', selected.label)
            form.setFieldValue('system_modules', selected.system_modules)
            setSelectedSystem(selected)
          }}
          notFoundContent={isFetching ? 'Carregando...' : 'Nenhum sistema encontrado'}
        >
          <S.Input
            placeholder="Ex: Conecta"
            suffix={isFetching ? <LoadingSpinner /> : <SearchOutlined />}
          />
        </AutoComplete>
      </Form.Item>

      <Form.Item
        name="system_module_id"
        label="Vincular módulo interno do sistema"
        rules={[
          {
            required: true,
            message: 'Selecione um módulo',
          },
        ]}
      >
        <S.Select
          placeholder="Selecione um módulo"
          disabled={
            isLoading ||
            (isEditing && !selectedSystem) ||
            (!selectedSystem && !formValues.system_module_id)
          }
          options={systemModuleOptions}
          onSelect={(_, selected) => {
            form.setFieldValue('system_module_id', selected)
          }}
        />
      </Form.Item>
    </S.Container>
  )
}

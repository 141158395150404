import { jwtDecode } from 'jwt-decode'

import { useAuthStore } from '@/app'

export const useUsername = () => {
  const idToken = useAuthStore((state) => state.tokens.idToken)

  if (!idToken) return { username: '' }

  const { name } = jwtDecode<{ name: string }>(idToken)

  return { username: name }
}

import { DeleteOutlined, FileDoneOutlined, WarningOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useTheme } from 'styled-components'

import { useRoleFilterStore } from '@/app'
import { TableAuthenticatedFeatures, formatDate } from '@/common'
import { BaseTable, TableAction, TableColumnButton, TableDataTooltip } from '@/components'
import type { Group } from '@/features/groups'
import type { Role } from '@/features/roles'
import type { SelectRoleAction } from '@/pages'

interface RolesListTableProps extends TableAuthenticatedFeatures {
  data: Role[]
  isLoading: boolean
  handleSelectRole: ({ role, type }: SelectRoleAction) => void
}

export const RolesListTable = ({
  data,
  isLoading,
  handleSelectRole,
  canDelete,
}: RolesListTableProps) => {
  const theme = useTheme()
  const setActiveSorting = useRoleFilterStore((state) => state.setActiveSorting)

  function confirmDeletion(role: Role) {
    Modal.confirm({
      title: `Tem certeza que deseja excluir esta permissão?`,
      content: (
        <span>
          A permissão <strong>{role.title}</strong> será excluída permanentemente.
        </span>
      ),
      centered: true,
      icon: <WarningOutlined style={{ color: '#FF0000' }} />,
      okText: 'Excluir',
      cancelText: 'Cancelar',
      okButtonProps: {
        type: 'primary',
        style: {
          color: '#FFF',
          backgroundColor: theme.colors.brand,
        },
      },
      cancelButtonProps: {
        type: 'default',
      },
      onOk: () => handleSelectRole({ role, type: 'DELETE_ROLE' }),
    })
  }

  const columns: ColumnsType<Role> = [
    {
      title: 'nº',
      key: 'number',
      align: 'center',
      width: 50,
      render: (_, __, index) => {
        const number = String(index + 1).padStart(2, '0')

        return <span>{number}</span>
      },
    },
    {
      title: 'Permissão',
      dataIndex: 'title',
      key: 'title',
      width: 200,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (title: string, role) => (
        <TableColumnButton onClick={() => handleSelectRole({ role, type: 'VIEW_ROLE' })}>
          {title}
        </TableColumnButton>
      ),
    },
    {
      title: 'Valor',
      dataIndex: 'role_value',
      key: 'role_value',
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
    },

    {
      title: 'Sistema',
      dataIndex: ['system', 'system_name'],
      key: 'system',
    },
    {
      title: 'Grupos',
      dataIndex: 'groups',
      key: 'groups',
      width: 180,
      render: (groups: Group[]) => {
        const activeGroups = groups.filter((group) => group.active)

        return (
          <TableDataTooltip<Group>
            data={activeGroups}
            firstItem={activeGroups?.[0]?.group_name}
            listTitle="Grupos vinculados"
            nameKey="group_name"
          />
        )
      },
    },
    {
      title: 'Criado em',
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'center',
      width: 125,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (date) => formatDate({ date }),
    },
    {
      title: 'Atualizado em',
      dataIndex: 'updated_at',
      key: 'updated_at',
      align: 'center',
      width: 125,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (date) => formatDate({ date }),
    },
    {
      title: 'Ações',
      key: 'actions',
      width: 100,
      align: 'center',
      render: (_, role) => {
        const isActive = role.active

        const actions = [
          {
            key: '1',
            label: (
              <Button
                type="link"
                onClick={() => handleSelectRole({ role, type: 'VIEW_ROLE' })}
                icon={<FileDoneOutlined />}
              >
                Dados cadastrais
              </Button>
            ),
          },
          canDelete
            ? {
                key: '2',
                label: (
                  <Button
                    danger
                    type="link"
                    icon={<DeleteOutlined />}
                    onClick={() => confirmDeletion(role)}
                  >
                    Excluir
                  </Button>
                ),
              }
            : null,
        ]

        return <TableAction actions={actions} disabled={!isActive} />
      },
    },
  ]

  return (
    <BaseTable
      loading={isLoading}
      dataSource={data}
      columns={columns}
      rowKey={(record) => record.pk}
      setActiveSorting={setActiveSorting}
    />
  )
}

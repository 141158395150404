export const logTypes = new Map([
  ['GET', 'Consulta'],
  ['CREATE', 'Criação'],
  ['UPDATE', 'Atualização'],
  ['DELETE', 'Exclusão'],
])

export const userLogsFiltersRemap = new Map([
  // Log types
  ...logTypes,

  // Areas
  ['LOGS', 'Logs'],
  ['AUTENTICAÇÃO', 'Autenticação'],
  ['PERMISSÕES', 'Permissões'],
  ['EMPRESAS', 'Empresas'],
  ['ÁREAS', 'Áreas'],
  ['SISTEMAS', 'Sistemas'],
  ['GRUPOS', 'Grupos'],
  ['USUÁRIOS', 'Usuários'],
])

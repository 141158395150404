import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Form, Input, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'

import * as S from './styles'

import { areaQueries } from '@/features/area'
import { SystemModule } from '@/features/systems'

interface EditSystemDataFormProps {
  initialValues: SystemModule[]
}

export interface EditSystemDataFormValues {
  system_name: string
  description: string
  system_area: string
  system_modules: SystemModule[]
}

export const EditSystemDataForm = ({ initialValues }: EditSystemDataFormProps) => {
  const { data: areas, isLoading } = useQuery(
    areaQueries.list(undefined, undefined, {
      page: 1,
      per_page: 0,
    }),
  )

  const areaSelectOptions = areas?.results.map((area) => ({
    label: area.area_name,
    value: area.pk,
  }))

  return (
    <S.Container>
      <Form.Item
        name="system_name"
        label="Nome do sistema"
        rules={[
          {
            required: true,
            whitespace: true,
            message: 'Campo obrigatório',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="description"
        label="Descrição"
        rules={[
          {
            required: true,
            whitespace: true,
            message: 'Campo obrigatório',
          },
        ]}
      >
        <TextArea rows={3} />
      </Form.Item>

      <Form.Item
        name="system_area"
        label="Área associada"
        rules={[
          {
            required: true,
            whitespace: true,
            message: 'Campo obrigatório',
          },
        ]}
      >
        <Select
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          loading={isLoading}
          options={areaSelectOptions}
        />
      </Form.Item>

      <S.DynamicFieldContainer>
        <S.FieldsContainer>
          <Form.List name="system_modules" initialValue={initialValues}>
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map((field) => {
                    const isFirstItem = fields.indexOf(field) === 0

                    return (
                      <Form.Item key={field.key}>
                        <Form.Item
                          {...field}
                          initialValue={initialValues[field.key]?.module_name || ''}
                          name={[field.name, 'module_name']}
                          label="Modulo interno"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: 'Campo obrigatório',
                            },
                          ]}
                        >
                          <S.InputContainer>
                            <Input
                              defaultValue={initialValues[field.key]?.module_name || ''}
                              placeholder="Ex: Logística"
                            />

                            {isFirstItem && (
                              <S.AddFieldButton
                                type="primary"
                                onClick={() => add()}
                                icon={<PlusOutlined />}
                              />
                            )}

                            {!isFirstItem && fields.length >= 1 && (
                              <S.RemoveFieldButton
                                onClick={() => remove(field.name)}
                                icon={<MinusOutlined />}
                              />
                            )}
                          </S.InputContainer>
                        </Form.Item>
                      </Form.Item>
                    )
                  })}
                </>
              )
            }}
          </Form.List>
        </S.FieldsContainer>
      </S.DynamicFieldContainer>
    </S.Container>
  )
}

import { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Button, Divider, Form, Skeleton } from 'antd'
import { toast } from 'sonner'

import { EditSystemDataFormValues } from '../../Form/EditSystemForm/SystemForm'
import * as S from './styles'

import { type ModalAuthenticatedFeatures, useToggle } from '@/common'
import { BaseModal, TitleTabs } from '@/components'
import {
  EditSystemForm,
  SystemLink,
  useEditSystemMutation,
  systemQueries,
  SystemModule,
  System,
} from '@/features/systems'

interface SystemModalProps extends ModalAuthenticatedFeatures {
  systemPk: string
  isOpen: boolean
  onClose: () => void
}

export type SystemTabs = 'system' | 'pages'

export const SystemModal = ({ systemPk, isOpen, onClose, canEdit }: SystemModalProps) => {
  const [form] = Form.useForm()
  const editSystemMutation = useEditSystemMutation()

  const { data: system, isLoading } = useQuery({
    ...systemQueries.detail(systemPk),
    enabled: isOpen,
  })

  const [activeTab, setActiveTab] = useState<SystemTabs>('system')
  const [isEditing, toggleEdit] = useToggle(false)

  useEffect(() => {
    if (system) {
      form.setFieldsValue({
        ...system,
        system_area: system?.system_area.area_name,
      })
    }
  }, [form, system])

  function handleCancelEdit() {
    toggleEdit()
    form.resetFields()
  }

  async function handleEditSystem(
    values: EditSystemDataFormValues | Record<'system_pages', SystemLink[]>,
  ) {
    let promise

    if (activeTab === 'system') {
      const { system_area, ...formValues } = values as EditSystemDataFormValues

      // If the system area is the same as the current one, we don't need to send the area name
      const systemArea =
        system_area === system?.system_area.area_name ? system.system_area.pk : system_area

      const payload = {
        ...formValues,
        system_links: system?.system_links as SystemLink[],
        pk: systemPk,
        area_pk: systemArea,
      }

      promise = editSystemMutation.mutateAsync(payload)
    } else {
      const formValues = values as Record<'system_pages', SystemLink[]>

      const systemLinks = formValues.system_pages.map((link, index) => {
        const isFirstItem = index === 0

        if (isFirstItem) return link

        return {
          ...link,
          default: false,
        }
      })

      const payload = {
        system_name: system?.system_name as string,
        description: system?.description as string,
        system_modules: system?.system_modules as SystemModule[],
        pk: systemPk,
        system_links: systemLinks,
      }

      promise = editSystemMutation.mutateAsync(payload)
    }

    toast.promise(promise, {
      loading: 'Salvando...',
      success: 'Sistema editado com sucesso',
      error: 'Erro ao editar sistema',
    })

    await promise

    toggleEdit()
    form.resetFields()
  }

  const SystemView = () => {
    return (
      <>
        <S.SystemData>
          <span>Nome do sistema</span>
          <strong>{system?.system_name}</strong>
        </S.SystemData>

        <S.SystemData>
          <span>Descrição</span>
          <strong>{system?.description}</strong>
        </S.SystemData>

        <S.SystemData>
          <span>Área associada</span>
          <strong>{system?.system_area?.area_name}</strong>
        </S.SystemData>

        <S.SystemData>
          <span>Módulos</span>
          <S.ModulesContainer>
            {system?.system_modules?.map((module, index, arr) => {
              const hasMultipleModules = arr.length > 1

              const isLastItem = index === arr.length - 1

              return (
                <S.Module key={module.id}>
                  <strong>{module.module_name}</strong>
                  {hasMultipleModules && !isLastItem && (
                    <Divider type="vertical" style={{ margin: '0 16px' }} />
                  )}
                </S.Module>
              )
            })}
          </S.ModulesContainer>
        </S.SystemData>
      </>
    )
  }

  const PagesView = () => {
    const defaultPage = system?.system_links?.find((link) => link.default === true)

    const otherPages = system?.system_links?.filter((link) => link.default === false)

    return (
      <>
        <S.PagesContainer>
          <S.PageSubtitle>Página padrão sistema:</S.PageSubtitle>
          <S.FieldRow>
            <strong>{defaultPage?.page_name || '--'}</strong>
            <span>{defaultPage?.url || '--'}</span>
          </S.FieldRow>

          {otherPages && otherPages.length > 0 && (
            <>
              <S.PageSubtitle>Outras paginas:</S.PageSubtitle>

              {otherPages.map((page) => (
                <S.FieldRow key={page.id}>
                  <strong>{page.page_name}</strong>
                  <span>{page.url}</span>
                </S.FieldRow>
              ))}
            </>
          )}
        </S.PagesContainer>
      </>
    )
  }

  return (
    <BaseModal
      title="Dados cadastrais do sistema"
      open={isOpen}
      onCancel={onClose}
      width={718}
      footer={
        <>
          <Button type="link" htmlType="button" onClick={isEditing ? handleCancelEdit : onClose}>
            Cancelar
          </Button>

          {!isEditing ? (
            <>
              {canEdit && (
                <Button type="primary" htmlType="button" onClick={toggleEdit}>
                  Editar
                </Button>
              )}
            </>
          ) : (
            <Button type="primary" htmlType="submit" onClick={() => form.submit()}>
              Salvar
            </Button>
          )}
        </>
      }
    >
      <S.Container>
        {isLoading ? (
          <Skeleton active />
        ) : (
          <>
            <TitleTabs
              centered
              defaultActiveKey="system"
              activeKey={activeTab}
              items={[
                {
                  key: 'system',
                  label: 'Dados do sistema',
                  disabled: isEditing && activeTab !== 'system',
                },
                {
                  key: 'pages',
                  label: 'Páginas do sistema',
                  disabled: isEditing && activeTab !== 'pages',
                },
              ]}
              onChange={(key) => setActiveTab(key as SystemTabs)}
            />

            {!isEditing && <>{activeTab === 'system' ? <SystemView /> : <PagesView />}</>}

            {isEditing && (
              <EditSystemForm
                activeTab={activeTab}
                form={form}
                onSubmit={handleEditSystem}
                data={system as System}
              />
            )}
          </>
        )}
      </S.Container>
    </BaseModal>
  )
}

import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
export const FieldRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
`

export const FieldData = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const SystemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const UserSystems = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`

export const System = styled.div`
  min-width: 300px;
  max-width: 340px;
  width: 100%;
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 12px 8px;
  color: #6c6c6c;
  /* color: ${({ theme }) => theme.colors.brand}; */
  font-weight: 600;

  background-color: #fafafa;
  border-radius: 4px;
`

import { jwtDecode } from 'jwt-decode'

import { useAuthStore } from '@/app'

export const useUserId = () => {
  const idToken = useAuthStore((state) => state.tokens.idToken)

  const userId = jwtDecode<{ 'cognito:username': string }>(idToken as string)?.['cognito:username']

  return userId || ''
}

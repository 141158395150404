import { Form, Input, Select } from 'antd'

import * as S from './styles'

import { contractTypeOptions } from '@/features/parameters'

export const ParameterForm = () => {
  return (
    <S.Container>
      <S.FormArea>
        <S.AreaTitle>Dados do Parâmetro</S.AreaTitle>

        <S.FormRow>
          <Form.Item
            name="parameter_name"
            label="Nome do Parâmetro"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
                whitespace: true,
              },
              {
                max: 50,
                message: 'O nome do parâmetro deve ter no máximo 50 caracteres',
              },
            ]}
          >
            <Input placeholder="Ex: Inatividade CLT" />
          </Form.Item>

          <Form.Item
            required={false}
            initialValue={'inatividade'}
            name="parameter_type"
            label="Tipo do Parâmetro"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
                whitespace: true,
              },
            ]}
          >
            <Select
              disabled
              placeholder="Ex: Inatividade"
              options={[
                {
                  value: 'inatividade',
                  label: 'Inatividade',
                },
              ]}
            />
          </Form.Item>
        </S.FormRow>

        <S.FormRow>
          <Form.Item
            name="tipo_contrato"
            label="Tipo do Contrato"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
                whitespace: true,
              },
            ]}
          >
            <Select placeholder="Ex: CLT" options={contractTypeOptions} />
          </Form.Item>

          <Form.Item
            name="inactivity_days"
            label="Tempo de Inatividade(dias)"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
                whitespace: true,
              },
              {
                max: 3,
                message: 'Digite no maximo 3 digitos',
              },
              {
                min: 0,
                message: 'Digite um valor maior que 0',
                validator: (_, value) => {
                  const numberValue = Number(value)

                  if (numberValue < 0 || !numberValue) {
                    return Promise.reject(new Error('Digite um valor maior que 0'))
                  }
                  return Promise.resolve()
                },
              },
            ]}
          >
            <Input placeholder="Ex: 15" type="number" maxLength={3} min={0} />
          </Form.Item>
        </S.FormRow>
      </S.FormArea>
    </S.Container>
  )
}

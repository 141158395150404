import type { User, UserCsv } from '../types'

import { formatDate } from '@/common'

export const useCsvExport = () => {
  const csvHeaders = [
    { displayName: 'Nome', id: 'name' },
    { displayName: 'Email', id: 'email' },
    { displayName: 'Telefone', id: 'phone_number' },
    { displayName: 'Empresa', id: 'empresa' },
    { displayName: 'Tipo de Contrato', id: 'tipo_contrato' },
    { displayName: 'Último acesso', id: 'last_sign_in' },
    { displayName: 'Grupos', id: 'user_groups' },
  ]

  function formatCsv(dataCsv: User[]): UserCsv[] {
    return dataCsv.map((user) => {
      const formattedNumber = user.phone_number
        .toString()
        .replace(/(\+55)(\d{2})(\d{4,5})(\d{4})/, '$1 ($2) $3-$4')
      const lastSignIn = user.last_sign_in ? formatDate({ date: user?.last_sign_in }) : '-'

      return {
        name: user.name,
        email: user.email,
        phone_number: formattedNumber,
        empresa: user.empresa,
        tipo_contrato: user.tipo_contrato,
        last_sign_in: lastSignIn,
        user_groups: user.user_groups.map((group) => group.group_name).join(', '),
      }
    })
  }

  return { csvHeaders, formatCsv }
}

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { companyQueries } from '..'

import { api } from '@/services'

export interface ICreateCompanyMutation {
  company_cnpj: string
  company_fancy_name: string
  company_name: string
  manager_email: string
  manager_name: string
  manager_phone: string
}

export const useCreateCompanyMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: ICreateCompanyMutation) => {
      return api.post('/company', data)
    },
    onMutate: (data) => {
      queryClient.cancelQueries({ queryKey: companyQueries.all() })

      const cachedCompanies = queryClient.getQueryData(companyQueries.list().queryKey)

      if (cachedCompanies) {
        const updatedCompaniesList = [
          ...cachedCompanies.results,
          {
            ...data,
            pk: crypto.randomUUID(),
            updated_at: new Date().toISOString(),
            created_at: new Date().toISOString(),
            active: true,
          },
        ]

        queryClient.setQueryData(companyQueries.list().queryKey, {
          ...cachedCompanies,
          results: updatedCompaniesList,
        })
      }

      return cachedCompanies
    },
    onError: (error, _, cachedCompanies) => {
      console.error(error)

      if (cachedCompanies) {
        queryClient.setQueryData(companyQueries.list().queryKey, cachedCompanies)
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: companyQueries.lists() }),
  })
}

import type { RegisterRolePermission, SelectedRoleValue } from '../types'

export function formatRolePermissions(permissions: SelectedRoleValue[]) {
  return (
    (permissions
      .map((perm) => {
        if (!perm.status) return

        return {
          value: perm.value,
          title: perm.title,
          description: perm.description,
          custom: perm.custom,
        }
      })
      .filter(Boolean) as RegisterRolePermission[]) || []
  )
}

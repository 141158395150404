import { useState } from 'react'

import { Button, Form, Steps } from 'antd'
import { AxiosError } from 'axios'
import { toast } from 'sonner'

import {
  // UserForm,
  UserFormFirstStep,
  UserFormSecondStep,
} from '../..'
import * as S from './styles'

import { unmaskValue } from '@/common'
import { BaseModal } from '@/components'
import { useCreateUserMutation, getCreateUserErrorMessage, UserFormData } from '@/features/users'

interface CreateUserModalProps {
  isOpen: boolean
  onClose: () => void
}

export const CreateUserModal = ({ isOpen, onClose }: CreateUserModalProps) => {
  const [form] = Form.useForm()
  const createUserMutation = useCreateUserMutation()

  const [currentStep, setCurrentStep] = useState(0)
  const [formValues, setFormValues] = useState<UserFormData>({} as UserFormData)

  const steps = [
    {
      title: 'Dados do usuário',
      content: <UserFormFirstStep />,
    },
    {
      title: 'Vincular Grupos',
      content: <UserFormSecondStep />,
    },
  ]

  async function handleAdvanceStep() {
    try {
      const step1Values = await form.validateFields()

      setFormValues((old) => ({ ...old, ...step1Values }))

      setCurrentStep(1)
    } catch (error) {
      console.error(error)
    }
  }

  function handleReturnStep() {
    // const step2Values = form.getFieldsValue()
    // console.log(step2Values)
    // setFormValues((old) => ({ ...old, ...step2Values }))

    // TODO: Remove reset & keep data.
    // reset second step on step return.
    form.setFieldsValue({
      'user-groups': [],
      groups: undefined,
      system: undefined,
    })

    setCurrentStep(0)
  }

  async function handleCreateUser() {
    const { phone_number, ...values } = formValues

    const userGroups = form.getFieldValue('user-groups')

    const payload: UserFormData = {
      ...values,
      phone_number: unmaskValue(phone_number),
      group_pks: userGroups,
    }

    const promise = createUserMutation.mutateAsync(payload)

    toast.promise(promise, {
      loading: 'Criando usuário...',
      success: 'Usuário criado com sucesso',
      error: (error: AxiosError<{ message: string }>) => getCreateUserErrorMessage(error),
    })

    await promise

    onClose()
  }

  return (
    <BaseModal
      title="Novo usuário"
      open={isOpen}
      onCancel={onClose}
      closable={!createUserMutation.isPending}
      maskClosable={!createUserMutation.isPending}
      footer={
        <>
          <Button
            htmlType="button"
            disabled={createUserMutation.isPending}
            onClick={currentStep === 0 ? onClose : handleReturnStep}
          >
            {currentStep === 0 ? 'Cancelar' : 'Voltar'}
          </Button>

          {currentStep === 0 ? (
            <Button type="primary" htmlType="button" onClick={handleAdvanceStep}>
              Próximo
            </Button>
          ) : (
            <Button
              type="primary"
              htmlType="button"
              onClick={() => form.submit()}
              loading={createUserMutation.isPending}
            >
              Cadastrar
            </Button>
          )}
        </>
      }
    >
      <S.Container>
        <Steps current={currentStep} items={steps} />

        <Form
          form={form}
          requiredMark
          layout="vertical"
          name="create-user"
          onFinish={handleCreateUser}
          disabled={createUserMutation.isPending}
        >
          {steps[currentStep].content}
        </Form>
      </S.Container>
    </BaseModal>
  )
}

import { useEffect } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Button, Form } from 'antd'
import { toast } from 'sonner'

import * as S from './styles'

import { type ModalAuthenticatedFeatures, useToggle } from '@/common'
import { BaseModal } from '@/components'
import { Area, EditAreaForm, useEditAreaMutation, areaQueries } from '@/features/area'

interface AreaModalProps extends ModalAuthenticatedFeatures {
  data: Area
  isOpen: boolean
  onClose: () => void
}

export interface AreaFormData {
  area_name: string
  description: string
}

export const ViewAreaModal = ({ data, isOpen, onClose, canEdit }: AreaModalProps) => {
  const [form] = Form.useForm<AreaFormData>()
  const [isEditing, toggleEdit] = useToggle(false)

  const editAreaMutation = useEditAreaMutation()

  const { data: area } = useQuery(areaQueries.detail(data.pk))

  useEffect(() => {
    if (area || data) {
      form.setFieldsValue(area || data)
    }
  }, [area, data, form])

  async function handleEditArea({ area_name, description }: AreaFormData) {
    const promise = editAreaMutation.mutateAsync({
      area_name,
      description,
      pk: data.pk,
      active: data.active,
    })

    toast.promise(promise, {
      loading: 'Editando área...',
      success: 'Área editada com sucesso!',
      error: 'Erro ao editar área!',
    })

    await promise
    toggleEdit()
  }

  const AreaView = () => (
    <>
      <S.AreaData>
        <span>Nome</span>
        <strong>{area?.area_name || data.area_name}</strong>
      </S.AreaData>

      <S.AreaData>
        <span>Descrição</span>
        <strong>{area?.description || data.description}</strong>
      </S.AreaData>
    </>
  )

  return (
    <BaseModal
      open={isOpen}
      onCancel={onClose}
      title="Dados cadastrais da área"
      width={740}
      footer={
        <>
          <Button
            onClick={isEditing ? toggleEdit : onClose}
            type="link"
            htmlType="button"
            disabled={editAreaMutation.isPending}
          >
            Cancelar
          </Button>

          {!isEditing ? (
            <>
              {canEdit && (
                <Button type="primary" htmlType="button" onClick={toggleEdit}>
                  Editar
                </Button>
              )}
            </>
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => form.submit()}
              loading={editAreaMutation.isPending}
            >
              Salvar
            </Button>
          )}
        </>
      }
    >
      <S.Container>
        {!isEditing ? (
          <AreaView />
        ) : (
          <EditAreaForm
            form={form}
            handleSubmit={handleEditArea}
            isLoading={editAreaMutation.isPending}
          />
        )}
      </S.Container>
    </BaseModal>
  )
}

/**
 * Formats a phone number into ptBR format.
 *
 * The function removes any non-digit characters, extracts the country and area codes,
 * and the remaining number. It then checks the length of the remaining number to
 * determine whether it's a landline or mobile number and formats accordingly.
 *
 * @example
 *
 * const example1 = "+555124443109";
 * Output: +55 (51) 2444-3109
 *
 * const example2 = "+5511955807775";
 * Output: +55 (11) 95580-7775
 */

export function formatPhone(phoneNumber: string): string {
  // Remove any non-digit characters
  const digitsOnly = phoneNumber.replace(/\D/g, '')

  // Extract the country code, area code and remaining number
  const countryCode = digitsOnly.slice(0, 2)
  const areaCode = digitsOnly.slice(2, 4)
  const remainingNumber = digitsOnly.slice(4)

  let formattedNumber

  // If it's a cellphone, it will have 9 digits. Landlines will have 8.
  if (remainingNumber.length === 9) {
    // If it's a cellphone, slice the remaining number differently
    const firstPart = remainingNumber.slice(0, 5)
    const secondPart = remainingNumber.slice(5, 9)
    formattedNumber = `+${countryCode} (${areaCode}) ${firstPart}-${secondPart}`
  } else {
    // Else, it's a landline
    const firstPart = remainingNumber.slice(0, 4)
    const secondPart = remainingNumber.slice(4, 8)
    formattedNumber = `+${countryCode} (${areaCode}) ${firstPart}-${secondPart}`
  }

  // Return the formatted phone number
  return formattedNumber
}

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { companyQueries } from '..'

import { api } from '@/services'

export const useDeleteCompanyMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (companyPk: string) => {
      return api.delete(`/company/${companyPk}`)
    },
    onMutate: async (pk) => {
      await queryClient.cancelQueries({ queryKey: companyQueries.all() })

      const cachedCompanies = queryClient.getQueryData(companyQueries.list().queryKey)

      if (cachedCompanies) {
        const updatedCompanies = cachedCompanies.results.filter((company) => company.pk !== pk)

        queryClient.setQueryData(companyQueries.list().queryKey, {
          ...cachedCompanies,
          results: updatedCompanies,
        })
      }

      return cachedCompanies
    },
    onError: (error, _, cachedCompanies) => {
      console.error(error)

      if (cachedCompanies) {
        queryClient.setQueryData(companyQueries.list().queryKey, cachedCompanies)
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: companyQueries.all() }),
  })
}

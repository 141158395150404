import { useNavigate } from 'react-router-dom'

import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from 'sonner'

import { MFAType } from '../..'

import { useAuthStore } from '@/app'
import { api } from '@/services'

interface ISignInMutation {
  email: string
  password: string
  mfa_type: MFAType
}

interface ISignInResponse {
  ChallengeName: string
  ChallengeParameters: {
    USERNAME: string
    message: string
    type: string
  }
  Session: string
}

const errorMessages = new Map([
  ['Incorrect username or password.', 'Usuário ou senha incorretos.'],
  ['User does not exist.', 'Usuário ou senha incorretos.'],
  [
    'Unable to set software token as mfa_type. User must configure or verify software token.',
    'O usuário deve configurar ou verificar o token de segurança.',
  ],
])

export const useSignInMutation = () => {
  const navigate = useNavigate()
  const setAuthTokens = useAuthStore((state) => state.setTokens)

  return useMutation({
    mutationFn: async ({ email, password, mfa_type }: ISignInMutation) => {
      return api.post<ISignInResponse>('/no-auth/auth/login', {
        email,
        password,
        mfa_type,
      })
    },
    onSuccess: ({ data }, { email }) => {
      const isFirstLogin =
        data.ChallengeName === 'CUSTOM_CHALLENGE' &&
        data.ChallengeParameters.type === 'FORCE_CHANGE_PASSWORD'

      const isEmailMfaRequired =
        data.ChallengeName === 'CUSTOM_CHALLENGE' &&
        data.ChallengeParameters.type === 'VALIDATE_EMAIL_MFA_CODE'

      const isSmsMfaRequired = data.ChallengeName === 'SMS_MFA'

      const isAppMfaRequired = data.ChallengeName === 'SOFTWARE_TOKEN_MFA'

      const encodedEmail = encodeURIComponent(email)

      if (isFirstLogin) {
        setAuthTokens({ session: data.Session })

        toast.info('Por favor, altere sua senha provisória.')

        return navigate(`/auth/new-password?username=${encodedEmail}`)
      }

      if (isEmailMfaRequired) {
        setAuthTokens({ session: data.Session })

        toast.info('Por favor, valide seu código de segurança.')

        return navigate(`/auth/code?username=${encodedEmail}`)
      }

      if (isSmsMfaRequired) {
        setAuthTokens({ session: data.Session })

        toast.info('Por favor, valide seu código de segurança.')

        return navigate(`/auth/code?username=${encodedEmail}&type=sms`)
      }

      if (isAppMfaRequired) {
        setAuthTokens({ session: data.Session })

        toast.info('Por favor, valide seu código de segurança.')

        return navigate(`/auth/code?username=${encodedEmail}&type=app`)
      }
    },
    onError: (error: AxiosError<{ message: string }>) => {
      console.error(error)

      const errorMessage =
        errorMessages.get(error.response?.data.message as string) ||
        'Erro ao realizar login. Tente novamente mais tarde.'

      toast.error(errorMessage)
    },
  })
}

import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const StepDescription = styled.span`
  color: #00000073;
  font-size: 14px;
`

export const Subtitle = styled.h3`
  font-weight: 700;
  font-size: 16px;
  padding-top: 8px;
`

import { useEffect } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Button, Form } from 'antd'
import { toast } from 'sonner'

import { ParameterForm } from '../../Form'
import * as S from './styles'

import { type ModalAuthenticatedFeatures, useToggle } from '@/common'
import { BaseModal } from '@/components'
import { parameterQueries, useEditParameterMutation } from '@/features/parameters'
import { Parameter } from '@/features/parameters/types'

interface ParameterModalProps extends ModalAuthenticatedFeatures {
  data: Parameter
  isOpen: boolean
  onClose: () => void
}

export interface ParameterFormData {
  parameter_name: string
  parameter_type: string
  tipo_contrato: string
  inactivity_days: number
}
export const ViewParameterModal = ({ data, isOpen, onClose, canEdit }: ParameterModalProps) => {
  const [form] = Form.useForm<ParameterFormData>()
  const [isEditing, toggleEdit] = useToggle(false)

  const editParameterMutation = useEditParameterMutation()

  const { data: parameter } = useQuery(parameterQueries.detail(data.pk))

  useEffect(() => {
    if (parameter || data) {
      form.setFieldsValue(parameter || data)
      form.setFieldValue(
        'inactivity_days',
        parameter?.inactivity_days.toString() || data.inactivity_days.toString(),
      )
    }
  }, [parameter, data, form])

  async function handleEditParameter({
    parameter_name,
    parameter_type,
    tipo_contrato,
    inactivity_days,
  }: ParameterFormData) {
    const promise = editParameterMutation.mutateAsync({
      parameter_name,
      parameter_type,
      tipo_contrato,
      inactivity_days,
      pk: data.pk,
    })

    toast.promise(promise, {
      loading: 'Editando parâmetro...',
      success: 'Parâmetro editado com sucesso!',
      error: 'Erro ao editar parâmetro!',
    })

    await promise
    toggleEdit()
  }

  const ParameterView = () => (
    <S.Container>
      <S.RowData>
        <S.ParameterData>
          <span>Nome do Parâmetro: </span>
          <strong>{parameter?.parameter_name || data.parameter_name}</strong>
        </S.ParameterData>

        <S.ParameterData>
          <span>Tipo do Parâmetro: </span>
          <strong>{parameter?.parameter_type || data.parameter_type}</strong>
        </S.ParameterData>
      </S.RowData>

      <S.RowData>
        <S.ParameterData>
          <span>Tipo do Contrato: </span>
          <strong>{parameter?.tipo_contrato || data.tipo_contrato}</strong>
        </S.ParameterData>

        <S.ParameterData>
          <span>Tempo de Inatividade(dias): </span>
          <strong>{parameter?.inactivity_days || data.inactivity_days}</strong>
        </S.ParameterData>
      </S.RowData>
    </S.Container>
  )

  return (
    <BaseModal
      open={isOpen}
      onCancel={onClose}
      title="Dados do Parâmetro"
      width={740}
      footer={
        <>
          <Button
            type="link"
            htmlType="button"
            onClick={isEditing ? toggleEdit : onClose}
            disabled={editParameterMutation.isPending}
          >
            Cancelar
          </Button>

          {!isEditing ? (
            <>
              {canEdit && (
                <Button type="primary" htmlType="button" onClick={toggleEdit}>
                  Editar
                </Button>
              )}
            </>
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => form.submit()}
              loading={editParameterMutation.isPending}
            >
              Salvar
            </Button>
          )}
        </>
      }
    >
      {!isEditing ? (
        <ParameterView />
      ) : (
        <Form
          form={form}
          layout="vertical"
          requiredMark
          onFinish={handleEditParameter}
          disabled={editParameterMutation.isPending}
        >
          <ParameterForm />
          {/* <EditParameterForm
            form={form}
            handleSubmit={handleEditParameter}
            isLoading={editParameterMutation.isPending}
          /> */}
        </Form>
      )}
    </BaseModal>
  )
}

import { useEffect } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Button, Form, Skeleton, Tag } from 'antd'
import { toast } from 'sonner'

import * as S from './styles'

import { ModalAuthenticatedFeatures, useToggle } from '@/common'
import { BaseModal } from '@/components'
import { UserForm, UserFormData, useEditUserMutation, userQueries } from '@/features/users'

interface UserModalProps extends ModalAuthenticatedFeatures {
  userId: string
  isOpen: boolean
  onClose: () => void
}

export const ViewUserModal = ({ userId, isOpen, onClose, canEdit }: UserModalProps) => {
  const [form] = Form.useForm<UserFormData>()
  const [isEditing, toggleEdit] = useToggle(false)

  const { data: user, isLoading } = useQuery({
    ...userQueries.detail(userId),
    enabled: isOpen,
  })

  const editUserMutation = useEditUserMutation()

  useEffect(() => {
    if (user) {
      const userActiveStatus =
        user.user_situation === 'Ativo'
          ? 'Ativo'
          : user.user_situation === 'Inativo'
          ? 'Inativo'
          : 'Excluído'

      form.setFieldsValue(user)
      form.setFieldValue('active', userActiveStatus)
    }
  }, [form, isEditing, user])

  function handleCancelEdit() {
    toggleEdit()
    form.resetFields()
  }

  async function handleEditUser(values: UserFormData) {
    const promise = editUserMutation.mutateAsync({ ...values, userId })

    toast.promise(promise, {
      loading: 'Atualizando usuário...',
      success: 'Usuário atualizado com sucesso!',
      error: 'Erro ao atualizar usuário!',
    })

    await promise

    handleCancelEdit()
  }

  const UserDataView = () => (
    <>
      <S.FieldRow>
        <S.FieldData>
          <span>Nome</span>
          <strong>{user?.name}</strong>
        </S.FieldData>

        <S.FieldData>
          <span>E-mail</span>
          <strong>{user?.email}</strong>
        </S.FieldData>
      </S.FieldRow>

      <S.FieldRow>
        <S.FieldData>
          <span>Telefone</span>
          <strong>{user?.phone_number}</strong>
        </S.FieldData>

        <S.FieldData>
          <span>CPF</span>
          <strong>{user?.cpf}</strong>
        </S.FieldData>
      </S.FieldRow>

      <S.FieldRow>
        <S.FieldData>
          <span>Apelido</span>
          <strong>{user?.nickname}</strong>
        </S.FieldData>

        <S.FieldData>
          <span>Empresa</span>
          <strong>{user?.empresa}</strong>
        </S.FieldData>
      </S.FieldRow>

      <S.FieldRow>
        <S.FieldData>
          <span>Tipo de contrato</span>
          <strong>{user?.tipo_contrato}</strong>
        </S.FieldData>

        <S.FieldData>
          <span>Status</span>
          <strong>
            {user?.user_situation === 'Ativo' ? (
              <Tag color="green">Ativo</Tag>
            ) : user?.user_situation === 'Inativo' ? (
              <Tag>Inativo</Tag>
            ) : user?.user_situation === 'Excluído' ? (
              <Tag color="red">Excluído</Tag>
            ) : (
              <Tag>Indefinido</Tag>
            )}
          </strong>
        </S.FieldData>
      </S.FieldRow>
    </>
  )

  return (
    <BaseModal
      open={isOpen}
      onCancel={onClose}
      title="Dados cadastrais do usuário"
      width={740}
      footer={
        <>
          <Button
            onClick={isEditing ? handleCancelEdit : onClose}
            type="link"
            htmlType="button"
            disabled={editUserMutation.isPending}
          >
            Cancelar
          </Button>

          {user?.active && (
            <>
              {!isEditing ? (
                <>
                  {canEdit && (
                    <Button type="primary" htmlType="button" onClick={toggleEdit}>
                      Editar
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => form.submit()}
                  loading={editUserMutation.isPending}
                >
                  Salvar
                </Button>
              )}
            </>
          )}
        </>
      }
    >
      <S.Container>
        {isLoading ? (
          <Skeleton active />
        ) : (
          <>
            {!isEditing ? (
              <UserDataView />
            ) : (
              <Form
                form={form}
                onFinish={handleEditUser}
                layout="vertical"
                requiredMark
                disabled={editUserMutation.isPending}
              >
                <UserForm isEditing />
              </Form>
            )}
          </>
        )}
      </S.Container>
    </BaseModal>
  )
}

import { Input as AntdInput } from 'antd'
import styled, { css } from 'styled-components'

export const Container = styled.div<{ $hasError?: boolean }>`
  form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .ant-form-item {
    margin: 0;
  }

  .input-container {
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 24px;
  }

  .mfa-input {
    width: 40px;
    height: 40px;

    padding: 0;
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;

    color: #494949;
    border: 0;
    border-bottom: 1px solid #c7c7c7;
    border-radius: 0;

    background: none;
    background-clip: padding-box;
    outline-color: ${({ theme }) => theme.colors.brand};

    :focus {
      border-color: red;
      outline-color: red;
    }
  }

  ${({ $hasError }) =>
    $hasError &&
    css`
      .mfa-input {
        border-color: red;
        outline-color: red;
      }
    `}
`

export const Input = styled(AntdInput)`
  height: 40px;
  margin: 0;

  .ant-input-prefix {
    color: ${({ theme }) => theme.colors.brand};
  }
`
export const InputPassword = styled(AntdInput.Password)`
  height: 40px;
  margin: 0;

  .ant-input-prefix {
    color: ${({ theme }) => theme.colors.brand};
  }
`

import { Button } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const FormRow = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 24px;

  .ant-form-item {
    flex: 1;
    max-width: 300px;
  }

  .ant-input {
    height: 40px;
  }
`

export const DynamicFieldContainer = styled.div`
  height: 100%;
  max-height: 300px;
  overflow-y: auto;

  margin: 0 -24px;
`

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 24px;
`

export const Field = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 24px;
  flex: 1;

  .ant-form-item {
    flex: 1;
    margin: 0;
  }
`

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`

export const AddFieldButton = styled(Button)`
  &.ant-btn.ant-btn-icon-only {
    height: 40px !important;
    width: 40px !important;

    border-radius: 9999999px;
    font-size: 14px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const RemoveFieldButton = styled(AddFieldButton)``

export const PlaceholderButton = styled.button`
  color: transparent;
  background: transparent;
  border: none;
  width: 40px;
  pointer-events: none;
`

import { Button } from 'antd'

import type { SystemFilters } from '../../types'
import { SystemsFilterForm } from '../Form'

import { useSystemFilters } from '@/app'
import { useFilterActions, useFormatFilters } from '@/common'
import type { BaseFilterSidebarProps } from '@/common'
import { FilterSidebar } from '@/components'

export const SystemFilterSidebar = ({ isOpen, toggleDrawer, form }: BaseFilterSidebarProps) => {
  const setSystemFilters = useSystemFilters((s) => s.setFilters)
  const resetFilters = useSystemFilters((s) => s.resetFilters)
  const resetSystemPagination = useSystemFilters((s) => s.resetPagination)
  const { submitFilters, handleClearFilters } = useFilterActions(form)
  const { formatDateFieldFilter, formatRelationalFields } = useFormatFilters()

  function handleFilter() {
    const values = form.getFieldsValue()

    const filters: SystemFilters = {}

    const relationalFields = {
      system_area: 'area_name',
    }

    formatDateFieldFilter(values, filters)
    formatRelationalFields(values, filters, relationalFields)

    setSystemFilters({ ...filters })
    resetSystemPagination()
  }

  return (
    <FilterSidebar
      isDrawerOpen={isOpen}
      toggleDrawer={toggleDrawer}
      filterFooter={
        <>
          <Button onClick={() => handleClearFilters(resetFilters, toggleDrawer)}>Limpar</Button>
          <Button type="primary" htmlType="submit" onClick={() => submitFilters(toggleDrawer)}>
            Filtrar
          </Button>
        </>
      }
    >
      <SystemsFilterForm form={form} onSubmit={handleFilter} />
    </FilterSidebar>
  )
}

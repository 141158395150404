import { useEffect } from 'react'

import { CollapseProps, DatePicker, Form, Select, Slider } from 'antd'

import { useParameterFilters } from '@/app'
import { type FilterValue, useInitialFilterFormValues, type BaseFilterFormProps } from '@/common'
import { FilterCollapse } from '@/components'

export const ParameterFilterForm = ({ form, onSubmit }: BaseFilterFormProps) => {
  const parameterFilters = useParameterFilters((s) => s.filters)

  const initialFilterValues = useInitialFilterFormValues(
    parameterFilters as unknown as Record<string, FilterValue>,
  )

  useEffect(() => {
    form.setFieldsValue(initialFilterValues)
  }, [form, initialFilterValues])

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Data de criação',
      children: (
        <Form.Item name="created_at">
          <DatePicker.RangePicker allowEmpty format={'DD/MM/YYYY'} />
        </Form.Item>
      ),
    },
    {
      key: '2',
      label: 'Data de atualizacão',
      children: (
        <Form.Item name="updated_at">
          <DatePicker.RangePicker allowEmpty format={'DD/MM/YYYY'} />
        </Form.Item>
      ),
    },
    {
      key: '3',
      label: 'Tipo de contrato',
      children: (
        <Form.Item name="tipo_contrato">
          <Select
            placeholder="Selecione um tipo de contrato"
            options={[
              {
                label: 'CLT',
                value: 'CLT',
              },
              {
                label: 'PJ',
                value: 'PJ',
              },
              {
                label: 'Outros',
                value: 'Outros',
              },
              {
                label: 'Terceiros',
                value: 'Terceiros',
              },
            ]}
          />
        </Form.Item>
      ),
    },
    {
      key: '4',
      label: 'Dias de inatividade',
      children: (
        <Form.Item name="inactivity_days">
          <Slider range={{ draggableTrack: true }} max={999} />
        </Form.Item>
      ),
    },
  ]

  return <FilterCollapse form={form} onSubmit={onSubmit} items={items} />
}

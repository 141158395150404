import axios, { AxiosError } from 'axios'
import { jwtDecode } from 'jwt-decode'

import authStore from '@/app/stores/auth'
import rolesStore from '@/app/stores/roles'

export const api = axios.create({
  baseURL: `${import.meta.env.VITE_API_URL}`,
})

// set auth header with fresh token
api.defaults.headers.common.Authorization = `Bearer ${authStore.getState().tokens.idToken}`

let isRefreshing = false
let failedRequestsQueue: any[] = []

api.interceptors.response.use(
  (response) => response,
  (error: AxiosError<{ message: string }>) => {
    // Unauthorized requests error
    // if (error.response?.status === 401 && error.response?.data.message === 'Unauthorized') {
    //   // redirect to home page
    //   window.location.href = '/'
    // }

    // token invalid error
    if (
      error.response?.status === 401 &&
      error.response?.data.message === 'The incoming token has expired'
    ) {
      const originalErrorConfig = error.config

      const refreshToken = authStore.getState().tokens.refreshToken

      if (!isRefreshing) {
        try {
          isRefreshing = true

          const idToken = authStore.getState().tokens.idToken
          const decodedIdToken = jwtDecode<{ 'cognito:username': string }>(idToken as string)
          const userId = decodedIdToken['cognito:username']

          api
            .post('/no-auth/auth/refresh-token', {
              refresh_token: refreshToken as string,
              user_id: userId,
            })
            .then((res) => {
              const { AccessToken, IdToken, RefreshToken } = res.data

              api.defaults.headers.common.Authorization = `Bearer ${IdToken}`

              authStore.setState(
                {
                  tokens: {
                    accessToken: AccessToken,
                    idToken: IdToken,
                    refreshToken: RefreshToken,
                  },
                },
                false,
                'refreshTokens',
              )

              // update user roles from new token
              rolesStore.getState().getDecodedRoles(IdToken)

              failedRequestsQueue.forEach((req) => req.onSuccess(IdToken))
              failedRequestsQueue = []
            })
            .catch((err) => {
              failedRequestsQueue.forEach((req) => req.onFailure(err))
              failedRequestsQueue = []

              authStore.getState().clearStore()
            })
            .finally(() => {
              isRefreshing = false
            })
        } catch (err) {
          console.error('Failed to refresh token', err)
          authStore.getState().clearStore()
        }
      }

      return new Promise((resolve, reject) => {
        failedRequestsQueue.push({
          onSuccess: (token: string) => {
            originalErrorConfig!.headers.Authorization = `Bearer ${token}`

            resolve(api(originalErrorConfig!))
          },
          onFailure: (error: AxiosError) => {
            reject(error)
          },
        })
      })
    }

    return Promise.reject(error)
  },
)

import { useEffect, useMemo, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Button, Form, Skeleton, Table } from 'antd'
import { toast } from 'sonner'

import * as S from './styles'

import { ModalAuthenticatedFeatures, useToggle } from '@/common'
import { BaseModal, TitleTabs } from '@/components'
import {
  RoleFormFirstStep,
  RoleFormSecondStep,
  RoleFormThirdStep,
  RoleHelpContentFirstStep,
  RoleHelpContentSecondStep,
  RoleHelpContentThirdStep,
  type RolePermissionTableValue,
  roleQueries,
  type Role,
  mergeRolePermissions,
  formatRolePermissions,
  useEditRoleMutation,
  type RoleFormValues,
} from '@/features/roles'

interface ViewRoleModal extends ModalAuthenticatedFeatures {
  data: Role
  isOpen: boolean
  onClose: () => void
}

export const ViewRoleModal = ({ data, isOpen, onClose, canEdit }: ViewRoleModal) => {
  const [form] = Form.useForm()
  const [isEditing, toggleEdit] = useToggle(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [isAddingRole, setIsAddingRole] = useState(false)

  const { data: role, isFetched, isLoading } = useQuery(roleQueries.detail(data.pk))
  const editRoleMutation = useEditRoleMutation()

  const roleData = useMemo(() => {
    const serverRolePermissions = role?.role_permissions?.map((perm) => ({
      key: perm.value,
      value: perm.value,
      title: perm.title,
      description: perm.description,
      status: true,
      custom: perm.custom,
    })) as RolePermissionTableValue[]

    return {
      title: role?.title,
      description: role?.description,
      role_value: role?.role_value,
      role_permissions: mergeRolePermissions(serverRolePermissions),
      serverRolePermissions,
      system: role?.system.system_name,
      system_module_id: { value: role?.system_module_id, label: role?.system_module.module_name },
    }
  }, [role])

  useEffect(() => {
    if (isFetched) {
      form.setFieldsValue(roleData)
    }
  }, [form, isFetched, roleData])

  async function handleCancelEdit(formData?: RoleFormValues) {
    toggleEdit()
    form.resetFields()

    console.log({ formData, roleData })
    form.setFieldsValue(formData || roleData)
  }

  async function handleEditRole(formData: any) {
    try {
      await form.validateFields()

      const rolePermissions = form.getFieldValue('role_permissions')

      const payload = {
        title: formData?.title || roleData?.title,
        description: formData?.description || roleData?.description,
        role_value: formData?.role_value || roleData?.role_value,
        role_permissions: rolePermissions
          ? formatRolePermissions(rolePermissions)
          : formatRolePermissions(roleData?.role_permissions),
        system_module_id: formData?.system_module_id?.value || roleData?.system_module_id?.value,
      }

      const promise = editRoleMutation.mutateAsync({ ...payload, rolePk: data?.pk })

      toast.promise(promise, {
        loading: 'Editando permissão...',
        success: 'Permissão editada com sucesso!',
        error: 'Erro ao editar permissão!',
      })

      await promise

      const resetData: RoleFormValues & {
        system: string
      } = {
        ...payload,
        system: formData?.system || role?.system.system_name,
        system_module_id: formData?.system_module_id || {
          value: payload.system_module_id,
          label: role?.system_module.module_name as string,
        },
        role_permissions: mergeRolePermissions(
          rolePermissions
            .map((perm: any) => {
              if (!perm.status) return null

              return { key: perm.value, ...perm }
            })
            .filter(Boolean),
        ),
      }

      handleCancelEdit(resetData)
    } catch (error) {
      console.error('Error editing role', error)
    }
  }

  const editSteps = [
    {
      title: 'Dados da permissão',
      info: {
        title: 'Dados da permissão',
        description: 'Defina nome e descrição da permisão',
      },
      content: <RoleFormFirstStep />,
      help: <RoleHelpContentFirstStep />,
    },
    {
      title: 'Propriedades da permissão',
      info: {
        title: 'Propriedades da permissão',
        description: 'Crie, ative ou inative as propriedades da permissão',
      },
      content: <RoleFormSecondStep setIsAddingRole={setIsAddingRole} />,
      help: <RoleHelpContentSecondStep />,
    },
    {
      title: 'Vincular sistema',
      info: {
        title: 'Vincular sistemas a permissão',
        description: 'Vincule a permissão ao sistema',
      },
      content: <RoleFormThirdStep formValues={roleData as any} isEditing />,
      help: <RoleHelpContentThirdStep />,
    },
  ]

  const RoleDataView = () => {
    const activeTab = String(currentStep)

    if (activeTab === '0') {
      return (
        <S.Container>
          <S.RoleData>
            <span>Permissão</span>
            <strong>{role?.title}</strong>
          </S.RoleData>

          <S.RoleData>
            <span>Valor</span>
            <strong>{role?.role_value}</strong>
          </S.RoleData>

          <S.RoleData>
            <span>Descrição</span>
            <strong>{role?.description}</strong>
          </S.RoleData>
        </S.Container>
      )
    }

    if (activeTab === '1') {
      return (
        <Table
          size="small"
          columns={[
            {
              title: 'Titulo',
              dataIndex: 'title',
            },
            {
              title: 'Descrição',
              dataIndex: 'description',
            },
            {
              title: 'Valor',
              dataIndex: 'value',
              align: 'center',
              render: (value: string, record) => {
                if (!record.custom) return record.title.toLowerCase()

                return <span>{value}</span>
              },
            },
          ]}
          pagination={false}
          dataSource={roleData?.serverRolePermissions}
          rowKey={(record) => record.value}
        />
      )
    }

    return (
      <S.Container>
        <S.RoleData>
          <span>Sistema</span>
          <strong>{role?.system.system_name}</strong>
        </S.RoleData>

        <S.RoleData>
          <span>Módulo</span>
          <strong>{role?.system_module.module_name}</strong>
        </S.RoleData>
      </S.Container>
    )
  }

  const ModalFooter = () => {
    if (!isEditing) {
      return (
        <>
          <Button onClick={onClose} type="link" htmlType="button">
            Cancelar
          </Button>

          {canEdit && (
            <Button type="primary" htmlType="button" onClick={toggleEdit}>
              Editar
            </Button>
          )}
        </>
      )
    }

    return (
      <>
        <Button
          onClick={() => handleCancelEdit()}
          type="link"
          disabled={editRoleMutation.isPending || isAddingRole}
        >
          Cancelar
        </Button>

        <Button
          type="primary"
          onClick={() => form.submit()}
          disabled={isAddingRole}
          loading={editRoleMutation.isPending}
        >
          Finalizar
        </Button>
      </>
    )
  }

  return (
    <BaseModal
      open={isOpen}
      onCancel={onClose}
      title="Dados cadastrais da permissão"
      width={String(currentStep) === '1' ? 1000 : 740}
      footer={<ModalFooter />}
    >
      <S.Container>
        {isLoading ? (
          <Skeleton active />
        ) : (
          <>
            {!isEditing ? (
              <>
                <TitleTabs
                  centered
                  defaultActiveKey="0"
                  activeKey={String(currentStep)}
                  onChange={(key) => setCurrentStep(String(key) as any)}
                  items={[
                    {
                      key: '0',
                      label: 'Dados da permissão',
                    },
                    {
                      key: '1',
                      label: 'Permissões vinculadas',
                    },
                    {
                      key: '2',
                      label: 'Sistema vinculado',
                    },
                  ]}
                />
                <RoleDataView />
              </>
            ) : (
              <Form
                form={form}
                onFinish={handleEditRole}
                layout="vertical"
                requiredMark
                // disabled={editGroupMutation.isPending}
              >
                {editSteps[currentStep].content || editSteps[currentStep].title}
              </Form>
            )}
          </>
        )}
      </S.Container>
    </BaseModal>
  )
}

import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import styled, { css } from 'styled-components'

export const Container = styled.div<{ $hasError: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .ant-table-container,
  .ant-table-title {
    border: 1px solid #e2e4e7;
    border-radius: 4px;
  }

  .ant-table-title {
    border-bottom: 0;
  }

  .ant-table {
    max-height: 500px;
    overflow-y: auto;
  }

  .ant-table-selection-col {
    width: 50px !important;
  }

  ${({ $hasError }) =>
    $hasError &&
    css`
      .ant-table-container,
      .ant-table-title {
        border: 1px solid #ff4d4f;
        border-radius: 4px;
      }

      .ant-table-title {
        border-bottom: 0;
      }
    `}
`

export const Title = styled.h2`
  font-size: 16px;
  font-weight: 700;
  color: #434343;
`

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  color: #434343;

  strong {
    font-weight: 600;
  }
`

export const SearchInput = styled(Input)`
  border-radius: 4px;
  max-width: 600px;
  height: 40px;

  display: flex;
  align-items: center;

  .ant-input,
  .ant-input-search-button {
    height: 40px;
  }
`

export const SearchIcon = styled(SearchOutlined)`
  color: #d9d9d9;
`

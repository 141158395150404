import { useMutation, useQueryClient } from '@tanstack/react-query'

import { notificationQueries } from '../queries'

import { api } from '@/services'

export const useClearAllNotificationsMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (notificationPks: string[]) => {
      return api.post(`/notification/set_many_as_viewed`, {
        notification_pks: notificationPks,
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: notificationQueries.all() }),
  })
}

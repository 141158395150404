import { Form } from 'antd'

import * as S from '../styles'

export const GroupFormFirstStep = () => {
  return (
    <S.Container>
      <Form.Item
        name="group_name"
        label="Nome do grupo"
        rules={[
          {
            required: true,
            message: 'Campo obrigatório',
          },
        ]}
      >
        <S.Input placeholder="Ex: Pós-venda" />
      </Form.Item>

      <Form.Item
        name="description"
        label="Descrição"
        rules={[
          {
            required: true,
            message: 'Campo obrigatório',
          },
          {
            max: 300,
            message: 'Máximo de 300 caracteres',
          },
        ]}
      >
        <S.TextArea rows={4} placeholder="Descrição do grupo" />
      </Form.Item>
    </S.Container>
  )
}

import { useEffect, useReducer, useRef } from 'react'
import CsvDownload from 'react-csv-downloader'
import { useLocation } from 'react-router-dom'

import { DownOutlined, DownloadOutlined, PlusOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Button, Dropdown, Form, MenuProps } from 'antd'
import { toast } from 'sonner'
import { shallow } from 'zustand/shallow'

import * as S from './styles'

import { useUserFilters } from '@/app'
import {
  type TitleTabItem,
  getActiveFiltersList,
  pagePermissionsKeys,
  removeActiveFilter,
  useAuth,
  useFilterActions,
  useTabNavigation,
  useToggle,
} from '@/common'
import {
  ActiveFiltersList,
  CustomPagination,
  PageHeader,
  SearchInput,
  TitleTabs,
} from '@/components'
import {
  CreateUserModal,
  type UserFilters,
  UsersListTable,
  ViewUserModal,
  useDeleteUserMutation,
  userQueries,
  UserFilterSidebar,
  fetchAllUsers,
  UserCsv,
  useUpdateUserStatusMutation,
  type User,
  useCsvExport,
  userFilterValuesRemap,
} from '@/features/users'
import { PageLayout } from '@/layouts/PageLayout'

export type SelectUserAction = {
  userId: string
  type: 'DELETE_USER' | 'VIEW_USER'
}

export const UsersPage = () => {
  const { search } = useLocation()
  const navigateToTab = useTabNavigation()
  const [form] = Form.useForm<UserFilters>()
  const { handleClearFilters, handleSearchFilter } = useFilterActions(form)
  const { csvHeaders, formatCsv } = useCsvExport()
  const hasRanEffect = useRef(false)
  const { getPagePermissions, formatPagePermissions, formatTitleTabItems } = useAuth()

  const pagePerms = getPagePermissions({
    moduleKey: pagePermissionsKeys.users.module,
    roleKey: pagePermissionsKeys.users.role,
  })

  const { isAdmin, canCreate, canDelete, canEdit } = formatPagePermissions(pagePerms)

  const {
    userFilters,
    userSorting,
    userPagination,
    setUserFilters,
    resetUserFilters,
    resetPagination,
    setUserPagination,
  } = useUserFilters(
    (state) => ({
      userFilters: state.filters,
      userSorting: state.activeSorting,
      userPagination: state.pagination,
      setUserFilters: state.setFilters,
      resetUserFilters: state.resetFilters,
      resetPagination: state.resetPagination,
      setUserPagination: state.setPagination,
    }),
    shallow,
  )

  const {
    data: users,
    isLoading,
    isFetching,
  } = useQuery(userQueries.list(userFilters, userSorting, userPagination))

  const deleteUserMutation = useDeleteUserMutation()
  const updateUserStatusMutation = useUpdateUserStatusMutation()

  const [isFilterDrawerOpen, toggleFilterDrawer] = useToggle(false)
  const [isCreateUserModalOpen, toggleCreateUserModal] = useToggle(false)
  const [isViewUserModalOpen, toggleViewUserModal] = useToggle(false)

  const [selectedUser, selectUserDispatch] = useReducer(
    (_: string | null, action: SelectUserAction) => {
      switch (action.type) {
        case 'DELETE_USER':
          handleDeleteUser(action.userId)
          return null
        case 'VIEW_USER':
          toggleViewUserModal()
          return action.userId
        default:
          return null
      }
    },
    null,
  )

  const titleTabItems: TitleTabItem[] = [
    {
      key: 'users',
      label: 'Usuários',
      disabled: true,
      allowedRoles: ['usuarios'],
    },
    {
      key: 'companies',
      label: 'Empresas',
      disabled: false,
      allowedRoles: ['empresas'],
    },
  ]

  useEffect(() => {
    if (!!search && !hasRanEffect.current && !isViewUserModalOpen) {
      hasRanEffect.current = true

      const params = new URLSearchParams(search)
      const userId = params.get('user')

      if (userId) {
        selectUserDispatch({ userId, type: 'VIEW_USER' })
      }
    }

    return () => {
      hasRanEffect.current = false
    }
  }, [search, selectedUser])

  async function handleUpdateUserStatus(userId: string, newStatus: boolean) {
    if (updateUserStatusMutation.isPending) return

    const promise = updateUserStatusMutation.mutateAsync({ userId, enabled: newStatus })

    toast.promise(promise, {
      loading: 'Atualizando status do usuário...',
      success: 'Status do usuário atualizado com sucesso!',
      error: 'Erro ao atualizar status do usuário!',
    })
  }

  async function handleDeleteUser(userId: string) {
    if (deleteUserMutation.isPending) return

    const promise = deleteUserMutation.mutateAsync(userId)

    toast.promise(promise, {
      loading: 'Excluindo usuário...',
      success: 'Usuário excluído com sucesso!',
      error: 'Erro ao excluir usuário!',
    })
  }

  function handleRemoveFilter(filterKey: keyof UserFilters, filterValue?: string) {
    const updatedFilters = removeActiveFilter({
      activeFilters: userFilters,
      filterKey,
      filterValue,
      form,
    })

    setUserFilters({ ...updatedFilters })
  }

  async function handleDownloadCsv(key: 'pageUsers' | 'allUsers' | 'filterResults') {
    try {
      let formatted: UserCsv[] = []
      const toastId = toast.loading('Baixando planilha...')

      if (key === 'pageUsers') {
        if (!users || users.results.length <= 0) {
          toast.error('Não há dados para baixar!', { id: toastId })
          return Promise.reject('No data to download')
        }

        formatted = formatCsv(users?.results as User[])
      }

      if (key === 'allUsers') {
        const { results } = await fetchAllUsers()
        formatted = formatCsv(results)
      }

      if (key === 'filterResults') {
        const { results } = await fetchAllUsers(userFilters)

        formatted = formatCsv(results)
      }

      toast.success('Planilha baixada com sucesso!', { id: toastId })
      return Promise.resolve(formatted)
    } catch (err) {
      console.error('Error downloading', err)
      toast.dismiss()
      toast.error('Erro ao baixar planilha!')
      return Promise.reject(err)
    }
  }

  const headerActionItems: MenuProps['items'] = [
    pagePerms && (isAdmin || canCreate)
      ? {
          key: '1',
          label: (
            <Button type="link" onClick={toggleCreateUserModal} icon={<PlusOutlined />}>
              Novo usuário
            </Button>
          ),
        }
      : null,
    {
      key: '2',
      label: (
        <Button type="link" icon={<DownloadOutlined />}>
          Baixar Planilha
        </Button>
      ),
      children: [
        {
          key: '2-1',
          label: (
            <CsvDownload
              filename="Tabela_da_Página_de_Usuários.csv"
              extension=".csv"
              separator=";"
              wrapColumnChar="'"
              columns={csvHeaders}
              datas={() => handleDownloadCsv('pageUsers')}
            >
              <Button type="link">Página atual</Button>
            </CsvDownload>
          ),
        },
        {
          key: '2-2',
          label: (
            <CsvDownload
              filename="Tabela_de_Usuários_Filtro.csv"
              extension=".csv"
              separator=";"
              wrapColumnChar="'"
              columns={csvHeaders}
              datas={() => handleDownloadCsv('filterResults')}
            >
              <Button type="link">Todos os resultados</Button>
            </CsvDownload>
          ),
        },
        {
          key: '2-3',
          label: (
            <CsvDownload
              filename="Tabela_de_Usuários.csv"
              extension=".csv"
              separator=";"
              wrapColumnChar="'"
              columns={csvHeaders}
              datas={() => handleDownloadCsv('allUsers')}
            >
              <Button type="link">Todos os usuários</Button>
            </CsvDownload>
          ),
        },
      ],
    },
  ]

  const ExtraContent = () => (
    <S.ExtraContent>
      <SearchInput
        placeholder="Buscar por nome..."
        onSearch={(searchValue) =>
          handleSearchFilter({
            searchValue,
            filterKey: 'name',
            comparator: 'like',
            resetPagination,
            storeCallback: setUserFilters,
          })
        }
        initialValue={userFilters.name?.value}
        onClose={() => setUserFilters({ name: undefined })}
      />

      <Dropdown
        menu={{ items: headerActionItems }}
        trigger={['click']}
        overlayClassName="dropdown-test"
      >
        <Button type="primary">
          Ações
          <DownOutlined />
        </Button>
      </Dropdown>
    </S.ExtraContent>
  )

  return (
    <PageLayout
      title="Usuários"
      isListPage
      sidebar={
        <UserFilterSidebar
          form={form}
          isOpen={isFilterDrawerOpen}
          toggleDrawer={toggleFilterDrawer}
        />
      }
    >
      <PageHeader
        title="Lista de usuários"
        itemsFound={users?.info.total || 0}
        isLoading={isLoading || isFetching}
      />

      <TitleTabs
        items={isAdmin ? titleTabItems : formatTitleTabItems(titleTabItems)}
        defaultActiveKey="users"
        onChange={navigateToTab}
        tabBarExtraContent={<ExtraContent />}
      />

      {getActiveFiltersList(userFilters).length > 0 ? (
        <ActiveFiltersList
          filterList={getActiveFiltersList(userFilters)}
          removeFilter={handleRemoveFilter}
          clearFilters={() => handleClearFilters(resetUserFilters)}
          valueRemap={userFilterValuesRemap}
        />
      ) : null}

      {isCreateUserModalOpen && (
        <CreateUserModal isOpen={isCreateUserModalOpen} onClose={toggleCreateUserModal} />
      )}

      {isViewUserModalOpen && (
        <ViewUserModal
          isOpen={isViewUserModalOpen}
          onClose={toggleViewUserModal}
          userId={selectedUser as string}
          canEdit={isAdmin || canEdit}
        />
      )}

      <UsersListTable
        data={users?.results || []}
        isLoading={isLoading}
        handleSelectUser={selectUserDispatch}
        handleChangeUserStatus={handleUpdateUserStatus}
        canDelete={isAdmin || canDelete}
        canEdit={isAdmin || canEdit}
      />

      <CustomPagination
        scrollToTop
        page={users?.info.current_page || userPagination.page}
        isLoading={isLoading}
        pageSize={userPagination.per_page}
        totalItems={users?.info.total || 0}
        totalPages={users?.info.pages || 0}
        setPagination={setUserPagination}
      />
    </PageLayout>
  )
}

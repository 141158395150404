import { useLocation, useNavigate } from 'react-router-dom'

export function useTabNavigation() {
  const navigate = useNavigate()
  const path = useLocation().pathname.split('/')[1]

  const navigateToTab = (tabKey: string) => {
    if (tabKey !== path) {
      navigate(`/${tabKey}`)
    }
  }

  return navigateToTab
}

export function unmaskValue(maskedValue: string, type?: 'all' | 'cnpj') {
  const maskCharacters = ['*', '#', '@', '!', '(', ')', '-', '.', ' ']

  const cnpjMaskCharacters = ['*', '#', '@', '!', '(', ')', '.', ' ']

  if (type === 'cnpj') {
    const unmaskedValue = maskedValue
      .split('')
      .filter((char) => !cnpjMaskCharacters.includes(char))
      .join('')
      .trim()

    return unmaskedValue
  }

  const unmaskedValue = maskedValue
    .split('')
    .filter((char) => !maskCharacters.includes(char))
    .join('')
    .trim()

  return unmaskedValue
}

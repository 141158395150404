interface GetRedirectHref {
  systemUrl: string
  refreshToken: string
  userId: string
  systemPage?: string
}

export function getRedirectHref({ systemUrl, refreshToken, userId, systemPage }: GetRedirectHref) {
  if (!systemUrl.endsWith('/')) {
    systemUrl.concat('/')
  }

  const redirectHref = `${systemUrl}redirect-login?refresh-token=${refreshToken}&user-id=${userId}`

  if (systemPage) {
    return `${redirectHref}&page=${systemPage}`
  }

  return redirectHref
}

import { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { CollapseProps, DatePicker, Form, Input } from 'antd'

import { useRoleFilterStore } from '@/app'
import { type FilterValue, useInitialFilterFormValues, type BaseFilterFormProps } from '@/common'
import { CheckboxFilter, FilterCollapse } from '@/components'
import { groupQueries } from '@/features/groups'
import { systemQueries } from '@/features/systems'

export const RoleFilterForm = ({ form, onSubmit }: BaseFilterFormProps) => {
  const roleFilters = useRoleFilterStore((s) => s.filters)
  const initialFilterValues = useInitialFilterFormValues(
    roleFilters as unknown as Record<string, FilterValue>,
  )

  // Systems filter
  const [systemsSearch, setSystemsSearch] = useState('')
  const { data: systems, isFetching: isSystemsFetching } = useQuery(
    systemQueries.list({
      system_name: {
        value: systemsSearch,
        comparator: 'like',
      },
    }),
  )

  // Groups filter
  const [groupsSearch, setGroupsSearch] = useState('')
  const { data: groups, isFetching: isGroupsFetching } = useQuery(
    groupQueries.list({
      group_name: {
        value: groupsSearch,
        comparator: 'like',
      },
    }),
  )

  useEffect(() => {
    form.setFieldsValue(initialFilterValues)
  }, [form, initialFilterValues])

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Acesso',
      children: (
        <Form.Item name="role_value">
          <Input.Search placeholder="Ex: dashboard" />
        </Form.Item>
      ),
    },
    {
      key: '2',
      label: 'Data de criação',
      children: (
        <Form.Item name="created_at">
          <DatePicker.RangePicker allowEmpty format={'DD/MM/YYYY'} />
        </Form.Item>
      ),
    },
    {
      key: '3',
      label: 'Data de atualizacão',
      children: (
        <Form.Item name="updated_at">
          <DatePicker.RangePicker allowEmpty format={'DD/MM/YYYY'} />
        </Form.Item>
      ),
    },
    {
      key: '4',
      label: 'Sistema',
      children: (
        <Form.Item name="system">
          <CheckboxFilter
            filterNameKey="system_name"
            formFieldName="system"
            searchValue={systemsSearch}
            filterSearch={setSystemsSearch}
            optionsData={systems?.results}
            isSearching={isSystemsFetching}
            initialValues={initialFilterValues?.system?.system_name}
          />
        </Form.Item>
      ),
    },
    {
      key: '5',
      label: 'Grupo',
      children: (
        <Form.Item name="groups">
          <CheckboxFilter
            filterNameKey="group_name"
            formFieldName="groups"
            searchValue={groupsSearch}
            filterSearch={setGroupsSearch}
            optionsData={groups?.results}
            isSearching={isGroupsFetching}
            initialValues={initialFilterValues?.groups?.group_name}
          />
        </Form.Item>
      ),
    },
  ]

  return <FilterCollapse form={form} onSubmit={onSubmit} items={items} />
}

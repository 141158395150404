import { QuestionOutlined } from '@ant-design/icons'
import { Input as AntdInput, Segmented, Tabs } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .ant-form-item {
    margin: 0;
  }
`

export const Input = styled(AntdInput)`
  height: 40px;
  margin: 0;

  .ant-input-prefix {
    color: ${({ theme }) => theme.colors.brand};
  }
`
export const InputPassword = styled(AntdInput.Password)`
  height: 40px;
  margin: 0;

  .ant-input-prefix {
    color: ${({ theme }) => theme.colors.brand};
  }
`

export const MfaSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const SectionTitle = styled(Tabs)`
  color: #5f5f5f;

  .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs-nav-list {
    cursor: pointer;
    pointer-events: none;
  }

  .ant-tabs-tab {
    padding: 4px 0;
  }
`

export const MfaSegment = styled(Segmented)`
  background-color: white;
  /* padding: 2px 0; */
  padding: 0;

  .ant-segmented-item {
    color: ${({ theme }) => theme.colors.brand};

    border: 1px solid white;

    // border-radius if first item
    &:first-child {
      border-radius: 6px 0px 0px 6px !important;
    }

    // border-radius if last item
    &:last-child {
      border-radius: 0px 6px 6px 0px !important;
    }

    transition:
      border 0.15s ease-in,
      border-radius 0.15s ease-in;

    :hover {
      color: ${({ theme }) => theme.colors.brand};
    }
  }

  .ant-segmented-item-selected {
    font-weight: 600;

    border: 1px solid ${({ theme }) => theme.colors.brand};
  }

  &.ant-segmented
    .ant-segmented-item:hover:not(.ant-segmented-item-selected):not(.ant-segmented-item-disabled) {
    color: ${({ theme }) => theme.colors.brand};
  }
`

export const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`

export const TooltipIcon = styled(QuestionOutlined)`
  padding: 2px;
  font-size: 12px;
  font-weight: 600;

  border: 1px solid ${({ theme }) => theme.colors.brand};
  border-radius: 99999999px;

  background-color: white;
  color: ${({ theme }) => theme.colors.brand};
`

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { areaQueries } from '..'

import { api } from '@/services'

type DeleteAreaMutation = {
  areaPk: string
}

export const useDeleteAreaMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ areaPk }: DeleteAreaMutation) => {
      return api.delete(`/area/${areaPk}`)
    },
    onMutate: async ({ areaPk }) => {
      await queryClient.cancelQueries({ queryKey: areaQueries.all() })

      const cachedAreas = queryClient.getQueryData(areaQueries.list().queryKey)

      if (cachedAreas) {
        const updatedAreas = cachedAreas.results.filter((area) => area.pk !== areaPk)

        queryClient.setQueryData(areaQueries.list().queryKey, {
          ...cachedAreas,
          results: updatedAreas,
        })
      }

      return cachedAreas
    },
    onError: (error, _, cachedAreas) => {
      console.error(error)

      if (cachedAreas) {
        queryClient.setQueryData(areaQueries.list().queryKey, cachedAreas)
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: areaQueries.all() }),
  })
}

import { infiniteQueryOptions } from '@tanstack/react-query'

import type { Notification } from '../types'

import { DEFAULT_PAGE, ListResponse } from '@/common'
import { api } from '@/services'

const fetchNotificationsList = async ({ pageParam }: any) => {
  const { data } = await api.post<ListResponse<Notification[]>>('/notification/list', {
    pagination_expression: {
      page: pageParam || DEFAULT_PAGE,
      per_page: 20,
    },
  })

  return data
}

export const notificationQueries = {
  all: () => ['notifications'],
  lists: () => [...notificationQueries.all(), 'list'],
  list: () =>
    infiniteQueryOptions({
      queryKey: [...notificationQueries.lists()],
      queryFn: fetchNotificationsList,
      initialPageParam: DEFAULT_PAGE,
      getNextPageParam: (lastPage) => {
        const isLastPage = lastPage.info.current_page === lastPage.info.pages

        if (!isLastPage) {
          return lastPage.info.current_page + 1
        }

        return null
      },
    }),
}

import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Form, Tooltip } from 'antd'

import * as S from './styles'

export type LoginFormData = {
  email: string
  password: string
  mfa: 'EMAIL' | 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA'
}

type LoginFormProps = {
  onSubmit: (data: LoginFormData) => void
  isLoading: boolean
}

const mfaOptions = [
  {
    label: (
      <S.TooltipContainer>
        <span>E-mail</span>
        <Tooltip title='É possível que o código seja encaminhado para a pasta "Spam/Lixo Eletrônico/Outros"'>
          <S.TooltipIcon />
        </Tooltip>
      </S.TooltipContainer>
    ),
    value: 'EMAIL',
  },
  {
    label: <span>SMS</span>,
    value: 'SMS_MFA',
  },
  {
    label: (
      <S.TooltipContainer>
        <span>Aplicativo</span>
        <Tooltip title="Necessário realizar o cadastro no aplicativo após o login">
          <S.TooltipIcon />
        </Tooltip>
      </S.TooltipContainer>
    ),
    value: 'SOFTWARE_TOKEN_MFA',
  },
]

export const LoginForm = ({ onSubmit, isLoading }: LoginFormProps) => {
  return (
    <S.Container>
      <Form
        layout="vertical"
        onFinish={onSubmit}
        disabled={isLoading}
        initialValues={{
          mfa: 'EMAIL',
        }}
        validateTrigger="onSubmit"
      >
        <Form.Item
          name="email"
          data-testid="login-form-email"
          rules={[
            {
              required: true,
              message: 'E-mail é obrigatório',
            },
            {
              type: 'email',
              message: 'E-mail inválido',
            },
          ]}
        >
          <S.Input prefix={<UserOutlined />} placeholder="E-mail" />
        </Form.Item>

        <Form.Item
          name="password"
          data-testid="login-form-password"
          rules={[
            {
              required: true,
              message: 'A senha é obrigatória',
            },
          ]}
        >
          <S.InputPassword type="password" prefix={<LockOutlined />} placeholder="Senha" />
        </Form.Item>

        <S.MfaSectionContainer>
          <S.SectionTitle
            activeKey="n/a"
            items={[
              {
                key: 'mfa',
                label: 'Método de autenticação multifatores',
              },
            ]}
          />

          <Form.Item
            name="mfa"
            data-testid="login-form-mfa"
            rules={[
              {
                required: true,
                message: 'Mfa é obrigatório',
              },
              {
                type: 'enum',
                enum: ['EMAIL', 'SMS_MFA', 'SOFTWARE_TOKEN_MFA'],
                message: 'Mfa inválido',
              },
            ]}
          >
            <S.MfaSegment options={mfaOptions} block defaultValue={'email'} />
          </Form.Item>
        </S.MfaSectionContainer>

        <Button
          type="primary"
          htmlType="submit"
          block
          loading={isLoading}
          data-testid="login-form-submit"
        >
          Entrar
        </Button>
      </Form>
    </S.Container>
  )
}

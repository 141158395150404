import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .ant-table-container,
  .ant-table-title {
    border: 1px solid #e2e4e7;
    border-radius: 4px;
  }

  .ant-table-title {
    border-bottom: 0;
  }
`

export const RoleData = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;

  strong {
    color: #434343;
    text-transform: capitalize;
  }
`

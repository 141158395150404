import { createJSONStorage, devtools, persist } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@/common'
import type { BaseFilterStore } from '@/common'
import type { RoleFilters } from '@/features/roles'

interface RoleFilterStore extends BaseFilterStore {
  filters: RoleFilters
  setFilters: (filters: RoleFilters) => void
}

const roleFilterStore = createWithEqualityFn<RoleFilterStore>()(
  devtools(
    persist(
      (set) => ({
        filters: {
          title: undefined,
          role_value: undefined,
          // system_module_id: undefined,
          created_at: undefined,
          updated_at: undefined,
          groups: undefined,
          system: undefined,
        },

        activeSorting: {
          field: undefined,
          order: undefined,
        },

        pagination: {
          page: DEFAULT_PAGE,
          per_page: DEFAULT_PAGE_SIZE,
        },

        setFilters: (filters) => {
          set(
            (oldState) => ({
              filters: { ...oldState.filters, ...filters },
            }),
            false,
            'set-role-filters',
          )
        },

        setActiveSorting: (sorting) => {
          set({ activeSorting: sorting }, false, 'set-role-sorting')
        },

        setPagination: (page, pageSize) => {
          set(
            (oldState) => ({
              pagination: {
                page,
                per_page: pageSize ?? oldState.pagination.per_page,
              },
            }),
            false,
            'set-role-pagination',
          )
        },

        resetPagination: () => {
          set(
            {
              pagination: {
                page: DEFAULT_PAGE,
                per_page: DEFAULT_PAGE_SIZE,
              },
            },
            false,
            'reset-role-pagination',
          )
        },

        resetFilters: () => {
          set(
            {
              filters: {
                title: undefined,
                role_value: undefined,
                created_at: undefined,
                updated_at: undefined,
                groups: undefined,
                system: undefined,
              },
            },
            false,
            'reset-role-filters',
          )
        },
      }),
      {
        name: 'role-filter-store',
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
    {
      name: 'role-filter-store',
    },
  ),
  shallow,
)

export { roleFilterStore, roleFilterStore as useRoleFilterStore }

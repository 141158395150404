import { keepPreviousData, queryOptions } from '@tanstack/react-query'

import type { Company, CompanyFilters } from '..'

import type { ActiveSorting, PaginationExpression, ListResponse, FilterValue } from '@/common'
import {
  DEFAULT_ACTIVE_SORTING,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGINATION,
  getFilterExpression,
  handleActiveSorting,
} from '@/common'
import { api } from '@/services'

const fetchCompaniesList = async (
  filters: CompanyFilters,
  sorting: ActiveSorting,
  pagination: PaginationExpression,
) => {
  const filterExpression = getFilterExpression(filters as unknown as Record<string, FilterValue>)

  const activeSorting = handleActiveSorting(sorting)

  const { data } = await api.post<ListResponse<Company[]>>('/company/list', {
    filter_expression: filterExpression,
    pagination_expression: {
      page: pagination.page || DEFAULT_PAGE,
      per_page: pagination.per_page === undefined ? DEFAULT_PAGE_SIZE : pagination.per_page,
    },
    order_by_expression: activeSorting,
  })

  return data
}

async function fetchCompany(companyPk: string) {
  const { data } = await api.get<Company>(`/company/${companyPk}`)

  return data
}

export const companyQueries = {
  all: () => ['companies'],
  lists: () => [...companyQueries.all(), 'list'],
  list: (
    filters: CompanyFilters = {},
    sorting: ActiveSorting = DEFAULT_ACTIVE_SORTING,
    pagination: PaginationExpression = DEFAULT_PAGINATION,
  ) =>
    queryOptions({
      queryKey: [...companyQueries.lists(), filters, sorting, pagination],
      queryFn: () => fetchCompaniesList(filters, sorting, pagination),
      placeholderData: keepPreviousData,
    }),

  details: () => [...companyQueries.all(), 'details'],
  detail: (companyPk: string) =>
    queryOptions({
      queryKey: [...companyQueries.details(), companyPk],
      queryFn: () => fetchCompany(companyPk),
      placeholderData: keepPreviousData,
    }),
}

import { DeleteOutlined } from '@ant-design/icons'
import { ButtonProps, Dropdown, MenuProps } from 'antd'

import * as S from './styles'

interface TableActionProps {
  actions: MenuProps['items']
  icon?: React.ReactNode
  disabled?: boolean
}

interface TableActionButtonProps extends ButtonProps {
  children: React.ReactNode
}

export const TableAction = ({ actions, icon, disabled }: TableActionProps) => {
  return (
    <Dropdown
      menu={{ items: actions }}
      trigger={['click']}
      arrow={{ pointAtCenter: true }}
      disabled={disabled}
    >
      <S.DropdownTrigger type="text" onClick={(e) => e.preventDefault()}>
        {icon ? icon : <S.DropdownIcon />}
      </S.DropdownTrigger>
    </Dropdown>
  )
}

export const TableActionButton = ({ children, ...props }: TableActionButtonProps) => {
  return (
    <S.ActionButton type="link" icon={<DeleteOutlined style={{ fontSize: 16 }} />} {...props}>
      {children}
    </S.ActionButton>
  )
}

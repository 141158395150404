import { Input as AntdInput, Select } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .ant-form-item {
    margin-bottom: 0;
  }
`

export const Input = styled(AntdInput)`
  height: 40px;
`

export const TextArea = styled(AntdInput.TextArea)``

export const SystemsSelect = styled(Select)`
  .ant-select-selector {
    height: 40px;
  }

  .ant-select-selection-item {
    display: none;
  }
`

export const SelectedSystemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 8px 0;
  max-height: 230px;
  overflow-y: auto;
`

export const SelectedSystems = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`

export const System = styled.div`
  min-width: 300px;
  max-width: 340px;
  width: 100%;
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 12px 8px;
  color: #6c6c6c;
  /* color: ${({ theme }) => theme.colors.brand}; */
  font-weight: 600;

  background-color: #fafafa;
  border-radius: 4px;
`

export const SelectAdminContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .ant-form-item {
    max-height: 250px;
    overflow-y: auto;
  }
`

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  color: #434343;

  h3 {
    font-weight: 700;
    font-size: 16px;

    > span {
      color: #6c6c6c;
    }
  }

  span {
    font-size: 14px;
  }
`

import { DownOutlined, RightOutlined } from '@ant-design/icons'
import {
  Table,
  type GetProp,
  type TableColumnsType,
  type TableProps,
  type TransferProps,
} from 'antd'
import Transfer, { type TransferDirection } from 'antd/es/transfer'

import * as S from './styles'

type TransferItem = GetProp<TransferProps, 'dataSource'>[number]
type TableRowSelection<T extends object> = TableProps<T>['rowSelection']

interface BaseDataItem {
  key: string
  children?: BaseDataItem[]
}

interface AntdTableTransferProps<T> extends TransferProps<TransferItem> {
  leftColumns: TableColumnsType<T>
  rightColumns: TableColumnsType<T>
  leftTableData: TransferItem[]
  rightTableData: TransferItem[]
  leftTableTitle?: string
  rightTableTitle?: string
  isLoading?: boolean
}

interface TransferTableProps<T> {
  leftTableData: {
    state: T[]
    setState: React.Dispatch<React.SetStateAction<T[]>>
    title?: string
  }
  rightTableData: {
    state: T[]
    setState: React.Dispatch<React.SetStateAction<T[]>>
    title?: string
  }
  leftColumns: TableColumnsType<T>
  rightColumns: TableColumnsType<T>
  tableTitles: [string, string]
  isLoading?: boolean
}

const TableTransfer = <T extends BaseDataItem>({
  leftColumns,
  rightColumns,
  leftTableData,
  rightTableData,
  leftTableTitle,
  rightTableTitle,
  isLoading,
  ...restProps
}: AntdTableTransferProps<T>) => {
  const titles = [
    <S.TransferTitlesContainer key={'left'}>
      <span>Sistema: </span> <strong>{leftTableTitle}</strong>
    </S.TransferTitlesContainer>,

    <S.TransferTitlesContainer key={'right'}>
      <span>Grupo: </span> <strong>{rightTableTitle}</strong>
    </S.TransferTitlesContainer>,
  ]

  return (
    <Transfer {...restProps} showSelectAll={false} titles={titles}>
      {({ direction, onItemSelectAll, selectedKeys: listSelectedKeys, disabled: listDisabled }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns

        const tableData = direction === 'left' ? leftTableData : rightTableData

        const rowSelection: TableRowSelection<TransferItem> = {
          columnWidth: 12,
          getCheckboxProps: (item) => ({ disabled: listDisabled || item.disabled }),
          onSelect: (record: T, selected: boolean) => {
            const selectItemKeys = getSelectedKeys(record)

            onItemSelectAll(selectItemKeys, selected)
          },
          selectedRowKeys: listSelectedKeys,
          hideSelectAll: true,
        }

        function getSelectedKeys(row: T) {
          const hasChildren = !!row.children

          if (hasChildren) {
            // if has children, get all children keys (select all including parent)
            return [row.key, ...row.children!.map((child) => child.key)]
          }

          // get only selected item key
          return [row.key]
        }

        return (
          <Table
            virtual
            size="small"
            columns={columns}
            pagination={false}
            loading={isLoading}
            dataSource={tableData}
            rowSelection={{ ...rowSelection }}
            style={{ pointerEvents: listDisabled ? 'none' : undefined }}
            expandable={{
              expandIcon: ({ expanded, onExpand, record }) => {
                if (!record.children) return null

                if (expanded) {
                  return (
                    <S.TableButton
                      onClick={(e) => onExpand(record, e)}
                      type="link"
                      icon={<DownOutlined />}
                    />
                  )
                }

                return (
                  <S.TableButton
                    onClick={(e) => onExpand(record, e)}
                    type="link"
                    icon={<RightOutlined />}
                  />
                )
              },
            }}
            onRow={({ key }) => ({
              onClick: () => {
                return key
                // if (listDisabled) return

                // setSelectedKeys((prev) => {
                //   console.log({ prev, direction, listSelectedKeys, key })

                //   return {
                //     ...prev,
                //     [direction]: listSelectedKeys.includes(key)
                //       ? listSelectedKeys.filter((i) => i !== key)
                //       : [...prev[direction], key],
                //   }
                // })
                // onItemSelect(key, !listSelectedKeys.includes(key))
              },
            })}
          />
        )
      }}
    </Transfer>
  )
}

export const TransferTable = <T extends BaseDataItem>({
  leftColumns,
  rightColumns,
  leftTableData,
  rightTableData,
  tableTitles,
  isLoading,
}: TransferTableProps<T>) => {
  function getSelectedParentRows(dataSource: T[], keys: string[]) {
    // get parent rows that have children selected keys
    return dataSource.filter(
      (row) => keys.includes(row.key) || row.children?.some((child) => keys.includes(child.key)),
    )
  }

  function getSelectedItems(parentRows: T[], keys: string[]) {
    // get parent rows with children filtered by selected keys (only returns parents with selected children)
    return parentRows.map((row) => {
      const hasChildren = !!row.children

      if (hasChildren) {
        return {
          ...row,
          children: row.children?.filter((c) => keys.includes(c.key)),
        }
      }

      return row
    })
  }

  function getUpdatedTableData(
    oldSourceItems: T[], // source items that will be updated
    selectedItems: T[], // selected items to be moved
    targetItems: T[], // target items that will be updated
  ): [T[], T[]] {
    let updatedSourceItems: T[] = []
    let updatedTargetItems: T[] = [...targetItems]

    oldSourceItems.forEach((currentItem) => {
      // check if an item with the same key exists in the target items
      const itemInTargetItems = updatedTargetItems.find((item) => item.key === currentItem.key)

      // if current source item has children
      if (currentItem.children && currentItem.children.length > 0) {
        const childrenSelected = currentItem.children.filter(
          (child) =>
            selectedItems.some((item) => item.key === child.key) ||
            selectedItems.some((item) => item.children?.some((c) => c.key === child.key)),
        )

        const childrenNotSelected = currentItem.children.filter(
          (child) => !childrenSelected.includes(child),
        )

        // if any children are selected
        if (childrenSelected.length > 0) {
          // if the item already exists in target items, update its children
          if (itemInTargetItems) {
            const updatedChildren = itemInTargetItems.children
              ? [...itemInTargetItems.children, ...childrenSelected]
              : childrenSelected

            // sort children by key here
            updatedChildren.sort((a, b) => a.key.localeCompare(b.key))

            // update the target items with the updated children
            updatedTargetItems = updatedTargetItems.map((item) =>
              item.key === currentItem.key ? { ...item, children: updatedChildren } : item,
            )
          } else {
            // if the item does not exist in target items
            const newItem = { ...currentItem, children: childrenSelected }

            // sort children by key here
            newItem.children.sort((a, b) => a.key.localeCompare(b.key))

            // add the new item to the target items
            updatedTargetItems = [...updatedTargetItems, newItem]
          }

          // if there are any unselected children, preserve them in the source items
          if (childrenNotSelected.length > 0) {
            const newItem = { ...currentItem, children: childrenNotSelected }

            // sort children by key here
            newItem.children.sort((a, b) => a.key.localeCompare(b.key))

            updatedSourceItems = [...updatedSourceItems, newItem]
          }
        } else {
          // if there are no selected children, preserve the entire item in the source items
          updatedSourceItems = [...updatedSourceItems, currentItem]
        }
      } else if (!itemInTargetItems) {
        // If there are no children and the item does not exist in target items, preserve it in the source items
        updatedSourceItems = [...updatedSourceItems, currentItem]
      }
    })

    // sort parent array by key here
    updatedSourceItems.sort((a, b) => a.key.localeCompare(b.key))
    updatedTargetItems.sort((a, b) => a.key.localeCompare(b.key))

    return [updatedSourceItems, updatedTargetItems]
  }

  function handleChange(
    nextTargetKeys: string[],
    direction: TransferDirection,
    moveKeys: string[],
  ) {
    const dataSource = direction === 'right' ? leftTableData.state : rightTableData.state
    const keysSource = direction === 'right' ? nextTargetKeys : moveKeys

    const originalParentRows = getSelectedParentRows(dataSource, keysSource)

    // list of parent rows with children filtered by selected keys
    const selectedItems = getSelectedItems(originalParentRows, keysSource)

    if (direction === 'right') {
      const [updatedSource, updatedTarget] = getUpdatedTableData(
        leftTableData.state,
        selectedItems,
        rightTableData.state,
      )

      // console.log(direction, { updatedSource, updatedTarget })

      leftTableData.setState(updatedSource)
      rightTableData.setState(updatedTarget)
    } else {
      const [updatedSource, updatedTarget] = getUpdatedTableData(
        rightTableData.state,
        selectedItems,
        leftTableData.state,
      )

      // console.log(direction, { updatedSource, updatedTarget })

      rightTableData.setState(updatedSource)
      leftTableData.setState(updatedTarget)
    }
  }

  return (
    <S.Container>
      <S.TitleContainer>
        {tableTitles.map((title) => (
          <h3 key={title}>{title}</h3>
        ))}
      </S.TitleContainer>

      <TableTransfer<T>
        onChange={handleChange}
        leftTableData={leftTableData.state}
        rightTableData={rightTableData.state}
        leftTableTitle={leftTableData?.title}
        rightTableTitle={rightTableData?.title}
        leftColumns={leftColumns}
        rightColumns={rightColumns}
        isLoading={isLoading}
      />
    </S.Container>
  )
}

import { useNavigate } from 'react-router-dom'

import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from 'sonner'
import { shallow } from 'zustand/shallow'

import { useAuthStore } from '@/app'
import { MFAType } from '@/features/auth'
import { api } from '@/services'

interface IValidateMfaMutation {
  mfa_code: string
  mfa_type: MFAType
  username: string
  session: string
}

interface IValidateMfaMutationResponse {
  IdToken: string
  AccessToken: string
  RefreshToken: string
  ExpiresIn: number
  TokenType: string
}

const errorMessages = new Map([
  ['Invalid session for the user.', 'Sessão expirada.'],
  ['Invalid MFA Code. User must log-in again.', 'Código MFA inválido, faça login novamente.'],
])

export const useValidateMfaMutation = () => {
  const navigate = useNavigate()

  const { setAuthTokens, setUserAuthStatus } = useAuthStore(
    (state) => ({
      setAuthTokens: state.setTokens,
      setUserAuthStatus: state.setAuthenticated,
    }),
    shallow,
  )

  return useMutation({
    mutationFn: async (data: IValidateMfaMutation) => {
      return api.post<IValidateMfaMutationResponse>('/no-auth/auth/validate-mfa', data)
    },
    onSuccess: ({ data }) => {
      toast.success('Autenticação validada com sucesso')

      api.defaults.headers.common.Authorization = `Bearer ${data.IdToken}`

      setAuthTokens({
        accessToken: data.AccessToken,
        idToken: data.IdToken,
        refreshToken: data.RefreshToken,
      })

      setUserAuthStatus(true)

      navigate('/')
    },
    onError: (error: AxiosError<{ message: string }>) => {
      console.log(error)

      const message =
        errorMessages.get(error.response?.data.message as string) || 'Falha ao validar autenticação'

      toast.error(message)

      navigate('/auth/login')
    },
  })
}

import InputMask from 'react-input-mask'

import { useQuery } from '@tanstack/react-query'
import { Form, Input, Select } from 'antd'

import * as S from './styles'

import { companyQueries } from '@/features/company'
import { contractTypeOptions } from '@/features/parameters'

interface Props {
  isEditing?: boolean
}

export const UserFormFirstStep = ({ isEditing = false }: Props) => {
  const { data: companies, isLoading: isCompaniesLoading } = useQuery(companyQueries.list())

  const companySelectOptions = companies?.results.map((company) => ({
    label: company.company_fancy_name,
    value: company.company_fancy_name,
  }))

  return (
    <S.FormContainer>
      <S.FormArea>
        <S.AreaTitle>Dados do usuário</S.AreaTitle>

        <S.FormRow>
          <Form.Item
            name="name"
            label="Nome"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="Ex: John Doe" />
          </Form.Item>

          <Form.Item
            name="email"
            label="E-mail"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
                whitespace: true,
              },
              {
                type: 'email',
                message: 'E-mail inválido',
              },
            ]}
          >
            <Input type="email" placeholder="Ex: john-doe@email.com" disabled={isEditing} />
          </Form.Item>
        </S.FormRow>

        <S.FormRow>
          <Form.Item
            name="phone_number"
            label="Telefone"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
                whitespace: true,
              },
            ]}
          >
            <InputMask mask={'+55 (99) 99999-9999'} maskPlaceholder={''}>
              <Input type="tel" placeholder="Ex: +55 (99) 99999-9999" />
            </InputMask>
          </Form.Item>

          <Form.Item
            name="cpf"
            label="CPF"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
                whitespace: true,
              },
            ]}
          >
            <InputMask mask={'999.999.999-99'} maskPlaceholder={''}>
              <Input placeholder="Ex: 123.456.789-00" />
            </InputMask>
          </Form.Item>
        </S.FormRow>
      </S.FormArea>

      <S.AreaTitle>Informações do usuário</S.AreaTitle>

      <S.FormArea>
        <S.FormRow>
          <Form.Item name="nickname" label="Apelido">
            <Input placeholder="Ex: Johnny" />
          </Form.Item>

          <Form.Item
            name="empresa"
            label="Empresa (nome fantasia)"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
                whitespace: true,
              },
            ]}
          >
            <Select
              placeholder="Ex: Acer"
              options={companySelectOptions}
              loading={isCompaniesLoading}
            />
          </Form.Item>
        </S.FormRow>

        <S.FormRow>
          <Form.Item
            name="tipo_contrato"
            label="Tipo de contrato"
            rules={[
              {
                required: true,
                message: 'Campo obrigatório',
                whitespace: true,
              },
            ]}
          >
            <Select placeholder="Ex: CLT" options={contractTypeOptions} />
          </Form.Item>

          <Form.Item name="active" label="Status">
            <Input disabled />
          </Form.Item>
        </S.FormRow>
      </S.FormArea>
    </S.FormContainer>
  )
}

import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(timezone)
dayjs.extend(utc)

interface formatDateProps {
  date: string
  dateFormat?: string
}

export function formatDate({ date, dateFormat = 'DD/MM/YYYY' }: formatDateProps) {
  return dayjs.utc(date).format(dateFormat)
}

import { LockOutlined } from '@ant-design/icons'
import { Button, Form } from 'antd'

import * as S from './styles'

import { passwordValidator } from '@/features/auth'

export type ResetFirstPasswordFormData = {
  password: string
  confirmPassword: string
}

type ResetFirstPasswordFormProps = {
  onSubmit: (data: ResetFirstPasswordFormData) => void
  isLoading: boolean
}

export const ResetFirstPasswordForm = ({ onSubmit, isLoading }: ResetFirstPasswordFormProps) => {
  return (
    <S.Container>
      <Form layout="vertical" onFinish={onSubmit} disabled={isLoading}>
        <Form.Item
          name="password"
          data-testid="first-login-form-password"
          rules={[
            () => ({
              validator(_, value) {
                return passwordValidator(value)
              },
            }),
          ]}
        >
          <S.InputPassword type="password" prefix={<LockOutlined />} placeholder="Senha" />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          data-testid="first-login-form-confirm-password"
          rules={[
            { required: true, message: 'A confirmação da senha é obrigatória' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('As senhas não coincidem'))
              },
            }),
          ]}
        >
          <S.InputPassword
            type="password"
            prefix={<LockOutlined />}
            placeholder="Confirme sua senha"
          />
        </Form.Item>

        <Button
          block
          type="primary"
          htmlType="submit"
          loading={isLoading}
          data-testid="first-login-form-submit"
        >
          Confirmar
        </Button>
      </Form>
    </S.Container>
  )
}

import { useNavigate } from 'react-router-dom'

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { clearAuthStore, useAuthStore } from '@/app'
import { clearAllCaches } from '@/common'
import { api } from '@/services'

export const useSignOutMutation = () => {
  const navigate = useNavigate()
  const refreshToken = useAuthStore((state) => state.tokens.refreshToken)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async () => {
      return api.post('/auth/logout', {
        refresh_token: refreshToken,
      })
    },
    onMutate: () => {
      clearAuthStore()
      localStorage.clear()
      sessionStorage.clear()
      queryClient.clear()
    },
    onSuccess: async () => {
      await clearAllCaches()
      return navigate('/auth')
    },
    onError: (error) => console.error('Sign out error', error),
    onSettled: async () => await clearAllCaches(),
  })
}

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { roleQueries } from '../queries'
import type { RegisterRolePermission } from '../types'

import { api } from '@/services'

interface IEditRoleMutation {
  title: string
  description: string
  role_value: string
  role_permissions: RegisterRolePermission[]
  system_module_id: string
  rolePk: string
}

export const useEditRoleMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ rolePk, ...rest }: IEditRoleMutation) => {
      return api.put(`/role/${rolePk}`, rest)
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: roleQueries.all() }),
  })
}

import type { FilterExpression } from '@/common'

export type FilterValue = FilterExpression | { [key: string]: FilterExpression }

const defaultComparator = 'eq'

export function getFilterExpression<T extends Record<string, FilterValue>>(
  filters: T,
): Record<string, any> {
  const seenObjects = new WeakMap<object, any>()

  function processObject(obj: FilterValue): any {
    // Check for circular reference
    if (seenObjects.has(obj)) {
      return seenObjects.get(obj)
    }

    let result
    if ('value' in obj) {
      // It's a filter node
      result = {
        value: obj.value,
        comparator: obj.comparator || defaultComparator,
      }
      seenObjects.set(obj, result)
    } else {
      // It's a nested filter node
      result = Object.entries(obj).reduce(
        (acc, [key, nestedObj]) => {
          if (nestedObj === undefined) {
            return acc
          }
          acc[key] = processObject(nestedObj as FilterValue)
          return acc
        },
        {} as Record<string, any>,
      )
      seenObjects.set(obj, result)
    }
    return result
  }

  if (!filters || Object.keys(filters).length === 0) {
    return {}
  }

  return Object.entries(filters).reduce(
    (acc, [key, objValue]) => {
      if (objValue === undefined) {
        return acc
      }
      acc[key] = processObject(objValue)
      return acc
    },
    {} as Record<string, any>,
  )
}

// import type { FilterExpression } from '@/common'

// export type FilterValue = FilterExpression | { [key: string]: FilterExpression }

// export function getFilterExpression<T extends Record<string, FilterValue>>(filters: T) {
//   const defaultComparator = 'eq'

//   function processObject(obj: FilterValue): any {
//     if (obj?.value) {
//       // It's a filter node
//       return {
//         value: obj.value,
//         comparator: obj.comparator || defaultComparator,
//       }
//     } else {
//       // It's a nested filter node (relational filter)
//       return Object.entries(obj).reduce(
//         (acc, [key, nestedObj]) => {
//           const result = processObject(nestedObj)
//           if (result !== undefined) {
//             acc[key] = result
//           }
//           return acc
//         },
//         {} as Record<string, any>,
//       )
//     }
//   }

//   try {
//     if (!filters || Object.keys(filters).length === 0) {
//       return {}
//     }

//     return Object.entries(filters).reduce(
//       (acc, [key, objValue]) => {
//         if (objValue === undefined) {
//           return acc
//         }
//         acc[key] = processObject(objValue)
//         return acc
//       },
//       {} as Record<string, any>,
//     )
//   } catch (error) {
//     console.error('Error getting filter expression', error)
//     return {} // return an empty object on error
//   }
// }

import styled from 'styled-components'


export const Container = styled.div`
display: flex;
flex-direction: column;
`

export const FormArea = styled.div`
display: flex;
flex-direction: column;
`
export const AreaTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #434343;
  padding-bottom: 24px;
`
export const FormRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  .ant-form-item {
    flex: 1;
    width: 334px;
  }

  .ant-input {
    height: 40px;
  }

  .ant-select,
  .ant-select-selector {
    height: 40px !important;
  }
`

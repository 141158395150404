import { useMutation, useQueryClient } from '@tanstack/react-query'

import { notificationQueries } from '../queries'

import { api } from '@/services'

export const useViewNotificationMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (notificationPk: string) => {
      return api.post(`/notification/set_as_viewed/${notificationPk}`)
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: notificationQueries.all() }),
  })
}

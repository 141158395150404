import { useMutation, useQueryClient } from '@tanstack/react-query'

import { systemQueries } from '..'

import { api } from '@/services'

export const useDeleteSystemMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (systemPk: string) => {
      return api.delete(`/system/${systemPk}`)
    },
    onMutate: async (systemPk) => {
      await queryClient.cancelQueries({ queryKey: systemQueries.all() })

      const cachedSystems = queryClient.getQueryData(systemQueries.list().queryKey)

      if (cachedSystems) {
        const updatedSystems = cachedSystems.results.filter((system) => system.pk !== systemPk)

        queryClient.setQueryData(systemQueries.list().queryKey, {
          ...cachedSystems,
          results: updatedSystems,
        })
      }

      return cachedSystems
    },
    onError: (error, _, cachedSystems) => {
      console.error(error)

      if (cachedSystems) {
        queryClient.setQueryData(systemQueries.list().queryKey, cachedSystems)
      }
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: systemQueries.all() }),
  })
}

import { jwtDecode } from 'jwt-decode'
import pako from 'pako'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import authStore from '@/app/stores/auth'

type GetPagePermissions = {
  moduleKey: string
  roleKey: string
}

interface RolesStoreState {
  decodedRoles: any | null
  allowedModules: string[] | null
  isSystemAdmin: boolean
  getDecodedRoles: (idToken?: string) => void
  getPagePermissions: ({ moduleKey, roleKey }: GetPagePermissions) => string[] | undefined
  clearRolesStore: () => void
}

const rolesStore = create<RolesStoreState>()(
  devtools(
    (set, get) => {
      return {
        decodedRoles: null,
        allowedModules: null,
        isSystemAdmin: false,

        getDecodedRoles: (idToken?: string) => {
          const token = idToken || authStore.getState().tokens.idToken

          if (token) {
            try {
              const { access_data: accessData } = jwtDecode<{ access_data: string }>(token)

              const binary = atob(accessData)
              const charData = binary.split('').map((char) => char.charCodeAt(0))
              const binData = new Uint8Array(charData)

              const decompressedRoles = pako.inflate(binData, { to: 'string' })
              const decodedRoles = JSON.parse(decompressedRoles)

              const systemRoles = decodedRoles?.roles?.login_unico as Record<string, string[]>

              if (systemRoles) {
                set({ allowedModules: Object.keys(systemRoles) })
              }

              set({ decodedRoles })
            } catch (error) {
              console.error('Failed decoding roles', error)
              set({ decodedRoles: null })
            }
          }
        },

        getPagePermissions: ({ moduleKey, roleKey }) => {
          const decodedRoles = get().decodedRoles

          if (!decodedRoles) return undefined

          try {
            const systemRoles = decodedRoles?.roles?.login_unico as Record<string, string[]>

            if (!systemRoles) {
              console.log('No system roles found')
              return undefined
            }

            const isSystemAdmin = systemRoles?.['*']?.includes('*')

            if (isSystemAdmin) {
              // console.log('System admin')
              set({ isSystemAdmin: true })
              return ['*']
            }

            const module = systemRoles?.[moduleKey]

            if (!module) {
              console.log('No module found')
              return undefined
            }

            const isModuleAdmin = module.includes('*') || module.includes('*:*')

            if (isModuleAdmin) {
              // console.log('Module admin')
              return ['*']
            }

            const modulePerms = module?.find((role) => role.startsWith(`${roleKey}:`))

            if (!modulePerms) {
              console.log('No module perms found')
              return undefined
            }

            const pagePerms = modulePerms
              .split(':')[1]
              .split(' ')
              .filter((char: string) => char.trim() !== '')
              .flat()

            return pagePerms
          } catch (err) {
            console.error('Error getting page permissions', err)
          }
        },

        clearRolesStore: () => {
          set({ decodedRoles: null, allowedModules: null, isSystemAdmin: false })
        },
      }
    },
    { name: 'roles-store' },
  ),
)

export const clearRolesStore = rolesStore.getState().clearRolesStore

export { rolesStore as useRolesStore }

export default rolesStore

import { Tabs } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 70px;
`

export const Title = styled(Tabs)`
  .ant-tabs-nav-list {
    cursor: pointer;
    pointer-events: none;
  }
`
